import React, { useMemo, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { MRT_Localization_ES } from 'material-react-table/locales/es/index.js';

import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import { Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { Box, IconButton } from '@mui/material';

import AuthUser from '../../pages/pagesAuth/AuthUser';

import Config from '../../Config';

import Swal from 'sweetalert2';
import { toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import { RotatingLines } from 'react-loader-spinner';

import { FaPlus, FaFileCsv } from 'react-icons/fa';
import CsvDownloader from 'react-csv-downloader';
import { IoCheckmarkSharp } from "react-icons/io5";
import { FaCopy } from "react-icons/fa6";

import { FaCircle } from "react-icons/fa";

import ModalAnadirProveedor from '../forms/Proveedor/ModalAnadirProveedor';
import ModalEditarProveedor from '../forms/Proveedor/ModalEditarProveedor';
import ProveedoresPdf from '../createPdf/Proveedores/ProveedoresPdf';
import ProveedoresExcel from '../createExcel/ProveedoresExcel/ProveedoresExcel';
//import CopyTable from '../../helpers/Copy/CopyTable';

export default function UserTable() {

    const { getRol } = AuthUser();

    //const [users, setUsers] = useState([]);
    const [proveedores, setProveedores] = useState([]);
    const [tableLoading, setTableLoading] = useState(true);

    //table state
    const [columnFilters, setColumnFilters] = useState([]);
    const [globalFilter, setGlobalFilter] = useState('');
    const [sorting, setSorting] = useState([]);
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 5,
    });
    const [rowCount, setRowCount] = useState(0);

    //Modal
    const [openAdd, setOpenAdd] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);

    //Botones
    const [btnCopiar, setBtnCopiar] = useState(false);

    //Cargar datos por ID en proveedores
    /* const [editRuc, setEditRuc] = useState('');
    const [editNombre, setEditNombre] = useState('');
    const [editDireccion, setEditDireccion] = useState('');
    const [editTelefono, setEditTelefono] = useState('');
    const [editEstado, setEditEstado] = useState(''); */

    const [editId, setEditId] = useState('');

    useEffect(() => {
        getProveedorAll();
    }, [
        pagination.pageIndex,
        pagination.pageSize,
        columnFilters,
        globalFilter,
        sorting
    ]);

    //Para actualizar luego de agregar o editar
    const updateProveedorList = async () => {
        await getProveedorAll();
    };

    const getProveedorAll = async () => {
        setTableLoading(true);

        let getProveedoresList;

        if (getRol() === "Administrador") {
            getProveedoresList = Config.getProveedoresListAdmin(
                pagination.pageIndex,
                pagination.pageSize,
                globalFilter,
                columnFilters,
                sorting
            );
        } else if (getRol() === "Gerente") {
            getProveedoresList = Config.getProveedoresLisGerente(
                pagination.pageIndex,
                pagination.pageSize,
                globalFilter,
                columnFilters,
                sorting
            );
        } else if (getRol() === "Almacenero") {
            getProveedoresList = Config.getProveedoresLisAlmacenero(
                pagination.pageIndex,
                pagination.pageSize,
                globalFilter,
                columnFilters,
                sorting
            );
        }

        try {
            const response = await getProveedoresList;
            // console.log(response);
            setRowCount(response.data.total);
            setProveedores(response.data.data);
            /* console.log(response); */
        } catch (error) {
            console.error("Error:", error);
        } finally {
            setTableLoading(false);
        }
    }

    const deleteOneProveedor = async (id) => {
        setTableLoading(true);
        let deleteOneProveedor;

        if (getRol() == "Administrador") {
            deleteOneProveedor = Config.deleteProveedorAdmin(id);
        } else if (getRol() == "Almacenero"){
            deleteOneProveedor = Config.deleteProveedorAlmacenero(id);
        }

        try {
            const response = await deleteOneProveedor;
            console.log(response);
            await getProveedorAll();
            return { status: response.status, message: response.data.message };
        } catch (error) {
            await getProveedorAll();
            console.error("Error:", error);
            if (error.response) {
                return { status: error.response.status, message: error.response.data.message };
            } else {
                return { status: 500, message: "Error desconocido. Vuelva a intentarlo." };
            }
        } finally {
            setTableLoading(false);
        }
    }

    /* const cargarProveedor = async (id) => {
        setLoadingData(true);
    
        try {
    
          let response;
    
          if (getRol() === "Administrador") {
            response = await Config.getOneProveedorAdmin(id);
          }
    
          if (response.data.exito == 0) {
            notify(`Error: ${response.data.message}`, "error");
          } else {
            setEditRuc(response.data.ruc);
            setEditNombre(response.data.nombre);
            setEditDireccion(response.data.direccion);
            setEditTelefono(response.data.telefono);
            setEditEstado(response.data.estado);
          }
    
        } catch (error) {
          console.error(error);
          notify("Ha ocurrido un error. Vuelva a intentarlo más tarde.", "error");
        } finally {
          setLoadingData(false);
        };
      } */

    const notify = (message, type) => {
        toast[type](message, {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
        });
    };

    //SweetAlert2
    const showSwal = async (id) => {
        Swal.fire({
            title: "¿Estás seguro de eliminar este proveedor?",
            text: "¡No podrás revertir esta acción!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#dc2626",
            cancelButtonColor: "#71717a",
            confirmButtonText: "Sí, eliminar"
        }).then(async (result) => {
            if (result.isConfirmed) {

                try {
                    let mensaje = await deleteOneProveedor(id);

                    let title = "";
                    let icon = "";
                    let text = mensaje.message;

                    console.log(mensaje);

                    if (mensaje.status === 200) {
                        title = "¡Eliminado!";
                        icon = "success";
                    } else {
                        title = "Error";
                        icon = "error";
                    }

                    notify(text, icon);

                    /* Swal.fire({
                        title: title,
                        text: text,
                        icon: icon,
                        confirmButtonColor: "#16a34a",
                    }); */
                } catch (error) {
                    console.error("Error eliminando el proveedor:", error);
                    notify("Ha ocurrido un error. Vuelva a intentarlo más tarde.", "error");
                }
            }
        });
    };

    /* const handleCopyTable = () => {
        const result = CopyTable('0', '8', 'Tabla de Proveedores', false);
        setBtnCopiar(result);
        setTimeout(() => {
            setBtnCopiar(false);
        }, 1000);
    }; */

    // COLUMNAS DE LA TABLA

    const columns = useMemo(
        () => [
            /* {
                id: "#",
                header: "Nro.",
                size: 1,
                Cell: ({ row }) => {
                    return row.index + 1;
                }
            }, */
            {
                accessorFn: (row) => row.numero_orden_proveedores,
                id: "numero_orden_proveedores",
                header: "Nro.",
                size: 5,
                enableSorting: false,
            },
            {
                accessorFn: (row) => row.nombre_proveedor,
                id: "nombre_proveedor",
                header: "Proveedor",
            }
            ,
            {
                accessorFn: (row) => row.ruc,
                id: "ruc",
                header: "RUC",
                /* Header: <i style={{ color: "red" }}>Correo Electrónico</i> //optional custom markup */
            },
            {
                accessorFn: (row) => row.direccion,
                id: "direccion",
                header: "Dirección",
                /* Header: <i style={{ color: "red" }}>Correo Electrónico</i> //optional custom markup */
            },
            {
                accessorFn: (row) => row.celular,
                id: "celular",
                header: "Celular",
                /* Header: <i style={{ color: "red" }}>Correo Electrónico</i> //optional custom markup */
            },
            {
                accessorFn: (row) => new Date(row.fecha_registro),
                id: "fecha_registro",
                header: "Fecha de Creación",
                filterVariant: 'datetime-range',
                Cell: ({ cell }) => {
                    const date = new Date(cell.getValue());
                    return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')} ${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}:${date.getSeconds().toString().padStart(2, '0')}`;
                },
            },
            {
                accessorFn: (row) => new Date(row.fecha_editado),
                id: "fecha_editado",
                header: "Fecha de Actualización",
                filterVariant: 'datetime-range',
                Cell: ({ cell }) => {
                    const date = new Date(cell.getValue());
                    return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')} ${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}:${date.getSeconds().toString().padStart(2, '0')}`;
                },
            },
            {
                accessorFn: (row) => row.estado_proveedor,
                id: "estado_proveedor",
                header: "Estado",
                size: 10,
                Cell: ({ cell }) => (
                    <Box
                        component="span"
                        sx={(theme) => ({
                            fontSize: "10px",
                            color: `${cell.getValue() == '1' ? 'green' : '#d63333'}`
                        })}
                        data_estado={`${cell.getValue() == '1' ? '1' : '0'}`}
                    >
                        <FaCircle />
                    </Box>
                ),
            },
        ],
        []
    );

    const data = (proveedores ? proveedores : []);

    const mostrarAccionesRol = () => {
        const rol = getRol();
        return rol === "Administrador" || rol === "Almacenero";
    };

    const table = useMaterialReactTable({
        columns,
        data,

        initialState: {
            showColumnFilters: false,
            density: 'compact'
        },

        muiPaginationProps: {
            rowsPerPageOptions: [5, 10, 15, 20, 50, 100, 500],
            showFirstButton: true,
            showLastButton: true,
          },

        manualFiltering: true,
        manualPagination: true,
        manualSorting: true,

        onColumnFiltersChange: setColumnFilters,
        onGlobalFilterChange: setGlobalFilter,
        onPaginationChange: setPagination,
        onSortingChange: setSorting,
        rowCount,

        state: {
            columnFilters,
            globalFilter,
            isLoading: tableLoading,
            pagination,
            sorting,
        },

        localization: MRT_Localization_ES,
        muiSearchTextFieldProps: {
            placeholder: 'Buscar todos los proveedores',
            sx: { minWidth: '300px' },
            variant: 'outlined',
        },

        enableRowActions: mostrarAccionesRol(),
        positionActionsColumn: mostrarAccionesRol() ? 'last' : undefined,

        renderRowActions: ({ row }) => (
            <Box>
                {getRol() === "Administrador" || getRol() === "Almacenero" ?
                    <>
                        <IconButton title='Editar' onClick={() => {
                            setEditId(row.original.id_proveedor);
                            setOpenEdit(true);
                            console.log("fila", row.original.id_proveedor);
                        }} color="primary">
                            <EditIcon />
                        </IconButton>
                        <IconButton title='Eliminar' onClick={() => {
                            showSwal(row.original.id_proveedor);
                            console.log("fila id: ", row.original.id_proveedor);
                        }} color="error">
                            <DeleteIcon />
                        </IconButton>
                    </>
                    : null}
            </Box>
        ),

        renderTopToolbarCustomActions: ({ table }) => (
            <Box
                sx={{
                    display: 'flex',
                    gap: '16px',
                    padding: '8px',
                    flexWrap: 'wrap',
                }}
            >
                {/* <div className=''>
                    <button className={`flex items-center px-2 py-1 border border-sky-600 rounded
                        bg-white text-sky-600 hover:bg-sky-600 hover:text-white
                        `}
                        style={{ width: '145px' }}
                        onClick={handleCopyTable}
                    >
                        {!btnCopiar ?
                            <><FaCopy className='me-3' /> Copiar Tabla</>
                            : <><IoCheckmarkSharp className='me-3' />Copiado</>}
                    </button>
                </div> */}

                <ProveedoresExcel proveedoresData={proveedores} />
                <ProveedoresPdf
                    proveedoresData={proveedores}
                />
            </Box>
        ),
    });

    return (
        <div>

            {getRol() != "Gerente" ?

                <div className='flex justify-start mb-5 flex-wrap'>
                    <button className='bg-[#D27313] hover:bg-[#cb6f13] rounded shadow-md text-white p-3 flex items-center'
                        onClick={() => { setOpenAdd(true); console.log(openAdd); }}
                    >
                        <FaPlus className='me-3' />
                        Añadir Proveedor
                    </button>
                </div>

                : null}

            <MaterialReactTable
                table={table}
            />

            <ModalAnadirProveedor
                open={openAdd}
                onClose={() => setOpenAdd(false)}
                updateProveedorList={updateProveedorList}
            />

            <ModalEditarProveedor
                open={openEdit}
                editId={editId}
                onClose={() => setOpenEdit(false)}
                updateProveedorList={updateProveedorList}
            />

        </div>
    )

}
