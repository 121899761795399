import Config from '../../Config';
import validator from 'validator';
import React, { useState } from 'react';
import { RotatingLines } from 'react-loader-spinner';
import { Link, useNavigate } from 'react-router-dom';

import Swal from 'sweetalert2';

export default function RecuperarContrasenia() {

    const navigate = useNavigate();

    const [email, setEmail] = useState('');

    const [message, setMessage] = useState('');
    const [emailError, setEmailError] = useState('');

    const [loading, setLoading] = useState(false);

    const handleEmail = (e) => {
        setEmail(e.target.value);
    }

    const handleSubmitRecover = async (e) => {
        e.preventDefault();
        setLoading(true);

        // Validar el email
        if (!validator.isEmail(email)) {
            setEmailError('Por favor ingrese un correo electrónico válido.');
            setLoading(false);
            return; // Salir de la función si el email no es válido
        } else {
            setEmailError('');
        }

        try {
            // Hacer la solicitud para recuperar la contraseña
            const response = await Config.postRecuperar({ email });

            // Almacenar el correo en sessionStorage
            sessionStorage.setItem('correo_recuperar', email);

            console.log(response);

            // Mostrar el mensaje de la respuesta
            setMessage(response.data.message);

            let tituloSwal = 'Enviado';
            let iconSwal = 'success';

            if (response.data.existe === "true" || response.data.error === '1') {
                tituloSwal = 'Error';
                iconSwal = 'error';
            }

            showSwalMessage(response.data.message, tituloSwal, iconSwal);

        } catch (error) {
            // Manejar errores de la solicitud
            console.error('Error:', error);
            showSwalMessage('Ha ocurrido un error al enviar el código de recuperación.', 'Error', 'error');
        } finally {
            // Asegurarse de que el estado de carga se detenga
            setLoading(false);
        }
    }

    const showSwalMessage = (mensaje, titulo, icono) => {
        Swal.fire({
            title: titulo,
            text: `${mensaje} Se le redirigirá al formulario de validación.`,
            icon: icono,
            confirmButtonColor: "#EB8927",
        }).then(() => {
            navigate('/validar-codigo', { state: { email: email } });
        });
    }

    return (
        <div>
            <div className='h-screen bg-gray-100 flex items-center justify-center'>
                <div className='bg-white w-96 p-6 rounded-md shadow-lg'>

                    <div className='text-center text-xl mb-2 text-gray-500 font-semibold'><h1>Recuperar Contraseña</h1></div>

                    <p className='text-gray-500 my-4 text-sm'>Ingrese su correo electrónico para recuperar su contraseña.</p>

                    {/* {message ? <p className='text-red-600 -mt-3 mb-1'>{message}</p> : ''} */}


                    <form>
                        <label className="text-gray-500 text-sm" htmlFor='email'>Correo Electrónico</label>
                        <input id="email"
                            className={`w-full py-2 bg-gray-50 text-gray-500 px-2 outline-none mb-1 rounded-md text-sm
                                    placeholder-slate-400
                                    focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500
                                    border ${emailError ? 'border-red-500' : 'border-slate-300'}`}
                            type="email"
                            value={email}
                            onChange={handleEmail}
                        />

                        {emailError && <p className="text-red-500 text-xs -mt-1 mb-2">{emailError}</p>}

                        {/* <div className='text-start mt-0 mb-5'>
                            <p className='text-gray-400 text-sm'>Si ya tiene un código, <Link to='/validar-codigo' className='text-blue-400 underline cursor-pointer'>ingrese aquí</Link> </p>
                        </div> */}


                        <button type="submit"
                            disabled={loading} // Desactiva el botón mientras se carga
                            className={`bg-[#EB8927] w-full mt-3
                                text-white py-2 rounded-md hover:bg-[#D27313]
                                transition-colors font-semibold leading-6
                                focus:ring-4 focus:outline-none focus:ring-[#EB8927] mx-auto block ${loading ? 'cursor-not-allowed opacity-50' : ''}`}
                            onClick={handleSubmitRecover}

                        >
                            {loading ?
                                <div className='flex items-center justify-center'>
                                    <RotatingLines
                                        strokeColor="white"
                                        strokeWidth='3'
                                        width='22'
                                        radius="5"
                                        ariaLabel="lines-loading"
                                    />
                                </div>
                                :
                                'Enviar Código'}
                        </button>



                        <hr className='mt-5 h-0.5 border-none bg-gray-200'></hr>

                        <div className='text-center mt-2'>
                            <p className='text-gray-400 text-sm'>¿Desea Ingresar al Sistema? <Link to='/' className='text-blue-400 underline cursor-pointer'>Iniciar Sesión</Link> </p>
                        </div>

                    </form>
                </div>
            </div>
        </div>
    )
}
