import React from 'react';
import { useMs } from '../../../../context/MsContext';
import ContactoEnviar from '../../../../components/contacto/ContactoEnviar';

export default function Contacto() {
    const { ms } = useMs();

    return (
        <div className={`${ms ? 'ms-4 transition-all' : 'ms-56 transition-all'} 
    mt-16 pt-2 pb-16 h-screen
    
    max-[768px]:${ms ? 'ms-2 transition-all' : 'ms-36 transition-all'}
    max-[480px]:mt-28
    `}>
            <ContactoEnviar/>
        </div>
    )
}
