import React, { useMemo, useState, useEffect } from 'react'
import { Link } from 'react-router-dom';

import { MRT_Localization_ES } from 'material-react-table/locales/es/index.js';

import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import { Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { Box, IconButton, Typography } from '@mui/material';

import AuthUser from '../../../pages/pagesAuth/AuthUser';
import Config from '../../../Config';

import Swal from 'sweetalert2';
import { toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import { RotatingLines } from 'react-loader-spinner';

import { FaPlus, FaFileCsv } from 'react-icons/fa';
/* import CsvDownloader from 'react-csv-downloader';
import UserPdf from '../createPdf/UserPdf'; */

import AddIcon from '@mui/icons-material/Add';
import MinusIcon from '@mui/icons-material/Remove';

import { FaCircle } from "react-icons/fa";
import ModalAnadirSalidaEquipo from '../../forms/MovimientoEquipo/SalidaEquipos/ModalAnadirSalidaEquipo';
import ModalEditarSalidaEquipo from '../../forms/MovimientoEquipo/SalidaEquipos/ModalEditarSalidaEquipo';

export default function SalidaEquiposTable() {

  const { getRol } = AuthUser();

  const [salidaEquipos, setSalidaEquipos] = useState([]);
  const [tableLoading, setTableLoading] = useState(true);

  //table state
  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 20,
  });
  const [rowCount, setRowCount] = useState(0);

  //Para las alertas
  const [equipoAlerta, setEquipoAlerta] = useState('0');

  // Constante para verificar si estamos en la página de herramientas
  const [isSalidaEquiposPage, setIsSalidaEquiposPage] = useState(false);

  //Modal
  const [openAdd, setOpenAdd] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);

  const [editId, setEditId] = useState('');

  //Para actualizar luego de agregar o editar
  const updateEquipoList = async () => {
    await getSalidaEquiposAll();
  };

  // Limpiar sessionStorage en cambios de página
  useEffect(() => {
    if (isSalidaEquiposPage) {
      sessionStorage.removeItem("equipos-salidas-bajo-stockBajoStock");
      setEquipoAlerta('0');
    }
  }, [isSalidaEquiposPage]); // Ejecuta cada vez que cambia la ubicación de la página

  useEffect(() => {
    getSalidaEquiposAll();
  }, [
    pagination.pageIndex,
    pagination.pageSize,
    columnFilters,
    globalFilter,
    sorting,
    equipoAlerta
  ]);

  const getSalidaEquiposAll = async () => {
    setTableLoading(true);

    // Leer códigos guardados en sessionStorage
    const codigosEquipos = JSON.parse(sessionStorage.getItem("equipos-salidas-bajo-stockBajoStock") || "[]");

    // Actualizar herramientaAlerta e herramientaAlertaArreglo
    setEquipoAlerta(codigosEquipos.length > 0 ? "1" : "0");

    let getSalidaEquiposList;

    if (getRol() === "Administrador") {
      getSalidaEquiposList = Config.getSalidaEquiposListAdmin(
        pagination.pageIndex,
        pagination.pageSize,
        globalFilter,
        columnFilters,
        sorting,
        equipoAlerta
      );
    } else if (getRol() === "Almacenero") {
      getSalidaEquiposList = Config.getSalidaEquiposListAlmacenero(
        pagination.pageIndex,
        pagination.pageSize,
        globalFilter,
        columnFilters,
        sorting,
        equipoAlerta
      );
    } else if (getRol() === "Gerente") {
      getSalidaEquiposList = Config.getSalidaEquiposListGerente(
        pagination.pageIndex,
        pagination.pageSize,
        globalFilter,
        columnFilters,
        sorting,
        equipoAlerta
      );
    } else if (getRol() === "Operario") {
      getSalidaEquiposList = Config.getSalidaEquiposListOperario(
        pagination.pageIndex,
        pagination.pageSize,
        globalFilter,
        columnFilters,
        sorting
      );
    }


    try {
      const response = await getSalidaEquiposList;

      if (response.data.exito == '0') {
        notify(response.data.message, 'error')
      } else {
        setSalidaEquipos(response.data.data);
        setRowCount(response.data.total);
        //setClientes(response.data);
        /* console.log(response); */
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setTableLoading(false);
    }
  }

  const deleteOneSalidaEquipo = async (id) => {
    setTableLoading(true);
    let deleteOneEquipo;

    if (getRol() === "Administrador") {
      deleteOneEquipo = Config.deleteOneSalidaEquipoAdmin(id);
    } else if (getRol() === "Almacenero") {
      deleteOneEquipo = Config.deleteOneSalidaEquipoAlmacenero(id);
    }

    try {
      const response = await deleteOneEquipo;
      console.log(response);
      await getSalidaEquiposAll();
      return { status: response.status, message: response.data.message };
    } catch (error) {
      await getSalidaEquiposAll();
      console.error("Error:", error);
      if (error.response) {
        return { status: error.response.status, message: error.response.data.message };
      } else {
        return { status: 500, message: `Error desconocido. Vuelva a intentarlo.` };
      }
    } finally {
      setTableLoading(false);
    }
  }

  const notify = (message, type) => {
    toast[type](message, {
      position: "bottom-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  };

  //SweetAlert2
  const showSwal = async (id) => {
    Swal.fire({
      title: "¿Estás seguro de eliminar esta salida de equipo?",
      text: "¡No podrás revertir esta acción!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#dc2626",
      cancelButtonColor: "#71717a",
      confirmButtonText: "Sí, eliminar"
    }).then(async (result) => {
      if (result.isConfirmed) {

        try {
          let mensaje = await deleteOneSalidaEquipo(id);

          let title = "";
          let icon = "";
          let text = mensaje.message;

          console.log(mensaje);

          if (mensaje.status === 200) {
            title = "¡Eliminado!";
            icon = "success";
          } else {
            title = "Error";
            icon = "error";
          }

          notify(text, icon);
        } catch (error) {
          console.error("Error eliminando el insumo:", error);
          setTableLoading(false);
          notify(`Ha ocurrido un error. Vuelva a intentarlo más tarde.`, "error");
        }
      }
    });
  }

  // COLUMNAS DE LA TABLA

  const columns = useMemo(
    () => [
      // {
      //    accessorFn: (row) => row.id,
      //    id: "id", //simple recommended way to define a column
      //    header: "ID",
      //    size: 10
      // /* muiTableHeadCellProps: { sx: { color: "green" } }, //custom props
      // Cell: ({ renderedCellValue }) => <strong>{renderedCellValue}</strong> //optional custom cell render */
      // },
      {
        id: "#",
        header: "Nro.",
        size: 1,
        Cell: ({ row }) => {
          return row.index + 1;
        }
      },
      {
        accessorFn: (row) => (row.equipo ? row.codigo_salida_equipo : '-'),
        id: "codigo_salida_equipo",
        header: "Código de Salida de Equ.",
        size: 180,
        minSize: 200, // Anchura mínima
      },
      {
        accessorFn: (row) => (row.equipo ? row.equipo.codigo_equipo : '-'),
        id: "codigo_equipo",
        header: "Cod. Equipo",
        size: 5
      },
      {
        accessorFn: (row) => (row.equipo ? row.equipo.nombre_equipo : '-'),
        id: "nombre_equipo",
        header: "Equipo",
        size: 5
      },
      {
        accessorFn: (row) => (row.equipo ? row.equipo.nro_serie_equipo : '-'),
        id: "nro_serie_equipo",
        header: "N° Serie",
        size: 1,
      },
      {
        accessorFn: (row) => row.cantidad_salida_equipo,
        id: "cantidad_salida_equipo",
        header: "Cantidad",
        size: 1,
      },
      {
        accessorFn: (row) => row.nro_documento_responsable_equipo_salida,
        id: "nro_documento_responsable_equipo_salida",
        header: "N° Doc.",
        size: 1,
      },
      {
        accessorFn: (row) => row.nombre_completo_responsable_equipo_salida,
        id: "nombre_completo_responsable_equipo_salida",
        header: "Nom. Responsable",
        size: 1,
      },
      {
        accessorFn: (row) => (row.correo_responsable_equipo_salida ? row.correo_responsable_equipo_salida : '-'),
        id: "correo_responsable_equipo_salida",
        header: "Correo Responsable",
        size: 1,
      },
      {
        accessorFn: (row) => row.proyecto_equipo_salida,
        id: "proyecto_equipo_salida",
        header: "En Proyecto",
        size: 1,
      },
      {
        accessorFn: (row) => new Date(row.fecha_salida_equipo),
        id: "fecha_salida_equipo",
        header: "Fecha de Salida",
        filterVariant: 'datetime-range',
        Cell: ({ cell }) => {
          const date = new Date(cell.getValue());
          return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')} ${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}:${date.getSeconds().toString().padStart(2, '0')}`;
        },
      },
      {
        accessorFn: (row) => (row.usuario ? row.usuario.name : '-'),
        id: "name",
        header: "Ingresado por:",
        size: 2
      },
      {
        accessorFn: (row) => (row.usuario ? row.usuario.email : '-'),
        id: "email",
        header: "Correo:",
        size: 2
      },
    ],
    []
  );

  const data = (salidaEquipos ? salidaEquipos : []);

  const mostrarAccionesRol = () => {
    const rol = getRol();
    return rol === "Administrador" || rol === "Almacenero";
  };

  const table = useMaterialReactTable({
    columns,
    data,

    enableExpandAll: false, //disable expand all button
    muiDetailPanelProps: () => ({
      sx: (theme) => ({
        backgroundColor:
          theme.palette.mode === 'dark'
            ? 'rgba(255,210,244,0.1)'
            : 'rgba(0,0,0,0.1)',
      }),
    }),
    //custom expand button rotation
    muiExpandButtonProps: ({ row, table }) => ({
      onClick: () => table.setExpanded({ [row.id]: !row.getIsExpanded() }), //only 1 detail panel open at a time
      sx: {
        transform: row.getIsExpanded() ? 'rotate(180deg)' : 'rotate(-90deg)',
        transition: 'transform 0.2s',
      },
    }),

    renderDetailPanel: ({ row }) =>
      row.original.usuario ? (
        <Box
          sx={{
            display: '',
            margin: 'auto',
            gridTemplateColumns: '1fr 1fr',
            width: '100%',
          }}
        >
          <Typography><span className='font-bold'>Ingresado por:</span></Typography>
          <Typography><span className='font-bold'>- Nombre:</span> {row.original.usuario.name}</Typography>
          <Typography><span className='font-bold'>- Correo:</span> {row.original.usuario.email}</Typography>
          <Typography><span className='font-bold'>- Rol:</span> {row.original.usuario.role.rol}</Typography>
          <Typography><p className='font-bold mt-3'>Datos del Responsable:</p></Typography>
          <Typography><span className='font-bold'>- Tip. Documento:</span> {row.original.tipo_documento.nombre_documento}</Typography>
          <Typography><span className='font-bold'>- N° Documento:</span> {row.original.nro_documento_responsable_equipo_salida}</Typography>
          <Typography><span className='font-bold'>- Nomb. Compl.:</span> {row.original.nombre_completo_responsable_equipo_salida}</Typography>
          <Typography><span className='font-bold'>- Correo:</span> {row.original.correo_responsable_equipo_salida}</Typography>
          <Typography><span className='font-bold'>- Proyecto:</span> {row.original.proyecto_equipo_salida}</Typography>
        </Box>
      ) : null,

    initialState: {
      showColumnFilters: false,
      density: 'compact',
      columnVisibility: {

      }
    },

    manualFiltering: true,
    manualPagination: true,
    manualSorting: true,

    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    rowCount,

    state: {
      columnFilters,
      globalFilter,
      isLoading: tableLoading,
      pagination,
      sorting,
    },

    localization: MRT_Localization_ES,
    muiSearchTextFieldProps: {
      placeholder: 'Buscar todas las salidas de equipos',
      sx: { minWidth: '300px' },
      variant: 'outlined',
    },
    enableRowActions: mostrarAccionesRol(),
    positionActionsColumn: mostrarAccionesRol() ? 'last' : undefined,

    renderRowActions: ({ row }) => (
      <Box>
        {getRol() === "Administrador" || getRol() === "Almacenero" ?
          <IconButton title='Editar' onClick={() => {
            setEditId(row.original.id_salida_equipo);
            setOpenEdit(true);
          }} color="primary">
            <EditIcon />
          </IconButton>
          : null}

        {getRol() === "Administrador" || getRol() === "Almacenero" ?
          <IconButton title='Eliminar' onClick={() => {
            showSwal(row.original.id_salida_equipo);
            /* console.log("fila id: ", row.original.id); */
          }}
            color="error">
            <DeleteIcon />
          </IconButton>
          : null}

      </Box>
    ),
  });

  return (
    <div>

      {getRol() == "Administrador" || getRol() == "Almacenero" ?

        <div className='flex justify-start mb-5 flex-wrap'>
          <button className='bg-[#D27313] hover:bg-[#cb6f13] rounded shadow-md text-white p-3 flex items-center'
            onClick={() => { setOpenAdd(true); console.log(openAdd); }}
          >
            <FaPlus className='me-3' />
            Añadir Salida de Equipo
          </button>
        </div>

        : null}

      {equipoAlerta === "1" ?
        <>
          <div className='bg-blue-400 rounded border border-blue-500 p-2 text-lg text-white mb-5'>
            Está visualizando sólo las salidas de los equipos con nulo stock para que pueda saber qué responsables tienen esos equipos en su posesión. Si desea eliminar este filtro de alerta,
            <span class="ms-2 bg-blue-800 text-white underline cursor-pointer py-1 px-2 rounded hover:bg-blue-900"
              onClick={(e) => { setIsSalidaEquiposPage(true) }}> haga click aquí </span>
          </div>
        </>
        :
        null}

      <div className='mb-20'>

        <MaterialReactTable
          table={table}
        />

      </div>

      {getRol() == "Administrador" || getRol() == "Almacenero" ?

        <>

          <ModalAnadirSalidaEquipo
            open={openAdd}
            onClose={() => setOpenAdd(false)}
            updateEquipoList={updateEquipoList}
          />

          <ModalEditarSalidaEquipo
            open={openEdit}
            editId={editId}
            onClose={() => setOpenEdit(false)}
            updateEquipoList={updateEquipoList}
          />

        </>

        : null}

    </div>
  )
}
