import React from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';

import 'moment/locale/es-mx';

export default function CalendarioHerramientas({ dataHerramientas }) {
    moment.locale("es-mx");
    moment.updateLocale("es-mx", {
        week: {
            dow: 1,
            doy: 4,
        },
    });

    const localizer = momentLocalizer(moment);

    const preprocessData = (data) => {
        return data.map(item => ({
            start: new Date(item.fecha),
            end: new Date(item.fecha),
            title: `${item.nombre_herramienta} - ${item.nro_serie_herramienta || '-sin n° serie-'} | Cantidad: ${item.quantity_general}`,
            es_ingreso: item.es_ingreso_herramienta,
        }));
    };

    const Calendario = ({ events }) => {
        const eventStyleGetter = (event) => {
            const backgroundColor = event.es_ingreso == 1 ? 'green' : event.es_ingreso == 0 ? 'red' : event.es_ingreso == 2 ? 'blue' : '';
            const style = {
                backgroundColor,
                borderRadius: '0px',
                opacity: 0.8,
                color: 'white',
                border: '0px',
                /* display: 'flex' */
            };
            return { style };
        };

        return (
            <Calendar
                localizer={localizer}
                events={events}
                
                style={{ height: 500 }}
                eventPropGetter={eventStyleGetter}
                messages={{
                    date: 'Fecha',
                    time: 'Hora',
                    event: 'Información de la Herramienta',
                    next: "sig",
                    previous: "ant",
                    today: "Hoy",
                    month: "Mes",
                    week: "Semana",
                    day: "Día",
                    noEventsInRange: 'No hay ingresos y/o salidas en este rango de fechas.',
                    
                }}
                popup
            />
        );
    };

    const events = preprocessData(dataHerramientas);

    return <Calendario events={events} />;
}
