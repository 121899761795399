export default function TextAreaForm({id, labelNameArea, value, funcion, errors, maxLength, labelWidth = 'w-20', row, cols}) {
    return (
      <div className='flex mb-3'>
          <label htmlFor={id} className={`${labelWidth} flex items-center font-normal text-[#454545]`}>{labelNameArea} </label>
          <textarea className={`w-full px-2 py-1 
          bg-[#F9F9F9] border border-[#D9D9D9] rounded-md
            focus:outline-none focus:border-[#339A5B] focus:ring-1 focus:ring-[#339A5B]
            ${errors ? 'border-red-500' : 'border-slate-300'}`}
            id={id}
            name={id}
            value={value}
            onChange={funcion}
            rows={row}
            cols={cols}
            required
            maxLength={maxLength}
          />
        </div>
    )
  }
  