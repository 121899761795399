import React, { useEffect } from 'react';
import AuthUser from '../../../pagesAuth/AuthUser';
import { useNavigate } from 'react-router-dom';

import { useMs } from '../../../../context/MsContext';

import 'react-toastify/dist/ReactToastify.css';
import ServiciosTable from '../../../../components/tables/ServiciosTable';

import { RiToolsFill } from "react-icons/ri";

export default function ServiciosList() {

    const { getRol } = AuthUser();
    const navigate = useNavigate();

    const { ms } = useMs();

    useEffect(() => {
        document.title = "SDI ICB | Servicios";
        if (getRol() != "Administrador" && getRol() != "Gerente" && getRol() != "Almacenero") {
            navigate('/usuario/dashboard');
        }
    }, []);

    return (
        <div className={`${ms ? 'ms-4 transition-all' : 'ms-56 transition-all'} 
      mt-16 me-4 pt-2 overflow-y-auto h-screen
      max-[768px]:${ms ? 'ms-2 transition-all' : 'ms-36 transition-all'}
      max-[480px]:mt-28`}>
            <h1 className='mb-4 text-2xl text-slate-500 font-semibold'>Gestión de Proyectos</h1>

            <div className='flex flex-col items-center justify-center mt-32'>
                <RiToolsFill className="text-8xl text-gray-400 mb-4" />
                <p className="text-3xl text-gray-500">En proceso de elaboración...</p>
            </div>

            {/* <ServiciosTable /> */}

        </div>
    )
}
