import React, { useMemo, useState, useEffect } from 'react'
import { Link } from 'react-router-dom';

import { MRT_Localization_ES } from 'material-react-table/locales/es/index.js';

import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import { Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { Box, IconButton, Typography } from '@mui/material';


import AuthUser from '../../../pages/pagesAuth/AuthUser';
import Config from '../../../Config';

import Swal from 'sweetalert2';
import { toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import { RotatingLines } from 'react-loader-spinner';

import { FaPlus, FaFileCsv } from 'react-icons/fa';

import { FaDownload } from "react-icons/fa6";

import { TbFileUpload } from "react-icons/tb";

export default function EquiposCertificacionesTable() {

    const { getRol } = AuthUser();

    const [equipos, setEquipos] = useState([]);
    const [tableLoading, setTableLoading] = useState(true);
    const [selectedFile, setSelectedFile] = useState(null);

    //table state
    const [columnFilters, setColumnFilters] = useState([]);
    const [globalFilter, setGlobalFilter] = useState('');
    const [sorting, setSorting] = useState([]);
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 5,
    });
    const [rowCount, setRowCount] = useState(0);

    //Modal
    const [openAdd, setOpenAdd] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);

    const [editId, setEditId] = useState('');

    //Filtrar por optimo, buen estado, desuso
    const [mostrarOptimo, setMostrarOptimo] = useState(0);
    const [mostrarBuenEstado, setMostrarBuenEstado] = useState(0);
    const [mostrarDesuso, setMostrarDesuso] = useState(0);


    //Para actualizar luego de agregar o editar
    const updateEquipoList = async () => {
        await getEquiposAll();
    };

    useEffect(() => {
        getEquiposAll();
    }, [
        pagination.pageIndex,
        pagination.pageSize,
        columnFilters,
        globalFilter,
        sorting,
        mostrarOptimo,
        mostrarBuenEstado,
        mostrarDesuso,
    ]);

    const getEquiposAll = async () => {
        setTableLoading(true);

        let getEquiposList;

        if (getRol() === "Administrador") {
            getEquiposList = Config.getEquiposCertificacionesListAdmin(
                pagination.pageIndex,
                pagination.pageSize,
                globalFilter,
                columnFilters,
                sorting,
                mostrarOptimo,
                mostrarBuenEstado,
                mostrarDesuso,
            );
        } else if (getRol() === "Almacenero") {
            getEquiposList = Config.getEquiposCertificacionesListAlmacenero(
                pagination.pageIndex,
                pagination.pageSize,
                globalFilter,
                columnFilters,
                sorting,
                mostrarOptimo,
                mostrarBuenEstado,
                mostrarDesuso,
            );
        } else if (getRol() === "Gerente") {
            getEquiposList = Config.getEquiposCertificacionesListGerente(
                pagination.pageIndex,
                pagination.pageSize,
                globalFilter,
                columnFilters,
                sorting,
                mostrarOptimo,
                mostrarBuenEstado,
                mostrarDesuso,
            );
        }

        try {
            const response = await getEquiposList;
            if (response.data.exito == '0') {
                notify(response.data.message, 'error')
            } else {
                console.log(response);
                setEquipos(response.data.data);
                setRowCount(response.data.total);
                //setClientes(response.data);
                /* console.log(response); */
            }
        } catch (error) {
            console.error("Error:", error);
        } finally {
            setTableLoading(false);
        }
    }

    const notify = (message, type) => {
        toast[type](message, {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
        });
    };

    const uploadFileToBackend = async (idEquipo, file) => {
        
        const maxFileSizeMB = 40;
        const maxFileSizeBytes = maxFileSizeMB * 1024 * 1024;
        
        // Validación del tamaño del archivo
        if (file.size > maxFileSizeBytes) {
            notify(`El archivo excede el tamaño máximo de ${maxFileSizeMB} MB.`, "error");
            return;
        }
        
        setTableLoading(true);
        const formData = new FormData();
        formData.append("file", file);

        try {
            let response;

            if (getRol() === "Administrador") {
                response = await Config.postSubirCertificadoEquipoAdmin(idEquipo, formData);
            } else if (getRol() === "Almacenero") {
                response = await Config.postSubirCertificadoEquipoAlmacenero(idEquipo, formData);
            }

            const data = await response.data;
            if (data.success) {
                notify("La certificación ha sido guardado correctamente.", "success");
                await getEquiposAll();
                //alert("Archivo subido con éxito.");
            } else {
                notify(data.message || "Ha ocurrido un error. Vuelva a intentarlo más tarde. Recuerde que el máximo de peso del archivo es de 40mb", "error");
                await getEquiposAll();
            }
        } catch (error) {
            console.error("Error en la solicitud:", error);
            const errorMessage = error.response?.data?.message || "Sólo se aceptan documentos pdf de 40mb como máximo. Vuelva a intentarlo más tarde.";
            notify(errorMessage, "error");
            await getEquiposAll();
        } finally {
            setTableLoading(false);
        }
    };


    const handleFileChange = (idEquipo, event) => {
        const file = event.target.files[0];
        if (file && file.type === "application/pdf") {
            setSelectedFile(file);
            uploadFileToBackend(idEquipo, file); // Llama a la función para enviar el archivo
        } else {
            notify("Por favor, selecciona un archivo PDF.", "error");
            //alert("Por favor, selecciona un archivo PDF.");
        }
    };

    // COLUMNAS DE LA TABLA

    const columns = useMemo(
        () => [
            {
                id: "#",
                header: "Nro.",
                size: 1,
                Cell: ({ row }) => {
                    return row.index + 1;
                }
            },
            {
                accessorFn: (row) => (row.proveedor ? row.proveedor.nombre_proveedor : '-'),
                id: "nombre_proveedor",
                header: "Proveedor",
                size: 5
            },
            {
                accessorFn: (row) => row.nombre_equipo,
                id: "nombre_equipo",
                header: "Herramienta",
                size: 5
            },
            {
                accessorFn: (row) => row.codigo_equipo,
                id: "codigo_equipo",
                header: "Código",
                size: 1,
            },
            {
                accessorFn: (row) => (row.nro_serie_equipo ? row.nro_serie_equipo : '-'),
                id: "nro_serie_equipo",
                header: "N° de serie",
                size: 1,
            },
            {
                accessorFn: (row) => row.tiempo_vida_inicial,
                id: "tiempo_vida_inicial",
                header: "F. Vida Inicial",
                size: 1,
            },
            {
                accessorFn: (row) => row.tiempo_vida_final,
                id: "tiempo_vida_final",
                header: "F. Vida Final",
                size: 1,
            },
            {
                accessorFn: (row) => row.estado_equipo,
                id: "estado_equipo",
                header: "Estado Equipo",
                size: 1,
                Cell: ({ cell }) => (
                    <Box
                        component="span"
                        sx={(theme) => ({
                            backgroundColor:
                                cell.getValue() == "Óptimo"
                                    ? theme.palette.success.dark
                                    : cell.getValue() == "Buen Estado"
                                        ? theme.palette.warning.dark
                                        : theme.palette.error.dark,
                            borderRadius: '0.25rem',
                            color: "white",
                            p: "0.25rem"
                        })}
                    >
                        {cell.getValue()}
                    </Box>
                ),
            },
            {
                accessorFn: (row) => row.url_descarga,
                id: "url_descarga",
                header: "Certificado",
                size: 1,
                enableColumnFilter: false,
                enableSorting: false,
                Cell: ({ cell }) => {
                    const filePath = cell.getValue();

                    return (
                        <Box
                            component="span"
                            sx={(theme) => ({
                                backgroundColor:
                                    !filePath ? theme.palette.grey[600] : '',
                                borderRadius: '0.25rem',
                                color: "white",
                                p: "0.25rem",
                                height: '20px',
                                display: 'flex',
                                alignItems: 'center', // Alinea el contenido verticalmente
                                justifyContent: 'start', // Centra el contenido horizontalmente
                                width: '115px'
                            })}
                        >
                            {
                                !filePath ? (
                                    "Sin Certificación"
                                ) : (
                                    <a
                                        href={filePath}
                                        //download
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className='flex items-center justify-start text-blue-600 underline cursor-pointer hover:text-blue-800'
                                    >
                                        <FaDownload />
                                        Descargar
                                    </a>
                                )
                            }
                        </Box>
                    );
                },
            },
            {
                accessorFn: (row) => row,
                id: "row",
                header: "Subir Certificado",
                size: 1,
                enableColumnFilter: false,
                enableSorting: false,
                Cell: ({ cell, row }) => (
                    <Box
                        component="span"
                        sx={(theme) => ({
                            //backgroundColor: "gray",
                            borderRadius: '0.25rem',
                            color: "#2d6acc",
                            p: "0.15rem",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            cursor: "pointer",
                            width: "124px",
                            height: "24px",
                            border: "1px solid #2d6acc",
                            "&:hover": {
                                color: "#4285F4",
                                border: "1px solid #4285F4",
                            },
                        })}
                        // onClick={() => {
                        //     alert(`ID de la fila: ${row.original.id_equipo}`);
                        // }}
                        onClick={() => document.getElementById(`file-input-${row.original.id_equipo}`).click()}
                    >
                        <TbFileUpload style={{ fontSize: "22px" }} />
                        <input
                            type="file"
                            id={`file-input-${row.original.id_equipo}`}
                            style={{ display: "none" }}
                            accept="application/pdf"
                            onChange={(event) => handleFileChange(row.original.id_equipo, event)}
                        />
                    </Box>
                ),
            },
            //   {
            //     accessorFn: (row) => row.estado,
            //     id: "estado",
            //     header: "Estado",
            //     size: 10,
            //     Cell: ({ cell }) => (
            //       <Box
            //         component="span"
            //         sx={(theme) => ({
            //           fontSize: "10px",
            //           color: `${cell.getValue() == '1' ? 'green' : '#d63333'}`,
            //         })}
            //       >
            //         <FaCircle />
            //       </Box>
            //     ),
            //   },
        ],
        []
    );

    const data = (equipos ? equipos : []);

    const mostrarAccionesRol = () => {
        const rol = getRol();
        return rol === "Administrador" || rol === "Almacenero";
    };

    const table = useMaterialReactTable({
        columns,
        data,

        enableExpandAll: false, //disable expand all button

        muiDetailPanelProps: () => ({
            sx: (theme) => ({
                backgroundColor:
                    theme.palette.mode === 'dark'
                        ? 'rgba(255,210,244,0.1)'
                        : 'rgba(0,0,0,0.1)',
            }),
        }),
        //custom expand button rotation
        muiExpandButtonProps: ({ row, table }) => ({
            onClick: () => table.setExpanded({ [row.id]: !row.getIsExpanded() }), //only 1 detail panel open at a time
            sx: {
                transform: row.getIsExpanded() ? 'rotate(180deg)' : 'rotate(-90deg)',
                transition: 'transform 0.2s',
            },
        }),

        renderDetailPanel: ({ row }) =>
            row.original.proveedor ? (
                <Box
                    sx={{
                        display: '',
                        margin: 'auto',
                        gridTemplateColumns: '1fr 1fr',
                        width: '100%',
                    }}
                >
                    <Typography><span className='font-bold'>Proveedor:</span></Typography>
                    <Typography><span className='font-bold'>- Nombre:</span> {row.original.proveedor.nombre_proveedor}</Typography>
                    {/* <Typography><span className='font-bold'>- Correo:</span> {row.original.usuario.email}</Typography>
          <Typography><span className='font-bold'>- Rol:</span> {row.original.usuario.role.rol}</Typography> */}
                </Box>
            ) : null,

        initialState: {
            showColumnFilters: false,
            density: 'compact',
            columnVisibility: {
                nombre_proveedor: false,
            }
        },

        muiPaginationProps: {
            rowsPerPageOptions: [5, 10, 15, 20, 50, 100, 500],
            showFirstButton: true,
            showLastButton: true,
        },

        manualFiltering: true,
        manualPagination: true,
        manualSorting: true,

        onColumnFiltersChange: setColumnFilters,
        onGlobalFilterChange: setGlobalFilter,
        onPaginationChange: setPagination,
        onSortingChange: setSorting,
        rowCount,

        state: {
            columnFilters,
            globalFilter,
            isLoading: tableLoading,
            pagination,
            sorting,
        },

        localization: MRT_Localization_ES,
        muiSearchTextFieldProps: {
            placeholder: 'Buscar todos los equipos',
            sx: { minWidth: '300px' },
            variant: 'outlined',
        },

        // enableRowActions: mostrarAccionesRol(),
        // positionActionsColumn: mostrarAccionesRol() ? 'last' : undefined,

        // renderRowActions: ({ row }) => (
        //   <Box>
        //     <IconButton title='Editar' onClick={() => {
        //       setEditId(row.original.id_equipo);
        //       setOpenEdit(true);
        //       console.log("fila", row.original.id_equipo);
        //     }} color="primary">
        //       <EditIcon />
        //     </IconButton>

        //     {getRol() == "Administrador" || getRol() == "Almacenero" ?
        //       <IconButton title='Eliminar' onClick={() => {
        //         showSwal(row.original.id_equipo);
        //         /* console.log("fila id: ", row.original.id); */
        //       }}
        //         color="error">
        //         <DeleteIcon />
        //       </IconButton>
        //       : null}

        //   </Box>
        // ),

        // renderTopToolbarCustomActions: ({ table }) => (
        //   <Box
        //     sx={{
        //       display: 'flex',
        //       gap: '16px',
        //       padding: '8px',
        //       flexWrap: 'wrap',
        //     }}
        //   >
        //     <Box sx={{ display: 'flex', gap: '16px' }}>

        //       {getRol() === "Administrador" || getRol() === "Gerente" ?

        //         <>
        //           <ProductosExcel productosData={equipos} />
        //           <ProductosPdf productosData={equipos} />
        //         </>

        //         : null}

        //     </Box>
        //   </Box>
        // ),

    });

    return (
        <div>

            <div className='mb-20'>

                <MaterialReactTable
                    table={table}
                />

            </div>


        </div>
    )
}
