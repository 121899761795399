export default function Modal({ open, onClose, children }) {
  return (
    <div
      className={`
        fixed inset-0 flex justify-center items-center
        transition
        ${open ? "z-50 visible bg-black/20" : "transition-all invisible -z-50"}
    `}

      style={{ overflow: 'auto' }}
    >

      <div
        /* onClick={(e) => e.stopPropagation()} */
        className={`bg-white rounded-xl shadow p-6 transition-all modal-content
        ${open ? "scale-100 opacity-100" : "scale-125 opacity-0"}`}
        style={{ maxHeight: '90vh', maxWidth: '90vw', overflow: 'auto' }}
      >

        <button onClick={onClose}
          className="absolute top-2 right-2 p-1 rounded-lg
            text-gray-400 bg-white hover:bg-gray-50
            hover:text-gray-600">X</button>

        <div className="content-wrapper" style={{ overflow: 'auto' }}>
          {children}
        </div>
        
      </div>
    </div>
  )
}
