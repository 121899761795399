import React, { useMemo, useState, useEffect } from 'react'
import { Link } from 'react-router-dom';

import { MRT_Localization_ES } from 'material-react-table/locales/es/index.js';

import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import { Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { Box, IconButton, Typography, TextField } from '@mui/material';

import AuthUser from '../../../pages/pagesAuth/AuthUser';
import Config from '../../../Config';

import { toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import { RotatingLines } from 'react-loader-spinner';

import AddIcon from '@mui/icons-material/Add';
import MinusIcon from '@mui/icons-material/Remove';
import { FaPlus } from "react-icons/fa";

import { FaCircle } from "react-icons/fa";
import DOMPurify from 'dompurify';

export default function LogsGeneralTable() {

    const { getRol } = AuthUser();

    const [logsGenerales, setLogsGenerales] = useState([]);
    const [tableLoading, setTableLoading] = useState(true);

    //table state
    const [columnFilters, setColumnFilters] = useState([]);
    const [globalFilter, setGlobalFilter] = useState('');
    const [sorting, setSorting] = useState([]);
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 15,
    });
    const [rowCount, setRowCount] = useState(0);

    // Función para formatear la fecha
    const formatFechaForDB = (dateString) => {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = "00";
        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    };

    const now = new Date();
    const nowLocalString = formatFechaForDB(now);

    // Fecha inicial: primer día del mes actual
    const fechaInicialDefault = new Date(now.getFullYear(), now.getMonth(), 1);

    const [fechaInicial, setFechaInicial] = useState(formatFechaForDB(fechaInicialDefault));
    const [fechaFinal, setFechaFinal] = useState(formatFechaForDB(now));

    //Filtrar por ingresos y/o salidas
    const [mostrarIngreso, setMostrarIngreso] = useState(0);
    const [mostrarSalida, setMostrarSalida] = useState(0);

    const [openDevolverModal, setOpenDevolverModal] = useState(false);
    const [openBuscarSalidaInsuModal, setOpenBuscarSalidaInsuModal] = useState(false);
    const [editId, setEditId] = useState('');

    const [errors, setErrors] = useState({});

    const renderErrorMessages = (messages) => {
        return messages.map((msg, index) => (
            <div key={index} style={{ marginLeft: '100px', marginTop: '12px' }}>
                <p className="text-red-500 text-sm -mt-3 mb-3">{msg}</p>
            </div>
        ));
    };

    //Para actualizar luego de agregar o editar
    const updateKardexInsumoList = async () => {
        await getKardexInsumosAll();
    };

    useEffect(() => {
        getKardexInsumosAll();
    }, [
        pagination.pageIndex,
        pagination.pageSize,
        columnFilters,
        globalFilter,
        fechaInicial,
        fechaFinal,
    ]);

    const getKardexInsumosAll = async () => {
        setTableLoading(true);

        let getLogsGeneralList;

        setErrors({});

        if (getRol() == "Administrador") {
            getLogsGeneralList = Config.getLogsGeneralesListAdmin(
                pagination.pageIndex,
                pagination.pageSize,
                globalFilter,
                columnFilters,
                fechaInicial,
                fechaFinal,
            );
        } else if (getRol() === "Gerente") {
            getLogsGeneralList = Config.getLogsGeneralesListGerente(
                pagination.pageIndex,
                pagination.pageSize,
                globalFilter,
                columnFilters,
                fechaInicial,
                fechaFinal,
            );
        }

        try {
            const response = await getLogsGeneralList;
            console.log(response);

            if (response.data.errors) {
                setErrors(response.data.errors);
            }

            if (response.data.exito == '0') {
                notify(response.data.message, 'error')
            } else {
                setLogsGenerales(response.data.data);
                setRowCount(response.data.total);
            }
        } catch (error) {
            console.log(error);
            console.error("Error:", error);
        } finally {
            setTableLoading(false);
        }
    }

    const notify = (message, type) => {
        toast[type](message, {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
        });
    };



    // COLUMNAS DE LA TABLA

    const columns = useMemo(
        () => [
            {
                id: "#",
                header: "Nro.",
                size: 1,
                Cell: ({ row }) => {
                    return row.index + 1;
                }
            },
            {
                accessorFn: (row) => (row.movimiento_log ? row.movimiento_log : '-'),
                id: "movimiento_log",
                header: "Movimiento",
                size: 5
            },
            {
                accessorFn: (row) => (row.descripcion_log ? row.descripcion_log : '-'),
                id: "descripcion_log",
                header: "Descripción",
                size: 5
            },
            {
                accessorFn: (row) => new Date(row.fecha_hora_registro_log),
                id: "fecha_hora_registro_log",
                header: "Fecha de Registro",
                filterVariant: 'datetime-range',
                Cell: ({ cell }) => {
                    const date = new Date(cell.getValue());
                    return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')} ${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}:${date.getSeconds().toString().padStart(2, '0')}`;
                },
            },
            {
                accessorFn: (row) => (row.usuario ? row.usuario.name : '-'),
                id: "name",
                header: "Usuario Responsable:",
                size: 2
            },
            {
                accessorFn: (row) => (row.usuario ? row.usuario.email : '-'),
                id: "email",
                header: "Correo:",
                size: 2
            },
            {
                accessorFn: (row) => (row.usuario ? row.usuario.role.rol : '-'),
                id: "rol",
                header: "Rol:",
                size: 2,
                Cell: ({ cell }) => (
                    <Box
                        component="span"
                        sx={(theme) => ({
                            backgroundColor:
                                cell.getValue() == "Administrador"
                                    ? theme.palette.success.dark
                                    : cell.getValue() == "Gerente"
                                        ? theme.palette.info.dark
                                        : theme.palette.secondary.dark,
                            borderRadius: '0.25rem',
                            color: "white",
                            p: "0.25rem"
                        })}
                    >
                        {cell.getValue() == "Administrador" ? "Admin" : cell.getValue()}
                    </Box>
                )
            },
        ],
        []
    );

    const data = (logsGenerales ? logsGenerales : []);

    const table = useMaterialReactTable({
        columns,
        data,

        enableStickyHeader: true,
        enableStickyFooter: true,
        muiTableContainerProps: { sx: { maxHeight: '600px' } },

        muiPaginationProps: {
            rowsPerPageOptions: [5, 10, 15, 20, 50, 100, 500, 1000],
            showFirstButton: true,
            showLastButton: true,
        },

        enableSorting: false,
        enableColumnFilters: true,

        enableExpandAll: false, //disable expand all button
        muiDetailPanelProps: () => ({
            sx: (theme) => ({
                backgroundColor:
                    theme.palette.mode === 'dark'
                        ? 'rgba(255,210,244,0.1)'
                        : 'rgba(0,0,0,0.1)',
            }),
        }),

        //custom expand button rotation
        muiExpandButtonProps: ({ row, table }) => ({
            onClick: () => table.setExpanded({ [row.id]: !row.getIsExpanded() }), //only 1 detail panel open at a time
            sx: {
                transform: row.getIsExpanded() ? 'rotate(180deg)' : 'rotate(-90deg)',
                transition: 'transform 0.2s',
            },
        }),

        renderDetailPanel: ({ row }) =>
            row.original ? (
                <Box
                    sx={{
                        display: '',
                        margin: 'auto',
                        gridTemplateColumns: '1fr 1fr',
                        width: '100%',
                    }}
                >
                    <Typography>
                        <span className='font-bold mb-5'>- Descripción: </span>
                        {row.original.descripcion_log ? (
                            <span
                                dangerouslySetInnerHTML={{
                                    __html: DOMPurify.sanitize(row.original.descripcion_log),
                                }}
                            ></span>
                        ) : (
                            '-Sin Descripción-'
                        )}
                    </Typography>
                    <Typography><span className='font-bold'>Ingresado por:</span></Typography>
                    <Typography><span className='font-bold'>- Nombre:</span> {row.original.usuario ? row.original.usuario.name : "-"}</Typography>
                    <Typography><span className='font-bold'>- Correo:</span> {row.original.usuario ? row.original.usuario.email : '-'}</Typography>
                    <Typography><span className='font-bold'>- Rol:</span> {row.original.usuario ? row.original.usuario.role.rol : '-'}</Typography>
                </Box>
            ) : null,

        initialState: {
            showColumnFilters: false,
            density: 'compact',
            columnVisibility: {
                // movimiento_log: false,
                descripcion_log: false,
            },
        },

        manualFiltering: true,
        manualPagination: true,
        manualSorting: true,

        onColumnFiltersChange: setColumnFilters,
        onGlobalFilterChange: setGlobalFilter,
        onPaginationChange: setPagination,
        onSortingChange: setSorting,
        rowCount,

        state: {
            columnFilters,
            globalFilter,
            isLoading: tableLoading,
            pagination,
            sorting,
        },

        localization: MRT_Localization_ES,
        muiSearchTextFieldProps: {
            placeholder: 'Buscar todos los registros log',
            sx: { minWidth: '300px' },
            variant: 'outlined',
        },

        renderTopToolbarCustomActions: ({ table }) => (
            <Box
                sx={{
                    display: 'flex',
                    gap: '16px',
                    padding: '8px',
                    flexWrap: 'wrap',
                    flexDirection: 'column'
                }}
            >
                {/* <Box sx={{ display: 'flex', gap: '16px' }}>

                    {getRol() === "Administrador" || getRol() === "Gerente" ?

                        <>
                            <KardexInsumosExcel kardexInsumosData={kardexInsumos} />
                            <KardexInsumosPdf kardexInsumosData={kardexInsumos} />
                        </>

                        : null}

                </Box> */}

                <Box sx={{
                    display: 'flex',
                    padding: '0px',
                    paddingX: '8px',
                    alignItems: 'start',
                    justifyContent: 'center',
                    marginTop: '-5px',
                    marginBottom: '10px',
                    flexDirection: 'column'
                }}>
                    <p variant="subtitle1" className='flex items-center justify-center mb-2 underline'>Filtro por fechas: </p>
                    <Box sx={{ display: 'flex', gap: '16px', borderTopRightRadius: '8px', borderBottomRightRadius: '8px', }}>
                        <div className='flex flex-col'>

                            <div className='flex'>
                                <label htmlFor="date_picker" className={`w-56 flex items-center font-normal text-[#454545] text-sm`} style={{ width: "100px" }}>Fecha de Ini.:</label>
                                <input
                                    value={fechaInicial || ""}
                                    onChange={(e) => { setFechaInicial(formatFechaForDB(e.target.value)); console.log(formatFechaForDB(e.target.value)); }}

                                    disabled={tableLoading}

                                    type="datetime-local"
                                    max={fechaFinal}
                                    className={`px-2 py-0 bg-[#F9F9F9] border border-[#D9D9D9] text-sm
              rounded-md focus:outline-none focus:border-[#339A5B] focus:ring-1 focus:ring-[#339A5B] 
              ${tableLoading ? 'opacity-50 cursor-not-allowed' : ''}
              ${errors.fecha_inicial ? 'border-red-500' : 'border-slate-300'}`}
                                />
                            </div>

                            {errors.fecha_inicial && Array.isArray(errors.fecha_inicial) && renderErrorMessages(errors.fecha_inicial)}
                        </div>

                        <div className='flex flex-col'>

                            <div className='flex'>
                                <label htmlFor="date_picker" className={`w-56 flex items-center font-normal text-[#454545] text-sm`} style={{ width: "100px" }}>Fecha Fin.:</label>
                                <input
                                    value={fechaFinal || ""}
                                    onChange={(e) => { setFechaFinal(formatFechaForDB(e.target.value)); console.log(formatFechaForDB(e.target.value)) }}

                                    disabled={tableLoading}

                                    type="datetime-local"
                                    min={fechaInicial}
                                    max={nowLocalString}
                                    className={`px-2 py-0 bg-[#F9F9F9] border border-[#D9D9D9] text-sm
                rounded-md focus:outline-none focus:border-[#339A5B] focus:ring-1 focus:ring-[#339A5B] 
                ${tableLoading ? 'opacity-50 cursor-not-allowed' : ''}
                ${errors.fecha_final ? 'border-red-500' : 'border-slate-300'}`}
                                />
                            </div>

                            <div>
                                {errors.fecha_final && Array.isArray(errors.fecha_final) && renderErrorMessages(errors.fecha_final)}
                            </div>
                        </div>
                    </Box>

                    {/* <p variant="subtitle1" className='flex items-center justify-center mb-1 mt-2 underline'>Filtro por Movimiento (Ingresos y/o Salidas): </p>
                    <Box sx={{
                        display: 'flex', gap: '16px', borderTopRightRadius: '8px',
                        borderBottomRightRadius: '8px', marginTop: '8px',
                        alignItems: 'center', alignContent: 'center', justifyContent: 'center'
                    }}>
                        <div className="flex items-center">
                            <label className={`pe-2 text-sm select-none ${tableLoading ? 'cursor-not-allowed opacity-50' : 'cursor-pointer'}`} htmlFor='mostrar_ingresos'>Mostrar sólo Ingresos: </label>
                            <input type="checkbox"
                                id="mostrar_ingresos"
                                className={`${tableLoading ? 'cursor-not-allowed opacity-50' : 'cursor-pointer'}`}
                                disabled={tableLoading}
                                onChange={(e) => { setMostrarIngreso(e.target.checked ? 1 : 0); }} />
                        </div>

                        <div className="flex items-center">
                            <label className={`pe-2 text-sm select-none ${tableLoading ? 'cursor-not-allowed opacity-50' : 'cursor-pointer'}`} htmlFor='mostrar_salidas'>Mostrar sólo Salidas: </label>
                            <input type="checkbox"
                                id="mostrar_salidas"
                                className={`${tableLoading ? 'cursor-not-allowed opacity-50' : 'cursor-pointer'}`}
                                disabled={tableLoading}
                                onChange={(e) => { setMostrarSalida(e.target.checked ? 1 : 0); }} />
                        </div>
                    </Box> */}


                </Box>
            </Box>
        ),

    });

    return (
        <div>

            <div className='mb-20'>

                <MaterialReactTable
                    table={table}
                />

            </div>

        </div>
    )
}
