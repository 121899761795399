export default function InputForm({id, labelName, inputType, value, funcion, errors, maxLength, labelWidth = 'w-20', 
                                    isDisabled = false, bgColor = 'bg-[#F9F9F9]', inputWidth = 'w-full', minZero = false, 
                                    onFocus, onKeyUp,
                                    inputkey,
                                    inputStyleWidth
                                    }) {

  const handleChange = (e) => {
    if (inputType === 'number') {
      const newValue = e.target.value;
      if (newValue === '' || Number(newValue) >= 1) {
        funcion(e);
      }
    } else {
      funcion(e);
    }
  };
  
  return (
    <div className='flex mb-3'>
        <label htmlFor={id} className={`${labelWidth} flex items-center font-normal text-[#454545]`}>{labelName} </label>
        <input type={inputType} className={`${inputWidth} px-2 py-1 
        ${bgColor} border border-[#D9D9D9] rounded-md
          focus:outline-none focus:border-[#EB8927] focus:ring-1 focus:ring-[#EB8927]
          ${errors ? 'border-red-500' : 'border-slate-300'}`}
          key={inputkey}
          id={id}
          name={id}
          value={value}
          onChange={funcion}
          onFocus={onFocus}
          onKeyUp={onKeyUp}
          required
          maxLength={maxLength}
          {...(inputType === 'number' ? { min: 0 } : {})}
          disabled={isDisabled}
          style={{ marginLeft: inputStyleWidth }}
        />
      </div>
  )
}
