import React, { useState, useEffect } from 'react';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

import Swal from 'sweetalert2';

import { RotatingLines } from 'react-loader-spinner';

import { FaFilePdf } from 'react-icons/fa';

export default function InsumosPdf({ insumosData }) {

    const [loadingExportPdf, setLoadingExportPdf] = useState(false);

    const dateFormatter = (dateString) => {
        const parsedDate = new Date(dateString).toLocaleString('es', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
            hour12: true
        });
        return parsedDate;
    };

    const customDateFormatter = (date) => {
        const months = ['enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'];
        const day = date.getDate();
        const month = months[date.getMonth()];
        const year = date.getFullYear();
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        const seconds = date.getSeconds().toString().padStart(2, '0');
        const ampm = date.getHours() >= 12 ? 'p. m.' : 'a. m.';
        return `${day} de ${month} del ${year} a las ${hours}:${minutes}:${seconds} ${ampm}`;
        //return `${day} de ${month} del ${year}, ${hours}:${minutes}:${seconds}`;
    };

    const handleGeneratePdf = async () => {
        try {
            setLoadingExportPdf(true);

            const data2 = insumosData;
            const currentDateTime = customDateFormatter(new Date());

            // Luego de obtener los datos actualizados, genera el PDF
            const doc = new jsPDF('landscape', 'mm', 'A4', 0);
            let imgData = '/img/logo/logo_hori_menu.png';

            const getEstadoStyle = (estado) => {
                return estado == 1
                    ? { text: 'Con Stock', style: { textColor: [255, 255, 255], fillColor: [76, 175, 80] } }
                    : { text: 'Sin Stock', style: { textColor: [255, 255, 255], fillColor: [211, 47, 47] } };
            };

            //doc.addImage(imgData, 'PNG', 5, 1, 17, 27);
            const title1 = "Empresa ICB Industrial | RUC: 20330684411";
            const title2 = "Reporte de Insumos";
            const subtitule = `Generado el: ${currentDateTime}`;
            const padding = 13;

            const addHeadersAndFooters = (data) => {
                // Add logo
                doc.addImage(imgData, 'PNG', 5, 1, 40, 20);

                // Add title1
                doc.setTextColor('#ca7521').setFont(undefined, 'bold').setFontSize(12);
                let title1Width = doc.getTextWidth(title1);
                let title1Center = (doc.internal.pageSize.width / 2) - (title1Width / 2);
                doc.text(title1, title1Center, padding);

                // Add title2
                let title2Width = doc.getTextWidth(title2);
                let title2Center = (doc.internal.pageSize.width / 2) - (title2Width / 2);
                doc.text(title2, title2Center, padding + 6);

                // Add subtitule
                doc.setTextColor('#ca7521').setFont(undefined, 'normal').setFontSize(9);
                doc.text(subtitule, doc.internal.pageSize.width - doc.getTextWidth(subtitule) - 10, padding - 6);


            };

            //const pageCount = Math.ceil(data2.length / 10);

            doc.autoTable({
                head: [['Nro.', 'Categoría', 'Proveedor', 'Insumo', 'Código', 'Marca', 'Descripción', 'Color', 'Ingreso', 'Salida', 'Saldo', 'Estado']],
                body: data2.map((val, i) => {
                    const estado = getEstadoStyle(val.estado);
                    return [
                        i + 1,
                        val.categoria_insumo ? val.categoria_insumo.nombre_categoria_insumo : '-',
                        val.proveedor ? val.proveedor.nombre_proveedor : '-',
                        val.nombre,
                        val.codigo ? val.codigo : '-',
                        val.marca_insumo ? val.marca_insumo.nombre_marca_insumo : '-',
                        val.descripcion ? val.descripcion : '-',
                        val.color ? val.color : '-',
                        val.ingreso,
                        val.salida,
                        val.saldo,
                        { content: estado.text, styles: estado.style }
                        //val.estado_proveedor === 1 ? 'Activo' : 'Inactivo'
                    ];
                }),

                headStyles: {
                    fillColor: "#dd8022",
                    textColor: "white"
                },
                margin: { top: 29, right: 5, bottom: 10, left: 5 },
                styles: {
                    fontSize: 8 // Reduciendo el tamaño del texto de la tabla
                },
                didDrawPage: function (data) {
                    addHeadersAndFooters(data);
                }
            });

            setTimeout(() => {
                doc.save(`pdf_insumos_${new Date().toLocaleDateString()}`);
            }, 1000);

        } catch (error) {
            showSwalMessage('Error', 'Ha ocurrido un error. Vuelva a intentarlo.', 'error');
        } finally {
            setTimeout(() => {
                setLoadingExportPdf(false);
            }, 1000);
        }
    }

    const showSwalMessage = (titulo, texto, icono) => {
        Swal.fire({
            title: titulo,
            text: texto,
            icon: icono,
            confirmButtonColor: "#339A5B",
        });
    }

    return (
        <div className='flex justify-start flex-col mb-4 flex-wrap relative max-w-96'>

            <div className='flex'>

                <button
                    disabled={loadingExportPdf}
                    className={`max-[495px]:ms-1 max-[467px]:ms-0 max-[315px]:mt-3 px-2 py-1 flex items-center
                        ${loadingExportPdf ? 'cursor-not-allowed opacity-50 bg-red-600 text-white border border-red-600 rounded' :
                            `bg-white rounded text-red-600 
                 border border-red-600
                 hover:text-white hover:bg-red-600
                 `}
                 `}
                    onClick={handleGeneratePdf}
                >
                    {loadingExportPdf ?
                        <div className='flex items-center justify-center'>
                            <RotatingLines
                                strokeColor="white"
                                strokeWidth='3'
                                width='22'
                                radius="5"
                                ariaLabel="lines-loading"
                            />
                            <p className='ms-3'>Exportando...</p>
                        </div>
                        : <>
                            <FaFilePdf className='me-3' /> Exportar PDF
                        </>
                    }
                </button>


            </div>
        </div>
    )
}
