import React, { useMemo, useState, useEffect } from 'react'
import { Link } from 'react-router-dom';

import { MRT_Localization_ES } from 'material-react-table/locales/es/index.js';

import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import { Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { Box, IconButton, Typography } from '@mui/material';

import AuthUser from '../../../pages/pagesAuth/AuthUser';
import Config from '../../../Config';

import Swal from 'sweetalert2';
import { toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import { RotatingLines } from 'react-loader-spinner';

import { FaPlus, FaFileCsv } from 'react-icons/fa';
/* import CsvDownloader from 'react-csv-downloader';
import UserPdf from '../createPdf/UserPdf'; */

import AddIcon from '@mui/icons-material/Add';
import MinusIcon from '@mui/icons-material/Remove';

import { FaCircle } from "react-icons/fa";
import ModalEditarReintegroInsumo from '../../forms/MovimientoInsumos/ReintegroInsumos/ModalEditarReintegroInsumo';

export default function ReintegroInsumosTable() {

    const { getRol } = AuthUser();

    const [reintegroInsumos, setReintegroInsumos] = useState([]);
    const [tableLoading, setTableLoading] = useState(true);

    //table state
    const [columnFilters, setColumnFilters] = useState([]);
    const [globalFilter, setGlobalFilter] = useState('');
    const [sorting, setSorting] = useState([]);
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 20,
    });
    const [rowCount, setRowCount] = useState(0);

    //Modal
    const [openAdd, setOpenAdd] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);

    const [editId, setEditId] = useState('');

    //Para actualizar luego de agregar o editar
    const updateInsumoList = async () => {
        await getReintegroInsumosAll();
    };

    useEffect(() => {
        getReintegroInsumosAll();
    }, [
        pagination.pageIndex,
        pagination.pageSize,
        columnFilters,
        globalFilter,
        sorting
    ]);

    const getReintegroInsumosAll = async () => {
        setTableLoading(true);

        let getReintegroInsumosList;

        if (getRol() === "Administrador") {
            getReintegroInsumosList = Config.getReintegroInsumosListAdmin(
                pagination.pageIndex,
                pagination.pageSize,
                globalFilter,
                columnFilters,
                sorting
            );
        } else if (getRol() === "Almacenero") {
            getReintegroInsumosList = Config.getReintegroInsumosListAlmacenero(
                pagination.pageIndex,
                pagination.pageSize,
                globalFilter,
                columnFilters,
                sorting
            );
        } else if (getRol() === "Gerente") {
            getReintegroInsumosList = Config.getReintegroInsumosListGerente(
                pagination.pageIndex,
                pagination.pageSize,
                globalFilter,
                columnFilters,
                sorting
            );
        } else if (getRol() === "Operario") {
            getReintegroInsumosList = Config.getReintegroInsumosListOperario(
                pagination.pageIndex,
                pagination.pageSize,
                globalFilter,
                columnFilters,
                sorting
            );
        }

        try {
            const response = await getReintegroInsumosList;
            if (response.data.exito == '0') {
                notify(response.data.message, 'error')
            } else {
                console.log(response);
                setReintegroInsumos(response.data.data);
                setRowCount(response.data.total);
                //setClientes(response.data);
                /* console.log(response); */
            }
        } catch (error) {
            console.error("Error:", error);
        } finally {
            setTableLoading(false);
        }
    }

    const notify = (message, type) => {
        toast[type](message, {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
        });
    };

    // COLUMNAS DE LA TABLA

    const columns = useMemo(
        () => [
            // {
            //    accessorFn: (row) => row.id,
            //    id: "id", //simple recommended way to define a column
            //    header: "ID",
            //    size: 10
            // /* muiTableHeadCellProps: { sx: { color: "green" } }, //custom props
            // Cell: ({ renderedCellValue }) => <strong>{renderedCellValue}</strong> //optional custom cell render */
            // },
            {
                id: "#",
                header: "Nro.",
                size: 1,
                Cell: ({ row }) => {
                    return row.index + 1;
                }
            },
            {
                accessorFn: (row) => (row.insumo ? row.codigo_salida_insumo : '-'),
                id: "codigo_salida_insumo",
                header: "Código de Reintegro de Ins.",
                size: 180,
                minSize: 200, // Anchura mínima
            },
            {
                accessorFn: (row) => (row.insumo ? row.insumo.codigo : '-'),
                id: "codigo",
                header: "Código de Insumo",
            },
            {
                accessorFn: (row) => (row.insumo ? row.insumo.nombre : '-'),
                id: "nombre",
                header: "Insumo",
                size: 5
            },
            {
                accessorFn: (row) => (row.insumo ? row.insumo.color : '-'),
                id: "color",
                header: "Color",
                size: 1,
            },
            {
                accessorFn: (row) => row.cantidad_reintegro_insumo,
                id: "cantidad_reintegro_insumo",
                header: "Cantidad Reintegrada",
                size: 1,
            },
            {
                accessorFn: (row) => row.nro_documento_responsable_insumo_reintegro,
                id: "nro_documento_responsable_insumo_reintegro",
                header: "N° Doc.",
                size: 1,
            },
            {
                accessorFn: (row) => row.nombre_completo_responsable_insumo_reintegro,
                id: "nombre_completo_responsable_insumo_reintegro",
                header: "Nom. Responsable",
                size: 1,
            },
            {
                accessorFn: (row) => (row.correo_responsable_insumo_reintegro ? row.correo_responsable_insumo_reintegro : '-'),
                id: "correo_responsable_insumo_reintegro",
                header: "Correo Responsable",
                size: 1,
            },
            {
                accessorFn: (row) => row.proyecto_insumo_reintegro,
                id: "proyecto_insumo_reintegro",
                header: "En Proyecto",
                size: 1,
            },
            {
                accessorFn: (row) => new Date(row.fecha_reintegro),
                id: "fecha_reintegro",
                header: "Fecha de Reintegro",
                filterVariant: 'datetime-range',
                Cell: ({ cell }) => {
                    const date = new Date(cell.getValue());
                    return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')} ${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}:${date.getSeconds().toString().padStart(2, '0')}`;
                },
            },
            {
                accessorFn: (row) => (row.usuario ? row.usuario.name : '-'),
                id: "name",
                header: "Ingresado por:",
                size: 2
            },
            {
                accessorFn: (row) => (row.usuario ? row.usuario.email : '-'),
                id: "email",
                header: "Correo:",
                size: 2
            },
        ],
        []
    );

    const data = (reintegroInsumos ? reintegroInsumos : []);

    const mostrarAccionesRol = () => {
        const rol = getRol();
        return rol === "Administrador" || rol === "Almacenero";
    };

    const table = useMaterialReactTable({
        columns,
        data,

        muiPaginationProps: {
            rowsPerPageOptions: [5, 10, 15, 20, 50, 100, 500],
            showFirstButton: true,
            showLastButton: true,
        },

        enableExpandAll: false, //disable expand all button
        muiDetailPanelProps: () => ({
            sx: (theme) => ({
                backgroundColor:
                    theme.palette.mode === 'dark'
                        ? 'rgba(255,210,244,0.1)'
                        : 'rgba(0,0,0,0.1)',
            }),
        }),
        //custom expand button rotation
        muiExpandButtonProps: ({ row, table }) => ({
            onClick: () => table.setExpanded({ [row.id]: !row.getIsExpanded() }), //only 1 detail panel open at a time
            sx: {
                transform: row.getIsExpanded() ? 'rotate(180deg)' : 'rotate(-90deg)',
                transition: 'transform 0.2s',
            },
        }),

        renderDetailPanel: ({ row }) =>
            row.original.usuario ? (
                <Box
                    sx={{
                        display: '',
                        margin: 'auto',
                        gridTemplateColumns: '1fr 1fr',
                        width: '100%',
                    }}
                >
                    <Typography><span className='font-bold'>Ingresado por:</span></Typography>
                    <Typography><span className='font-bold'>- Nombre:</span> {row.original.usuario.name}</Typography>
                    <Typography><span className='font-bold'>- Correo:</span> {row.original.usuario.email}</Typography>
                    <Typography><span className='font-bold'>- Rol:</span> {row.original.usuario.role.rol}</Typography>
                    <Typography><p className='font-bold mt-3'>Datos del Responsable de Merma:</p></Typography>
                    <Typography><span className='font-bold'>- Tip. Documento:</span> {row.original.tipo_documento.nombre_documento}</Typography>
                    <Typography><span className='font-bold'>- N° Documento:</span> {row.original.nro_documento_responsable_insumo_reintegro}</Typography>
                    <Typography><span className='font-bold'>- Nomb. Compl.:</span> {row.original.nombre_completo_responsable_insumo_reintegro}</Typography>
                    <Typography><span className='font-bold'>- Correo:</span> {row.original.correo_responsable_insumo_reintegro}</Typography>
                    <Typography><span className='font-bold'>- Proyecto:</span> {row.original.proyecto_insumo_reintegro}</Typography>
                    <Typography><p className='font-bold mt-3'>Datos de Merma:</p></Typography>
                    <Typography><span className='font-bold'>- Cantidad de Merma:</span> {row.original.cantidad_reintegro_insumo}</Typography>
                    <Typography><span className='font-bold'>- Observación de Merma:</span> {row.original.observacion_reintegro_insumo}</Typography>
                </Box>
            ) : null,

        initialState: {
            showColumnFilters: false,
            density: 'compact',
            columnVisibility: {

            }
        },

        manualFiltering: true,
        manualPagination: true,
        manualSorting: true,

        onColumnFiltersChange: setColumnFilters,
        onGlobalFilterChange: setGlobalFilter,
        onPaginationChange: setPagination,
        onSortingChange: setSorting,
        rowCount,

        state: {
            columnFilters,
            globalFilter,
            isLoading: tableLoading,
            pagination,
            sorting,
        },

        localization: MRT_Localization_ES,
        muiSearchTextFieldProps: {
            placeholder: 'Buscar en todas las reintegraciones de insumos',
            sx: { minWidth: '300px' },
            variant: 'outlined',
        },
        enableRowActions: mostrarAccionesRol(),
        positionActionsColumn: mostrarAccionesRol() ? 'last' : undefined,

        renderRowActions: ({ row }) => (
            <Box>
                {getRol() === "Administrador" || getRol() === "Almacenero" ?
                    <IconButton title='Editar' onClick={() => {
                        setEditId(row.original.id_reintegroinsumo);
                        setOpenEdit(true);
                        console.log("fila", row.original.id_reintegroinsumo);
                    }} color="primary">
                        <EditIcon />
                    </IconButton>
                    : null}
            </Box>
        ),
    });

    return (
        <div>

            <div className='mb-20'>

                <MaterialReactTable
                    table={table}
                />

            </div>

            {getRol() == "Administrador" || getRol() == "Almacenero" ?
                <>
                    {/* <ModalAnadirInsumo
                        open={openAdd}
                        onClose={() => setOpenAdd(false)}
                        updateInsumoList={updateInsumoList}
                    /> */}

                    <ModalEditarReintegroInsumo
                        open={openEdit}
                        editId={editId}
                        onClose={() => setOpenEdit(false)}
                        updateInsumoList={updateInsumoList}
                    />
                </>
                : null}

        </div>
    )
}
