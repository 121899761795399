import React, { useMemo, useState, useEffect } from 'react'
import { Link } from 'react-router-dom';

import { MRT_Localization_ES } from 'material-react-table/locales/es/index.js';

import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import { Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { Box, IconButton, Typography } from '@mui/material';

import AuthUser from '../../../pages/pagesAuth/AuthUser';
import Config from '../../../Config';

import Swal from 'sweetalert2';
import { toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import { RotatingLines } from 'react-loader-spinner';

import { FaPlus, FaFileCsv } from 'react-icons/fa';
/* import CsvDownloader from 'react-csv-downloader';
import UserPdf from '../createPdf/UserPdf'; */

import AddIcon from '@mui/icons-material/Add';
import MinusIcon from '@mui/icons-material/Remove';

import { FaCircle } from "react-icons/fa";

export default function MermaHerramientasTable() {

  const { getRol } = AuthUser();

  const [mermaHerramientas, setMermaHerramientas] = useState([]);
  const [tableLoading, setTableLoading] = useState(true);

  //table state
  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 20,
  });
  const [rowCount, setRowCount] = useState(0);

  //Modal
  const [openAdd, setOpenAdd] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);

  const [editId, setEditId] = useState('');

  //Para actualizar luego de agregar o editar
  const updateHerramientaList = async () => {
    await getMermaHerramientasAll();
  };

  useEffect(() => {
    getMermaHerramientasAll();
  }, [
    pagination.pageIndex,
    pagination.pageSize,
    columnFilters,
    globalFilter,
    sorting
  ]);

  const getMermaHerramientasAll = async () => {
    setTableLoading(true);

    let getMermaHerramientasList;

    if (getRol() === "Administrador") {
      getMermaHerramientasList = Config.getMermaHerramientasListAdmin(
        pagination.pageIndex,
        pagination.pageSize,
        globalFilter,
        columnFilters,
        sorting
      );
    } else if (getRol() === "Almacenero") {
      getMermaHerramientasList = Config.getMermaHerramientasListAlmacenero(
        pagination.pageIndex,
        pagination.pageSize,
        globalFilter,
        columnFilters,
        sorting
      );
    } else if (getRol() === "Gerente") {
      getMermaHerramientasList = Config.getMermaHerramientasListGerente(
        pagination.pageIndex,
        pagination.pageSize,
        globalFilter,
        columnFilters,
        sorting
      );
    } else if (getRol() === "Operario") {
      getMermaHerramientasList = Config.getMermaHerramientasListOperario(
        pagination.pageIndex,
        pagination.pageSize,
        globalFilter,
        columnFilters,
        sorting
      );
    }

    try {
      const response = await getMermaHerramientasList;
      if (response.data.exito == '0') {
        notify(response.data.message, 'error')
      } else {
        console.log(response);
        setMermaHerramientas(response.data.data);
        setRowCount(response.data.total);
        //setClientes(response.data);
        /* console.log(response); */
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setTableLoading(false);
    }
  }

  const notify = (message, type) => {
    toast[type](message, {
      position: "bottom-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  };

  // COLUMNAS DE LA TABLA

  const columns = useMemo(
    () => [
      // {
      //    accessorFn: (row) => row.id,
      //    id: "id", //simple recommended way to define a column
      //    header: "ID",
      //    size: 10
      // /* muiTableHeadCellProps: { sx: { color: "green" } }, //custom props
      // Cell: ({ renderedCellValue }) => <strong>{renderedCellValue}</strong> //optional custom cell render */
      // },
      {
        id: "#",
        header: "Nro.",
        size: 1,
        Cell: ({ row }) => {
          return row.index + 1;
        }
      },
      {
        accessorFn: (row) => (row.herramienta ? row.codigo_salida_herramienta : '-'),
        id: "codigo_salida_herramienta",
        header: "Código de Salida de Herr.",
        size: 180,
        minSize: 200, // Anchura mínima
      },
      {
        accessorFn: (row) => (row.herramienta ? row.herramienta.codigo : '-'),
        id: "codigo",
        header: "Código de Herrami.",
      },
      {
        accessorFn: (row) => (row.herramienta ? row.herramienta.nombre_herramienta : '-'),
        id: "nombre_herramienta",
        header: "Herramienta",
        size: 5
      },
      {
        accessorFn: (row) => (row.herramienta ? row.herramienta.medida : '-'),
        id: "medida",
        header: "Medida",
        size: 1,
      },
      {
        accessorFn: (row) => row.cantidad_merma_herramienta,
        id: "cantidad_merma_herramienta",
        header: "Cantidad Merma",
        size: 1,
      },
      {
        accessorFn: (row) => row.nro_documento_responsable_herramienta_merma,
        id: "nro_documento_responsable_herramienta_merma",
        header: "N° Doc.",
        size: 1,
      },
      {
        accessorFn: (row) => row.nombre_completo_responsable_herramienta_merma,
        id: "nombre_completo_responsable_herramienta_merma",
        header: "Nom. Responsable",
        size: 1,
      },
      {
        accessorFn: (row) => (row.correo_responsable_herramienta_merma ? row.correo_responsable_herramienta_merma : '-'),
        id: "correo_responsable_herramienta_merma",
        header: "Correo Responsable",
        size: 1,
      },
      {
        accessorFn: (row) => row.proyecto_herramienta_merma,
        id: "proyecto_herramienta_merma",
        header: "En Proyecto",
        size: 1,
      },
      {
        accessorFn: (row) => new Date(row.fecha_merma),
        id: "fecha_merma",
        header: "Fecha de Merma",
        filterVariant: 'datetime-range',
        Cell: ({ cell }) => {
          const date = new Date(cell.getValue());
          return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')} ${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}:${date.getSeconds().toString().padStart(2, '0')}`;
        },
      },
      {
        accessorFn: (row) => (row.usuario ? row.usuario.name : '-'),
        id: "name",
        header: "Ingresado por:",
        size: 2
      },
      {
        accessorFn: (row) => (row.usuario ? row.usuario.email : '-'),
        id: "email",
        header: "Correo:",
        size: 2
      },
    ],
    []
  );

  const data = (mermaHerramientas ? mermaHerramientas : []);

  const mostrarAccionesRol = () => {
    const rol = getRol();
    return rol === "Administrador" || rol === "Almacenero";
  };

  const table = useMaterialReactTable({
    columns,
    data,

    muiPaginationProps: {
      rowsPerPageOptions: [5, 10, 15, 20, 50, 100, 500],
      showFirstButton: true,
      showLastButton: true,
    },

    enableExpandAll: false, //disable expand all button
    muiDetailPanelProps: () => ({
      sx: (theme) => ({
        backgroundColor:
          theme.palette.mode === 'dark'
            ? 'rgba(255,210,244,0.1)'
            : 'rgba(0,0,0,0.1)',
      }),
    }),
    //custom expand button rotation
    muiExpandButtonProps: ({ row, table }) => ({
      onClick: () => table.setExpanded({ [row.id]: !row.getIsExpanded() }), //only 1 detail panel open at a time
      sx: {
        transform: row.getIsExpanded() ? 'rotate(180deg)' : 'rotate(-90deg)',
        transition: 'transform 0.2s',
      },
    }),

    renderDetailPanel: ({ row }) =>
      row.original.usuario ? (
        <Box
          sx={{
            display: '',
            margin: 'auto',
            gridTemplateColumns: '1fr 1fr',
            width: '100%',
          }}
        >
          <Typography><span className='font-bold'>Ingresado por:</span></Typography>
          <Typography><span className='font-bold'>- Nombre:</span> {row.original.usuario.name}</Typography>
          <Typography><span className='font-bold'>- Correo:</span> {row.original.usuario.email}</Typography>
          <Typography><span className='font-bold'>- Rol:</span> {row.original.usuario.role.rol}</Typography>
          <Typography><p className='font-bold mt-3'>Datos del Responsable de Merma:</p></Typography>
          <Typography><span className='font-bold'>- Tip. Documento:</span> {row.original.tipo_documento.nombre_documento}</Typography>
          <Typography><span className='font-bold'>- N° Documento:</span> {row.original.nro_documento_responsable_herramienta_merma}</Typography>
          <Typography><span className='font-bold'>- Nomb. Compl.:</span> {row.original.nombre_completo_responsable_herramienta_merma}</Typography>
          <Typography><span className='font-bold'>- Correo:</span> {row.original.correo_responsable_herramienta_merma}</Typography>
          <Typography><span className='font-bold'>- Proyecto:</span> {row.original.proyecto_herramienta_merma}</Typography>
          <Typography><p className='font-bold mt-3'>Datos de Merma:</p></Typography>
          <Typography><span className='font-bold'>- Cantidad de Merma:</span> {row.original.cantidad_merma_herramienta}</Typography>
          <Typography><span className='font-bold'>- Observación de Merma:</span> {row.original.observacion_merma_herramienta}</Typography>
        </Box>
      ) : null,

    initialState: {
      showColumnFilters: false,
      density: 'compact',
      columnVisibility: {

      }
    },

    manualFiltering: true,
    manualPagination: true,
    manualSorting: true,

    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    rowCount,

    state: {
      columnFilters,
      globalFilter,
      isLoading: tableLoading,
      pagination,
      sorting,
    },

    localization: MRT_Localization_ES,
    muiSearchTextFieldProps: {
      placeholder: 'Buscar todas las mermas de herramientas',
      sx: { minWidth: '300px' },
      variant: 'outlined',
    },
    // enableRowActions: mostrarAccionesRol(),
    // positionActionsColumn: mostrarAccionesRol() ? 'last' : undefined,

    // renderRowActions: ({ row }) => (
    //   <Box>
    //     {getRol() === "Administrador" || getRol() === "Almacenero" ?
    //       <IconButton title='Editar' onClick={() => {
    //         setEditId(row.original.salida_herramienta_id);
    //         setOpenEdit(true);
    //         console.log("fila", row.original.salida_herramienta_id);
    //       }} color="primary">
    //         <EditIcon />
    //       </IconButton>
    //       : null}

    //     {getRol() === "Administrador" || getRol() === "Almacenero" ?
    //       <IconButton title='Eliminar' onClick={() => {
    //         showSwal(row.original.salida_herramienta_id);
    //         /* console.log("fila id: ", row.original.id); */
    //       }}
    //         color="error">
    //         <DeleteIcon />
    //       </IconButton>
    //       : null}

    //   </Box>
    // ),
  });

  return (
    <div>

      <div className='mb-20'>

        <MaterialReactTable
          table={table}
        />

      </div>

    </div>
  )
}
