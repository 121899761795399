import { Link } from "react-router-dom";

export default function NotFound404() {
  return (
    <div className="m-5">
        <h1 className="text-slate-700 text-4xl">404 | Página no Encontrada</h1>
        <p className="mt-2 text-xl">Volver a la <Link to="/" className="underline text-sky-600">Página Principal</Link></p>
    </div>
  )
}
