import { useState } from "react";
import Fab from '@mui/material/Fab/Fab';
import { FaQuestion } from "react-icons/fa6";
import { Link } from "react-router-dom";

export default function BotonInfoFlotante({ linkRedirect }) {
    const [visible, setVisible] = useState(true);

    const handleClose = () => {
        setVisible(false);
    };

    if (!visible) return null;
    return (
        <div className="fixed bottom-4 right-4 flex items-center justify-center 
        text-white" style={{ zIndex: 999999999999999 }} title="Ayuda">
            <Fab color="primary" aria-label="add">
                {/* <button className="fixed mb-14 mr-24 ml-2 bg-gray-600 rounded-full border-none text-white text-lg" onClick={handleClose}>
                    X
                </button> */}

                <Link to={linkRedirect}>
                    <FaQuestion style={{ fontSize: 28 }} />
                </Link>
            </Fab>
        </div>
    )
}
