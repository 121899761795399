import {useState} from 'react'
import {useNavigate} from 'react-router-dom';

export default function AuthUser() {

    const navigate = useNavigate();

    const getToken = () => {
        const tokenString = localStorage.getItem('token');
        const token = JSON.parse(tokenString);
        return token;
    }

    const getUser = () => {
        const userString = localStorage.getItem('user');
        const user = JSON.parse(userString);
        return user;
    }

    const getRol = () => {
        const rolString = localStorage.getItem('rol');
        const rol = JSON.parse(rolString);
        return rol;
    }

    const [token, setToken] = useState(getToken());
    const [user, setUser] = useState(getUser());
    const [rol, setRol] = useState(getRol());

    const saveToken = (user, token, rol) => {
        localStorage.setItem('user', JSON.stringify(user));
        localStorage.setItem('token', JSON.stringify(token));
        localStorage.setItem('rol', JSON.stringify(rol));

        setUser(user);
        setToken(token);
        setRol(rol);

        if(getToken()){
            navigate('/usuario/dashboard');
        }
    }

    //Usado solo para refrescar
    const refreshTokenFront = (user, token, rol) => {
        localStorage.setItem('user', JSON.stringify(user));
        localStorage.setItem('token', JSON.stringify(token));
        localStorage.setItem('rol', JSON.stringify(rol));

        setUser(user);
        setToken(token);
        setRol(rol);
    }

    const getLogout = () => {
        localStorage.clear();
        navigate('/');
    }

  return {
    setToken:saveToken,
    setRefreshToken: refreshTokenFront,
    token,
    user,
    rol,
    getToken, getRol, getUser, getLogout
  } 
}
