import React, { useState } from 'react';
import * as XLSX from "xlsx";
import * as XLSXStyle from "xlsx-js-style";

import { RotatingLines } from 'react-loader-spinner';
import { FaFileCsv } from 'react-icons/fa';

export default function ProveedoresExcel({ proveedoresData }) {

    const [loadingDataExcel, setLoadingDataExcel] = useState(false);

    const getFormattedDate = () => {
        const date = new Date();
        const options = {
            day: '2-digit',
            month: 'long',
            year: 'numeric',
        };
        return date.toLocaleString('es-ES', options);
    };

    const getFormattedHour = () => {
        const date = new Date();
        const options = {
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: true,
        };
        return date.toLocaleString('es-ES', options);
    };

    const titulo = [{ A: "Reporte de Proveedores"}, {}];

    const InfoAdicional = {
        A: "Generado por ICB Industrial"
    };

    const FechaActual = {
        A: `Reporte generado el ${getFormattedDate()} a las ${getFormattedHour()}`
    };

    const longitudes = [5, 20, 15, 20, 15, 25, 25, 12];

    const handleExcelDownload = () => {
        setLoadingDataExcel(true);

        let table = [
            {
                A: "Nro.",
                B: "Proveedor",
                C: "RUC",
                D: "Dirección",
                E: "Celular",
                F: "Fecha de Creación",
                G: "Fecha de Actualización",
                H: "Estado",                
            },
            ...proveedoresData.map((proveedor, index) => ({
                A: index+1,
                B: proveedor.nombre_proveedor,
                C: proveedor.ruc,
                D: proveedor.direccion,
                E: proveedor.celular,
                F: dateFormatter(proveedor.fecha_registro),
                G: dateFormatter(proveedor.fecha_editado),
                H: proveedor.estado_proveedor == "1" ? 'Activo' : 'Inactivo',
            }))
        ];

        const dataFinal = [...titulo, FechaActual, {}, ...table, {}, InfoAdicional];

        setTimeout(() => {
            creandoArchivo(dataFinal);
            setLoadingDataExcel(false);
        }, 1000);
    }

    const creandoArchivo = (dataFinal) => {
        const libro = XLSX.utils.book_new();

        const hoja = XLSX.utils.json_to_sheet(dataFinal, {skipHeader: true});

        //Unir celdas
        hoja["!merges"] = [
            XLSX.utils.decode_range("A1:H1"),
            XLSX.utils.decode_range("A2:H2"),
        ];

        // Estilo para A1:G1
        const tituloStyle = {
            font: { bold: true, color: { rgb: "ca7521" }, sz: 18 },
            //fill: { fgColor: { rgb: "C5E1A5" } },
            alignment: { horizontal: "center", vertical: "center" }
        };

        // Aplicar estilo a A1:G1
        const tituloCols = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H'];
        tituloCols.forEach(col => {
            const cell = `${col}1`;
            if (hoja[cell]) {
                hoja[cell].s = tituloStyle;
            }
        });

        //Aplicar estilos a A3
        const reporteStyle = {
            font: { bold: true, color: { rgb: "ca7521" }, sz: 10 },
        };

        //Aplicar estilos a A3
        const reporteCols = ['A', 'B', 'C', 'D', 'E', 'F', 'G'];
        reporteCols.forEach(col => {
            const cell = `${col}3`;
            if (hoja[cell]) {
                hoja[cell].s = reporteStyle;
            }
        });

        // Agregar estilo a los encabezados A5:G5
        const headerStyle = {
            font: { bold: true, color: { rgb: "FFFFFF" } },
            fill: { fgColor: { rgb: "dd8022" } },
            alignment: { horizontal: "center", vertical: "center" }
        };

        // Aplicar estilo a los encabezados
        const cols = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H'];
        cols.forEach(col => {
            const cell = `${col}5`;
            if (hoja[cell]) {
                hoja[cell].s = headerStyle;
            }
        });

        // Aplicar estilos condicionales a las celdas "Es Activo"
        dataFinal.forEach((row, rowIndex) => {
            if (rowIndex > 4 && row.H) {  // Saltar encabezados y título
                const cell = `H${rowIndex + 1}`;
                const isActive = row.H === 'Activo';  // Verificar si el estado es 'Activo'
                const style = {
                    fill: { fgColor: { rgb: isActive ? "218014" : "801414" } },
                    font: { color: { rgb: "FFFFFF" } }
                };
                if (hoja[cell]) {
                    hoja[cell].s = style;
                }
            }
        });

        let propiedades = [];

        longitudes.forEach((col) => {
            propiedades.push({
                width: col,
            });
        });

        hoja["!cols"] = propiedades;

        XLSXStyle.utils.book_append_sheet(libro, hoja, "Proveedores");

        XLSXStyle.writeFile(libro, `excel_proveedores_${new Date().toLocaleDateString()}.xlsx`);
    };

    const dateFormatter = (dateString) => {
        const parsedDate = new Date(dateString).toLocaleString('es', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
            hour12: true
        });
        return parsedDate;
    };

  return (
    <div>
        <button disabled={loadingDataExcel}
        className={`max-[495px]:ms-1 max-[467px]:ms-0 max-[315px]:mt-3
            ${loadingDataExcel ? 'bg-lime-600 text-white cursor-not-allowed opacity-50 rounded border border-lime-600' :
            `bg-white rounded text-lime-600 
            border border-lime-600
            hover:text-white hover:bg-lime-600 `}
            px-2 py-1 flex items-center 
            `}
               onClick={handleExcelDownload}
        >
            {loadingDataExcel ?
                        <div className='flex items-center justify-center'>
                            <RotatingLines
                                strokeColor="white"
                                strokeWidth='3'
                                width='22'
                                radius="5"
                                ariaLabel="lines-loading"
                            />
                            <p className='ms-3'>Exportando...</p>
                        </div>
                        : <>
                            <FaFileCsv className='me-3' /> Exportar Excel
                        </>
                    }
        </button>
    </div>
  )
}
