import React, { useMemo, useState, useEffect } from 'react'
import { Link } from 'react-router-dom';

import { MRT_Localization_ES } from 'material-react-table/locales/es/index.js';

import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import { Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { Box, IconButton, Typography } from '@mui/material';

import AuthUser from '../../pages/pagesAuth/AuthUser';
import Config from '../../Config';

import Swal from 'sweetalert2';
import { toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import { RotatingLines } from 'react-loader-spinner';

import { FaPlus, FaFileCsv } from 'react-icons/fa';
import CsvDownloader from 'react-csv-downloader';
import UserPdf from '../createPdf/UserPdf';

import { FaCircle } from "react-icons/fa";
import ModalAnadirHerramienta from '../forms/Herramienta/ModalAnadirHerramienta';
import ModalEditarHerramienta from '../forms/Herramienta/ModalEditarHerramienta';

import { IoIosAlert } from "react-icons/io";
import HerramientasExcel from '../createExcel/Herramientas/HerramientasExcel';
import HerramientasPdf from '../createPdf/Herramientas/HerramientasPdf';

export default function HerramientasTable() {

  const { getRol } = AuthUser();

  const [herramientas, setHerramientas] = useState([]);
  const [tableLoading, setTableLoading] = useState(true);

  //table state
  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 5,
  });
  const [rowCount, setRowCount] = useState(0);

  //Para las alertas
  const [herramientaSinSalidaAlerta, setHerramientaSinSalidaAlerta] = useState('0');

  const [herramientaFueraServicioAlerta, setHerramientaFueraServicioAlerta] = useState('0');  

  // Constante para verificar si estamos en la página de insumos
  const [isHerramientaSinSalidaPage, setIsHerramientaSinSalidaPage] = useState(false);
  const [isHerramientaFueraServicioPage, setIsHerramientaFueraServicioPage] = useState(false);

  //Modal
  const [openAdd, setOpenAdd] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);

  const [editId, setEditId] = useState('');


  //Para actualizar luego de agregar o editar
  const updateHerramientaList = async () => {
    await getHerramientasAll();
  };

  // Limpiar sessionStorage en cambios de página
  useEffect(() => {
    if (isHerramientaSinSalidaPage) {
      sessionStorage.removeItem("herramientas-sin-salidaBajoStock");
      setHerramientaSinSalidaAlerta('0');
    }

    if (isHerramientaFueraServicioPage) {
      sessionStorage.removeItem("herramientas-fuera-servicioBajoStock");
      setHerramientaFueraServicioAlerta('0');
    }
  }, [isHerramientaSinSalidaPage, isHerramientaFueraServicioPage]); // Ejecuta cada vez que cambia la ubicación de la página

  useEffect(() => {
    getHerramientasAll();
  }, [
    pagination.pageIndex,
    pagination.pageSize,
    columnFilters,
    globalFilter,
    sorting,
    herramientaSinSalidaAlerta,
    herramientaFueraServicioAlerta
  ]);

  const getHerramientasAll = async () => {
    setTableLoading(true);

    let codigosHerramientasSinSalida = null;
    if (sessionStorage.getItem("herramientas-sin-salidaBajoStock")) {
      // Leer códigos guardados en sessionStorage
      codigosHerramientasSinSalida = JSON.parse(sessionStorage.getItem("herramientas-sin-salidaBajoStock") || "[]");

      // Actualizar insumoAlerta e insumoAlertaArreglo
      setHerramientaSinSalidaAlerta(codigosHerramientasSinSalida.length > 0 ? "1" : "0");
    }

    let codigosHerramientasFueraServicio = null;
    if (sessionStorage.getItem("herramientas-fuera-servicioBajoStock")) {
      // Leer códigos guardados en sessionStorage
      codigosHerramientasFueraServicio = JSON.parse(sessionStorage.getItem("herramientas-fuera-servicioBajoStock") || "[]");

      // Actualizar insumoAlerta e insumoAlertaArreglo
      setHerramientaFueraServicioAlerta(codigosHerramientasFueraServicio.length > 0 ? "1" : "0");
    }

    let getHerramientasList;

    if (getRol() === "Administrador") {
      getHerramientasList = Config.getHerramientasListAdmin(
        pagination.pageIndex,
        pagination.pageSize,
        globalFilter,
        columnFilters,
        sorting,
        herramientaSinSalidaAlerta,
        herramientaFueraServicioAlerta
      );
    } else if (getRol() === "Almacenero") {
      getHerramientasList = Config.getHerramientasListAlmacenero(
        pagination.pageIndex,
        pagination.pageSize,
        globalFilter,
        columnFilters,
        sorting,
        herramientaSinSalidaAlerta,
        herramientaFueraServicioAlerta
      );
    } else if (getRol() === "Gerente") {
      getHerramientasList = Config.getHerramientasListGerente(
        pagination.pageIndex,
        pagination.pageSize,
        globalFilter,
        columnFilters,
        sorting,
        herramientaSinSalidaAlerta,
        herramientaFueraServicioAlerta
      );
    } else if (getRol() === "Operario") {
      getHerramientasList = Config.getHerramientasListOperario(
        pagination.pageIndex,
        pagination.pageSize,
        globalFilter,
        columnFilters,
        sorting,
        herramientaSinSalidaAlerta,
        herramientaFueraServicioAlerta
      );
    }

    try {
      const response = await getHerramientasList;
      if (response.data.exito == '0') {
        notify(response.data.message, 'error')
      } else {
        console.log(response);
        setHerramientas(response.data.data);
        setRowCount(response.data.total);
        //setClientes(response.data);
        /* console.log(response); */
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setTableLoading(false);
    }
  }

  const deleteOneHerramienta = async (id) => {
    setTableLoading(true);
    let deleteOneHerramienta;

    if (getRol() == "Administrador") {
      deleteOneHerramienta = Config.deleteOneHerramientaAdmin(id);
    } else if (getRol() === "Almacenero") {
      deleteOneHerramienta = Config.deleteOneHerramientaAlmacenero(id);
    }

    //EL DELETE NO ES PARA OPERARIO, SÓLO PARA ADMINISTRADOR

    try {
      const response = await deleteOneHerramienta;
      console.log(response);
      await getHerramientasAll();
      return { status: response.status, message: response.data.message };
    } catch (error) {
      await getHerramientasAll();
      console.error("Error:", error);
      if (error.response) {
        return { status: error.response.status, message: error.response.data.message };
      } else {
        return { status: 500, message: `Error desconocido. Vuelva a intentarlo.` };
      }
    } finally {
      setTableLoading(false);
    }
  }

  const notify = (message, type) => {
    toast[type](message, {
      position: "bottom-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  };

  //SweetAlert2
  const showSwal = async (id) => {
    Swal.fire({
      title: "¿Estás seguro de eliminar esta herramienta?",
      text: "¡No podrás revertir esta acción!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#dc2626",
      cancelButtonColor: "#71717a",
      confirmButtonText: "Sí, eliminar"
    }).then(async (result) => {
      if (result.isConfirmed) {

        try {
          let mensaje = await deleteOneHerramienta(id);

          let title = "";
          let icon = "";
          let text = mensaje.message;

          console.log(mensaje);

          if (mensaje.status === 200) {
            title = "¡Eliminado!";
            icon = "success";
          } else {
            title = "Error";
            icon = "error";
          }

          notify(text, icon);
        } catch (error) {
          console.error("Error eliminando la herramienta:", error);
          setTableLoading(false);
          notify(`Ha ocurrido un error. Vuelva a intentarlo más tarde. ${error}`, "error");
        }
      }
    });
  }

  // COLUMNAS DE LA TABLA

  const columns = useMemo(
    () => [
      {
        id: "#",
        header: "Nro.",
        size: 1,
        Cell: ({ row }) => {
          return row.index + 1;
        }
      },
      {
        accessorFn: (row) => (row.marca_herramienta ? row.marca_herramienta.nombre_marca_herramienta : '-'),
        id: "nombre_marca_herramienta",
        header: "Marca",
        size: 5
      },
      {
        accessorFn: (row) => (row.proveedor ? row.proveedor.nombre_proveedor : '-'),
        id: "nombre_proveedor",
        header: "Proveedor",
        size: 5
      },
      {
        accessorFn: (row) => row.nombre_herramienta,
        id: "nombre_herramienta",
        header: "Herramienta",
        size: 5
      },
      {
        accessorFn: (row) => row.codigo,
        id: "codigo",
        header: "Código",
        size: 1,
      },
      {
        accessorFn: (row) => (row.nro_serie_herramienta ? row.nro_serie_herramienta : '-'),
        id: "nro_serie_herramienta",
        header: "N° Serie",
        size: 1,
      },
      {
        accessorFn: (row) => row.medida,
        id: "medida",
        header: "Medida",
        size: 1,
      },
      {
        accessorFn: (row) => row.estado_herramienta,
        id: "estado_herramienta",
        header: "Estado Herr.",
        size: 1,
        Cell: ({ cell }) => (
          <Box
            component="span"
            sx={(theme) => ({
              backgroundColor:
                cell.getValue() == "0"
                  ? theme.palette.success.dark
                  : cell.getValue() == "1"
                    ? theme.palette.warning.dark
                    : cell.getValue() === "2"
                      ? theme.palette.error.dark
                      : cell.getValue() === "4"
                      ? "#b8bf2e"
                      : theme.palette.grey[800],
              borderRadius: '0.25rem',
              color: "white",
              p: "0.25rem"
            })}
          >
            {cell.getValue() === "0"
              ? "Óptimo"
              : cell.getValue() === "1"
                ? "Buen Estado"
                : cell.getValue() === "2"
                  ? "Fuera de Servicio"
                  : cell.getValue() === "3" 
                  ? "Múltiple"
                  : cell.getValue() === "4" 
                  ? "Falta Mantenimiento"
                  : "Indefinido"}
          </Box>
        ),
        filterVariant: 'select',
        filterSelectOptions: [
          { value: "0", label: "1 - Óptimo" },
          { value: "1", label: "2 - Buen Estado" },
          { value: "2", label: "3 - Fuera de Servicio" },
          { value: "4", label: "4 - Falta Mantenimiento" }
        ],
      },
      {
        accessorFn: (row) => row.ingreso,
        id: "ingreso",
        header: "Ingreso",
        size: 1,
      },
      {
        accessorFn: (row) => row.salida,
        id: "salida",
        header: "Salida",
        size: 2,
      },
      {
        accessorFn: (row) => row.saldo,
        id: "saldo",
        header: "Saldo",
        size: 2,
      },
      {
        accessorFn: (row) => row.estado,
        id: "estado",
        header: "Estado",
        size: 10,
        Cell: ({ cell }) => (
          <Box
            component="span"
            sx={(theme) => ({
              fontSize: "10px",
              color: `${cell.getValue() == '1' ? 'green' : '#d63333'}`,
            })}
          >
            <FaCircle />
          </Box>
        ),
      },
    ],
    []
  );

  const data = (herramientas ? herramientas : []);

  const mostrarAccionesRol = () => {
    const rol = getRol();
    return rol === "Administrador" || rol === "Almacenero";
  };

  const table = useMaterialReactTable({
    columns,
    data,

    enableExpandAll: false, //disable expand all button

    muiDetailPanelProps: () => ({
      sx: (theme) => ({
        backgroundColor:
          theme.palette.mode === 'dark'
            ? 'rgba(255,210,244,0.1)'
            : 'rgba(0,0,0,0.1)',
      }),
    }),
    //custom expand button rotation
    muiExpandButtonProps: ({ row, table }) => ({
      onClick: () => table.setExpanded({ [row.id]: !row.getIsExpanded() }), //only 1 detail panel open at a time
      sx: {
        transform: row.getIsExpanded() ? 'rotate(180deg)' : 'rotate(-90deg)',
        transition: 'transform 0.2s',
      },
    }),

    renderDetailPanel: ({ row }) =>
      row.original.cantidad_optimo || row.original.cantidad_buen_estado || row.original.cantidad_fuera_servicio || row.original.cantidad_falta_mantenimiento ? (
        <Box
          sx={{
            display: '',
            margin: 'auto',
            gridTemplateColumns: '1fr 1fr',
            width: '100%',
          }}
        >
          <Typography><span className='font-bold'>Proveedor:</span></Typography>
          <Typography><span className='font-bold'>- Nombre:</span> {row.original.proveedor ? row.original.proveedor.nombre_proveedor : '-'}</Typography>

          <Typography><span className='font-bold mb-5'>Estado de Herramientas:</span></Typography>
          <Typography><span className='font-bold'>- Cant. óptimo:</span> {row.original.cantidad_optimo ? row.original.cantidad_optimo : '-'}</Typography>
          <Typography><span className='font-bold'>- Cant. buen estado:</span> {row.original.cantidad_buen_estado ? row.original.cantidad_buen_estado : '-'}</Typography>
          <Typography><span className='font-bold'>- Cant. falta mantenimiento:</span> {row.original.cantidad_falta_mantenimiento ? row.original.cantidad_falta_mantenimiento : '-'}</Typography>
          <Typography><span className='font-bold'>- Cant. fuera de servicio:</span> {row.original.cantidad_fuera_servicio ? row.original.cantidad_fuera_servicio : '-'}</Typography>
          {/* <Typography><span className='font-bold'>- Correo:</span> {row.original.usuario.email}</Typography>
          <Typography><span className='font-bold'>- Rol:</span> {row.original.usuario.role.rol}</Typography> */}
        </Box>
      ) : null,

    initialState: {
      showColumnFilters: false,
      density: 'compact',
      columnVisibility: {
        nombre_proveedor: false,
      }
    },

    muiPaginationProps: {
      rowsPerPageOptions: [5, 10, 15, 20, 50, 100, 500],
      showFirstButton: true,
      showLastButton: true,
    },

    manualFiltering: true,
    manualPagination: true,
    manualSorting: true,

    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    rowCount,

    state: {
      columnFilters,
      globalFilter,
      isLoading: tableLoading,
      pagination,
      sorting,
    },

    localization: MRT_Localization_ES,
    muiSearchTextFieldProps: {
      placeholder: 'Buscar todas las herramientas',
      sx: { minWidth: '300px' },
      variant: 'outlined',
    },

    enableRowActions: mostrarAccionesRol(),
    positionActionsColumn: mostrarAccionesRol() ? 'last' : undefined,

    renderRowActions: ({ row }) => (
      <Box>
        <IconButton title='Editar' onClick={() => {
          setEditId(row.original.herramienta_id);
          setOpenEdit(true);
          console.log("fila", row.original.herramienta_id);
        }} color="primary">
          <EditIcon />
        </IconButton>

        {getRol() == "Administrador" || getRol() == "Almacenero" ?
          <IconButton title='Eliminar' onClick={() => {
            showSwal(row.original.herramienta_id);
            /* console.log("fila id: ", row.original.id); */
          }}
            color="error">
            <DeleteIcon />
          </IconButton>
          : null}

      </Box>
    ),

    renderTopToolbarCustomActions: ({ table }) => (
      <Box
        sx={{
          display: 'flex',
          gap: '16px',
          padding: '8px',
          flexWrap: 'wrap',
        }}
      >
        <Box sx={{ display: 'flex', gap: '16px' }}>

          {getRol() === "Administrador" || getRol() === "Gerente" ?

            <>
              <HerramientasExcel herramientasData={herramientas} />
              <HerramientasPdf herramientasData={herramientas} />
            </>

            : null}

        </Box>
      </Box>
    ),

  });

  return (
    <div>

      {getRol() == "Administrador" || getRol() == "Almacenero" ?

        <div className='flex justify-start mb-5 flex-wrap'>
          <button className='bg-[#D27313] hover:bg-[#cb6f13] rounded shadow-md text-white p-3 flex items-center'
            onClick={() => { setOpenAdd(true); console.log(openAdd); }}
          >
            <FaPlus className='me-3' />
            Añadir Herramienta
          </button>
        </div>

        : null}

      {herramientaSinSalidaAlerta === "1" ?
        <>
          <div className='flex items-center bg-blue-400 rounded border border-blue-500 p-2 text-lg text-white mb-5'>
            <IoIosAlert className='flex items-center me-2 text-2xl' /> Está visualizando sólo las herramientas sin salida. Si desea eliminar este filtro de alerta,
            <span class="ms-2 bg-blue-800 text-white underline cursor-pointer py-1 px-2 rounded hover:bg-blue-900"
              onClick={(e) => { setIsHerramientaSinSalidaPage(true) }}> haga click aquí </span>
          </div>
        </>
        :
        null}

      {herramientaFueraServicioAlerta === "1" ?
        <>
          <div className='flex items-center bg-blue-400 rounded border border-blue-500 p-2 text-lg text-white mb-5'>
            <IoIosAlert className='flex items-center me-2 text-2xl' /> Está visualizando sólo las herramientas que están fuera de servicio. Si desea eliminar este filtro de alerta,
            <span class="ms-2 bg-blue-800 text-white underline cursor-pointer py-1 px-2 rounded hover:bg-blue-900"
              onClick={(e) => { setIsHerramientaFueraServicioPage(true) }}> haga click aquí </span>
          </div>
        </>
        :
        null}

      <div className='mb-20'>

        <MaterialReactTable
          table={table}
        />

      </div>

      {getRol() == "Administrador" || getRol() == "Almacenero" ?

        <>

          <ModalAnadirHerramienta
            open={openAdd}
            onClose={() => setOpenAdd(false)}
            updateHerramientaList={updateHerramientaList}
          />

          <ModalEditarHerramienta
            open={openEdit}
            editId={editId}
            onClose={() => setOpenEdit(false)}
            updateHerramientaList={updateHerramientaList}
          />

        </>

        : null}

    </div>
  )
}
