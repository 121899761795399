import React, { useState, useEffect } from 'react';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

import Swal from 'sweetalert2';

import { RotatingLines } from 'react-loader-spinner';

import { FaFilePdf } from 'react-icons/fa';

export default function KardexEquiposPdf({ kardexEquiposData, mostrarIngreso, mostrarSalida, mostrarDevolucion }) {

    const [loadingExportPdf, setLoadingExportPdf] = useState(false);

    const isSingleOptionSelected = (mostrarIngreso === 1 && mostrarSalida === 0 && mostrarDevolucion === 0) ||
                                   (mostrarIngreso === 0 && mostrarSalida === 1 && mostrarDevolucion === 0) ||
                                   (mostrarIngreso === 0 && mostrarSalida === 0 && mostrarDevolucion === 1);
    
    const titulo_reporte = mostrarIngreso === 1 ? "Ingresos" : (mostrarSalida === 1 ? "Salidas" : mostrarDevolucion === 1 ? "Devoluciones" : "");

    const dateFormatter = (dateString) => {
        const parsedDate = new Date(dateString).toLocaleString('es', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
            hour12: true
        });
        return parsedDate;
    };

    const customDateFormatter = (date) => {
        const months = ['enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'];
        const day = date.getDate();
        const month = months[date.getMonth()];
        const year = date.getFullYear();
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        const seconds = date.getSeconds().toString().padStart(2, '0');
        const ampm = date.getHours() >= 12 ? 'p. m.' : 'a. m.';
        return `${day} de ${month} del ${year} a las ${hours}:${minutes}:${seconds} ${ampm}`;
        //return `${day} de ${month} del ${year}, ${hours}:${minutes}:${seconds}`;
    };

    const handleGeneratePdf = async () => {
        try {
            setLoadingExportPdf(true);

            const data2 = kardexEquiposData;
            const currentDateTime = customDateFormatter(new Date());

            // Luego de obtener los datos actualizados, genera el PDF
            const doc = new jsPDF('landscape', 'mm', 'A4', 0);
            let imgData = '/img/logo/logo_hori_menu.png';

            const title1 = "Empresa ICB Industrial | RUC: 20330684411";
            //const title2 = `Reporte de Ingresos y Salidas de Herramientas`;
            const title2 = `Reporte de ${titulo_reporte} de Herramientas`;
            const subtitule = `Generado el: ${currentDateTime}`;
            const padding = 13;
            
            const addHeadersAndFooters = (data) => {                
                // Add logo
                doc.addImage(imgData, 'PNG', 5, 1, 40, 20);

                // Add title1
                doc.setTextColor('#ca7521').setFont(undefined, 'bold').setFontSize(12);
                let title1Width = doc.getTextWidth(title1);
                let title1Center = (doc.internal.pageSize.width / 2) - (title1Width / 2);
                doc.text(title1, title1Center, padding);

                // Add title2
                let title2Width = doc.getTextWidth(title2);
                let title2Center = (doc.internal.pageSize.width / 2) - (title2Width / 2);
                doc.text(title2, title2Center, padding + 6);

                // Add subtitule
                doc.setTextColor('#ca7521').setFont(undefined, 'normal').setFontSize(9);
                doc.text(subtitule, doc.internal.pageSize.width - doc.getTextWidth(subtitule) - 10, padding - 6);
                

            };

            //const pageCount = Math.ceil(data2.length / 10);

            let header = [];
            let data_pdf = [];
            if (mostrarIngreso == 1 && mostrarSalida == 0 && mostrarDevolucion == 0) {

                header = ['#', 'Movimiento', 'Código', 'Equipo', 'N° de Serie', 'Cant. Ingreso', 'Fecha Ingreso',
                    'Nom. Ingreso', 'Email Ingreso', 'Rol Ingreso'];
                data_pdf = data2.map((val, i) => [i + 1,

                    val.es_ingreso_equipo == 1 ? 'Ingreso' : 
                    ( val.es_ingreso_equipo == 2 ? 'Devolución' : 
                        val.es_ingreso_equipo == 0 && val.es_devuelto_equipo == 0 ? 'Salida sin Devolución' : 
                        (val.es_ingreso_equipo == 0 && val.es_devuelto_equipo == 1 ? 'Salida Devuelta' : 'Indefinido')),
    
                    val.codigo_equipo,
                    val.nombre_equipo,
                    val.nro_serie_equipo,

                    val.cantidad_ingreso_equipo,
                    val.fecha_ingreso_equipo ? dateFormatter(val.fecha_ingreso_equipo) : null,
                    val.nombre_ingreso,
                    val.email_ingreso,
                    val.rol_ingreso])

            } else if (mostrarIngreso == 0 && mostrarSalida == 1 && mostrarDevolucion == 0) {

                header = ['#', 'Movimiento', 'Código', 'Equipo', 'N° de Serie', '¿Devuelto?', 'Cod. Salida', 'Nro. Doc. Respons.',
                    'Nom. Respons.', 'Proyecto', 'Cant. Salida', 'Fecha Salida',
                    'Nom. Salida', 'Email Salida', 'Rol Salida'];
                data_pdf = data2.map((val, i) => [i + 1,

                    val.es_ingreso_equipo == 1 ? 'Ingreso' : 
                    ( val.es_ingreso_equipo == 2 ? 'Devolución' : 
                        val.es_ingreso_equipo == 0 && val.es_devuelto_equipo == 0 ? 'Salida sin Devolución' : 
                        (val.es_ingreso_equipo == 0 && val.es_devuelto_equipo == 1 ? 'Salida Devuelta' : 'Indefinido')),
    
                    val.codigo_equipo,
                    val.nombre_equipo,
                    val.nro_serie_equipo,

                    val.es_devuelto_equipo == 1 && val.tiene_merma == 1 ? 'Devuelto con Merma' : val.es_devuelto_equipo == 0 ? "No" : val.es_devuelto_equipo == 1 ? "Sí" : "-",
                    val.codigo_salida_equipo,
                    val.responsable_nro_documento_salida,
                    val.responsable_nombre_salida,
                    val.proyecto_equipo_salida,
                    val.cantidad_salida_equipo,
                    val.fecha_salida_equipo ? dateFormatter(val.fecha_salida_equipo) : null,
                    val.nombre_salida,
                    val.email_salida,
                    val.rol_salida])
            } else if (mostrarIngreso == 0 && mostrarSalida == 0 && mostrarDevolucion == 1) {

                header = ['#', 'Movimiento', 'Código', 'Equipo', 'N° de Serie', 'Nro. Doc. Respons.',
                    'Nom. Respons.', 'Proyecto', 'Cant. Devoluc.', 'Fecha Devoluc.',
                    'Nom. Salida', 'Email Salida', 'Rol Salida'];
                data_pdf = data2.map((val, i) => [i + 1,

                    val.es_ingreso_equipo == 1 ? 'Ingreso' : 
                    ( val.es_ingreso_equipo == 2 ? 'Devolución' : 
                        val.es_ingreso_equipo == 0 && val.es_devuelto_equipo == 0 ? 'Salida sin Devolución' : 
                        (val.es_ingreso_equipo == 0 && val.es_devuelto_equipo == 1 ? 'Salida Devuelta' : 'Indefinido')),
    
                    val.codigo_equipo,
                    val.nombre_equipo,
                    val.nro_serie_equipo,

                    val.nro_documento_responsable_equipo_devolucion,
                    val.nombre_completo_responsable_equipo_devolucion,
                    val.proyecto_equipo_devolucion,
                    val.cantidad_devolucion_equipo,
                    val.fecha_devolucion_equipo ? dateFormatter(val.fecha_devolucion_equipo) : null,
                    val.nombre_devolucion,
                    val.email_devolucion,
                    val.rol_devolucion])
            }


            doc.autoTable({
                head: [header],
                body: data_pdf,

                headStyles: {
                    fillColor: "#dd8022",
                    textColor: "white"
                },
                margin: { top: 29, right: 5, bottom: 10, left: 5 },
                styles: {
                    fontSize: 8 // Reduciendo el tamaño del texto de la tabla
                },
                didDrawPage: function (data) {
                    //let pageCount = doc.internal.getNumberOfPages();
                    addHeadersAndFooters(data);
                },

                didParseCell: function (data) {
                    console.log(data);
                    if(data.section === 'body' && data.column.index === 1) {
                        if(data.cell.raw === 'Ingreso') {
                            data.cell.styles.fillColor = [65, 163, 73];
                            data.cell.styles.textColor = "white";
                        } else if (data.cell.raw === 'Salida Devuelta') {
                            data.cell.styles.fillColor = [201, 66, 56];
                            data.cell.styles.textColor = "white";
                        } else if (data.cell.raw === 'Salida sin Devolución') {
                            data.cell.styles.fillColor = [212, 163, 19];
                            data.cell.styles.textColor = "white";
                        } else if (data.cell.raw === 'Devolución') {
                            data.cell.styles.fillColor = [0, 139, 139];
                            data.cell.styles.textColor = "white";
                        }
                    }
                }
                /* didDrawPage: function (data) {
                    // Footer
                    const pageNumber = doc.internal.getNumberOfPages();
                    const str = `Página ${pageNumber} de ${pageCount}`;
                    doc.setFontSize(10);
                    doc.text(str, data.settings.margin.left, doc.internal.pageSize.height - 10);
                } */
            });

            setTimeout(() => {
                doc.save(`pdf_herramientas_kardex_${new Date().toLocaleDateString()}`);
            }, 1000);

            /* if (status === 200) {
                showSwalMessage('Éxito', 'Usuarios exportados con éxito.', 'success');
            } */
        } catch (error) {
            /* console.error("Error generando PDF:", error);
            console.log(error); */
            showSwalMessage('Error', 'Ha ocurrido un error. Vuelva a intentarlo.', 'error');
        } finally {
            setTimeout(() => {
                setLoadingExportPdf(false);
            }, 1000);
        }
    }

    const showSwalMessage = (titulo, texto, icono) => {
        Swal.fire({
            title: titulo,
            text: texto,
            icon: icono,
            confirmButtonColor: "#339A5B",
        });
    }

  return (
    <div className='flex justify-start flex-col mb-4 flex-wrap relative max-w-96'>

            <div className='flex'>

                <button
                    disabled={loadingExportPdf || !isSingleOptionSelected}
                    className={`max-[495px]:ms-1 max-[467px]:ms-0 max-[315px]:mt-3 px-2 py-1 flex items-center
                        ${loadingExportPdf ? 'cursor-not-allowed opacity-50 bg-red-600 text-white border border-red-600 rounded' : 
                 `bg-white rounded text-red-600 
                 border border-red-600
                 hover:text-white hover:bg-red-600                 
                 `}
                 
                 ${!isSingleOptionSelected ? 
                    'text-red-600 cursor-not-allowed opacity-50 bg-red-600 border border-red-600 rounded' : ''}
                    
                `}

                    onClick={handleGeneratePdf}
                >
                    {loadingExportPdf ?
                        <div className='flex items-center justify-center'>
                            <RotatingLines
                                strokeColor="white"
                                strokeWidth='3'
                                width='22'
                                radius="5"
                                ariaLabel="lines-loading"
                            />
                            <p className='ms-3'>Exportando...</p>
                        </div>
                        : <>
                            <FaFilePdf className='me-3' /> Exportar PDF
                        </>
                    }
                </button>


            </div>
        </div>
  )
}
