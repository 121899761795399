import React, { useState, useEffect } from 'react';
import { RotatingLines } from 'react-loader-spinner';

import AuthUser from '../../../../pages/pagesAuth/AuthUser';
import Modal from '../../../modals/Modal';
import Config from '../../../../Config';

import { FaPlus } from "react-icons/fa";

import { toast } from 'react-toastify';
import InputForm from '../../../inputs/InputForm';

import AsyncSelect from 'react-select/async';

export default function ModalAnadirSalidaHerramienta({ open, onClose, updateHerramientaList }) {

    const { getRol, getToken } = AuthUser();

    const [userToken, setUserToken] = useState('');
    const [herramientaId, setHerramientaId] = useState('');
    const [tipoDocumentoId, setTipoDocumentoId] = useState('1');
    const [nroDocResponsable, setNroDocResponsable] = useState('');
    const [nombreResponsable, setNombreResponsable] = useState('');
    const [correoResponsable, setCorreoResponsable] = useState('');
    const [proyectoHerramienta, setProyectoHerramienta] = useState('');
    const [cantidad, setCantidad] = useState('');
    const [fechaSalida, setFechaSalida] = useState('');

    const [arrayTipoDocumentos, setArrayTipoDocumentos] = useState([]);
    const [nroDocWarning, setNroDocWarning] = useState('');

    // Función para formatear la fecha
    const formatFechaForDB = (dateString) => {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = "00";
        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    };

    //Variable para restringir fecha
    const now = new Date();
    const now_herramienta = formatFechaForDB(now);

    //Mostrar datos actuales de ingreso, salida y saldo
    const [labelIngreso, setLabelIngreso] = useState('');
    const [labelSalida, setLabelSalida] = useState('');
    const [labelSaldo, setLabelSaldo] = useState('');

    const [labelCantOptimo, setLabelCantOptimo] = useState('');
    const [labelCantBueno, setLabelCantBueno] = useState('');
    const [labelCantFueraS, setLabelCantFueraS] = useState('');
    const [labelCantFaltaMante, setLabelCantFaltaMante] = useState('');

    const [errors, setErrors] = useState({});
    const [loadingSubmit, setloadingSubmit] = useState(false);

    const [zIndex, setZIndex] = useState(-1);

    useEffect(() => {
        if (open) {
            document.addEventListener('mousedown', handleClickOutside);
            setUserToken(getToken());
            setZIndex(9999);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
            setZIndex(-1);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
            setErrors({});
        };
    }, [open]);

    let notificationShown = false;
    const loadHerramientasOptions = async (globalFilter) => {
        //Si la notificación ya se mostró
        let notificationShown = false;

        try {
            let response;
            if (getRol() === "Administrador") {
                response = await Config.getSelectHerramientasAdmin(globalFilter);
            } else if (getRol() === "Almacenero") {
                response = await Config.getSelectHerramientasAlmacenero(globalFilter);
            }

            const arrayHerramientas = response.data;
            if (arrayHerramientas.exito == 0) {
                return [];
            }

            if (response.data.errors && !notificationShown) {
                notify(response.data.errors, "error");
                notificationShown = true;
            }

            return arrayHerramientas.map(herramienta => ({
                value: herramienta.id,
                label: `${herramienta.id} - ${herramienta.codigo} - ${herramienta.nombre_herramienta} - ${herramienta.nro_serie_herramienta ? herramienta.nro_serie_herramienta : '-Sin n° serie-'} - ${herramienta.medida}`,
                ingreso: herramienta.ingreso,
                salida: herramienta.salida,
                saldo: herramienta.saldo,
                cantidad_optimo: herramienta.cantidad_optimo,
                cantidad_buen_estado: herramienta.cantidad_buen_estado,
                cantidad_fuera_servicio: herramienta.cantidad_fuera_servicio,
                cantidad_falta_mantenimiento: herramienta.cantidad_falta_mantenimiento
            }));
        } catch (error) {
            console.error("Error:", error);
            if (!notificationShown) {
                notify("Ha ocurrido un error al cargar la lista de herramientas. Vuelva a intentarlo más tarde.", "error");
                notificationShown = true;
            }
            return [];
        }
    };


    const loadTipDocumentosOptions = async () => {
        let getTipoDocumentosList;

        try {
            if (getRol() === "Administrador") {
                getTipoDocumentosList = await Config.getTipoDocumentosRespoAdmin();
            } else if (getRol() === "Almacenero") {
                getTipoDocumentosList = await Config.getTipoDocumentosRespoAlmacenero();
            }

            console.log("TIPO DOC: " + getTipoDocumentosList);

            const arrayTipDocs = getTipoDocumentosList.data;
            if (arrayTipDocs.exito == 0) {
                return [];
            }

            if (getTipoDocumentosList.data.errors && !notificationShown) {
                notify(getTipoDocumentosList.data.errors, "error");
                notificationShown = true;
            }

            return arrayTipDocs.map(tipDoc => ({
                value: tipDoc.id,
                label: `${tipDoc.id} - ${tipDoc.nombre_documento}`,
            }));
        } catch (error) {
            console.error("Error:", error);
            notify("Ha ocurrido un error al cargar los tipos de documentos. Vuelva a intentarlo más tarde.", "error");
        } finally {

        }
    }

    const handleClickOutside = (event) => {
        if (event.target.closest(".modal-content") === null) {
            onClose();
        }
    };

    const handleNroDocumentoFocus = () => {
        if (!tipoDocumentoId) {
            setNroDocWarning('Por favor, seleccione un tipo de documento primero.');
        } else {
            setNroDocWarning('');
        }
    }

    const addNewSalidaHerramienta = async (e) => {
        e.preventDefault();
        setloadingSubmit(true);
        setErrors({});

        const ingresoSalidaData = {
            token_usuario: userToken,
            tipo_documento_responsable_id: tipoDocumentoId.value,
            herramienta_id: herramientaId.value,

            nro_documento_responsable_herramienta_salida: nroDocResponsable,
            nombre_completo_responsable_herramienta_salida: nombreResponsable,
            correo_responsable_herramienta_salida: correoResponsable,
            proyecto_herramienta_salida: proyectoHerramienta,

            cantidad: cantidad,
            fecha_salida: fechaSalida
        };

        console.log("Info del salida herramienta: ", ingresoSalidaData);

        try {
            let response;

            if (getRol() === "Administrador") {
                response = await Config.postGuardarSalidaHerramientaAdmin(ingresoSalidaData);
            } else if (getRol() === "Almacenero") {
                response = await Config.postGuardarSalidaHerramientaAlmacenero(ingresoSalidaData);
            }

            console.log(response);

            if (response.data.errors) {
                setErrors(response.data.errors);
            } else {
                if (response.data.exito == 0) {
                    notify(response.data.message, "error");
                } else if (response.data.exito == 1) {
                    notify("La salida de herramienta ha sido añadido correctamente.", "success");
                    setUserToken('');
                    setHerramientaId('');
                    setTipoDocumentoId('');
                    setNroDocResponsable('');
                    setNombreResponsable('');
                    setCorreoResponsable('');
                    setProyectoHerramienta('');

                    setCantidad('');
                    setFechaSalida('');
                    onClose();
                    updateHerramientaList();
                } else {
                    notify("Ha ocurrido un error. Vuelva a intentarlo más tarde.", "error");
                }
            }

        } catch (error) {
            console.error("Error: ", error);
            notify("Ha ocurrido un error. Vuelva a intentarlo más tarde.", "error");
            updateHerramientaList();
        } finally {
            setloadingSubmit(false);
        };
    }

    const renderErrorMessages = (messages) => {
        return messages.map((msg, index) => (
            <div key={index} style={{ marginLeft: '162px' }}>
                <p className="text-red-500 text-sm -mt-3 mb-3">{msg}</p>
            </div>
        ));
    };

    const notify = (message, type) => {
        toast[type](message, {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
        });
    };

    const getColourStyles = (hasError) => ({
        control: (styles, { isFocused }) => ({
            ...styles,
            backgroundColor: '#F9F9F9',
            borderColor: hasError ? 'red' : (isFocused ? '#339A5B' : '#D9D9D9'),
            boxShadow: hasError ? '1px red' : (isFocused ? '1px #339A5B' : null),
            '&:hover': {
                borderColor: hasError ? 'red' : (isFocused ? '#D9D9D9' : '#339A5B')
            },
        }),

        option: (styles, { data, isDisabled, isFocused, isSelected }) => ({
            ...styles,
            backgroundColor: isDisabled
                ? undefined
                : isSelected
                    ? '#ecf9ec'
                    : isFocused
                        ? '#f2f2f2'
                        : undefined,
            color: isDisabled
                ? '#ccc'
                : isSelected
                    ? 'black'
                    : data.color,
            cursor: isDisabled ? 'not-allowed' : 'default',

            '&:active': {
                backgroundColor: !isDisabled && (isSelected ? 'darkgreen' : '#ecf9ec'),
                color: !isDisabled && (isSelected ? 'white' : 'black')
            }
        }),
        menuPortal: (base) => ({
            ...base,
            zIndex: zIndex
        }),
    });

    const cerrarModal = (e) => {
        e.preventDefault();
        onClose();
    }

    return (
        <Modal open={open} onClose={onClose}>
            <div className='w-100'>
                <h3 className='text-center'>Imagen de Añadir</h3>
                <h3 className='text-lg text-center font-black text-gray-800 w-full mb-8'>Añadir Salida de Herramienta</h3>

                <form>

                    {errors.token_usuario && Array.isArray(errors.token_usuario) && renderErrorMessages(errors.token_usuario)}

                    <div className='flex mb-3 w-full'>
                        <label htmlFor='tipo_documento_responsable_id' className='w-56 flex items-center font-normal text-[#454545]'>Tip. Documento: </label>
                        <AsyncSelect
                            cacheOptions
                            defaultOptions
                            loadOptions={loadTipDocumentosOptions}
                            className={`w-full`}
                            styles={getColourStyles(!!errors.tipo_documento_responsable_id)}
                            id="tipo_documento_responsable_id"
                            value={tipoDocumentoId}
                            onChange={(option, id) => { setTipoDocumentoId(option); console.log(option, id); }}
                            placeholder="Seleccionar tipo de documento..."
                            noOptionsMessage={() => "No se encontraron resultados"}
                            loadingMessage={() => "Cargando..."}
                            menuPortalTarget={document.querySelector('body')}
                        />
                    </div>

                    {errors.tipo_documento_responsable_id && Array.isArray(errors.tipo_documento_responsable_id) && renderErrorMessages(errors.tipo_documento_responsable_id)}


                    <InputForm
                        id="nro_documento"
                        labelName="N° de documento:"
                        inputType="text"
                        value={nroDocResponsable}
                        funcion={(e) => setNroDocResponsable(e.target.value.replace(/[^0-9]/g, ""))}
                        errors={errors.nro_documento_responsable_herramienta_salida}
                        labelWidth="w-56"
                        maxLength={tipoDocumentoId.value === 1 ? '8' : (tipoDocumentoId.value === 2 ? '11' : '0')}
                        onFocus={handleNroDocumentoFocus}
                    />


                    {nroDocWarning && <div style={{ marginLeft: '136px' }}><p className="text-red-500 text-sm -mt-3 mb-3">{nroDocWarning}</p></div>}
                    {errors.nro_documento_responsable_herramienta_salida && Array.isArray(errors.nro_documento_responsable_herramienta_salida) && renderErrorMessages(errors.nro_documento_responsable_herramienta_salida)}

                    <InputForm
                        id="nombre_completo_responsable_herramienta_salida"
                        labelName="Nombre Compl.:"
                        inputType="text"
                        value={nombreResponsable}
                        funcion={(e) => setNombreResponsable(e.target.value)}
                        errors={errors.nombre_completo_responsable_herramienta_salida}
                        labelWidth="w-56"
                    />

                    {errors.nombre_completo_responsable_herramienta_salida && Array.isArray(errors.nombre_completo_responsable_herramienta_salida) && renderErrorMessages(errors.nombre_completo_responsable_herramienta_salida)}

                    <InputForm
                        id="correo_responsable_herramienta_salida"
                        labelName="Correo Respons.:"
                        inputType="text"
                        value={correoResponsable}
                        funcion={(e) => setCorreoResponsable(e.target.value)}
                        errors={errors.correo_responsable_herramienta_salida}
                        labelWidth="w-56"
                    />

                    {errors.correo_responsable_herramienta_salida && Array.isArray(errors.correo_responsable_herramienta_salida) && renderErrorMessages(errors.correo_responsable_herramienta_salida)}

                    <InputForm
                        id="proyecto_herramienta_salida"
                        labelName="Proyecto:"
                        inputType="text"
                        value={proyectoHerramienta}
                        funcion={(e) => setProyectoHerramienta(e.target.value)}
                        errors={errors.proyecto_herramienta_salida}
                        labelWidth="w-56"
                    />

                    {errors.proyecto_herramienta_salida && Array.isArray(errors.proyecto_herramienta_salida) && renderErrorMessages(errors.proyecto_herramienta_salida)}

                    <div className='flex mb-3 w-full'>
                        <label htmlFor='herramienta_id' className='w-56 flex items-center font-normal text-[#454545]'>Herramienta: </label>
                        <AsyncSelect
                            cacheOptions
                            defaultOptions
                            loadOptions={loadHerramientasOptions}
                            className={`w-full`}
                            styles={getColourStyles(!!errors.herramienta_id)}
                            id="herramienta_id"
                            value={herramientaId}
                            onChange={(option, id) => {
                                setLabelIngreso('');
                                setLabelSalida('');
                                setLabelSaldo('');

                                setHerramientaId(option);
                                console.log(option, id);
                                setLabelIngreso(option.ingreso);
                                setLabelSalida(option.salida);
                                setLabelSaldo(option.saldo);
                                setLabelCantOptimo(option.cantidad_optimo);
                                setLabelCantBueno(option.cantidad_buen_estado);
                                setLabelCantFueraS(option.cantidad_fuera_servicio);
                                setLabelCantFaltaMante(option.cantidad_falta_mantenimiento);
                            }}
                            placeholder="Seleccionar herramienta..."
                            noOptionsMessage={() => "No se encontraron resultados"}
                            loadingMessage={() => "Cargando..."}
                            menuPortalTarget={document.querySelector('body')}
                        />
                    </div>

                    {errors.herramienta_id && Array.isArray(errors.herramienta_id) && renderErrorMessages(errors.herramienta_id)}

                    <InputForm
                        id="cantidad"
                        labelName="Cantidad:"
                        inputType="number"
                        value={cantidad}
                        funcion={(e) => setCantidad(e.target.value)}
                        errors={errors.cantidad}
                        labelWidth="w-56"
                    />

                    {errors.cantidad && Array.isArray(errors.cantidad) && renderErrorMessages(errors.cantidad)}

                    {/* FECHA DE SALIDA */}

                    <div className='flex mb-3'>

                        <label htmlFor="date_picker" className={`w-56 flex items-center font-normal text-[#454545]`} style={{ width: "158px" }}>Fecha de Salida:</label>
                        <input
                            value={fechaSalida || ""}
                            onChange={(e) => { setFechaSalida(formatFechaForDB(e.target.value)); console.log(formatFechaForDB(e.target.value)) }}

                            type="datetime-local"
                            max={now_herramienta}
                            className={`px-2 py-1 bg-[#F9F9F9] border border-[#D9D9D9] 
                        rounded-md focus:outline-none focus:border-[#339A5B] focus:ring-1 focus:ring-[#339A5B] 
                        ${errors.fecha_salida ? 'border-red-500' : 'border-slate-300'}`}
                        />

                    </div>

                    {errors.fecha_salida && Array.isArray(errors.fecha_salida) && renderErrorMessages(errors.fecha_salida)}

                    <h4 className='text-sm mb-3 text-gray-600'>Datos de herramienta seleccionada: </h4>

                    <div className='flex justify-between flex-wrap'>

                        <InputForm
                            id="ingreso"
                            labelName="Ingreso:"
                            inputType="number"
                            value={labelIngreso}
                            /* funcion={(e) => setCantidad(e.target.value)} */
                            labelWidth="w-16"
                            isDisabled={true}
                            bgColor='bg-[#e0e0e0]'
                            inputWidth='w-32'
                        />

                        <InputForm
                            id="cantidad_optimo"
                            labelName="Óptimo:"
                            inputType="number"
                            value={labelCantOptimo}
                            /* funcion={(e) => setCantidad(e.target.value)} */
                            labelWidth="w-16"
                            isDisabled={true}
                            bgColor='bg-green-100'
                            inputWidth='w-32'
                        />

                        <InputForm
                            id="cantidad_buen_estado"
                            labelName="Bueno:"
                            inputType="number"
                            value={labelCantBueno}
                            /* funcion={(e) => setCantidad(e.target.value)} */
                            labelWidth="w-16"
                            isDisabled={true}
                            bgColor='bg-orange-100'
                            inputWidth='w-32'
                        />

                        <InputForm
                            id="cantidad_falta_mantenimiento"
                            labelName="Mante.:"
                            inputType="number"
                            value={labelCantFaltaMante}
                            /* funcion={(e) => setCantidad(e.target.value)} */
                            labelWidth="w-16"
                            isDisabled={true}
                            bgColor='bg-yellow-100'
                            inputWidth='w-32'
                        />

                        <InputForm
                            id="cantidad_fuera_servicio"
                            labelName="Desuso:"
                            inputType="number"
                            value={labelCantFueraS}
                            /* funcion={(e) => setCantidad(e.target.value)} */
                            labelWidth="w-16"
                            isDisabled={true}
                            bgColor='bg-red-100'
                            inputWidth='w-32'
                        />

                        <InputForm
                            id="salida"
                            labelName="Salida:"
                            inputType="number"
                            value={labelSalida}
                            /* funcion={(e) => setCantidad(e.target.value)} */
                            labelWidth="w-16"
                            isDisabled={true}
                            bgColor='bg-[#e0e0e0]'
                            inputWidth='w-32'
                        />

                        <InputForm
                            id="saldo"
                            labelName="Saldo:"
                            inputType="number"
                            value={labelSaldo}
                            /* funcion={(e) => setCantidad(e.target.value)} */
                            labelWidth="w-16"
                            isDisabled={true}
                            bgColor='bg-[#e0e0e0]'
                            inputWidth='w-32'
                        />

                    </div>

                    {/* ******************** */}


                    <div className='flex justify-end flex-wrap mb-3 mt-6'>
                        <button
                            disabled={loadingSubmit}
                            className={`flex items-center justify-center py-2 font-semibold 
                            shadow-md rounded-md w-60
                            text-white bg-[#EB8927]
                            me-3
                            max-[439px]:me-0
                            max-[469px]:m-0
                            ${loadingSubmit ? 'cursor-not-allowed opacity-50' : ''}`}

                            onClick={addNewSalidaHerramienta}
                            type="submit">

                            {loadingSubmit ?
                                <div className='flex items-center justify-center w-52'>
                                    <RotatingLines
                                        strokeColor="white"
                                        strokeWidth='3'
                                        width='22'
                                        radius="5"
                                        ariaLabel="lines-loading"
                                    />
                                </div>
                                : <>
                                    <FaPlus /> Guardar Salida Herramienta
                                </>
                            }

                        </button>

                        <button
                            className='py-2 px-4 font-semibold shadow-md rounded-lg w-40
                            max-[426px]:mt-3
                            bg-white text-gray-500'
                            onClick={cerrarModal}
                        >
                            Cerrar
                        </button>
                    </div>

                </form>
            </div>
        </Modal >
    )
}
