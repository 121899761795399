import React, { useState } from 'react';
import * as XLSX from "xlsx";
import * as XLSXStyle from "xlsx-js-style";

import { RotatingLines } from 'react-loader-spinner';
import { FaFileCsv } from 'react-icons/fa';

export default function KardexEquiposExcel({ kardexEquiposData }) {

    const [loadingDataExcel, setLoadingDataExcel] = useState(false);

    const getFormattedDate = () => {
        const date = new Date();
        const options = {
            day: '2-digit',
            month: 'long',
            year: 'numeric',
        };
        return date.toLocaleString('es-ES', options);
    };

    const getFormattedHour = () => {
        const date = new Date();
        const options = {
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: true,
        };
        return date.toLocaleString('es-ES', options);
    };

    const titulo = [{ A: "Reporte | Kardex de Equipos"}, {}];

    const InfoAdicional = {
        A: "Generado por ICB Industrial"
    };

    const FechaActual = {
        A: `Reporte generado el ${getFormattedDate()} a las ${getFormattedHour()}`
    };

    const longitudes = [5, 20, 25, 18, 15, 10, 25, 40, 40, 40, 45, 50, 30, 20, 28, 40, 40, 40, 45, 45, 40, 30, 35, 45, 35, 35, 35, 40];
                    //  A   B   C   D   E   F   G   H   I   J   K   L   M   N   O   P   Q   R   S   T   U   V   W   X   Y   Z   AA AB

    const handleExcelDownload = () => {
        setLoadingDataExcel(true);

        let table = [
            {
                A: "Nro.",
                B: "Movimiento",
                C: "Código",
                D: "Equipo",
                E: "N° de Serie",
                F: "Can. Ingreso",
                G: "Fecha de Ingreso",
                H: "Nombre Usuario que Registró | Ingreso",
                I: "Email Usuario que Registró | Ingreso",
                J: "Rol Usuario que Registró | Ingreso",

                K: "Doc. de Responsable de Equipo | Salida",
                L: "Nombre de Responsable de Equipo | Salida",
                M: "Proyecto | Salida",
                N: "Cantidad Salida",
                O: "Fecha de Salida",
                P: "Nombre Usuario que Registró | Salida",
                Q: "Email Usuario que Registró | Salida",
                R: "Rol Usuario que Registró | Salida",

                S: "¿Devuelto?",
                T: "Cód. de Salida",
                U: "Doc. de Responsable de Equipo | Devolución",
                V: "Nombre de Responsable de Equipo | Devolución",
                W: "Proyecto | Devolución",
                X: "Cantidad | Devolución",
                Y: "Fecha de Salida Devolución",
                Z: "Nombre Usuario que Registró | Devolución",
                AA: "Email Usuario que Registró | Devolución",
                AB: "Rol Usuario que Registró | Devolución",
            },
            ...kardexEquiposData.map((kardexEquipo, index) => ({
                A: index+1,
                B: kardexEquipo.es_ingreso_equipo == 1 ? 'Ingreso' : 
                ( kardexEquipo.es_ingreso_equipo == 2 ? 'Devolución' : 
                    kardexEquipo.es_ingreso_equipo == 0 && kardexEquipo.es_devuelto_equipo == 0 ? 'Salida sin Devolución' : 
                    (kardexEquipo.es_ingreso_equipo == 0 && kardexEquipo.es_devuelto_equipo == 1 ? 'Salida Devuelta' : 'Indefinido')),

                C: kardexEquipo.codigo_equipo,
                D: kardexEquipo.nombre_equipo,
                E: kardexEquipo.nro_serie_equipo,
                F: kardexEquipo.cantidad_ingreso_equipo,
                G: kardexEquipo.fecha_ingreso_equipo ? dateFormatter(kardexEquipo.fecha_ingreso_equipo) : null,
                H: kardexEquipo.nombre_ingreso,
                I: kardexEquipo.email_ingreso,
                J: kardexEquipo.rol_ingreso,

                K: kardexEquipo.responsable_nro_documento_salida,
                L: kardexEquipo.responsable_nombre_salida,
                M: kardexEquipo.proyecto_equipo_salida,
                N: kardexEquipo.cantidad_salida_equipo,
                O: kardexEquipo.fecha_salida_equipo ? dateFormatter(kardexEquipo.fecha_salida_equipo) : null,
                P: kardexEquipo.nombre_salida,
                Q: kardexEquipo.email_salida,
                R: kardexEquipo.rol_salida,

                S: kardexEquipo.codigo_salida_equipo,
                T: (kardexEquipo.es_devuelto_equipo == 1 && kardexEquipo.tiene_merma == 1 ? "Devuelto con Merma" : 
                    (kardexEquipo.es_devuelto_equipo == 1 && kardexEquipo.tiene_merma == 0 ? "Devuelto" : (
                        kardexEquipo.es_devuelto_equipo == 0 ? "No Devuelto" : ""
                    ))),

                U: kardexEquipo.nro_documento_responsable_equipo_devolucion,
                V: kardexEquipo.nombre_completo_responsable_equipo_devolucion,
                W: kardexEquipo.proyecto_equipo_devolucion,
                X: kardexEquipo.cantidad_devolucion_equipo,
                Y: kardexEquipo.fecha_devolucion_equipo,
                Z: kardexEquipo.nombre_devolucion,
                AA: kardexEquipo.email_devolucion,
                AB: kardexEquipo.rol_devolucion,
            }))
        ];

        const dataFinal = [...titulo, FechaActual, {}, ...table, {}, InfoAdicional];

        setTimeout(() => {
            creandoArchivo(dataFinal);
            setLoadingDataExcel(false);
        }, 1000);
    }

    const creandoArchivo = (dataFinal) => {
        const libro = XLSX.utils.book_new();

        const hoja = XLSX.utils.json_to_sheet(dataFinal, {skipHeader: true});

        //Unir celdas
        hoja["!merges"] = [
            XLSX.utils.decode_range("A1:AB1"),
            XLSX.utils.decode_range("A2:AB2"),
        ];

        // Estilo para A1:G1
        const tituloStyle = {
            font: { bold: true, color: { rgb: "b8671c" }, sz: 18 },
            //fill: { fgColor: { rgb: "C5E1A5" } },
            alignment: { horizontal: "center", vertical: "center" }
        };

        // Aplicar estilo a A1:G1
        const tituloCols = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z', 'AA', 'AB'];
        tituloCols.forEach(col => {
            const cell = `${col}1`;
            if (hoja[cell]) {
                hoja[cell].s = tituloStyle;
            }
        });

        //Aplicar estilos a A3
        const reporteStyle = {
            font: { bold: true, color: { rgb: "b8671c" }, sz: 10 },
        };

        //Aplicar estilos a A3
        const reporteCols = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z', 'AA', 'AB'];
        reporteCols.forEach(col => {
            const cell = `${col}3`;
            if (hoja[cell]) {
                hoja[cell].s = reporteStyle;
            }
        });

        // Agregar estilo a los encabezados A5:S5
        const headerStyle = {
            font: { bold: true, color: { rgb: "FFFFFF" } },
            fill: { fgColor: { rgb: "dd7d23" } },
            alignment: { horizontal: "center", vertical: "center" }
        };

        // Aplicar estilo a los encabezados
        const cols = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z', 'AA', 'AB'];
        cols.forEach(col => {
            const cell = `${col}5`;
            if (hoja[cell]) {
                hoja[cell].s = headerStyle;
            }
        });

        // Aplicar estilos condicionales a las celdas "MOVIMIENTO"
        dataFinal.forEach((row, rowIndex) => {
            if (rowIndex > 4 && row.B) {  // Saltar encabezados y título
                const cell = `B${rowIndex + 1}`;

                const isActive = (row.B === 'Ingreso' ? '1' : 
                    (row.B === "Salida Devuelta" ? '3' : 
                        row.B === "Salida sin Devolución" ? '0' : 
                    row.B === "Devolución" ? "2" : ""));  // Verificar si el estado es 'Activo'

                const style = {
                    fill: { fgColor: { rgb: isActive == '1' ? "218014" :
                         ( isActive == '3' ? "801414" :
                            isActive == '2' ? '2986c4' :
                            isActive == '0' ? 'c26d27' : '') } },

                    font: { color: { rgb: "FFFFFF" } }
                };
                if (hoja[cell]) {
                    hoja[cell].s = style;
                }
            }
        });

        // Aplicar estilos condicionales a las celdas "Devuelto?"
        dataFinal.forEach((row, rowIndex) => {
            if (rowIndex > 4 && row.T) {  // Saltar encabezados y título
                const cell = `T${rowIndex + 1}`;

                const isActive = (row.T === 'Devuelto' ? '1' : 
                    (row.T === "No Devuelto" ? '3' : 
                        row.T === "Devuelto con Merma" ? '0' : 
                     ""));  // Verificar si el estado es 'Activo'

                const style = {
                    fill: { fgColor: { rgb: isActive == '1' ? "218014" :
                         ( isActive == '3' ? "801414" :
                            isActive == '0' ? 'e3ac05' : '') } },

                    font: { color: { rgb: "FFFFFF" } }
                };

                if (hoja[cell]) {
                    hoja[cell].s = style;
                }
            }
        });

        let propiedades = [];

        longitudes.forEach((col) => {
            propiedades.push({
                width: col,
            });
        });

        hoja["!cols"] = propiedades;

        XLSXStyle.utils.book_append_sheet(libro, hoja, "Kardex Equipos");

        XLSXStyle.writeFile(libro, `excel_kardex_equipos_${new Date().toLocaleDateString()}.xlsx`);
    };

    const dateFormatter = (dateString) => {
        const parsedDate = new Date(dateString).toLocaleString('es', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
            hour12: true
        });
        return parsedDate;
    };

  return (
    <div>
        <button disabled={loadingDataExcel}
        className={`max-[495px]:ms-1 max-[467px]:ms-0 max-[315px]:mt-3
            ${loadingDataExcel ? 'bg-lime-600 text-white cursor-not-allowed opacity-50 rounded border border-lime-600' :
            `bg-white rounded text-lime-600 
            border border-lime-600
            hover:text-white hover:bg-lime-600 `}
            px-2 py-1 flex items-center 
            `}
               onClick={handleExcelDownload}
        >
            {loadingDataExcel ?
                        <div className='flex items-center justify-center'>
                            <RotatingLines
                                strokeColor="white"
                                strokeWidth='3'
                                width='22'
                                radius="5"
                                ariaLabel="lines-loading"
                            />
                            <p className='ms-3'>Exportando...</p>
                        </div>
                        : <>
                            <FaFileCsv className='me-3' /> Exportar Excel
                        </>
                    }
        </button>
    </div>
  )
}
