import React, { useState } from 'react';
import * as XLSX from "xlsx";
import * as XLSXStyle from "xlsx-js-style";

import { RotatingLines } from 'react-loader-spinner';
import { FaFileCsv } from 'react-icons/fa';

export default function InsumosExcel({ insumosData }) {

    const [loadingDataExcel, setLoadingDataExcel] = useState(false);

    const getFormattedDate = () => {
        const date = new Date();
        const options = {
            day: '2-digit',
            month: 'long',
            year: 'numeric',
        };
        return date.toLocaleString('es-ES', options);
    };

    const getFormattedHour = () => {
        const date = new Date();
        const options = {
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: true,
        };
        return date.toLocaleString('es-ES', options);
    };

    const titulo = [{ A: "Reporte de Insumos"}, {}];

    const InfoAdicional = {
        A: "Generado por ICB Industrial"
    };

    const FechaActual = {
        A: `Reporte generado el ${getFormattedDate()} a las ${getFormattedHour()}`
    };

    const longitudes = [5, 15, 25, 18, 15, 20, 25, 20, 20, 20, 20, 20, 20, 20, 20];

    const handleExcelDownload = () => {
        setLoadingDataExcel(true);

        let table = [
            {
                A: "Nro.",
                B: "Categoría",
                C: "Proveedor",
                D: "Nombre de Insumo",
                E: "Código",
                F: "Marca",
                G: "Color",
                H: "Descripción",
                I: "Ingreso",
                J: "Salida",
                K: "Saldo",
                L: "Estado",
            },
            ...insumosData.map((insumo, index) => ({
                A: index+1,
                B: insumo.categoria_insumo ? insumo.categoria_insumo.nombre_categoria_insumo : '-',
                C: insumo.proveedor ? insumo.proveedor.nombre_proveedor : '-',
                D: insumo.nombre,
                E: insumo.codigo ? insumo.codigo : '-',
                F: insumo.marca_insumo ? insumo.marca_insumo.nombre_marca_insumo : '-',
                G: insumo.color ? insumo.color : '-',
                H: insumo.descripcion ? insumo.descripcion : '-',
                I: insumo.ingreso,
                J: insumo.salida,
                K: insumo.saldo,
                L: insumo.estado == 1 ? 'Con Stock' : 'Sin Stock',
            }))
        ];

        const dataFinal = [...titulo, FechaActual, {}, ...table, {}, InfoAdicional];

        setTimeout(() => {
            creandoArchivo(dataFinal);
            setLoadingDataExcel(false);
        }, 1000);
    }

    const creandoArchivo = (dataFinal) => {
        const libro = XLSX.utils.book_new();

        const hoja = XLSX.utils.json_to_sheet(dataFinal, {skipHeader: true});

        //Unir celdas
        hoja["!merges"] = [
            XLSX.utils.decode_range("A1:L1"),
            XLSX.utils.decode_range("A2:L2"),
        ];

        // Estilo para A1:G1
        const tituloStyle = {
            font: { bold: true, color: { rgb: "ca7521" }, sz: 18 },
            //fill: { fgColor: { rgb: "C5E1A5" } },
            alignment: { horizontal: "center", vertical: "center" }
        };

        // Aplicar estilo a A1:G1
        const tituloCols = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L'];
        tituloCols.forEach(col => {
            const cell = `${col}1`;
            if (hoja[cell]) {
                hoja[cell].s = tituloStyle;
            }
        });

        //Aplicar estilos a A3
        const reporteStyle = {
            font: { bold: true, color: { rgb: "ca7521" }, sz: 10 },
        };

        //Aplicar estilos a A3
        const reporteCols = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L'];
        reporteCols.forEach(col => {
            const cell = `${col}3`;
            if (hoja[cell]) {
                hoja[cell].s = reporteStyle;
            }
        });

        // Agregar estilo a los encabezados A5:O5
        const headerStyle = {
            font: { bold: true, color: { rgb: "FFFFFF" } },
            fill: { fgColor: { rgb: "dd8022" } },
            alignment: { horizontal: "center", vertical: "center" }
        };

        // Aplicar estilo a los encabezados
        const cols = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L'];
        cols.forEach(col => {
            const cell = `${col}5`;
            if (hoja[cell]) {
                hoja[cell].s = headerStyle;
            }
        });

        // Aplicar estilos condicionales a las celdas "Con Stock"
        dataFinal.forEach((row, rowIndex) => {
            if (rowIndex > 4 && row.L) {  // Saltar encabezados y título
                const cell = `L${rowIndex + 1}`;
                const isActive = row.L == 'Con Stock';  // Verificar si el estado es 'Con Stock'
                const style = {
                    fill: { fgColor: { rgb: isActive ? "218014" : "801414" } },
                    font: { color: { rgb: "FFFFFF" } }
                };
                if (hoja[cell]) {
                    hoja[cell].s = style;
                }
            }
        });

        let propiedades = [];

        longitudes.forEach((col) => {
            propiedades.push({
                width: col,
            });
        });

        hoja["!cols"] = propiedades;

        XLSXStyle.utils.book_append_sheet(libro, hoja, "Insumos");

        XLSXStyle.writeFile(libro, `excel_insumos_${new Date().toLocaleDateString()}.xlsx`);
    };

    const dateFormatter = (dateString) => {
        const parsedDate = new Date(dateString).toLocaleString('es', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
            hour12: true
        });
        return parsedDate;
    };

  return (
    <div>
        <button disabled={loadingDataExcel}
        className={`max-[495px]:ms-1 max-[467px]:ms-0 max-[315px]:mt-3
            ${loadingDataExcel ? 'bg-lime-600 text-white cursor-not-allowed opacity-50 rounded border border-lime-600' :
            `bg-white rounded text-lime-600 
            border border-lime-600
            hover:text-white hover:bg-lime-600 `}
            px-2 py-1 flex items-center 
            `}
               onClick={handleExcelDownload}
        >
            {loadingDataExcel ?
                        <div className='flex items-center justify-center'>
                            <RotatingLines
                                strokeColor="white"
                                strokeWidth='3'
                                width='22'
                                radius="5"
                                ariaLabel="lines-loading"
                            />
                            <p className='ms-3'>Exportando...</p>
                        </div>
                        : <>
                            <FaFileCsv className='me-3' /> Exportar Excel
                        </>
                    }
        </button>
    </div>
  )
}
