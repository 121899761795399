import axios from 'axios';

//Development
//const BASE_API_URL = "http://localhost:8000/api/v1";

//const BASE_API_URL = "http://192.168.1.2:8000/api/v1";

//Producción
const BASE_API_URL = "https://backend-proyecto.inkadeveloper.com/api/v1";

axios.defaults.withCredentials = true;

const getToken = () => {
    const tokenString = localStorage.getItem('token');
    const token = JSON.parse(tokenString);
    return token;
}

export default {

    //Refrescar token
    refreshToken: () => {
        return axios.post(`${BASE_API_URL}/auth/refresh`, {}, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    //Generar backup
    postGenerarBackupMysql: () => {
        return axios.post(`${BASE_API_URL}/admin/generarBackupMysql`, {}, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    //PANEL ADMINISTRATIVO LOGIN, LOGOUT Y RECUPERAR
    getLogin: (data) => axios.post(`${BASE_API_URL}/auth/login`, data),

    getLogout: () => {

        return axios.post(`${BASE_API_URL}/auth/logout`, {}, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        })
    },

    postRecuperar: (data) => axios.post(`${BASE_API_URL}/mail/recuperar_contrasena`, data),
    postValidarCodigo: (data) => axios.post(`${BASE_API_URL}/mail/validar_codigo`, data),
    postCambiaContrasenia: (data) => axios.post(`${BASE_API_URL}/mail/cambiar_contrasenia`, data),

    postEnviarMensajeContacto: (data) => axios.post(`${BASE_API_URL}/mail/enviar_mensaje_contacto`, data),

    //Admin y gerente
    //Obtener todos los usuarios
    getUsersListAdmin: (page, perPage, globalFilter, columnFilters, sortingColumns) => {
        return axios.get(`${BASE_API_URL}/admin/getUsers`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                page: page + 1,
                per_page: perPage,
                global_filter: globalFilter,
                column_filters: columnFilters,
                sorting: sortingColumns
            }
        });
    },

    getUsersListGerente: (page, perPage, globalFilter, columnFilters, sortingColumns) => {
        return axios.get(`${BASE_API_URL}/gerente/getUsers`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                page: page + 1,
                per_page: perPage,
                global_filter: globalFilter,
                column_filters: columnFilters,
                sorting: sortingColumns
            }
        });
    },

    //Obtener roles
    getRolesListAdmin: () => {
        return axios.get(`${BASE_API_URL}/admin/getRoles`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
        });
    },

    //Obtener usuario por ID
    getUserListAdmin: (id) => {
        return axios.get(`${BASE_API_URL}/admin/getUser/${id}`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    getUserListGerente: (id) => {
        return axios.get(`${BASE_API_URL}/gerente/getUser/${id}`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    //Actualizar usuario por ID
    putUpdateUserAdmin: (id, data) => {
        return axios.put(`${BASE_API_URL}/admin/UpdateUser/${id}`, data, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    putUpdateUserGerente: (id, data) => {
        return axios.put(`${BASE_API_URL}/gerente/UpdateUser/${id}`, data, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    //Eliminar usuario por ID
    deleteUserAdmin: (id) => {
        return axios.delete(`${BASE_API_URL}/admin/DeleteUser/${id}`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    deleteUserGerente: (id) => {
        return axios.delete(`${BASE_API_URL}/gerente/DeleteUser/${id}`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    //Agregar nuevo usuario
    addNewUserAdmin: (data) => {
        return axios.post(`${BASE_API_URL}/admin/storeUser`, data, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    addNewUserGerente: (data) => {
        return axios.post(`${BASE_API_URL}/gerente/storeUser`, data, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    //Exportar data para PDF y EXCEL
    getExportDataUsersAdmin: () => {
        return axios.get(`${BASE_API_URL}/admin/getExportUsers`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    /* **************************************** PROVEEDORES ********************************************************************* */
    /* Administrador */
    getProveedoresListAdmin: (page, perPage, globalFilter, columnFilters, sortingColumns) => {
        return axios.get(`${BASE_API_URL}/admin/getProveedores`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                page: page + 1,
                per_page: perPage,
                global_filter: globalFilter,
                column_filters: columnFilters,
                sorting: sortingColumns
            }
        });
    },

    /* Gerente */
    getProveedoresLisGerente: (page, perPage, globalFilter, columnFilters, sortingColumns) => {
        return axios.get(`${BASE_API_URL}/gerente/getProveedores`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                page: page + 1,
                per_page: perPage,
                global_filter: globalFilter,
                column_filters: columnFilters,
                sorting: sortingColumns
            }
        });
    },

    postGuardarProveedorAdmin: (data) => {
        return axios.post(`${BASE_API_URL}/admin/storeProveedor`, data, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    getOneProveedorAdmin:(id) => {
        return axios.get(`${BASE_API_URL}/admin/getProveedor/${id}`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    putUpdateProveedorAdmin: (id, data) => {
        return axios.put(`${BASE_API_URL}/admin/updateProveedor/${id}`, data, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    deleteProveedorAdmin: (id) => {
        return axios.delete(`${BASE_API_URL}/admin/deleteProveedor/${id}`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    /* ******************************************************** CLIENTES ********************************************************************* */
    getClientesListAdmin: (page, perPage, globalFilter, columnFilters, sortingColumns) => {
        return axios.get(`${BASE_API_URL}/admin/getClientes`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                page: page + 1,
                per_page: perPage,
                global_filter: globalFilter,
                column_filters: columnFilters,
                sorting: sortingColumns
            }
        });
    },

    getClientesListGerente: (page, perPage, globalFilter, columnFilters, sortingColumns) => {
        return axios.get(`${BASE_API_URL}/gerente/getClientes`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                page: page + 1,
                per_page: perPage,
                global_filter: globalFilter,
                column_filters: columnFilters,
                sorting: sortingColumns
            }
        });
    },

    postGuardarClienteAdmin: (data) => {
        return axios.post(`${BASE_API_URL}/admin/storeCliente`, data, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    /* Obtener datos para añadir o editar clientes */
    
    getTipoClientesAdmin: () => {
        return axios.get(`${BASE_API_URL}/admin/getTipoClientes`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
        });
    },

    getTipoDocumentosAdmin: () => {
        return axios.get(`${BASE_API_URL}/admin/getTipoDocumentos`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
        });
    },

    getTipoDocumentosRespoAdmin: () => {
        return axios.get(`${BASE_API_URL}/admin/getTipoDocumentosRespo`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
        });
    },

    getTipoDocumentosRespoAlmacenero: () => {
        return axios.get(`${BASE_API_URL}/almacenero/getTipoDocumentosRespo`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
        });
    },

    getTipoDocumentosAlmacenero: () => {
        return axios.get(`${BASE_API_URL}/almacenero/getTipoDocumentos`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
        });
    },

    getDistritosAdmin: () => {
        return axios.get(`${BASE_API_URL}/admin/getDistritos`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
        });
    },

    /* ************************************************************************* */

    getOneClienteAdmin: (id) => {
        return axios.get(`${BASE_API_URL}/admin/getCliente/${id}`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    putUpdateClienteAdmin: (id, data) => {
        return axios.put(`${BASE_API_URL}/admin/updateCliente/${id}`, data, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    deleteClienteAdmin: (id) => {
        return axios.delete(`${BASE_API_URL}/admin/deleteCliente/${id}`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    /* ***************************************************************************************************************************************** */
    /* **************************************** Categorías de Insumos **************************************** */
    /* Administrador */
    getCatInsumosListAdmin: (page, perPage, globalFilter, columnFilters, sortingColumns) => {
        return axios.get(`${BASE_API_URL}/admin/getCategoriasInsumos`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                page: page + 1,
                per_page: perPage,
                global_filter: globalFilter,
                column_filters: columnFilters,
                sorting: sortingColumns
            }
        });
    },

    /* Operario */
    getCatInsumosListAlmacenero: (page, perPage, globalFilter, columnFilters, sortingColumns) => {
        return axios.get(`${BASE_API_URL}/almacenero/getCategoriasInsumos`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                page: page + 1,
                per_page: perPage,
                global_filter: globalFilter,
                column_filters: columnFilters,
                sorting: sortingColumns
            }
        });
    },

    /* Gerente */
    getCatInsumosListGerente: (page, perPage, globalFilter, columnFilters, sortingColumns) => {
        return axios.get(`${BASE_API_URL}/gerente/getCategoriasInsumos`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                page: page + 1,
                per_page: perPage,
                global_filter: globalFilter,
                column_filters: columnFilters,
                sorting: sortingColumns
            }
        });
    },

    /* Gerente */
    getCatInsumosListOperario: (page, perPage, globalFilter, columnFilters, sortingColumns) => {
        return axios.get(`${BASE_API_URL}/operario/getCategoriasInsumos`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                page: page + 1,
                per_page: perPage,
                global_filter: globalFilter,
                column_filters: columnFilters,
                sorting: sortingColumns
            }
        });
    },

    postGuardarCatInsumoAdmin: (data) => {
        return axios.post(`${BASE_API_URL}/admin/storeCategoriasInsumos`, data, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    postGuardarCatInsumoAlmacenero: (data) => {
        return axios.post(`${BASE_API_URL}/almacenero/storeCategoriasInsumos`, data, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    getOneCatInsumoAdmin: (id) => {
        return axios.get(`${BASE_API_URL}/admin/getCategoriaInsumo/${id}`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    getOneCatInsumoAlmacenero: (id) => {
        return axios.get(`${BASE_API_URL}/almacenero/getCategoriaInsumo/${id}`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    putUpdateCatInsumoAdmin: (id, data) => {
        return axios.put(`${BASE_API_URL}/admin/updateCategoriaInsumo/${id}`, data, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    putUpdateCatInsumoAlmacenero: (id, data) => {
        return axios.put(`${BASE_API_URL}/almacenero/updateCategoriaInsumo/${id}`, data, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    deleteCatInsumoAdmin: (id) => {
        return axios.delete(`${BASE_API_URL}/admin/deleteCategoriaInsumo/${id}`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    deleteCatInsumoAlmacenero: (id) => {
        return axios.delete(`${BASE_API_URL}/almacenero/deleteCategoriaInsumo/${id}`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    /* ******************************************************************************************************* */
    /* **************************************** Categorías de Herramientas **************************************** */
    /* Administrador */
    getCatHerramientasListAdmin: (page, perPage, globalFilter, columnFilters, sortingColumns) => {
        return axios.get(`${BASE_API_URL}/admin/getCategoriasHerramientas`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                page: page + 1,
                per_page: perPage,
                global_filter: globalFilter,
                column_filters: columnFilters,
                sorting: sortingColumns
            }
        });
    },

    /* Almacenero */
    getCatHerramientasListAlmacenero: (page, perPage, globalFilter, columnFilters, sortingColumns) => {
        return axios.get(`${BASE_API_URL}/almacenero/getCategoriasHerramientas`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                page: page + 1,
                per_page: perPage,
                global_filter: globalFilter,
                column_filters: columnFilters,
                sorting: sortingColumns
            }
        });
    },

    /* Gerente */
    getCatHerramientasListGerente: (page, perPage, globalFilter, columnFilters, sortingColumns) => {
        return axios.get(`${BASE_API_URL}/gerente/getCategoriasHerramientas`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                page: page + 1,
                per_page: perPage,
                global_filter: globalFilter,
                column_filters: columnFilters,
                sorting: sortingColumns
            }
        });
    },

    /* Operario */
    getCatHerramientasListOperario: (page, perPage, globalFilter, columnFilters, sortingColumns) => {
        return axios.get(`${BASE_API_URL}/operario/getCategoriasHerramientas`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                page: page + 1,
                per_page: perPage,
                global_filter: globalFilter,
                column_filters: columnFilters,
                sorting: sortingColumns
            }
        });
    },

    postGuardarCatHerramientaAdmin: (data) => {
        return axios.post(`${BASE_API_URL}/admin/storeCategoriasHerramientas`, data, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    postGuardarCatHerramientaAlmacenero: (data) => {
        return axios.post(`${BASE_API_URL}/almacenero/storeCategoriasHerramientas`, data, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    getOneCatHerramientaAdmin: (id) => {
        return axios.get(`${BASE_API_URL}/admin/getCategoriaHerramienta/${id}`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    getOneCatHerramientaAlmacenero: (id) => {
        return axios.get(`${BASE_API_URL}/almacenero/getCategoriaHerramienta/${id}`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    putUpdateCatHerramientaAdmin: (id, data) => {
        return axios.put(`${BASE_API_URL}/admin/updateCategoriaHerramienta/${id}`, data, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    putUpdateCatHerramientaAlmacenero: (id, data) => {
        return axios.put(`${BASE_API_URL}/almacenero/updateCategoriaHerramienta/${id}`, data, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    deleteCatHerramientaAdmin: (id) => {
        return axios.delete(`${BASE_API_URL}/admin/deleteCategoriaHerramienta/${id}`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    /* ***************************************************************************************************************************************** */
    /* **************************************** Marcas de Insumos **************************************** */
    /* Administrador */
    getMarcasInsumosListAdmin: (page, perPage, globalFilter, columnFilters, sortingColumns) => {
        return axios.get(`${BASE_API_URL}/admin/getMarcasInsumos`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                page: page + 1,
                per_page: perPage,
                global_filter: globalFilter,
                column_filters: columnFilters,
                sorting: sortingColumns
            }
        });
    },

    /* Operario */
    getMarcasInsumosListAlmacenero: (page, perPage, globalFilter, columnFilters, sortingColumns) => {
        return axios.get(`${BASE_API_URL}/almacenero/getMarcasInsumos`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                page: page + 1,
                per_page: perPage,
                global_filter: globalFilter,
                column_filters: columnFilters,
                sorting: sortingColumns
            }
        });
    },

    /* Gerente */
    getMarcasInsumosListGerente: (page, perPage, globalFilter, columnFilters, sortingColumns) => {
        return axios.get(`${BASE_API_URL}/gerente/getMarcasInsumos`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                page: page + 1,
                per_page: perPage,
                global_filter: globalFilter,
                column_filters: columnFilters,
                sorting: sortingColumns
            }
        });
    },

    /* Operario */
    getMarcasInsumosListOperario: (page, perPage, globalFilter, columnFilters, sortingColumns) => {
        return axios.get(`${BASE_API_URL}/operario/getMarcasInsumos`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                page: page + 1,
                per_page: perPage,
                global_filter: globalFilter,
                column_filters: columnFilters,
                sorting: sortingColumns
            }
        });
    },

    postGuardarMarcaInsumoAdmin: (data) => {
        return axios.post(`${BASE_API_URL}/admin/storeMarcaInsumo`, data, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    postGuardarMarcaInsumoAlmacenero: (data) => {
        return axios.post(`${BASE_API_URL}/almacenero/storeMarcaInsumo`, data, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    getOneMarcaInsumoAdmin: (id) => {
        return axios.get(`${BASE_API_URL}/admin/getMarcaInsumo/${id}`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    getOneMarcaInsumoAlmacenero: (id) => {
        return axios.get(`${BASE_API_URL}/almacenero/getMarcaInsumo/${id}`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    putUpdateMarcaInsumoAdmin: (id, data) => {
        return axios.put(`${BASE_API_URL}/admin/updateMarcaInsumo/${id}`, data, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    putUpdateMarcaInsumoAlmacenero: (id, data) => {
        return axios.put(`${BASE_API_URL}/almacenero/updateMarcaInsumo/${id}`, data, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    deleteMarcaInsumoAdmin: (id) => {
        return axios.delete(`${BASE_API_URL}/admin/deleteMarcaInsumo/${id}`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    deleteMarcaInsumoAlmacenero: (id) => {
        return axios.delete(`${BASE_API_URL}/almacenero/deleteMarcaInsumo/${id}`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    /* ***************************************************************************************************************************************** */
    /* **************************************** Servicios de Específicos **************************************** */
    /* Administrador */
    getServiciosEspecificosListAdmin: (page, perPage, globalFilter, columnFilters, sortingColumns) => {
        return axios.get(`${BASE_API_URL}/admin/getServiciosEspecificos`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                page: page + 1,
                per_page: perPage,
                global_filter: globalFilter,
                column_filters: columnFilters,
                sorting: sortingColumns
            }
        });
    },

    /* Operario */
    getServiciosEspecificosListAlmacenero: (page, perPage, globalFilter, columnFilters, sortingColumns) => {
        return axios.get(`${BASE_API_URL}/almacenero/getServiciosEspecificos`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                page: page + 1,
                per_page: perPage,
                global_filter: globalFilter,
                column_filters: columnFilters,
                sorting: sortingColumns
            }
        });
    },

    postGuardarServicioEspecificoAdmin: (data) => {
        return axios.post(`${BASE_API_URL}/admin/storeServicioEspecifico`, data, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    postGuardarServicioEspecificoAlmacenero: (data) => {
        return axios.post(`${BASE_API_URL}/almacenero/storeServicioEspecifico`, data, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    getOneServicioEspecificoAdmin: (id) => {
        return axios.get(`${BASE_API_URL}/admin/getServicioEspecifico/${id}`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    getOneServicioEspecificoAlmacenero: (id) => {
        return axios.get(`${BASE_API_URL}/almacenero/getServicioEspecifico/${id}`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    putUpdateServicioEspecificoAdmin: (id, data) => {
        return axios.put(`${BASE_API_URL}/admin/updateServicioEspecifico/${id}`, data, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    putUpdateServicioEspecificoAlmacenero: (id, data) => {
        return axios.put(`${BASE_API_URL}/almacenero/updateServicioEspecifico/${id}`, data, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    deleteServicioEspecificoAdmin: (id) => {
        return axios.delete(`${BASE_API_URL}/admin/deleteServicioEspecifico/${id}`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    /* ******************************************************************************************************* */
    /* **************************************** Insumos **************************************** */
    /* Administrador */
    getInsumosListAdmin: (page, perPage, globalFilter, columnFilters, sortingColumns, alertaInsumosPrincipal) => {
        return axios.get(`${BASE_API_URL}/admin/getInsumos`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                page: page + 1,
                per_page: perPage,
                global_filter: globalFilter,
                column_filters: columnFilters,
                sorting: sortingColumns,

                alerta_insumos_principal: alertaInsumosPrincipal,
            }
        });
    },

    /* Almacenero */
    getInsumosListAlmacenero: (page, perPage, globalFilter, columnFilters, sortingColumns, alertaInsumosPrincipal) => {
        return axios.get(`${BASE_API_URL}/almacenero/getInsumos`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                page: page + 1,
                per_page: perPage,
                global_filter: globalFilter,
                column_filters: columnFilters,
                sorting: sortingColumns,

                alerta_insumos_principal: alertaInsumosPrincipal,
            }
        });
    },

    getInsumosListGerente: (page, perPage, globalFilter, columnFilters, sortingColumns, alertaInsumosPrincipal) => {
        return axios.get(`${BASE_API_URL}/gerente/getInsumos`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                page: page + 1,
                per_page: perPage,
                global_filter: globalFilter,
                column_filters: columnFilters,
                sorting: sortingColumns,

                alerta_insumos_principal: alertaInsumosPrincipal,
            }
        });
    },

    getInsumosListOperario: (page, perPage, globalFilter, columnFilters, sortingColumns) => {
        return axios.get(`${BASE_API_URL}/operario/getInsumos`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                page: page + 1,
                per_page: perPage,
                global_filter: globalFilter,
                column_filters: columnFilters,
                sorting: sortingColumns
            }
        });
    },

    //Listas para almacenar
    getSelectCategoriaInsumosAdmin: (globalFilter) => {
        return axios.get(`${BASE_API_URL}/admin/getSelectCategoriasInsumos`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                global_filter: globalFilter
            }
        });
    },

    getSelectCategoriaInsumosAlmacenero: (globalFilter) => {
        return axios.get(`${BASE_API_URL}/almacenero/getSelectCategoriasInsumos`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                global_filter: globalFilter
            }
        });
    },

    getSelectMarcasInsumosAdmin: (globalFilter) => {
        return axios.get(`${BASE_API_URL}/admin/getSelectMarcasInsumos`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                global_filter: globalFilter
            }
        });
    },

    getSelectMarcasInsumosAlmacenero: (globalFilter) => {
        return axios.get(`${BASE_API_URL}/almacenero/getSelectMarcasInsumos`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                global_filter: globalFilter
            }
        });
    },

    getSelectProveedoresAdmin: (globalFilter) => {
        return axios.get(`${BASE_API_URL}/admin/getSelectProveedores`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                global_filter: globalFilter
            }
        });
    },

    getSelectProveedoresAlmacenero: (globalFilter) => {
        return axios.get(`${BASE_API_URL}/almacenero/getSelectProveedores`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                global_filter: globalFilter
            }
        });
    },

    //******* */
    postGuardarInsumoAdmin: (data) => {
        return axios.post(`${BASE_API_URL}/admin/storeInsumo`, data, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    postGuardarInsumoAlmacenero: (data) => {
        return axios.post(`${BASE_API_URL}/almacenero/storeInsumo`, data, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    getOneInsumoAdmin: (id) => {
        return axios.get(`${BASE_API_URL}/admin/getInsumo/${id}`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    getOneInsumoAlmacenero: (id) => {
        return axios.get(`${BASE_API_URL}/almacenero/getInsumo/${id}`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    putUpdateInsumoAdmin: (id, data) => {
        return axios.put(`${BASE_API_URL}/admin/updateInsumo/${id}`, data, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    putUpdateInsumoAlmacenero: (id, data) => {
        return axios.put(`${BASE_API_URL}/almacenero/updateInsumo/${id}`, data, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    deleteInsumoAdmin: (id) => {
        return axios.delete(`${BASE_API_URL}/admin/deleteInsumo/${id}`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    deleteInsumoAlmacenero: (id) => {
        return axios.delete(`${BASE_API_URL}/almacenero/deleteInsumo/${id}`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    /* ******************************************************************************************************* */
    /* **************************************** Productos **************************************** */
    /* Administrador */
    getHerramientasListAdmin: (page, perPage, globalFilter, columnFilters, sortingColumns, alertaHerramientasSinSalida, alertaHerramientasFueraServicio) => {
        return axios.get(`${BASE_API_URL}/admin/getHerramientas`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                page: page + 1,
                per_page: perPage,
                global_filter: globalFilter,
                column_filters: columnFilters,
                sorting: sortingColumns,

                alerta_herramientas_sin_salida: alertaHerramientasSinSalida,
                alerta_herramientas_fuera_servicio: alertaHerramientasFueraServicio
            }
        });
    },

    /* Almacenero */
    getHerramientasListAlmacenero: (page, perPage, globalFilter, columnFilters, sortingColumns, alertaHerramientasSinSalida, alertaHerramientasFueraServicio) => {
        return axios.get(`${BASE_API_URL}/almacenero/getHerramientas`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                page: page + 1,
                per_page: perPage,
                global_filter: globalFilter,
                column_filters: columnFilters,
                sorting: sortingColumns,

                alerta_herramientas_sin_salida: alertaHerramientasSinSalida,
                alerta_herramientas_fuera_servicio: alertaHerramientasFueraServicio
            }
        });
    },

    /* Gerente */
    getHerramientasListGerente: (page, perPage, globalFilter, columnFilters, sortingColumns, alertaHerramientasSinSalida, alertaHerramientasFueraServicio) => {
        return axios.get(`${BASE_API_URL}/gerente/getHerramientas`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                page: page + 1,
                per_page: perPage,
                global_filter: globalFilter,
                column_filters: columnFilters,
                sorting: sortingColumns,

                alerta_herramientas_sin_salida: alertaHerramientasSinSalida,
                alerta_herramientas_fuera_servicio: alertaHerramientasFueraServicio
            }
        });
    },

    /* Operario */
    getHerramientasListOperario: (page, perPage, globalFilter, columnFilters, sortingColumns) => {
        return axios.get(`${BASE_API_URL}/operario/getHerramientas`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                page: page + 1,
                per_page: perPage,
                global_filter: globalFilter,
                column_filters: columnFilters,
                sorting: sortingColumns
            }
        });
    },

    //Listas para almacenar
    getSelectCategoriaHerramientasAdmin: (globalFilter) => {
        return axios.get(`${BASE_API_URL}/admin/selectCategoriaHerramienta`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                global_filter: globalFilter
            }
        });
    },

    getSelectCategoriaHerramientasAlmacenero: (globalFilter) => {
        return axios.get(`${BASE_API_URL}/almacenero/selectCategoriaHerramienta`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                global_filter: globalFilter
            }
        });
    },

    //******* */
    postGuardarHerramientaAdmin: (data) => {
        return axios.post(`${BASE_API_URL}/admin/storeHerramienta`, data, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    postGuardarHerramientaAlmacenero: (data) => {
        return axios.post(`${BASE_API_URL}/almacenero/storeHerramienta`, data, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    getOneHerramientaAdmin: (id) => {
        return axios.get(`${BASE_API_URL}/admin/getHerramienta/${id}`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    getOneHerramientaAlmacenero: (id) => {
        return axios.get(`${BASE_API_URL}/almacenero/getHerramienta/${id}`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    putUpdateHerramientaAdmin: (id, data) => {
        return axios.put(`${BASE_API_URL}/admin/updateHerramienta/${id}`, data, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    putUpdateHerramientaAlmacenero: (id, data) => {
        return axios.put(`${BASE_API_URL}/almacenero/updateHerramienta/${id}`, data, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    deleteOneHerramientaAdmin: (id) => {
        return axios.delete(`${BASE_API_URL}/admin/deleteHerramienta/${id}`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    deleteOneHerramientaAlmacenero: (id) => {
        return axios.delete(`${BASE_API_URL}/almacenero/deleteHerramienta/${id}`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    /* ******************************************************************************************************* */
    /* **************************************** Insumos **************************************** */
    /* Administrador */
    getServiciosListAdmin: (page, perPage, globalFilter, columnFilters, sortingColumns) => {
        return axios.get(`${BASE_API_URL}/admin/getServicios`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                page: page + 1,
                per_page: perPage,
                global_filter: globalFilter,
                column_filters: columnFilters,
                sorting: sortingColumns
            }
        });
    },

    /* Operario */
    getServiciosListAlmacenero: (page, perPage, globalFilter, columnFilters, sortingColumns) => {
        return axios.get(`${BASE_API_URL}/almacenero/getServicios`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                page: page + 1,
                per_page: perPage,
                global_filter: globalFilter,
                column_filters: columnFilters,
                sorting: sortingColumns
            }
        });
    },

    //Listas para seleccionar cliente
    getSelectClientesAdmin: (globalFilter) => {
        return axios.get(`${BASE_API_URL}/admin/selectClientes`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                global_filter: globalFilter
            }
        });
    },

    getSelectClientesAlmacenero: (globalFilter) => {
        return axios.get(`${BASE_API_URL}/almacenero/selectClientes`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                global_filter: globalFilter
            }
        });
    },

    //Listas para seleccionar servicio específico
    getSelectServiciosEspecificoAdmin: (globalFilter) => {
        return axios.get(`${BASE_API_URL}/admin/selectServiciosEspecificos`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                global_filter: globalFilter
            }
        });
    },

    getSelectServiciosEspecificoAlmacenero: (globalFilter) => {
        return axios.get(`${BASE_API_URL}/almacenero/selectServiciosEspecificos`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                global_filter: globalFilter
            }
        });
    },

    //******* */
    postGuardarServicioAdmin: (data) => {
        return axios.post(`${BASE_API_URL}/admin/storeServicio`, data, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    postGuardarServicioAlmacenero: (data) => {
        return axios.post(`${BASE_API_URL}/almacenero/storeServicio`, data, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    getOneServicioAdmin: (id) => {
        return axios.get(`${BASE_API_URL}/admin/getServicio/${id}`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    getOneServicioAlmacenero: (id) => {
        return axios.get(`${BASE_API_URL}/almacenero/getServicio/${id}`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    putUpdateServicioAdmin: (id, data) => {
        return axios.put(`${BASE_API_URL}/admin/updateServicio/${id}`, data, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    putUpdateServicioAlmacenero: (id, data) => {
        return axios.put(`${BASE_API_URL}/almacenero/updateServicio/${id}`, data, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    deleteOneServicioAdmin: (id) => {
        return axios.delete(`${BASE_API_URL}/admin/deleteServicio/${id}`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    /* ******************************************************************************************************* */
    /* **************************************** Ingreso de Insumos **************************************** */
    /* Administrador */
    getIngresoInsumosListAdmin: (page, perPage, globalFilter, columnFilters, sortingColumns) => {
        return axios.get(`${BASE_API_URL}/admin/getIngresoInsumos`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                page: page + 1,
                per_page: perPage,
                global_filter: globalFilter,
                column_filters: columnFilters,
                sorting: sortingColumns
            }
        });
    },

    /* Operario */
    getIngresoInsumosListAlmacenero: (page, perPage, globalFilter, columnFilters, sortingColumns) => {
        return axios.get(`${BASE_API_URL}/almacenero/getIngresoInsumos`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                page: page + 1,
                per_page: perPage,
                global_filter: globalFilter,
                column_filters: columnFilters,
                sorting: sortingColumns
            }
        });
    },

    /* Gerente */
    getIngresoInsumosListGerente: (page, perPage, globalFilter, columnFilters, sortingColumns) => {
        return axios.get(`${BASE_API_URL}/gerente/getIngresoInsumos`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                page: page + 1,
                per_page: perPage,
                global_filter: globalFilter,
                column_filters: columnFilters,
                sorting: sortingColumns
            }
        });
    },

    /* Operario */
    getIngresoInsumosListOperario: (page, perPage, globalFilter, columnFilters, sortingColumns) => {
        return axios.get(`${BASE_API_URL}/operario/getIngresoInsumos`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                page: page + 1,
                per_page: perPage,
                global_filter: globalFilter,
                column_filters: columnFilters,
                sorting: sortingColumns
            }
        });
    },

    //Listas para seleccionar insumo
    getSelectInsumosAdmin: (globalFilter) => {
        return axios.get(`${BASE_API_URL}/admin/selectInsumos`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                global_filter: globalFilter
            }
        });
    },

    getSelectInsumosAlmacenero: (globalFilter) => {
        return axios.get(`${BASE_API_URL}/almacenero/selectInsumos`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                global_filter: globalFilter
            }
        });
    },

    // getSelectInsumosAlmacenero: (globalFilter) => {
    //     return axios.get(`${BASE_API_URL}/almacenero/selectInsumos`, {
    //         headers: {
    //             Authorization: `Bearer ${getToken()}`
    //         },
    //         params: {
    //             global_filter: globalFilter
    //         }
    //     });
    // },

    //******* */
    postGuardarIngresoInsumoAdmin: (data) => {
        return axios.post(`${BASE_API_URL}/admin/storeIngresoInsumo`, data, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    postGuardarIngresoInsumoAlmacenero: (data) => {
        return axios.post(`${BASE_API_URL}/almacenero/storeIngresoInsumo`, data, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    // postGuardarIngresoInsumoAlmacenero: (data) => {
    //     return axios.post(`${BASE_API_URL}/almacenero/storeIngresoInsumo`, data, {
    //         headers: {
    //             Authorization: `Bearer ${getToken()}`
    //         }
    //     });
    // },

    getOneIngresoInsumoAdmin: (id) => {
        return axios.get(`${BASE_API_URL}/admin/getIngresoInsumo/${id}`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    getOneIngresoInsumoAlmacenero: (id) => {
        return axios.get(`${BASE_API_URL}/almacenero/getIngresoInsumo/${id}`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    // getOneIngresoInsumoAlmacenero: (id) => {
    //     return axios.get(`${BASE_API_URL}/almacenero/getIngresoInsumo/${id}`, {
    //         headers: {
    //             Authorization: `Bearer ${getToken()}`
    //         }
    //     });
    // },

    putUpdateIngresoInsumoAdmin: (id, data) => {
        return axios.put(`${BASE_API_URL}/admin/updateIngresoInsumo/${id}`, data, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    putUpdateIngresoInsumoAlmacenero: (id, data) => {
        return axios.put(`${BASE_API_URL}/almacenero/updateIngresoInsumo/${id}`, data, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    deleteOneIngresoInsumoAdmin: (id) => {
        return axios.delete(`${BASE_API_URL}/admin/deleteIngresoInsumo/${id}`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    deleteOneIngresoInsumoAlmacenero: (id) => {
        return axios.delete(`${BASE_API_URL}/almacenero/deleteIngresoInsumo/${id}`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    /* ******************************************************************************************************* */
    /* **************************************** Salida de Insumos **************************************** */
    /* Administrador */
    getSalidaInsumosListAdmin: (page, perPage, globalFilter, columnFilters, sortingColumns) => {
        return axios.get(`${BASE_API_URL}/admin/getSalidaInsumos`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                page: page + 1,
                per_page: perPage,
                global_filter: globalFilter,
                column_filters: columnFilters,
                sorting: sortingColumns
            }
        });
    },

    /* Almacenero */
    getSalidaInsumosListAlmacenero: (page, perPage, globalFilter, columnFilters, sortingColumns) => {
        return axios.get(`${BASE_API_URL}/almacenero/getSalidaInsumos`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                page: page + 1,
                per_page: perPage,
                global_filter: globalFilter,
                column_filters: columnFilters,
                sorting: sortingColumns
            }
        });
    },

    /* Gerente */
    getSalidaInsumosListGerente: (page, perPage, globalFilter, columnFilters, sortingColumns) => {
        return axios.get(`${BASE_API_URL}/gerente/getSalidaInsumos`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                page: page + 1,
                per_page: perPage,
                global_filter: globalFilter,
                column_filters: columnFilters,
                sorting: sortingColumns
            }
        });
    },

    /* Operario */
    getSalidaInsumosListOperario: (page, perPage, globalFilter, columnFilters, sortingColumns) => {
        return axios.get(`${BASE_API_URL}/operario/getSalidaInsumos`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                page: page + 1,
                per_page: perPage,
                global_filter: globalFilter,
                column_filters: columnFilters,
                sorting: sortingColumns
            }
        });
    },

    //******* */
    postGuardarSalidaInsumoAdmin: (data) => {
        return axios.post(`${BASE_API_URL}/admin/storeSalidaInsumo`, data, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    postGuardarSalidaInsumoAlmacenero: (data) => {
        return axios.post(`${BASE_API_URL}/almacenero/storeSalidaInsumo`, data, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    getOneSalidaInsumoAdmin: (id) => {
        return axios.get(`${BASE_API_URL}/admin/getSalidaInsumo/${id}`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    getOneSalidaInsumoAlmacenero: (id) => {
        return axios.get(`${BASE_API_URL}/almacenero/getSalidaInsumo/${id}`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    putUpdateSalidaInsumoAdmin: (id, data) => {
        return axios.put(`${BASE_API_URL}/admin/updateSalidaInsumo/${id}`, data, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    putUpdateSalidaInsumoAlmacenero: (id, data) => {
        return axios.put(`${BASE_API_URL}/almacenero/updateSalidaInsumo/${id}`, data, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    deleteOneSalidaInsumoAdmin: (id) => {
        return axios.delete(`${BASE_API_URL}/admin/deleteSalidaInsumo/${id}`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    deleteOneSalidaInsumoAlmacenero: (id) => {
        return axios.delete(`${BASE_API_URL}/almacenero/deleteSalidaInsumo/${id}`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    /* ******************************************************************************************************* */
    /* **************************************** Reintegro de Insumos **************************************** */
    /* Administrador */
    getReintegroInsumosListAdmin: (page, perPage, globalFilter, columnFilters, sortingColumns) => {
        return axios.get(`${BASE_API_URL}/admin/getReintegroInsumos`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                page: page + 1,
                per_page: perPage,
                global_filter: globalFilter,
                column_filters: columnFilters,
                sorting: sortingColumns
            }
        });
    },

    getReintegroInsumosListAlmacenero: (page, perPage, globalFilter, columnFilters, sortingColumns) => {
        return axios.get(`${BASE_API_URL}/almacenero/getReintegroInsumos`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                page: page + 1,
                per_page: perPage,
                global_filter: globalFilter,
                column_filters: columnFilters,
                sorting: sortingColumns
            }
        });
    },

    getReintegroInsumosListGerente: (page, perPage, globalFilter, columnFilters, sortingColumns) => {
        return axios.get(`${BASE_API_URL}/gerente/getReintegroInsumos`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                page: page + 1,
                per_page: perPage,
                global_filter: globalFilter,
                column_filters: columnFilters,
                sorting: sortingColumns
            }
        });
    },

    getReintegroInsumosListOperario: (page, perPage, globalFilter, columnFilters, sortingColumns) => {
        return axios.get(`${BASE_API_URL}/operario/getReintegroInsumos`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                page: page + 1,
                per_page: perPage,
                global_filter: globalFilter,
                column_filters: columnFilters,
                sorting: sortingColumns
            }
        });
    },

    //Buscar no reintegrados aun
    getSelectSalidasInsumosAdmin: (globalFilter) => {
        return axios.get(`${BASE_API_URL}/admin/getOnlySalidaInsumo`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                global_filter: globalFilter
            }
        });
    },

    getSelectSalidasInsumosAlmacenero: (globalFilter) => {
        return axios.get(`${BASE_API_URL}/almacenero/getOnlySalidaInsumo`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                global_filter: globalFilter
            }
        });
    },

    //Actualizar reintegrar insumos
    putUpdateOneSalidaReintegrarInsumoAdmin: (id, data) => {
        return axios.put(`${BASE_API_URL}/admin/updateReintegrarSalidaInsumo/${id}`, data, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    putUpdateOneSalidaReintegrarInsumoAlmacenero: (id, data) => {
        return axios.put(`${BASE_API_URL}/almacenero/updateReintegrarSalidaInsumo/${id}`, data, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    /* Admin */
    getOneReintegroInsumoAdmin: (id) => {
        return axios.get(`${BASE_API_URL}/admin/getReintegroInsumo/${id}`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    /* Almacenero */
    getOneReintegroInsumoAlmacenero: (id) => {
        return axios.get(`${BASE_API_URL}/almacenero/getReintegroInsumo/${id}`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    // ACTUALIZAR REINTEGRO
    putUpdateReintegroInsumoAdmin: (id, data) => {
        return axios.put(`${BASE_API_URL}/admin/updateReintegroInsumo/${id}`, data, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    putUpdateReintegroInsumoAlmacenero: (id, data) => {
        return axios.put(`${BASE_API_URL}/admin/updateReintegroInsumo/${id}`, data, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },


    /* ******************************************************************************************************* */
    /* **************************************** Ingreso de Herramientas **************************************** */
    /* Administrador */
    getIngresoHerramientasListAdmin: (page, perPage, globalFilter, columnFilters, sortingColumns) => {
        return axios.get(`${BASE_API_URL}/admin/getIngresoHerramientas`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                page: page + 1,
                per_page: perPage,
                global_filter: globalFilter,
                column_filters: columnFilters,
                sorting: sortingColumns
            }
        });
    },

    /* Almacenero */
    getIngresoHerramientasListAlmacenero: (page, perPage, globalFilter, columnFilters, sortingColumns) => {
        return axios.get(`${BASE_API_URL}/almacenero/getIngresoHerramientas`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                page: page + 1,
                per_page: perPage,
                global_filter: globalFilter,
                column_filters: columnFilters,
                sorting: sortingColumns
            }
        });
    },

    /* Gerente */
    getIngresoHerramientasListGerente: (page, perPage, globalFilter, columnFilters, sortingColumns) => {
        return axios.get(`${BASE_API_URL}/gerente/getIngresoHerramientas`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                page: page + 1,
                per_page: perPage,
                global_filter: globalFilter,
                column_filters: columnFilters,
                sorting: sortingColumns
            }
        });
    },

    /* Operario */
    getIngresoHerramientasListOperario: (page, perPage, globalFilter, columnFilters, sortingColumns) => {
        return axios.get(`${BASE_API_URL}/operario/getIngresoHerramientas`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                page: page + 1,
                per_page: perPage,
                global_filter: globalFilter,
                column_filters: columnFilters,
                sorting: sortingColumns
            }
        });
    },

    /* Almacenero */
    // getIngresoProductosListAlmacenero: (page, perPage, globalFilter, columnFilters, sortingColumns) => {
    //     return axios.get(`${BASE_API_URL}/almacenero/getIngresoProductos`, {
    //         headers: {
    //             Authorization: `Bearer ${getToken()}`
    //         },
    //         params: {
    //             page: page + 1,
    //             per_page: perPage,
    //             global_filter: globalFilter,
    //             column_filters: columnFilters,
    //             sorting: sortingColumns
    //         }
    //     });
    // },

    //Listas para seleccionar herramienta
    getSelectHerramientasAdmin: (globalFilter) => {
        return axios.get(`${BASE_API_URL}/admin/selectHerramientas`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                global_filter: globalFilter
            }
        });
    },

    getSelectHerramientasAlmacenero: (globalFilter) => {
        return axios.get(`${BASE_API_URL}/almacenero/selectHerramientas`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                global_filter: globalFilter
            }
        });
    },

    // getSelectProductosAlmacenero: (globalFilter) => {
    //     return axios.get(`${BASE_API_URL}/almacenero/selectProductos`, {
    //         headers: {
    //             Authorization: `Bearer ${getToken()}`
    //         },
    //         params: {
    //             global_filter: globalFilter
    //         }
    //     });
    // },

    //******* */
    postGuardarIngresoHerramientaAdmin: (data) => {
        return axios.post(`${BASE_API_URL}/admin/storeIngresoHerramienta`, data, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    postGuardarIngresoHerramientaAlmacenero: (data) => {
        return axios.post(`${BASE_API_URL}/almacenero/storeIngresoHerramienta`, data, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    // postGuardarIngresoProductoAlmacenero: (data) => {
    //     return axios.post(`${BASE_API_URL}/almacenero/storeIngresoProducto`, data, {
    //         headers: {
    //             Authorization: `Bearer ${getToken()}`
    //         }
    //     });
    // },

    getOneIngresoHerramientaAdmin: (id) => {
        return axios.get(`${BASE_API_URL}/admin/getIngresoHerramienta/${id}`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    getOneIngresoHerramientaAlmacenero: (id) => {
        return axios.get(`${BASE_API_URL}/almacenero/getIngresoHerramienta/${id}`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    putUpdateIngresoHerramientaAdmin: (id, data) => {
        return axios.put(`${BASE_API_URL}/admin/updateIngresoHerramienta/${id}`, data, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    putUpdateIngresoHerramientaAlmacenero: (id, data) => {
        return axios.put(`${BASE_API_URL}/almacenero/updateIngresoHerramienta/${id}`, data, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    deleteOneIngresoHerramientaAdmin: (id) => {
        return axios.delete(`${BASE_API_URL}/admin/deleteIngresoHerramienta/${id}`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    deleteOneIngresoHerramientaAlmacenero: (id) => {
        return axios.delete(`${BASE_API_URL}/almacenero/deleteIngresoHerramienta/${id}`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    /* ******************************************************************************************************* */
    /* **************************************** Salida de Herramientas **************************************** */
    /* Administrador */
    getSalidaHerramientasListAdmin: (page, perPage, globalFilter, columnFilters, sortingColumns, alertaHerramientasSinSalida) => {
        return axios.get(`${BASE_API_URL}/admin/getSalidaHerramientas`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                page: page + 1,
                per_page: perPage,
                global_filter: globalFilter,
                column_filters: columnFilters,
                sorting: sortingColumns,

                alerta_herramientas_sin_salida: alertaHerramientasSinSalida
            }
        });
    },

    /* Almacenero */
    getSalidaHerramientasListAlmacenero: (page, perPage, globalFilter, columnFilters, sortingColumns, alertaHerramientasSinSalida) => {
        return axios.get(`${BASE_API_URL}/almacenero/getSalidaHerramientas`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                page: page + 1,
                per_page: perPage,
                global_filter: globalFilter,
                column_filters: columnFilters,
                sorting: sortingColumns,

                alerta_herramientas_sin_salida: alertaHerramientasSinSalida
            }
        });
    },


    getSalidaHerramientasListGerente: (page, perPage, globalFilter, columnFilters, sortingColumns, alertaHerramientasSinSalida) => {
        return axios.get(`${BASE_API_URL}/gerente/getSalidaHerramientas`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                page: page + 1,
                per_page: perPage,
                global_filter: globalFilter,
                column_filters: columnFilters,
                sorting: sortingColumns,

                alerta_herramientas_sin_salida: alertaHerramientasSinSalida
            }
        });
    },

    getSalidaHerramientasListOperario: (page, perPage, globalFilter, columnFilters, sortingColumns) => {
        return axios.get(`${BASE_API_URL}/operario/getSalidaHerramientas`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                page: page + 1,
                per_page: perPage,
                global_filter: globalFilter,
                column_filters: columnFilters,
                sorting: sortingColumns
            }
        });
    },

    /* Almacenero */
    // getSalidaProductosListAlmacenero: (page, perPage, globalFilter, columnFilters, sortingColumns) => {
    //     return axios.get(`${BASE_API_URL}/almacenero/getSalidaProductos`, {
    //         headers: {
    //             Authorization: `Bearer ${getToken()}`
    //         },
    //         params: {
    //             page: page + 1,
    //             per_page: perPage,
    //             global_filter: globalFilter,
    //             column_filters: columnFilters,
    //             sorting: sortingColumns
    //         }
    //     });
    // },

    //******* */
    postGuardarSalidaHerramientaAdmin: (data) => {
        return axios.post(`${BASE_API_URL}/admin/storeSalidaHerramientas`, data, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    postGuardarSalidaHerramientaAlmacenero: (data) => {
        return axios.post(`${BASE_API_URL}/almacenero/storeSalidaHerramientas`, data, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    // postGuardarSalidaProductoAlmacenero: (data) => {
    //     return axios.post(`${BASE_API_URL}/almacenero/storeSalidaProductos`, data, {
    //         headers: {
    //             Authorization: `Bearer ${getToken()}`
    //         }
    //     });
    // },

    // getSelectClientesAlmacenero: (globalFilter) => {
    //     return axios.get(`${BASE_API_URL}/almacenero/selectClientes`, {
    //         headers: {
    //             Authorization: `Bearer ${getToken()}`
    //         },
    //         params: {
    //             global_filter: globalFilter
    //         }
    //     });
    // },

    getOneSalidaHerramientaAdmin: (id) => {
        return axios.get(`${BASE_API_URL}/admin/getSalidaHerramienta/${id}`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    getOneSalidaHerramientaAlmacenero: (id) => {
        return axios.get(`${BASE_API_URL}/almacenero/getSalidaHerramienta/${id}`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    putUpdateSalidaHerramientaAdmin: (id, data) => {
        return axios.put(`${BASE_API_URL}/admin/updateSalidaHerramienta/${id}`, data, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    putUpdateSalidaHerramientaAlmacenero: (id, data) => {
        return axios.put(`${BASE_API_URL}/almacenero/updateSalidaHerramienta/${id}`, data, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    deleteOneSalidaHerramientaAdmin: (id) => {
        return axios.delete(`${BASE_API_URL}/admin/deleteSalidaHerramienta/${id}`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    deleteOneSalidaHerramientaAlmacenero: (id) => {
        return axios.delete(`${BASE_API_URL}/almacenero/deleteSalidaHerramienta/${id}`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    /* ******************************************************************************************************* */
    /* **************************************** Merma de Herramienta **************************************** */
    /* Administrador */
    getMermaHerramientasListAdmin: (page, perPage, globalFilter, columnFilters, sortingColumns) => {
        return axios.get(`${BASE_API_URL}/admin/getMermaHerramientas`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                page: page + 1,
                per_page: perPage,
                global_filter: globalFilter,
                column_filters: columnFilters,
                sorting: sortingColumns
            }
        });
    },

    /* Almacenero */
    getMermaHerramientasListAlmacenero: (page, perPage, globalFilter, columnFilters, sortingColumns) => {
        return axios.get(`${BASE_API_URL}/almacenero/getMermaHerramientas`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                page: page + 1,
                per_page: perPage,
                global_filter: globalFilter,
                column_filters: columnFilters,
                sorting: sortingColumns
            }
        });
    },


    getMermaHerramientasListGerente: (page, perPage, globalFilter, columnFilters, sortingColumns) => {
        return axios.get(`${BASE_API_URL}/gerente/getMermaHerramientas`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                page: page + 1,
                per_page: perPage,
                global_filter: globalFilter,
                column_filters: columnFilters,
                sorting: sortingColumns
            }
        });
    },

    getMermaHerramientasListOperario: (page, perPage, globalFilter, columnFilters, sortingColumns) => {
        return axios.get(`${BASE_API_URL}/operario/getMermaHerramientas`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                page: page + 1,
                per_page: perPage,
                global_filter: globalFilter,
                column_filters: columnFilters,
                sorting: sortingColumns
            }
        });
    },

    /* ******************************************************************************************************* */
    /* **************************************** Ingreso de Servicios **************************************** */
    /* Administrador */
    getIngresoServiciosListAdmin: (page, perPage, globalFilter, columnFilters, sortingColumns) => {
        return axios.get(`${BASE_API_URL}/admin/getIngresoServicios`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                page: page + 1,
                per_page: perPage,
                global_filter: globalFilter,
                column_filters: columnFilters,
                sorting: sortingColumns
            }
        });
    },

    /* Operario */
    getIngresoServiciosListAlmacenero: (page, perPage, globalFilter, columnFilters, sortingColumns) => {
        return axios.get(`${BASE_API_URL}/almacenero/getIngresoServicios`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                page: page + 1,
                per_page: perPage,
                global_filter: globalFilter,
                column_filters: columnFilters,
                sorting: sortingColumns
            }
        });
    },

    /* Almacenero */
    // getIngresoServiciosListAlmacenero: (page, perPage, globalFilter, columnFilters, sortingColumns) => {
    //     return axios.get(`${BASE_API_URL}/almacenero/getIngresoServicios`, {
    //         headers: {
    //             Authorization: `Bearer ${getToken()}`
    //         },
    //         params: {
    //             page: page + 1,
    //             per_page: perPage,
    //             global_filter: globalFilter,
    //             column_filters: columnFilters,
    //             sorting: sortingColumns
    //         }
    //     });
    // },

    //Listas para seleccionar servicio
    getSelectServiciosAdmin: (globalFilter) => {
        return axios.get(`${BASE_API_URL}/admin/selectServicios`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                global_filter: globalFilter
            }
        });
    },

    getSelectServiciosAlmacenero: (globalFilter) => {
        return axios.get(`${BASE_API_URL}/almacenero/selectServicios`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                global_filter: globalFilter
            }
        });
    },

    // getSelectServiciosAlmacenero: (globalFilter) => {
    //     return axios.get(`${BASE_API_URL}/almacenero/selectServicios`, {
    //         headers: {
    //             Authorization: `Bearer ${getToken()}`
    //         },
    //         params: {
    //             global_filter: globalFilter
    //         }
    //     });
    // },

    //Listas para seleccionar usuario responsable
    getSelectServicioResponsableMermaAdmin: (globalFilter) => {
        return axios.get(`${BASE_API_URL}/admin/getUsuariosResponsables`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                global_filter: globalFilter
            }
        });
    },

    getSelectServicioResponsableMermaAlmacenero: (globalFilter) => {
        return axios.get(`${BASE_API_URL}/almacenero/getUsuariosResponsables`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                global_filter: globalFilter
            }
        });
    },

    // getSelectServicioResponsableMermaAlmacenero: (globalFilter) => {
    //     return axios.get(`${BASE_API_URL}/almacenero/getUsuariosResponsables`, {
    //         headers: {
    //             Authorization: `Bearer ${getToken()}`
    //         },
    //         params: {
    //             global_filter: globalFilter
    //         }
    //     });
    // },

    //******* */
    postGuardarIngresoServicioAdmin: (data) => {
        return axios.post(`${BASE_API_URL}/admin/storeIngresoServicios`, data, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    postGuardarIngresoServicioAlmacenero: (data) => {
        return axios.post(`${BASE_API_URL}/almacenero/storeIngresoServicios`, data, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    // postGuardarIngresoServicioAlmacenero: (data) => {
    //     return axios.post(`${BASE_API_URL}/almacenero/storeIngresoServicios`, data, {
    //         headers: {
    //             Authorization: `Bearer ${getToken()}`
    //         }
    //     });
    // },

    getOneIngresoServicioAdmin: (id) => {
        return axios.get(`${BASE_API_URL}/admin/getIngresoServicio/${id}`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    getOneIngresoServicioAlmacenero: (id) => {
        return axios.get(`${BASE_API_URL}/almacenero/getIngresoServicio/${id}`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    putUpdateIngresoServicioAdmin: (id, data) => {
        return axios.put(`${BASE_API_URL}/admin/updateIngresoServicio/${id}`, data, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    putUpdateIngresoServicioAlmacenero: (id, data) => {
        return axios.put(`${BASE_API_URL}/almacenero/updateIngresoServicio/${id}`, data, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    deleteOneIngresoServicioAdmin: (id) => {
        return axios.delete(`${BASE_API_URL}/admin/deleteIngresoServicio/${id}`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    deleteOneIngresoServicioAlmacenero: (id) => {
        return axios.delete(`${BASE_API_URL}/almacenero/deleteIngresoServicio/${id}`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    /* ******************************************************************************************************* */
    /* **************************************** Salida de Servicios **************************************** */
    /* Administrador */
    getSalidaServiciosListAdmin: (page, perPage, globalFilter, columnFilters, sortingColumns) => {
        return axios.get(`${BASE_API_URL}/admin/getSalidaServicios`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                page: page + 1,
                per_page: perPage,
                global_filter: globalFilter,
                column_filters: columnFilters,
                sorting: sortingColumns
            }
        });
    },

    /* Operario */
    getSalidaServiciosListAlmacenero: (page, perPage, globalFilter, columnFilters, sortingColumns) => {
        return axios.get(`${BASE_API_URL}/almacenero/getSalidaServicios`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                page: page + 1,
                per_page: perPage,
                global_filter: globalFilter,
                column_filters: columnFilters,
                sorting: sortingColumns
            }
        });
    },

    /* Almacenero */
    // getSalidaServiciosListAlmacenero: (page, perPage, globalFilter, columnFilters, sortingColumns) => {
    //     return axios.get(`${BASE_API_URL}/almacenero/getSalidaServicios`, {
    //         headers: {
    //             Authorization: `Bearer ${getToken()}`
    //         },
    //         params: {
    //             page: page + 1,
    //             per_page: perPage,
    //             global_filter: globalFilter,
    //             column_filters: columnFilters,
    //             sorting: sortingColumns
    //         }
    //     });
    // },

    //******* */
    postGuardarSalidaServicioAdmin: (data) => {
        return axios.post(`${BASE_API_URL}/admin/storeSalidaServicio`, data, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    postGuardarSalidaServicioAlmacenero: (data) => {
        return axios.post(`${BASE_API_URL}/almacenero/storeSalidaServicio`, data, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    // postGuardarSalidaServicioAlmacenero: (data) => {
    //     return axios.post(`${BASE_API_URL}/almacenero/storeSalidaServicio`, data, {
    //         headers: {
    //             Authorization: `Bearer ${getToken()}`
    //         }
    //     });
    // },

    getOneSalidaServicioAdmin: (id) => {
        return axios.get(`${BASE_API_URL}/admin/getSalidaServicio/${id}`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    getOneSalidaServicioAlmacenero: (id) => {
        return axios.get(`${BASE_API_URL}/almacenero/getSalidaServicio/${id}`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    putUpdateSalidaServicioAdmin: (id, data) => {
        return axios.put(`${BASE_API_URL}/admin/updateSalidaServicio/${id}`, data, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    putUpdateSalidaServicioAlmacenero: (id, data) => {
        return axios.put(`${BASE_API_URL}/almacenero/updateSalidaServicio/${id}`, data, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    deleteOneSalidaServicioAdmin: (id) => {
        return axios.delete(`${BASE_API_URL}/admin/deleteSalidaServicio/${id}`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    deleteOneSalidaServicioAlmacenero: (id) => {
        return axios.delete(`${BASE_API_URL}/almacenero/deleteSalidaServicio/${id}`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    /* ******************************************************************************************************* */
    /* ************************************** KARDEX INSUMOS ******************************************* */

    /* Administrador */
    getKardexInsumosListAdmin: (page, perPage, globalFilter, columnFilters, sortingColumns, fechaInicial, fechaFinal, mostrarIngreso, mostrarSalida) => {
        return axios.get(`${BASE_API_URL}/admin/getKardexInsumos`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                page: page + 1,
                per_page: perPage,
                global_filter: globalFilter,
                column_filters: columnFilters,
                sorting: sortingColumns,
                fecha_inicial: fechaInicial,
                fecha_final: fechaFinal,
                mostrar_ingreso: mostrarIngreso,
                mostrar_salida: mostrarSalida,
            }
        });
    },

    /* Operario */
    getKardexInsumosListAlmacenero: (page, perPage, globalFilter, columnFilters, sortingColumns, fechaInicial, fechaFinal, mostrarIngreso, mostrarSalida) => {
        return axios.get(`${BASE_API_URL}/almacenero/getKardexInsumos`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                page: page + 1,
                per_page: perPage,
                global_filter: globalFilter,
                column_filters: columnFilters,
                sorting: sortingColumns,
                fecha_inicial: fechaInicial,
                fecha_final: fechaFinal,
                mostrar_ingreso: mostrarIngreso,
                mostrar_salida: mostrarSalida,
            }
        });
    },

    /* Gerente */
    getKardexInsumosListGerente: (page, perPage, globalFilter, columnFilters, sortingColumns, fechaInicial, fechaFinal, mostrarIngreso, mostrarSalida) => {
        return axios.get(`${BASE_API_URL}/gerente/getKardexInsumos`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                page: page + 1,
                per_page: perPage,
                global_filter: globalFilter,
                column_filters: columnFilters,
                sorting: sortingColumns,
                fecha_inicial: fechaInicial,
                fecha_final: fechaFinal,
                mostrar_ingreso: mostrarIngreso,
                mostrar_salida: mostrarSalida,
            }
        });
    },

    /* Operario */
    getKardexInsumosListOperario: (page, perPage, globalFilter, columnFilters, sortingColumns, fechaInicial, fechaFinal, mostrarIngreso, mostrarSalida) => {
        return axios.get(`${BASE_API_URL}/operario/getKardexInsumos`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                page: page + 1,
                per_page: perPage,
                global_filter: globalFilter,
                column_filters: columnFilters,
                sorting: sortingColumns,
                fecha_inicial: fechaInicial,
                fecha_final: fechaFinal,
                mostrar_ingreso: mostrarIngreso,
                mostrar_salida: mostrarSalida,
            }
        });
    },

    /* ******************************************************************************************************* */
    /* ************************************** KARDEX HERRAMIENTAS ******************************************* */

    /* Administrador */
    getKardexHerramientasListAdmin: (page, perPage, globalFilter, columnFilters, sortingColumns, fechaInicial, fechaFinal, mostrarIngreso, mostrarSalida, mostrarDevolucion) => {
        return axios.get(`${BASE_API_URL}/admin/getKardexHerramientas`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                page: page + 1,
                per_page: perPage,
                global_filter: globalFilter,
                column_filters: columnFilters,
                sorting: sortingColumns,
                fecha_inicial: fechaInicial,
                fecha_final: fechaFinal,
                mostrar_ingreso_herramientas: mostrarIngreso,
                mostrar_salida_herramientas: mostrarSalida,
                mostrar_devolucion_herramientas: mostrarDevolucion
            }
        });
    },

    /* Operario */
    getKardexHerramientasListAlmacenero: (page, perPage, globalFilter, columnFilters, sortingColumns, fechaInicial, fechaFinal, mostrarIngreso, mostrarSalida, mostrarDevolucion) => {
        return axios.get(`${BASE_API_URL}/almacenero/getKardexHerramientas`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                page: page + 1,
                per_page: perPage,
                global_filter: globalFilter,
                column_filters: columnFilters,
                sorting: sortingColumns,
                fecha_inicial: fechaInicial,
                fecha_final: fechaFinal,
                mostrar_ingreso_herramientas: mostrarIngreso,
                mostrar_salida_herramientas: mostrarSalida,
                mostrar_devolucion_herramientas: mostrarDevolucion,
            }
        });
    },

    getKardexHerramientasListGerente: (page, perPage, globalFilter, columnFilters, sortingColumns, fechaInicial, fechaFinal, mostrarIngreso, mostrarSalida, mostrarDevolucion) => {
        return axios.get(`${BASE_API_URL}/gerente/getKardexHerramientas`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                page: page + 1,
                per_page: perPage,
                global_filter: globalFilter,
                column_filters: columnFilters,
                sorting: sortingColumns,
                fecha_inicial: fechaInicial,
                fecha_final: fechaFinal,
                mostrar_ingreso_herramientas: mostrarIngreso,
                mostrar_salida_herramientas: mostrarSalida,
                mostrar_devolucion_herramientas: mostrarDevolucion,
            }
        });
    },

    getKardexHerramientasListOperario: (page, perPage, globalFilter, columnFilters, sortingColumns, fechaInicial, fechaFinal, mostrarIngreso, mostrarSalida, mostrarDevolucion) => {
        return axios.get(`${BASE_API_URL}/operario/getKardexHerramientas`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                page: page + 1,
                per_page: perPage,
                global_filter: globalFilter,
                column_filters: columnFilters,
                sorting: sortingColumns,
                fecha_inicial: fechaInicial,
                fecha_final: fechaFinal,
                mostrar_ingreso_herramientas: mostrarIngreso,
                mostrar_salida_herramientas: mostrarSalida,
                mostrar_devolucion_herramientas: mostrarDevolucion,
            }
        });
    },


    /* ******************************************************************************************************* */

    /* ******************************************************************************************************* */
    /* ************************************** KARDEX SERVICIOS ******************************************* */

    /* Administrador */
    getKardexServiciosListAdmin: (page, perPage, globalFilter, columnFilters, sortingColumns, fechaInicial, fechaFinal, mostrarIngreso, mostrarSalida) => {
        return axios.get(`${BASE_API_URL}/admin/getKardexServicios`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                page: page + 1,
                per_page: perPage,
                global_filter: globalFilter,
                column_filters: columnFilters,
                sorting: sortingColumns,
                fecha_inicial: fechaInicial,
                fecha_final: fechaFinal,
                mostrar_ingreso_servicios: mostrarIngreso,
                mostrar_salida_servicios: mostrarSalida
            }
        });
    },

    /* Operario */
    getKardexServiciosListAlmacenero: (page, perPage, globalFilter, columnFilters, sortingColumns, fechaInicial, fechaFinal, mostrarIngreso, mostrarSalida) => {
        return axios.get(`${BASE_API_URL}/almacenero/getKardexServicios`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                page: page + 1,
                per_page: perPage,
                global_filter: globalFilter,
                column_filters: columnFilters,
                sorting: sortingColumns,
                fecha_inicial: fechaInicial,
                fecha_final: fechaFinal,
                mostrar_ingreso_servicios: mostrarIngreso,
                mostrar_salida_servicios: mostrarSalida
            }
        });
    },

    /* Almacenero */
    // getKardexServiciosListAlmacenero: (page, perPage, globalFilter, columnFilters, sortingColumns, fechaInicial, fechaFinal, mostrarIngreso, mostrarSalida) => {
    //     return axios.get(`${BASE_API_URL}/almacenero/getKardexServicios`, {
    //         headers: {
    //             Authorization: `Bearer ${getToken()}`
    //         },
    //         params: {
    //             page: page + 1,
    //             per_page: perPage,
    //             global_filter: globalFilter,
    //             column_filters: columnFilters,
    //             sorting: sortingColumns,
    //             fecha_inicial: fechaInicial,
    //             fecha_final: fechaFinal,
    //             mostrar_ingreso_servicios: mostrarIngreso,
    //             mostrar_salida_servicios: mostrarSalida
    //         }
    //     });
    // },

    /* Gerente */
    getKardexServiciosListGerente: (page, perPage, globalFilter, columnFilters, sortingColumns, fechaInicial, fechaFinal, mostrarIngreso, mostrarSalida) => {
        return axios.get(`${BASE_API_URL}/gerente/getKardexServicios`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                page: page + 1,
                per_page: perPage,
                global_filter: globalFilter,
                column_filters: columnFilters,
                sorting: sortingColumns,
                fecha_inicial: fechaInicial,
                fecha_final: fechaFinal,
                mostrar_ingreso_servicios: mostrarIngreso,
                mostrar_salida_servicios: mostrarSalida
            }
        });
    },


    /* ******************************************************************************************************* */


    /* ******************************************************************************************************* */
    
    /* ******************************************************************************************************* */
    /* ************************************** DASHBOARD Y REPORTES ******************************************* */
    //REPORTE DE USUARIOS
    /* Administrador */
    getUsuariosExportarAdmin: (fechaInicial, fechaFinal) => {
        return axios.get(`${BASE_API_URL}/admin/getReporteUsuarios`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                fecha_inicial: fechaInicial,
                fecha_final: fechaFinal,
            }
        });
    },

    /* Gerente */
    getUsuariosExportarGerente: (fechaInicial, fechaFinal) => {
        return axios.get(`${BASE_API_URL}/gerente/getReporteUsuarios`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                fecha_inicial: fechaInicial,
                fecha_final: fechaFinal,
            }
        });
    },

    /* ************************************** DASHBOARD ******************************************* */
    /* Administrador */
    getDashboardAdmin: (fechaInicial, fechaFinal, filtroAnioBarInsumosSalidas, filtroMesBarInsumosSalidas) => {
        return axios.get(`${BASE_API_URL}/admin/getDashboard`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                fecha_inicial: fechaInicial,
                fecha_final: fechaFinal,                
                filtro_anio_bar_salida_insumos: filtroAnioBarInsumosSalidas,
                filtro_mes_bar_salida_insumos: filtroMesBarInsumosSalidas,
            }
        });
    },

    /* Gerente */
    getDashboardGerente: (fechaInicial, fechaFinal, filtroAnioBarInsumosSalidas, filtroMesBarInsumosSalidas) => {
        return axios.get(`${BASE_API_URL}/gerente/getDashboard`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                fecha_inicial: fechaInicial,
                fecha_final: fechaFinal,
                filtro_anio_bar_salida_insumos: filtroAnioBarInsumosSalidas,
                filtro_mes_bar_salida_insumos: filtroMesBarInsumosSalidas,
            }
        });
    },

    /* Almacenero */
    getDashboardAlmacenero: (fechaInicial, fechaFinal, filtroAnioBarInsumosSalidas, filtroMesBarInsumosSalidas) => {
        return axios.get(`${BASE_API_URL}/almacenero/getDashboard`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                fecha_inicial: fechaInicial,
                fecha_final: fechaFinal,
                filtro_anio_bar_salida_insumos: filtroAnioBarInsumosSalidas,
                filtro_mes_bar_salida_insumos: filtroMesBarInsumosSalidas,
            }
        });
    },

    /* Almacenero PROVEEDOR */
    /* Proveedor */
    getProveedoresLisAlmacenero: (page, perPage, globalFilter, columnFilters, sortingColumns) => {
        return axios.get(`${BASE_API_URL}/almacenero/getProveedores`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                page: page + 1,
                per_page: perPage,
                global_filter: globalFilter,
                column_filters: columnFilters,
                sorting: sortingColumns
            }
        });
    },

    postGuardarProveedorAlmacenero: (data) => {
        return axios.post(`${BASE_API_URL}/almacenero/storeProveedor`, data, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    getOneProveedorAlmacenero:(id) => {
        return axios.get(`${BASE_API_URL}/almacenero/getProveedor/${id}`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    putUpdateProveedorAlmacenero: (id, data) => {
        return axios.put(`${BASE_API_URL}/almacenero/updateProveedor/${id}`, data, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    deleteProveedorAlmacenero: (id) => {
        return axios.delete(`${BASE_API_URL}/almacenero/deleteProveedor/${id}`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    /* Almacenero, gerente y Admin Devolucion Herramientas */
    getOneSalidaDevolucionHerramientaAdmin: (id) => {
        return axios.get(`${BASE_API_URL}/admin/getDevolucionSalidaHerramienta/${id}`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    getOneSalidaDevolucionHerramientaAlmacenero: (id) => {
        return axios.get(`${BASE_API_URL}/almacenero/getDevolucionSalidaHerramienta/${id}`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    getOneSalidaDevolucionHerramientaGerente: (id) => {
        return axios.get(`${BASE_API_URL}/almacenero/getDevolucionSalidaHerramienta/${id}`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    /* Alma, gerente y Admin update devolucion */

    putUpdateOneSalidaDevolucionHerramientaAdmin: (id, data) => {
        return axios.put(`${BASE_API_URL}/admin/updateDevolucionSalidaHerramienta/${id}`, data, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    putUpdateOneSalidaDevolucionHerramientaAlmacenero: (id, data) => {
        return axios.put(`${BASE_API_URL}/almacenero/updateDevolucionSalidaHerramienta/${id}`, data, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    putUpdateOneSalidaDevolucionHerramientaGerente: (id, data) => {
        return axios.put(`${BASE_API_URL}/gerente/updateDevolucionSalidaHerramienta/${id}`, data, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    /* Alma, gerente y Admin filtros de salidas de herramientas para devolución */
    getSelectSalidasHerramientasAdmin: (globalFilter) => {
        return axios.get(`${BASE_API_URL}/admin/getOnlySalidaHerramienta`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                global_filter: globalFilter
            }
        });
    },

    // getSelectSalidasHerramientasGerente: (globalFilter) => {
    //     return axios.get(`${BASE_API_URL}/gerente/getOnlySalidaHerramienta`, {
    //         headers: {
    //             Authorization: `Bearer ${getToken()}`
    //         },
    //         params: {
    //             global_filter: globalFilter
    //         }
    //     });
    // },

    getSelectSalidasHerramientasAlmacenero: (globalFilter) => {
        return axios.get(`${BASE_API_URL}/almacenero/getOnlySalidaHerramienta`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                global_filter: globalFilter
            }
        });
    },

    // EQUIPOS Y MARCAS DE EQUIPOS
    // MARCAS DE EQUIPOS
    getMarcasEquiposListAdmin: (page, perPage, globalFilter, columnFilters, sortingColumns) => {
        return axios.get(`${BASE_API_URL}/admin/getMarcasEquipos`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                page: page + 1,
                per_page: perPage,
                global_filter: globalFilter,
                column_filters: columnFilters,
                sorting: sortingColumns
            }
        });
    },

    getMarcasEquiposListAlmacenero: (page, perPage, globalFilter, columnFilters, sortingColumns) => {
        return axios.get(`${BASE_API_URL}/almacenero/getMarcasEquipos`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                page: page + 1,
                per_page: perPage,
                global_filter: globalFilter,
                column_filters: columnFilters,
                sorting: sortingColumns
            }
        });
    },

    getMarcasEquiposListGerente: (page, perPage, globalFilter, columnFilters, sortingColumns) => {
        return axios.get(`${BASE_API_URL}/gerente/getMarcasEquipos`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                page: page + 1,
                per_page: perPage,
                global_filter: globalFilter,
                column_filters: columnFilters,
                sorting: sortingColumns
            }
        });
    },

    getMarcasEquiposListOperario: (page, perPage, globalFilter, columnFilters, sortingColumns) => {
        return axios.get(`${BASE_API_URL}/operario/getMarcasEquipos`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                page: page + 1,
                per_page: perPage,
                global_filter: globalFilter,
                column_filters: columnFilters,
                sorting: sortingColumns
            }
        });
    },

    postGuardarMarcaEquipoAdmin: (data) => {
        return axios.post(`${BASE_API_URL}/admin/storeMarcaEquipo`, data, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    postGuardarMarcaEquipoAlmacenero: (data) => {
        return axios.post(`${BASE_API_URL}/almacenero/storeMarcaEquipo`, data, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    postGuardarMarcaEquipoGerente: (data) => {
        return axios.post(`${BASE_API_URL}/gerente/storeMarcaEquipo`, data, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    getOneMarcaEquipoAdmin: (id) => {
        return axios.get(`${BASE_API_URL}/admin/getMarcaEquipo/${id}`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    getOneMarcaEquipoAlmacenero: (id) => {
        return axios.get(`${BASE_API_URL}/almacenero/getMarcaEquipo/${id}`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    getOneMarcaEquipoGerente: (id) => {
        return axios.get(`${BASE_API_URL}/gerente/getMarcaEquipo/${id}`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    putUpdateOneMarcaEquipoAdmin: (id, data) => {
        return axios.put(`${BASE_API_URL}/admin/updateMarcaEquipo/${id}`, data, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    putUpdateOneMarcaEquipoAlmacenero: (id, data) => {
        return axios.put(`${BASE_API_URL}/almacenero/updateMarcaEquipo/${id}`, data, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    putUpdateOneMarcaEquipoGerente: (id, data) => {
        return axios.put(`${BASE_API_URL}/gerente/updateMarcaEquipo/${id}`, data, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    deleteMarcaEquipoAdmin: (id) => {
        return axios.delete(`${BASE_API_URL}/admin/deleteMarcaEquipo/${id}`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    deleteMarcaEquipoAlmacenero: (id) => {
        return axios.delete(`${BASE_API_URL}/almacenero/deleteMarcaEquipo/${id}`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    deleteMarcaEquipoGerente: (id) => {
        return axios.delete(`${BASE_API_URL}/gerente/deleteMarcaEquipo/${id}`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    //EQUIPOS
    getEquiposListAdmin: (page, perPage, globalFilter, columnFilters, sortingColumns, alertaEquiposFueraServicio, alertaEquiposSinSalida) => {
        return axios.get(`${BASE_API_URL}/admin/getEquipos`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                page: page + 1,
                per_page: perPage,
                global_filter: globalFilter,
                column_filters: columnFilters,
                sorting: sortingColumns,

                alerta_equipos_fuera_servicio: alertaEquiposFueraServicio,
                alerta_equipos_sin_salida: alertaEquiposSinSalida
            }
        });
    },

    getEquiposListAlmacenero: (page, perPage, globalFilter, columnFilters, sortingColumns, alertaEquiposFueraServicio, alertaEquiposSinSalida) => {
        return axios.get(`${BASE_API_URL}/almacenero/getEquipos`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                page: page + 1,
                per_page: perPage,
                global_filter: globalFilter,
                column_filters: columnFilters,
                sorting: sortingColumns,

                alerta_equipos_fuera_servicio: alertaEquiposFueraServicio,
                alerta_equipos_sin_salida: alertaEquiposSinSalida
            }
        });
    },
    
    getEquiposListGerente: (page, perPage, globalFilter, columnFilters, sortingColumns, alertaEquiposFueraServicio, alertaEquiposSinSalida) => {
        return axios.get(`${BASE_API_URL}/gerente/getEquipos`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                page: page + 1,
                per_page: perPage,
                global_filter: globalFilter,
                column_filters: columnFilters,
                sorting: sortingColumns,

                alerta_equipos_fuera_servicio: alertaEquiposFueraServicio,
                alerta_equipos_sin_salida: alertaEquiposSinSalida
            }
        });
    },

    getEquiposListOperario: (page, perPage, globalFilter, columnFilters, sortingColumns) => {
        return axios.get(`${BASE_API_URL}/operario/getEquipos`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                page: page + 1,
                per_page: perPage,
                global_filter: globalFilter,
                column_filters: columnFilters,
                sorting: sortingColumns
            }
        });
    },

    /* STORE EQUIPOS */
    postGuardarEquipoAdmin: (data) => {
        return axios.post(`${BASE_API_URL}/admin/storeEquipo`, data, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    postGuardarEquipoAlmacenero: (data) => {
        return axios.post(`${BASE_API_URL}/almacenero/storeEquipo`, data, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    postGuardarEquipoGerente: (data) => {
        return axios.post(`${BASE_API_URL}/gerente/storeEquipo`, data, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    /* MOSTRAR DATOS DE UN EQUIPO */
    getOneEquipoAdmin: (id) => {
        return axios.get(`${BASE_API_URL}/admin/getOneEquipo/${id}`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    getOneEquipoAlmacenero: (id) => {
        return axios.get(`${BASE_API_URL}/almacenero/getOneEquipo/${id}`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    getOneEquipoGerente: (id) => {
        return axios.get(`${BASE_API_URL}/gerente/getOneEquipo/${id}`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    /* ACTUALIZAR UN EQUIPO */
    putUpdateOneEquipoAdmin: (id, data) => {
        return axios.put(`${BASE_API_URL}/admin/updateOneEquipo/${id}`, data, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    putUpdateOneEquipoAlmacenero: (id, data) => {
        return axios.put(`${BASE_API_URL}/almacenero/updateOneEquipo/${id}`, data, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    putUpdateOneEquipoGerente: (id, data) => {
        return axios.put(`${BASE_API_URL}/gerente/updateOneEquipo/${id}`, data, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    /* ELIMINAR UN EQUIPO */
    deleteOneEquipoAdmin: (id) => {
        return axios.delete(`${BASE_API_URL}/admin/deleteOneEquipo/${id}`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    deleteOneEquipoAlmacenero: (id) => {
        return axios.delete(`${BASE_API_URL}/almacenero/deleteOneEquipo/${id}`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    deleteOneEquipoGerente: (id) => {
        return axios.delete(`${BASE_API_URL}/gerente/deleteOneEquipo/${id}`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    //CERTIFICACIONES DE EQUIPOS
    getEquiposCertificacionesListAdmin: (page, perPage, globalFilter, columnFilters, sortingColumns) => {
        return axios.get(`${BASE_API_URL}/admin/getEquiposCertificaciones`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                page: page + 1,
                per_page: perPage,
                global_filter: globalFilter,
                column_filters: columnFilters,
                sorting: sortingColumns
            }
        });
    },

    getEquiposCertificacionesListAlmacenero: (page, perPage, globalFilter, columnFilters, sortingColumns) => {
        return axios.get(`${BASE_API_URL}/almacenero/getEquiposCertificaciones`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                page: page + 1,
                per_page: perPage,
                global_filter: globalFilter,
                column_filters: columnFilters,
                sorting: sortingColumns
            }
        });
    },
    
    getEquiposCertificacionesListGerente: (page, perPage, globalFilter, columnFilters, sortingColumns) => {
        return axios.get(`${BASE_API_URL}/gerente/getEquiposCertificaciones`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                page: page + 1,
                per_page: perPage,
                global_filter: globalFilter,
                column_filters: columnFilters,
                sorting: sortingColumns
            }
        });
    },

    /* Subir certificacion */
    postSubirCertificadoEquipoAdmin: (id, data) => {
        return axios.post(`${BASE_API_URL}/admin/uploadCertificacionEquipo/${id}`, data, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    postSubirCertificadoEquipoAlmacenero: (id, data) => {
        return axios.post(`${BASE_API_URL}/almacenero/uploadCertificacionEquipo/${id}`, data, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    // postSubirCertificadoEquipoAdmin: (data) => {
    //     return axios.post(`${BASE_API_URL}/admin/uploadCertificacionEquipo`, data, {
    //         headers: {
    //             Authorization: `Bearer ${getToken()}`
    //         }
    //     });
    // },

    /* MARCAS EQUIPOS SELECT */
    getSelectMarcasEquiposAdmin: (globalFilter) => {
        return axios.get(`${BASE_API_URL}/admin/getSelectMarcasEquipos`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                global_filter: globalFilter
            }
        });
    },

    getSelectMarcasEquiposAlmacenero: (globalFilter) => {
        return axios.get(`${BASE_API_URL}/almacenero/getSelectMarcasEquipos`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                global_filter: globalFilter
            }
        });
    },

    getSelectMarcasEquiposGerente: (globalFilter) => {
        return axios.get(`${BASE_API_URL}/gerente/getSelectMarcasEquipos`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                global_filter: globalFilter
            }
        });
    },

    /* ******************************************************************************************************* */
    /* **************************************** Ingreso de EQUIPOS **************************************** */
    /* Administrador */
    getIngresoEquiposListAdmin: (page, perPage, globalFilter, columnFilters, sortingColumns) => {
        return axios.get(`${BASE_API_URL}/admin/getIngresoEquipos`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                page: page + 1,
                per_page: perPage,
                global_filter: globalFilter,
                column_filters: columnFilters,
                sorting: sortingColumns
            }
        });
    },

    /* Almacenero */
    getIngresoEquiposListAlmacenero: (page, perPage, globalFilter, columnFilters, sortingColumns) => {
        return axios.get(`${BASE_API_URL}/almacenero/getIngresoEquipos`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                page: page + 1,
                per_page: perPage,
                global_filter: globalFilter,
                column_filters: columnFilters,
                sorting: sortingColumns
            }
        });
    },

    /* Gerente */
    getIngresoEquiposListGerente: (page, perPage, globalFilter, columnFilters, sortingColumns) => {
        return axios.get(`${BASE_API_URL}/gerente/getIngresoEquipos`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                page: page + 1,
                per_page: perPage,
                global_filter: globalFilter,
                column_filters: columnFilters,
                sorting: sortingColumns
            }
        });
    },

    /* Operario */
    getIngresoEquiposListOperario: (page, perPage, globalFilter, columnFilters, sortingColumns) => {
        return axios.get(`${BASE_API_URL}/operario/getIngresoEquipos`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                page: page + 1,
                per_page: perPage,
                global_filter: globalFilter,
                column_filters: columnFilters,
                sorting: sortingColumns
            }
        });
    },

    /* INGRESO DE EQUIPOS */
    postGuardarIngresoEquipoAdmin: (data) => {
        return axios.post(`${BASE_API_URL}/admin/storeIngresoEquipo`, data, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    postGuardarIngresoEquipoAlmacenero: (data) => {
        return axios.post(`${BASE_API_URL}/almacenero/storeIngresoEquipo`, data, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    postGuardarIngresoEquipoGerente: (data) => {
        return axios.post(`${BASE_API_URL}/gerente/storeIngresoEquipo`, data, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    getOneIngresoEquipoAdmin: (id) => {
        return axios.get(`${BASE_API_URL}/admin/getIngresoEquipo/${id}`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    getOneIngresoEquipoAlmacenero: (id) => {
        return axios.get(`${BASE_API_URL}/almacenero/getIngresoEquipo/${id}`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    putUpdateIngresoEquipoAdmin: (id, data) => {
        return axios.put(`${BASE_API_URL}/admin/updateIngresoEquipo/${id}`, data, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    putUpdateIngresoEquipoAlmacenero: (id, data) => {
        return axios.put(`${BASE_API_URL}/almacenero/updateIngresoEquipo/${id}`, data, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    //Listas para seleccionar producto
    getSelectEquiposAdmin: (globalFilter) => {
        return axios.get(`${BASE_API_URL}/admin/getSelectEquipos`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                global_filter: globalFilter
            }
        });
    },

    getSelectEquiposAlmacenero: (globalFilter) => {
        return axios.get(`${BASE_API_URL}/almacenero/getSelectEquipos`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                global_filter: globalFilter
            }
        });
    },

    deleteOneIngresoEquipoAdmin: (id) => {
        return axios.delete(`${BASE_API_URL}/admin/deleteIngresoEquipo/${id}`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    deleteOneIngresoEquipoAlmacenero: (id) => {
        return axios.delete(`${BASE_API_URL}/almacenero/deleteIngresoEquipo/${id}`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    /* ******************************************************************************************************* */
    /* **************************************** Salida de Equipos **************************************** */
    /* Administrador */
    getSalidaEquiposListAdmin: (page, perPage, globalFilter, columnFilters, sortingColumns, alertaEquiposSinSalida) => {
        return axios.get(`${BASE_API_URL}/admin/getSalidaEquipos`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                page: page + 1,
                per_page: perPage,
                global_filter: globalFilter,
                column_filters: columnFilters,
                sorting: sortingColumns,

                alerta_equipos_sin_salida: alertaEquiposSinSalida
            }
        });
    },

    /* Almacenero */
    getSalidaEquiposListAlmacenero: (page, perPage, globalFilter, columnFilters, sortingColumns, alertaEquiposSinSalida) => {
        return axios.get(`${BASE_API_URL}/almacenero/getSalidaEquipos`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                page: page + 1,
                per_page: perPage,
                global_filter: globalFilter,
                column_filters: columnFilters,
                sorting: sortingColumns,

                alerta_equipos_sin_salida: alertaEquiposSinSalida
            }
        });
    },

    /* Gerente */
    getSalidaEquiposListGerente: (page, perPage, globalFilter, columnFilters, sortingColumns, alertaEquiposSinSalida) => {
        return axios.get(`${BASE_API_URL}/gerente/getSalidaEquipos`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                page: page + 1,
                per_page: perPage,
                global_filter: globalFilter,
                column_filters: columnFilters,
                sorting: sortingColumns,

                alerta_equipos_sin_salida: alertaEquiposSinSalida
            }
        });
    },

    /* Operario */
    getSalidaEquiposListOperario: (page, perPage, globalFilter, columnFilters, sortingColumns) => {
        return axios.get(`${BASE_API_URL}/operario/getSalidaEquipos`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                page: page + 1,
                per_page: perPage,
                global_filter: globalFilter,
                column_filters: columnFilters,
                sorting: sortingColumns
            }
        });
    },

    postGuardarSalidaEquipoAdmin: (data) => {
        return axios.post(`${BASE_API_URL}/admin/storeSalidaEquipos`, data, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    postGuardarSalidaEquipoAlmacenero: (data) => {
        return axios.post(`${BASE_API_URL}/almacenero/storeSalidaEquipos`, data, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    getOneSalidaEquipoAdmin: (id) => {
        return axios.get(`${BASE_API_URL}/admin/getSalidaEquipo/${id}`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    getOneSalidaEquipoAlmacenero: (id) => {
        return axios.get(`${BASE_API_URL}/almacenero/getSalidaEquipo/${id}`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    putUpdateOneSalidaEquipoAdmin: (id, data) => {
        return axios.put(`${BASE_API_URL}/admin/updateSalidaEquipo/${id}`, data, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    putUpdateOneSalidaEquipoAlmacenero: (id, data) => {
        return axios.put(`${BASE_API_URL}/almacenero/updateSalidaEquipo/${id}`, data, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    deleteOneSalidaEquipoAdmin: (id) => {
        return axios.delete(`${BASE_API_URL}/admin/deleteSalidaEquipo/${id}`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    deleteOneSalidaEquipoAlmacenero: (id) => {
        return axios.delete(`${BASE_API_URL}/almacenero/deleteSalidaEquipo/${id}`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    /* ******************************************************************************************************* */
    /* **************************************** Merma de Herramienta **************************************** */
    /* Administrador */
    getMermaEquiposListAdmin: (page, perPage, globalFilter, columnFilters, sortingColumns) => {
        return axios.get(`${BASE_API_URL}/admin/getMermaEquipos`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                page: page + 1,
                per_page: perPage,
                global_filter: globalFilter,
                column_filters: columnFilters,
                sorting: sortingColumns
            }
        });
    },

    /* Almacenero */
    getMermaEquiposListAlmacenero: (page, perPage, globalFilter, columnFilters, sortingColumns) => {
        return axios.get(`${BASE_API_URL}/almacenero/getMermaEquipos`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                page: page + 1,
                per_page: perPage,
                global_filter: globalFilter,
                column_filters: columnFilters,
                sorting: sortingColumns
            }
        });
    },

    getMermaEquiposListGerente: (page, perPage, globalFilter, columnFilters, sortingColumns) => {
        return axios.get(`${BASE_API_URL}/gerente/getMermaEquipos`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                page: page + 1,
                per_page: perPage,
                global_filter: globalFilter,
                column_filters: columnFilters,
                sorting: sortingColumns
            }
        });
    },

    getMermaEquiposListOperario: (page, perPage, globalFilter, columnFilters, sortingColumns) => {
        return axios.get(`${BASE_API_URL}/operario/getMermaEquipos`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                page: page + 1,
                per_page: perPage,
                global_filter: globalFilter,
                column_filters: columnFilters,
                sorting: sortingColumns
            }
        });
    },

    /* ******************************************************************************************************* */
    /* ************************************** KARDEX EQUIPOS ******************************************* */

    /* Administrador */
    getKardexEquiposListAdmin: (page, perPage, globalFilter, columnFilters, sortingColumns, fechaInicial, fechaFinal, mostrarIngreso, mostrarSalida, mostrarDevolucion) => {
        return axios.get(`${BASE_API_URL}/admin/getKardexEquipos`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                page: page + 1,
                per_page: perPage,
                global_filter: globalFilter,
                column_filters: columnFilters,
                sorting: sortingColumns,
                fecha_inicial: fechaInicial,
                fecha_final: fechaFinal,
                mostrar_ingreso_equipos: mostrarIngreso,
                mostrar_salida_equipos: mostrarSalida,
                mostrar_devolucion_equipos: mostrarDevolucion
            }
        });
    },

    /* Operario */
    getKardexEquiposListAlmacenero: (page, perPage, globalFilter, columnFilters, sortingColumns, fechaInicial, fechaFinal, mostrarIngreso, mostrarSalida, mostrarDevolucion) => {
        return axios.get(`${BASE_API_URL}/almacenero/getKardexEquipos`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                page: page + 1,
                per_page: perPage,
                global_filter: globalFilter,
                column_filters: columnFilters,
                sorting: sortingColumns,
                fecha_inicial: fechaInicial,
                fecha_final: fechaFinal,
                mostrar_ingreso_equipos: mostrarIngreso,
                mostrar_salida_equipos: mostrarSalida,
                mostrar_devolucion_equipos: mostrarDevolucion,
            }
        });
    },

    getKardexEquiposListGerente: (page, perPage, globalFilter, columnFilters, sortingColumns, fechaInicial, fechaFinal, mostrarIngreso, mostrarSalida, mostrarDevolucion) => {
        return axios.get(`${BASE_API_URL}/gerente/getKardexEquipos`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                page: page + 1,
                per_page: perPage,
                global_filter: globalFilter,
                column_filters: columnFilters,
                sorting: sortingColumns,
                fecha_inicial: fechaInicial,
                fecha_final: fechaFinal,
                mostrar_ingreso_equipos: mostrarIngreso,
                mostrar_salida_equipos: mostrarSalida,
                mostrar_devolucion_equipos: mostrarDevolucion,
            }
        });
    },

    getKardexEquiposListOperario: (page, perPage, globalFilter, columnFilters, sortingColumns, fechaInicial, fechaFinal, mostrarIngreso, mostrarSalida, mostrarDevolucion) => {
        return axios.get(`${BASE_API_URL}/operario/getKardexEquipos`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                page: page + 1,
                per_page: perPage,
                global_filter: globalFilter,
                column_filters: columnFilters,
                sorting: sortingColumns,
                fecha_inicial: fechaInicial,
                fecha_final: fechaFinal,
                mostrar_ingreso_equipos: mostrarIngreso,
                mostrar_salida_equipos: mostrarSalida,
                mostrar_devolucion_equipos: mostrarDevolucion,
            }
        });
    },

    /* Almacenero, gerente y Admin Devolucion Equipos */
    getOneSalidaDevolucionEquipoAdmin: (id) => {
        return axios.get(`${BASE_API_URL}/admin/getDevolucionSalidaEquipo/${id}`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    getOneSalidaDevolucionEquipoAlmacenero: (id) => {
        return axios.get(`${BASE_API_URL}/almacenero/getDevolucionSalidaEquipo/${id}`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    getOneSalidaDevolucionEquipoGerente: (id) => {
        return axios.get(`${BASE_API_URL}/almacenero/getDevolucionSalidaEquipo/${id}`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    /* Alma, gerente y Admin update devolucion equipo */

    putUpdateOneSalidaDevolucionEquipoAdmin: (id, data) => {
        return axios.put(`${BASE_API_URL}/admin/updateDevolucionSalidaEquipo/${id}`, data, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    putUpdateOneSalidaDevolucionEquipoAlmacenero: (id, data) => {
        return axios.put(`${BASE_API_URL}/almacenero/updateDevolucionSalidaEquipo/${id}`, data, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    putUpdateOneSalidaDevolucionEquipoGerente: (id, data) => {
        return axios.put(`${BASE_API_URL}/almacenero/updateDevolucionSalidaEquipo/${id}`, data, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    /* Alma, gerente y Admin filtros de salidas de equipos para devolución */
    getSelectSalidasEquiposAdmin: (globalFilter) => {
        return axios.get(`${BASE_API_URL}/admin/getOnlySalidaEquipo`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                global_filter: globalFilter
            }
        });
    },

    getSelectSalidasEquiposGerente: (globalFilter) => {
        return axios.get(`${BASE_API_URL}/gerente/getOnlySalidaEquipo`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                global_filter: globalFilter
            }
        });
    },

    getSelectSalidasEquiposAlmacenero: (globalFilter) => {
        return axios.get(`${BASE_API_URL}/almacenero/getOnlySalidaEquipo`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                global_filter: globalFilter
            }
        });
    },

    //Logs
    /* Administrador */
    getLogsGeneralesListAdmin: (page, perPage, globalFilter, columnFilters, fechaInicial, fechaFinal) => {
        return axios.get(`${BASE_API_URL}/admin/getLogsGenerales`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                page: page + 1,
                per_page: perPage,
                global_filter: globalFilter,
                column_filters: columnFilters,
                fecha_inicial: fechaInicial,
                fecha_final: fechaFinal,
            }
        });
    },

    /* Gerente */
    getLogsGeneralesListGerente: (page, perPage, globalFilter, columnFilters, fechaInicial, fechaFinal) => {
        return axios.get(`${BASE_API_URL}/gerente/getLogsGenerales`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                page: page + 1,
                per_page: perPage,
                global_filter: globalFilter,
                column_filters: columnFilters,
                fecha_inicial: fechaInicial,
                fecha_final: fechaFinal,
            }
        });
    },


    /* ******************************************************************************************************* */
}