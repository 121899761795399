import React, { useEffect, useState } from 'react';
import html2pdf from 'html2pdf.js';
// import { html2pdf } from 'html2pdf.js';
import DashboardCards from '../../components/dashboardComponents/DashboardCards';
import CircleGraphic from '../../components/dashboardComponents/CircleGraphic';

import { FaFilePdf } from 'react-icons/fa';

import { Link, useNavigate } from 'react-router-dom';
import AuthUser from '../pagesAuth/AuthUser';

import { useMs } from '../../context/MsContext';
import Swal from 'sweetalert2';

import SingleCard from '../../components/dashboardComponents/SingleCard';

import { RotatingLines } from 'react-loader-spinner';
import { IoMdWarning } from "react-icons/io";
import { MdErrorOutline } from "react-icons/md";


import { MdOutlineCarCrash } from "react-icons/md";
import { FaTruckLoading } from "react-icons/fa";


import { CiFilter } from "react-icons/ci";
import { FiTool } from "react-icons/fi";
import { GiAbstract072 } from "react-icons/gi";

import { FaBoxOpen } from "react-icons/fa6";
import { BsDatabaseFillDown } from "react-icons/bs";


import Config from '../../Config';


// import BarProductosDefec from '../../components/dashboardComponents/BarGraphics/BarProductosDefec';
import CalendarioInsumos from '../../components/dashboardComponents/Calendario/CalendarioInsumos';
import CalendarioHerramientas from '../../components/dashboardComponents/Calendario/CalendarioHerramientas';
import CalendarioEquipos from '../../components/dashboardComponents/Calendario/CalendarioEquipos';
import BarInsumosSalidas from '../../components/dashboardComponents/BarGraphics/BarInsumosSalidas';

export default function Dashboard() {

  const { setToken, getToken, getRol } = AuthUser();

  // Función para formatear la fecha
  const formatFechaForDB = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = "00";
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  };

  //Función para mostrar fechas como:  "1 de junio 2024"
  const customDateStringFormatter = (dateString) => {
    const date = new Date(dateString);

    const day = date.getDate();
    const monthIndex = date.getMonth();
    const year = date.getFullYear();

    const months = ['enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'];

    const formattedDate = `${day} de ${months[monthIndex]} del ${year}`;

    return formattedDate;
    //return `${day} de ${month} del ${year}, ${hours}:${minutes}:${seconds}`;
  };

  const now = new Date();
  const nowLocalString = formatFechaForDB(now);

  // Fecha inicial: primer día del mes actual
  const fechaInicialDefault = new Date(now.getFullYear(), now.getMonth(), 1);

  const [fechaInicial, setFechaInicial] = useState(formatFechaForDB(fechaInicialDefault));
  const [fechaFinal, setFechaFinal] = useState(formatFechaForDB(now));

  //Alerta de backup
  const [ultimoBak, setUltimoBak] = useState('');
  const [siguienteBak, setSiguienteBak] = useState('');

  //Filtro de gráfico insumos salidas
  const [anioBarInsumosSalidas, setAnioBarInsumosSalidas] = useState(new Date().getFullYear());
  const [mesBarInsumosSalidas, setMesBarInsumosSalidas] = useState(new Date().getMonth() + 1);

  //Fechas formateadas solo dia, mes y año
  const [fechaFormatI, setFechaFormatI] = useState(customDateStringFormatter(fechaInicial));
  const [fechaFormatF, setFechaFormatF] = useState(customDateStringFormatter(fechaFinal));

  //Tarjetas dashboard
  const [insumosRemanente, setInsumosRemanente] = useState(0);
  const [insumosBajo, setInsumosBajo] = useState('');

  const [insumosIngresoT, setInsumosIngresoT] = useState('');
  const [insumoInvertidoT, setInsumoInvertidoT] = useState('');
  const [insumoSalidaT, setInsumoSalidaT] = useState('');

  const [herramientasBajoRemanente, setHerramientasBajoRemanente] = useState(0);
  const [herramientasBajo, setHerramientasBajo] = useState('');

  const [herramientasFueraServicioRemanente, setHerramientasFueraServicioRemanente] = useState(0);
  const [herramientasFueraServicio, setHerramientasFueraServicio] = useState('');

  const [herramientasSinSalidaRemanente, setHerramientasSinSalidaRemanente] = useState(0);
  const [herramientasSinSalida, setHerramientasSinSalida] = useState('');
  //const [productosGanado, setProductosGanado] = useState('');
  //const [productosMerma, setProductosMerma] = useState('');
  //const [productosCantIngreso, setProductosCantIngreso] = useState('');

  //Equipos
  const [equiposRemanenteBajo, setEquiposRemanenteBajo] = useState(0);
  const [equiposBajo, setEquiposBajo] = useState('');

  const [equiposFueraServicioRemanente, setEquiposFueraServicioRemanente] = useState(0);
  const [equiposFueraServicio, setEquiposFueraServicio] = useState('');

  const [equiposSinSalidaRemanente, setEquiposSinSalidaRemanente] = useState(0);
  const [equiposSinSalida, setEquiposSinSalida] = useState('');


  const [proveedoresActivos, setProveedoresActivos] = useState('');
  const [proveedoresInactivos, setProveedoresInactivos] = useState('');

  const [serviciosCompletados, setServiciosCompletados] = useState('');
  const [serviciosPendientes, setServiciosPendientes] = useState('');

  //Gráficos
  //
  //const [productosDefectuosos, setProductosDefectuosos] = useState([]);

  const [iInsumosLineChart, setIInsumosLineChart] = useState([]);
  //const [gProductosLineChart, setGProductosLineChart] = useState([]);

  const [uniqueYears, setUniqueYears] = useState([]);
  const [labelAnioFiltroBarInsumos, setLabelAnioFiltroBarInsumos] = useState('');
  const [labelMesFiltroBarInsumos, setLabelMesFiltroBarInsumos] = useState('');

  //Tablas
  //const [topProductosSalidas, setTopProductosSalidas] = useState([]);

  // Lista de bajo stock
  const [listaInsumosBajoStock, setListaInsumosBajoStock] = useState([]);

  const [listaHerramientasBajoStock, setListaHerramientasBajoStock] = useState([]);
  const [listaHerramientasFueraServicio, setListaHerramientasFueraServicio] = useState([]);
  const [listaHerramientasSinSalidas, setListaHerramientasSinSalidas] = useState([]);

  const [listaEquiposBajoStock, setListaEquiposBajoStock] = useState([]);
  const [listaEquiposFueraServicio, setListaEquiposFueraServicio] = useState([]);
  const [listaEquiposSinSalidas, setListaEquiposSinSalidas] = useState([]);

  //Gráfico insumos
  const [insumosMasSalidas, setInsumosMasSalidas] = useState([]);

  //Calendario
  const [eventosInsumos, setEventosInsumos] = useState([]);
  const [eventosHerramientas, setEventosHerramientas] = useState([]);
  const [eventosEquipos, setEventosEquipos] = useState([]);

  /* ********************************************************* */

  /* ********************************************************* */

  const [loadingFilter, setLoadingFilter] = useState(false);
  const [loadingBtnPdf, setLoadingBtnPdf] = useState(false);
  const [loadingBtnBak, setLoadingBtnBak] = useState(false);

  const [errors, setErrors] = useState({});

  const renderErrorMessages = (messages) => {
    return messages.map((msg, index) => (
      <div key={index} style={{ marginLeft: '162px' }}>
        <p className="text-red-500 text-sm -mt-3 mb-3">{msg}</p>
      </div>
    ));
  };

  const { ms } = useMs();
  const navigate = useNavigate();

  useEffect(() => {
    if (getRol() !== "Administrador" && getRol() !== "Gerente" && getRol() !== "Almacenero") {
      navigate("/usuario/lista-insumos-principal");
    }
    document.title = "SDI ICB | Dashboard";

    getDashboardAll();

    //Poner fechas



  }, []);

  const getDashboardAll = async () => {
    try {

      setLoadingFilter(true);

      let getDashboardList;

      if (getRol() === "Administrador") {
        getDashboardList = Config.getDashboardAdmin(
          fechaInicial,
          fechaFinal,
          anioBarInsumosSalidas,
          mesBarInsumosSalidas
        );
      } else if (getRol() === "Gerente") {
        getDashboardList = Config.getDashboardGerente(
          fechaInicial,
          fechaFinal,
          anioBarInsumosSalidas,
          mesBarInsumosSalidas
        );
      } else if (getRol() === "Almacenero") {
        getDashboardList = Config.getDashboardAlmacenero(
          fechaInicial,
          fechaFinal,
          anioBarInsumosSalidas,
          mesBarInsumosSalidas
        );
      }

      const response = await getDashboardList;

      setFechaFormatI(customDateStringFormatter(fechaInicial));
      setFechaFormatF(customDateStringFormatter(fechaFinal));

      //Backup

      setUltimoBak(response.data.alerta_backup_ultimo_hecho?.fecha_ultimo_backup || '');
      setSiguienteBak(response.data.alerta_backup_fecha_siguiente?.fecha_siguiente_backup || '');

      setInsumosBajo(response.data.insumos_bajo_stock);
      setInsumosIngresoT(response.data.insumos_ingreso_total);
      setInsumoInvertidoT(response.data.insumos_inversion_total);
      setInsumoSalidaT(response.data.insumos_salida_total);

      setHerramientasBajo(response.data.herramientas_bajo_stock);
      setHerramientasFueraServicio(response.data.herramientas_fuera_de_servicio);
      setHerramientasSinSalida(response.data.herramientas_sin_salidas);

      // Equipos
      setEquiposBajo(response.data.equipos_bajo_stock);
      setEquiposFueraServicio(response.data.equipos_fuera_servicio);
      setEquiposSinSalida(response.data.equipos_sin_salidas);

      setProveedoresActivos(response.data.proveedores_activos);
      setProveedoresInactivos(response.data.proveedores_inactivos);

      setServiciosCompletados(response.data.servicios_completados);
      setServiciosPendientes(response.data.servicios_pendientes);

      //Gráficos
      // setProductosDefectuosos(response.data.suma_productos_defectuosos);

      setUniqueYears(response.data.anios_para_filtros_considerados);
      setLabelAnioFiltroBarInsumos(response.data.filtroAnio);
      setLabelMesFiltroBarInsumos(response.data.filtroMes);

      setIInsumosLineChart(response.data.invertido_insumos_line1);
      // setGProductosLineChart(response.data.ganado_productos_line2);


      // setTopProductosSalidas(response.data.top_productos_mas_salidas);

      setListaInsumosBajoStock(response.data.lista_insumos_bajo_stock);

      setListaHerramientasBajoStock(response.data.lista_herramientas_bajo_stock);
      setListaHerramientasFueraServicio(response.data.lista_herramientas_fuera_servicio);
      setListaHerramientasSinSalidas(response.data.lista_herramientas_sin_salidas);

      setListaEquiposBajoStock(response.data.lista_equipos_bajo_stock);
      setListaEquiposFueraServicio(response.data.lista_equipos_fuera_servicio);
      setListaEquiposSinSalidas(response.data.lista_equipos_sin_salidas);


      setInsumosRemanente(parseInt(response.data.insumos_bajo_stock) - parseInt(response.data.lista_insumos_bajo_stock.length));

      setHerramientasBajoRemanente(parseInt(response.data.herramientas_bajo_stock) - parseInt(response.data.lista_herramientas_bajo_stock.length));
      setHerramientasFueraServicioRemanente(parseInt(response.data.herramientas_fuera_de_servicio) - parseInt(response.data.lista_herramientas_fuera_servicio.length));
      setHerramientasSinSalidaRemanente(parseInt(response.data.herramientas_sin_salidas) - parseInt(response.data.lista_herramientas_sin_salidas.length));

      setEquiposRemanenteBajo(parseInt(response.data.equipos_bajo_stock) - parseInt(response.data.lista_equipos_bajo_stock.length));
      setEquiposFueraServicioRemanente(parseInt(response.data.equipos_fuera_servicio) - parseInt(response.data.lista_equipos_fuera_servicio.length));
      setEquiposSinSalidaRemanente(parseInt(response.data.equipos_sin_salidas) - parseInt(response.data.lista_equipos_sin_salidas.length));

      // Gráfico insumos más salidas
      setInsumosMasSalidas(response.data.lista_insumos_mayores_salidas);

      //Calendario
      setEventosInsumos(response.data.ingresos_salidas_insumos_report);
      setEventosHerramientas(response.data.ingresos_salidas_herramientas_report);
      setEventosEquipos(response.data.ingresos_salidas_equipos_report);

    } catch (error) {
      // console.error("Error:", error);
      showSwalMessage("Error", "Ha ocurrido un error al generar el Dashboard. Vuelva a intentarlo en unos minutos.", "error");
    } finally {
      setLoadingFilter(false);
    }
  }

  const generarBakMysql = async () => {
    try {
      setLoadingBtnBak(true);

      if (getRol() === "Administrador") {
        const response = await Config.postGenerarBackupMysql({
          responseType: "blob", // Manejar como archivo binario
        });

        // Verifica el tipo de contenido
        const contentType = response.headers["content-type"];

        if (contentType === "application/octet-stream") {

          //Formato de nombre de archivo bd
          const dbName = "inventario_icb_bd";
          const currentDate = new Date();

          // Función para formatear la fecha y hora Ymd_Hms
          const formatDate = (date) => {
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const day = String(date.getDate()).padStart(2, '0');
            const hours = String(date.getHours()).padStart(2, '0');
            const minutes = String(date.getMinutes()).padStart(2, '0');
            const seconds = String(date.getSeconds()).padStart(2, '0');

            return `${year}${month}${day}_${hours}${minutes}${seconds}`;
          };

          // Generar el nombre del archivo
          const fileName = `${dbName}_backup_${formatDate(currentDate)}.sql`;

          // Descargar el archivo
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", fileName); // Nombre del archivo
          document.body.appendChild(link);
          link.click();
          link.remove();

          showSwalMessage("Hecho", "El respaldo se ha descargado exitosamente.", "success");
        } else if (contentType === "application/json") {
          // Si la respuesta es JSON (indica un error del servidor)
          const reader = new FileReader();
          reader.onload = () => {
            const errorMessage = JSON.parse(reader.result).message || "Error desconocido.";
            showSwalMessage("Error", errorMessage, "error");
          };
          reader.readAsText(new Blob([response.data])); // Convertir data en Blob antes de leer
        } else {
          showSwalMessage("Error", "Tipo de respuesta inesperado.", "error");
        }
      }
    } catch (error) {
      console.log("ERROR: ", error);
      showSwalMessage(
        "Error",
        "Ha ocurrido un error al intentar exportar la base de datos. Vuelva a intentarlo en unos minutos.",
        "error"
      );
    } finally {
      setLoadingBtnBak(false);
      getDashboardAll();
    }
  };

  const exportToPDF = () => {
    try {
      setLoadingBtnPdf(true);
      const element = document.getElementById('exportSection');

      let fechaFormateadaInicial = customDateStringFormatter(fechaInicial);
      let fechaFormateadaFinal = customDateStringFormatter(fechaFinal);

      const fechaActual = new Date();
      const nombreArchivo = `reporte_dashboard_${fechaActual.getFullYear()}-${fechaActual.getMonth() + 1}-${fechaActual.getDate()}.pdf`;

      const opt = {
        margin: [25, 5, 5, 10],
        filename: nombreArchivo,
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: { scale: 2, ignoreElements: (element) => element.classList.contains('ignore-pdf') },
        jsPDF: { unit: 'mm', format: 'a4', orientation: 'landscape' },
        pagebreak: { before: '.page-break-tables', after: ['#page_break_one', '#after2'] }
      };

      html2pdf().set(opt).from(element).toPdf().get('pdf').then(function (pdf) {
        var totalPages = pdf.internal.getNumberOfPages();

        for (let i = 1; i <= totalPages; i++) {
          pdf.setPage(i);

          // Añadir encabezado
          pdf.setFontSize(16);
          pdf.setTextColor(221, 128, 34);
          pdf.text('Reporte Dashboard - ICB Industrial', 20, 10);

          pdf.setFontSize(10);
          pdf.setTextColor(221, 128, 34);
          pdf.text(`Página ${i} de ${totalPages}`, pdf.internal.pageSize.getWidth() - 30, pdf.internal.pageSize.getHeight() - 10);

          // pdf.setFontSize(10);
          // pdf.setTextColor(221, 128, 34);
          //pdf.text(`El reporte ha sido generado con datos entre las fechas: ${fechaFormateadaInicial} hasta el ${fechaFormateadaFinal}`, 20, 15);
        }

        pdf.save(nombreArchivo);
      });

    } catch (error) {
      showSwalMessage("Error", "Ha ocurrido un error al exportar el archivo PDF. Vuelva a intentarlo en unos minutos.", "error");
    } finally {
      setLoadingBtnPdf(false);
    }
  };

  const showSwalMessage = (titulo, texto, icono) => {
    Swal.fire({
      title: titulo,
      text: texto,
      icon: icono,
      confirmButtonColor: "#339A5B",
    });
  }

  return (
    <div className={`${ms ? 'ms-4 transition-all' : 'ms-56 transition-all'} 
    mt-16 pt-2 pb-16
    overflow-y-auto  
    max-[768px]:${ms ? 'ms-2 transition-all' : 'ms-36 transition-all'}
    max-[480px]:mt-28
    `}>

      <div id="exportSection">

        <h1 className='mb-8 text-2xl text-slate-500 font-semibold'>Dashboard - Resumen</h1>

        {loadingFilter ? null


          :
          <>
            {ultimoBak == null ?
              <div className='flex items-center -mt-4 mb-4 rounded bg-red-400 border p-2 border-red-500 text-white me-3'>
                <IoMdWarning className='me-2 text-2xl' />Aún no genera un respaldo de la base de datos.
              </div>
              :

              new Date() > new Date(siguienteBak) ?

                <div className='flex items-center -mt-4 mb-4 rounded bg-red-400 border p-2 border-red-500 text-white me-3'>
                  <IoMdWarning className='me-2 text-2xl' />Han pasado 15 días desde el último respaldo. Se recomienda realizar uno ahora mismo.
                </div>

                : ''

            }
          </>}

        <div className='flex flex-wrap gap-3'>
          <button onClick={exportToPDF} className={`ignore-pdf
        ${loadingBtnPdf ? 'bg-red-600 text-white opacity-50' : 'text-red-600 border border-red-600 hover:bg-red-600 hover:text-white'}  
      rounded py-1 px-2 mb-2 flex items-center`}
          >
            <FaFilePdf className='me-2' />Exportar a PDF
          </button>

          <div className='ignore-pdf'>
            <button onClick={generarBakMysql} className={`ignore-pdf
              ${loadingBtnBak ? 'bg-orange-600 text-white opacity-50' : 'text-orange-600 border border-orange-600 hover:bg-orange-600 hover:text-white'}  
            rounded py-1 px-2 mb-2 flex items-center`}
            >
              <BsDatabaseFillDown className='me-2' />Generar Backup de BD
            </button>
          </div>
        </div>


        {/* <form className='ignore-pdf'>
          <div className='flex flex-col flex-wrap mb-4 me-3
        border border-gray-300 rounded-sm p-2'>
            <h1 className='text-xl mb-3'>Filtros: </h1>
            <div className='flex'>

              <div className='flex'>

                <label htmlFor="date_picker" className={`flex items-center font-normal text-[#454545] text-sm me-2`}>Fecha Inicial:</label>
                <input
                  value={fechaInicial || ""}
                  onChange={(e) => { setFechaInicial(formatFechaForDB(e.target.value)); }}

                  type="datetime-local"
                  max={fechaFinal}
                  className={`px-4 py-1 bg-[#F9F9F9] border border-[#D9D9D9] text-sm me-4
              rounded-md focus:outline-none focus:border-[#339A5B] focus:ring-1 focus:ring-[#339A5B] 
              ${errors.fecha_ingreso ? 'border-red-500' : 'border-slate-300'}`}
                />

              </div>

              {errors.fecha_ingreso && Array.isArray(errors.fecha_ingreso) && renderErrorMessages(errors.fecha_ingreso)}
              <div className='flex'>

                <label htmlFor="date_picker" className={`flex items-center font-normal text-[#454545] text-sm me-2`}>Fecha Final:</label>
                <input
                  value={fechaFinal || ""}
                  onChange={(e) => { setFechaFinal(formatFechaForDB(e.target.value)); }}

                  type="datetime-local"
                  min={fechaInicial}
                  max={nowLocalString}
                  className={`px-4 py-1 bg-[#F9F9F9] border border-[#D9D9D9] text-sm
                rounded-md focus:outline-none focus:border-[#339A5B] focus:ring-1 focus:ring-[#339A5B] 
                ${errors.fecha_ingreso ? 'border-red-500' : 'border-slate-300'}`}
                />

              </div>

              {errors.fecha_ingreso && Array.isArray(errors.fecha_ingreso) && renderErrorMessages(errors.fecha_ingreso)}

              <div className='ms-4'>
                <button
                  disabled={loadingFilter}
                  className={`bg-sky-500 text-white rounded shadow-sm px-4 py-1 flex items-center
                ${loadingFilter ? 'cursor-not-allowed opacity-50' : ''}
                hover:bg-sky-600 transition`}
                  onClick={getDashboardAll}>
                  {loadingFilter ?
                    <div className='flex items-center justify-center'>
                      <RotatingLines
                        strokeColor="white"
                        strokeWidth='3'
                        width='22'
                        radius="5"
                        ariaLabel="lines-loading"
                        className='me-2'
                      />
                      Filtrando
                    </div>
                    : <>
                      <CiFilter className='me-2' /> Filtrar
                    </>
                  }

                </button>
              </div>
            </div>
          </div>



        </form> */}



        {/* <DashboardCards /> */}
        <div className={`mt-8 ${loadingFilter ? 'opacity-50 cursor-not-allowed' : null}`}>
          {/* <div className='d-flex justify-center items-center z-10'>Hola</div> */}
          <div className='flex flex-wrap justify-evenly mb-16'>

            {/* INSUMOS */}


            <SingleCard titulo_tarjeta="Cantidad de Insumos con Bajo (Menor a 3 unidades) o Nulo Stock"
              tarjeta_logo={<FaBoxOpen size={40} className='text-red-500' />}
              title_card_style="text-red-500 font-medium"
              color='border-red-500'
              data_card_style='text-slate-600 font-medium text-xl'
              showTooltip={true}
              // info_bubble={(listaInsumosBajoStock.map((insumo, index) => `${index+1}) ${insumo.codigo} - ${insumo.nombre} - ${insumo.color}\n`).join(''))}
              tipo="insumos"
              totalCount={insumosRemanente}
              listaInsumosBajoStock={listaInsumosBajoStock}
              contenido={!loadingFilter ? `${insumosBajo}` : <RotatingLines
                strokeColor="gray"
                strokeWidth='3'
                width='22'
                radius="5"
                ariaLabel="lines-loading"
                className='me-2'
              />}
            />

            <SingleCard titulo_tarjeta="Cantidad de Herramientas con Nulo Stock, Que No Están Fuera de Servicio y Tienen Al Menos Un Ingreso"
              tarjeta_logo={<FiTool size={40} className='text-red-500' />}
              title_card_style="text-red-500 font-medium"
              color='border-red-500'
              data_card_style='text-slate-600 font-medium text-xl'
              showTooltip={true}
              tipo="herramientas-salidas-stock"
              totalCount={herramientasBajoRemanente}
              listaHerramientasBajoStock={listaHerramientasBajoStock}
              contenido={!loadingFilter ? `${herramientasBajo}` : <RotatingLines
                strokeColor="gray"
                strokeWidth='3'
                width='22'
                radius="5"
                ariaLabel="lines-loading"
                className='me-2'
              />}
            />

            <SingleCard titulo_tarjeta="Cantidad de Herramientas Sin Salida"
              tarjeta_logo={<FiTool size={40} className='text-red-500' />}
              title_card_style="text-red-500 font-medium"
              color='border-red-500'
              data_card_style='text-slate-600 font-medium text-xl'
              showTooltip={true}
              tipo="herramientas-sin-salida"
              totalCount={herramientasSinSalidaRemanente}
              listaHerramientasBajoStock={listaHerramientasSinSalidas}
              contenido={!loadingFilter ? `${herramientasSinSalida}` : <RotatingLines
                strokeColor="gray"
                strokeWidth='3'
                width='22'
                radius="5"
                ariaLabel="lines-loading"
                className='me-2'
              />}
            />

            <SingleCard titulo_tarjeta="Cantidad de Herramientas Que Están Fuera de Servicio"
              tarjeta_logo={<FiTool size={40} className='text-red-500' />}
              title_card_style="text-red-500 font-medium"
              color='border-red-500'
              data_card_style='text-slate-600 font-medium text-xl'
              showTooltip={true}
              tipo="herramientas-fuera-servicio"
              totalCount={herramientasFueraServicioRemanente}
              listaHerramientasBajoStock={listaHerramientasFueraServicio}
              contenido={!loadingFilter ? `${herramientasFueraServicio}` : <RotatingLines
                strokeColor="gray"
                strokeWidth='3'
                width='22'
                radius="5"
                ariaLabel="lines-loading"
                className='me-2'
              />}
            />

            <SingleCard titulo_tarjeta="Cantidad de Equipos con Nulo Stock y Que No Están Fuera de Servicio"
              tarjeta_logo={<GiAbstract072 size={40} className='text-red-500' />}
              title_card_style="text-red-500 font-medium"
              color='border-red-500'
              data_card_style='text-slate-600 font-medium text-xl'
              showTooltip={true}
              tipo="equipos-salidas-bajo-stock"
              totalCount={equiposRemanenteBajo}
              listaEquiposBajoStock={listaEquiposBajoStock}
              contenido={!loadingFilter ? `${equiposBajo}` : <RotatingLines
                strokeColor="gray"
                strokeWidth='3'
                width='22'
                radius="5"
                ariaLabel="lines-loading"
                className='me-2'
              />}
            />

            <SingleCard titulo_tarjeta="Cantidad de Equipos Sin Salida"
              tarjeta_logo={<GiAbstract072 size={40} className='text-red-500' />}
              title_card_style="text-red-500 font-medium"
              color='border-red-500'
              data_card_style='text-slate-600 font-medium text-xl'
              showTooltip={true}
              tipo="equipos-sin-salida"
              totalCount={equiposSinSalidaRemanente}
              listaEquiposBajoStock={listaEquiposSinSalidas}
              contenido={!loadingFilter ? `${equiposSinSalida}` : <RotatingLines
                strokeColor="gray"
                strokeWidth='3'
                width='22'
                radius="5"
                ariaLabel="lines-loading"
                className='me-2'
              />}
            />

            <SingleCard titulo_tarjeta="Cantidad de Equipos Que Están Fuera de Servicio"
              tarjeta_logo={<GiAbstract072 size={40} className='text-red-500' />}
              title_card_style="text-red-500 font-medium"
              color='border-red-500'
              data_card_style='text-slate-600 font-medium text-xl'
              showTooltip={true}
              tipo="equipos-fuera-servicio"
              totalCount={equiposFueraServicioRemanente}
              listaEquiposBajoStock={listaEquiposFueraServicio}
              contenido={!loadingFilter ? `${equiposFueraServicio}` : <RotatingLines
                strokeColor="gray"
                strokeWidth='3'
                width='22'
                radius="5"
                ariaLabel="lines-loading"
                className='me-2'
              />}
            />

            {/* PROVEEDORES */}

            <SingleCard titulo_tarjeta="Proveedores Activos Desde la Apertura"
              tarjeta_logo={<FaTruckLoading size={40} className='text-fuchsia-500' />}
              title_card_style="text-fuchsia-500 font-medium"
              color='border-fuchsia-500'
              data_card_style='text-slate-600 font-medium text-xl'
              contenido={!loadingFilter ? `${proveedoresActivos}` : <RotatingLines
                strokeColor="gray"
                strokeWidth='3'
                width='22'
                radius="5"
                ariaLabel="lines-loading"
                className='me-2'
              />}
            />

            <SingleCard titulo_tarjeta="Proveedores Inactivos desde la Apertura"
              tarjeta_logo={<MdOutlineCarCrash size={40} className='text-purple-500' />}
              title_card_style="text-purple-500 font-medium"
              color='border-purple-500'
              data_card_style='text-slate-600 font-medium text-xl'
              contenido={!loadingFilter ? `${proveedoresInactivos}` : <RotatingLines
                strokeColor="gray"
                strokeWidth='3'
                width='22'
                radius="5"
                ariaLabel="lines-loading"
                className='me-2'
              />}
            />

          </div>

          <div className={`flex ${!loadingFilter ? 'justify-evenly' : 'justify-start'} flex-wrap mb-10`}>

            {/* {!loadingFilter ? */}
            {/* <BarProductosDefec
              id="chartHorizontalBar1"
              titulo="Gráfico Demanda de Productos Defectuosos"
              fechaI={fechaFormatI}
              fechaF={fechaFormatF}
              labels={productosDefectuosos.map(producto => `${producto.nombre_producto} - ${producto.color} - ${producto.talla}`)}
              data={productosDefectuosos.map(producto => producto.total_cantidad_defectuosa)}
            /> */}
            {/* : 
            <div role="status" class="p-2 border border-gray-100 rounded shadow animate-pulse md:p-6 dark:border-gray-700" style={{maxWidth: '1000px', width: '100%'}}>
              <div class="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-32 mb-2.5"></div>
              <div class="w-48 h-2 mb-10 bg-gray-200 rounded-full dark:bg-gray-700"></div>
              <div class="flex items-baseline mt-4">
                <div class="w-full bg-gray-200 rounded-t-lg h-72 dark:bg-gray-700"></div>
                <div class="w-full h-56 ms-6 bg-gray-200 rounded-t-lg dark:bg-gray-700"></div>
                <div class="w-full bg-gray-200 rounded-t-lg h-72 ms-6 dark:bg-gray-700"></div>
                <div class="w-full h-64 ms-6 bg-gray-200 rounded-t-lg dark:bg-gray-700"></div>
                <div class="w-full bg-gray-200 rounded-t-lg h-80 ms-6 dark:bg-gray-700"></div>
                <div class="w-full bg-gray-200 rounded-t-lg h-72 ms-6 dark:bg-gray-700"></div>
                <div class="w-full bg-gray-200 rounded-t-lg h-80 ms-6 dark:bg-gray-700"></div>
              </div>
              <span class="sr-only">Loading...</span>
            </div>} */}

          </div>

          <div className='flex justify-center'>
            <div className={`flex ${!loadingFilter ? 'justify-evenly' : 'justify-center'} flex-wrap mb-10 bg-white`} style={{ maxWidth: '1000px', width: '100%' }}>

              <div className='ignore-pdf flex items-center border border-gray-300 w-full p-2'>
                <label className='me-3' htmlFor='dropdown_years_line_invested_earn'>Filtro por año:</label>

                <select name="dropdown_years_line_invested_earn" id="dropdown_years_line_invested_earn"
                  className={`px-2 py-1 bg-[#F9F9F9] border border-[#D9D9D9] text-sm me-4
                  rounded-md focus:outline-none focus:border-[#339A5B] focus:ring-1 focus:ring-[#339A5B] 
                  ${errors.filtro_anio_line_invertido_ganancia ? 'border-red-500' : 'border-slate-300'}`}
                  value={anioBarInsumosSalidas}
                  onChange={(e) => { setAnioBarInsumosSalidas(e.target.value); }
                  }
                >

                  {uniqueYears.map((anio) => (
                    <option key={anio} value={anio}>
                      {anio}
                    </option>
                  ))}

                </select>

                <select
                  name="dropdown_months_line_invested_earn"
                  id="dropdown_months_line_invested_earn"
                  className={`px-2 py-1 bg-[#F9F9F9] border border-[#D9D9D9] text-sm me-4
              rounded-md focus:outline-none focus:border-[#339A5B] focus:ring-1 focus:ring-[#339A5B] 
              ${errors.filtro_mes_bar_salida_insumos ? 'border-red-500' : 'border-slate-300'}`}
                  value={mesBarInsumosSalidas}
                  onChange={(e) => setMesBarInsumosSalidas(e.target.value)}
                >
                  {[
                    { value: '01', label: 'Enero' },
                    { value: '02', label: 'Febrero' },
                    { value: '03', label: 'Marzo' },
                    { value: '04', label: 'Abril' },
                    { value: '05', label: 'Mayo' },
                    { value: '06', label: 'Junio' },
                    { value: '07', label: 'Julio' },
                    { value: '08', label: 'Agosto' },
                    { value: '09', label: 'Septiembre' },
                    { value: '10', label: 'Octubre' },
                    { value: '11', label: 'Noviembre' },
                    { value: '12', label: 'Diciembre' }
                  ].map(({ value, label }) => (
                    <option key={value} value={value}>
                      {label}
                    </option>
                  ))}
                </select>

                <div className='ms-4'>
                  <button
                    disabled={loadingFilter}
                    className={`bg-sky-500 text-white rounded shadow-sm px-4 py-1 flex items-center
                ${loadingFilter ? 'cursor-not-allowed opacity-50' : ''}
                hover:bg-sky-600 transition`}
                    onClick={getDashboardAll}>
                    {loadingFilter ?
                      <div className='flex items-center justify-center'>
                        <RotatingLines
                          strokeColor="white"
                          strokeWidth='3'
                          width='22'
                          radius="5"
                          ariaLabel="lines-loading"
                          className='me-2'
                        />
                        Filtrando
                      </div>
                      : <>
                        <CiFilter className='me-2' /> Filtrar por año
                      </>
                    }

                  </button>
                </div>

                {errors.filtro_anio_line_invertido_ganancia && Array.isArray(errors.filtro_anio_line_invertido_ganancia) && renderErrorMessages(errors.filtro_anio_line_invertido_ganancia)}
              </div>

              {!loadingFilter ?
                <BarInsumosSalidas
                  id="chartHorizontalBar1"
                  titulo="Gráfico Insumos con Mayores Salidas"
                  cantidad_salidas={insumosMasSalidas.length}
                  mes={labelMesFiltroBarInsumos}
                  anio={labelAnioFiltroBarInsumos}
                  labels={insumosMasSalidas.map(insumo => `${insumo.codigo} - ${insumo.nombre} - ${insumo.color}`)}
                  data={insumosMasSalidas.map(insumo => insumo.total_cantidad_salida)}
                />
                :
                <div role="status" className="p-2 border border-gray-100 rounded shadow animate-pulse md:p-6 dark:border-gray-700" style={{ maxWidth: '1000px', width: '100%' }}>
                  <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-32 mb-2.5"></div>
                  <div className="w-48 h-2 mb-10 bg-gray-200 rounded-full dark:bg-gray-700"></div>
                  <div className="flex items-baseline mt-4">
                    <div className="w-full bg-gray-200 rounded-t-lg h-72 dark:bg-gray-700"></div>
                    <div className="w-full h-56 ms-6 bg-gray-200 rounded-t-lg dark:bg-gray-700"></div>
                    <div className="w-full bg-gray-200 rounded-t-lg h-72 ms-6 dark:bg-gray-700"></div>
                    <div className="w-full h-64 ms-6 bg-gray-200 rounded-t-lg dark:bg-gray-700"></div>
                    <div className="w-full bg-gray-200 rounded-t-lg h-80 ms-6 dark:bg-gray-700"></div>
                    <div className="w-full bg-gray-200 rounded-t-lg h-72 ms-6 dark:bg-gray-700"></div>
                    <div className="w-full bg-gray-200 rounded-t-lg h-80 ms-6 dark:bg-gray-700"></div>
                  </div>
                  <span className="sr-only">Loading...</span>
                </div>}

              {/* <IngresoInsumosSalidasProductos
                id="chartLineChart1"
                titulo="Gráfico de Costo en Inversión de Insumos y Ganancia en Venta de Productos"
                texto={`Se muestra el costo invertido en soles en insumos y lo ganado en soles en venta de productos del año ${labelAnioFiltro}`}

                labels1={iInsumosLineChart.map(invinsumo => invinsumo.mes)}
                dataIngresosInsumos={iInsumosLineChart.map(invinsumo => invinsumo.precio_total)}

                labels2={gProductosLineChart.map(ganproductos => ganproductos.month)}
                dataGananciaProductos={gProductosLineChart.map(ganproductos => ganproductos.ganancia_mes)}
              /> */}

            </div>

          </div>

          {/* Calendario */}
          <div className='ignore-pdf'>

            <div className='bg-white rounded shadow-sm mt-8 mx-2 p-3'>
              <h3 className='p-2 underline text-[#D27313] font-semibold' style={{ fontSize: '18px' }}>Fechas de Ingresos y Salidas de Insumos</h3>
              <p className='p-2 font-normal text-gray-600 mb-2 italic' style={{ fontSize: '14px' }}>Se muestran los últimos 20 ingresos y salidas de insumos</p>
              <CalendarioInsumos dataInsumos={eventosInsumos} />
            </div>

            <div className='bg-white rounded shadow-sm mt-8 mx-2 p-3'>
              <h3 className='p-2 underline text-[#D27313] font-semibold' style={{ fontSize: '18px' }}>Fechas de Ingresos y Salidas de Herramientas</h3>
              <p className='p-2 font-normal text-gray-600 mb-2 italic' style={{ fontSize: '14px' }}>Se muestran los últimos 20 ingresos y salidas de herramientas</p>
              <CalendarioHerramientas dataHerramientas={eventosHerramientas} />
            </div>

            <div className='bg-white rounded shadow-sm mt-8 mx-2 p-3'>
              <h3 className='p-2 underline text-[#D27313] font-semibold' style={{ fontSize: '18px' }}>Fechas de Ingresos y Salidas de Equipos</h3>
              <p className='p-2 font-normal text-gray-600 mb-2 italic' style={{ fontSize: '14px' }}>Se muestran los últimos 20 ingresos y salidas de equipo</p>
              <CalendarioEquipos dataEquipos={eventosEquipos} />
            </div>

          </div>

        </div>
      </div>
    </div>
  )
}
