import React, { useState, useEffect } from 'react';
import { RotatingLines } from 'react-loader-spinner';

import AuthUser from '../../../../pages/pagesAuth/AuthUser';
import Modal from '../../../modals/Modal';

import { FaPlus } from "react-icons/fa";
import Config from '../../../../Config';

import { toast } from 'react-toastify';
import InputForm from '../../../inputs/InputForm';

import AsyncSelect from 'react-select/async';

export default function ModalAnadirIngresoEquipo({ open, onClose, updateEquipoList }) {

    const { getRol, getToken } = AuthUser();

    const [userToken, setUserToken] = useState('');
    const [equipoId, setEquipoId] = useState('');
    const [cantidad, setCantidad] = useState(1);
    const [fechaIngreso, setFechaIngreso] = useState('');

    // Función para formatear la fecha
    const formatFechaForDB = (dateString) => {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = "00";
        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    };

    //Variable para restringir fecha
    const now = new Date();
    const now_equipo = formatFechaForDB(now);

    //Mostrar datos actuales de ingreso, salida y saldo
    const [labelIngreso, setLabelIngreso] = useState('');
    const [labelSalida, setLabelSalida] = useState('');
    const [labelSaldo, setLabelSaldo] = useState('');

    const [errors, setErrors] = useState({});
    const [loadingSubmit, setloadingSubmit] = useState(false);

    const [zIndex, setZIndex] = useState(-1);

    useEffect(() => {
        if (open) {
            document.addEventListener('mousedown', handleClickOutside);
            setUserToken(getToken());
            setZIndex(9999);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
            setZIndex(-1);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
            setErrors({});
        };
    }, [open]);

    const loadEquiposOptions = async (globalFilter) => {
        let notificationShown = false;
        try {
            let response;
            if (getRol() === "Administrador") {
                response = await Config.getSelectEquiposAdmin(globalFilter);
            } else if (getRol() === "Almacenero") {
                response = await Config.getSelectEquiposAlmacenero(globalFilter);
            }

            const arrayEquipos = response.data;
            if (arrayEquipos.exito == 0) {
                return [];
            }

            if (response.data.errors && !notificationShown) {
                notify(response.data.errors, "error");
                notificationShown = true;
            }

            return arrayEquipos.map(equipo => ({
                value: equipo.id_equipo,
                label: `${equipo.id_equipo} - ${equipo.codigo_equipo} - ${equipo.nombre_equipo} - ${equipo.nro_serie_equipo ? equipo.nro_serie_equipo : '-Sin n° serie-'}`,
                ingreso: equipo.ingreso,
                salida: equipo.salida,
                saldo: equipo.saldo,
            }));
        } catch (error) {
            console.error("Error:", error);
            if (!notificationShown) {
                notify("Ha ocurrido un error al cargar la lista de equipos. Vuelva a intentarlo más tarde.", "error");
                notificationShown = true;
            }
            return [];
        }
    };

    const handleClickOutside = (event) => {
        if (event.target.closest(".modal-content") === null) {
            onClose();
        }
    };

    const addNewIngresoEquipo = async (e) => {
        e.preventDefault();
        setloadingSubmit(true);
        setErrors({});

        const ingresoEquipoData = {
            token_usuario: userToken,
            equipo_id: equipoId.value,
            cantidad_ingreso_equipo: cantidad,
            fecha_ingreso_equipo: fechaIngreso
        };

        try {
            let response;

            if (getRol() === "Administrador") {
                response = await Config.postGuardarIngresoEquipoAdmin(ingresoEquipoData);
            } else if (getRol() === "Almacenero") {
                response = await Config.postGuardarIngresoEquipoAlmacenero(ingresoEquipoData);
            }

            console.log(response);

            if (response.data.errors) {
                setErrors(response.data.errors);
            } else {
                if (response.data.exito == 0) {
                    notify(response.data.message, "error");
                } else if (response.data.exito == 1) {
                    notify("El ingreso de equipo ha sido añadido correctamente.", "success");
                    setUserToken('');
                    setEquipoId('');
                    setCantidad(1);
                    setFechaIngreso('');
                    onClose();
                    updateEquipoList();
                } else {
                    notify("Ha ocurrido un error. Vuelva a intentarlo más tarde.", "error");
                }
            }

        } catch (error) {
            console.error("Error: ", error);
            notify("Ha ocurrido un error. Vuelva a intentarlo más tarde.", "error");
            updateEquipoList();
        } finally {
            setloadingSubmit(false);
        };
    }

    const renderErrorMessages = (messages) => {
        return messages.map((msg, index) => (
            <div key={index} style={{ marginLeft: '162px' }}>
                <p className="text-red-500 text-sm -mt-3 mb-3">{msg}</p>
            </div>
        ));
    };

    const notify = (message, type) => {
        toast[type](message, {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
        });
    };

    const getColourStyles = (hasError) => ({
        control: (styles, { isFocused }) => ({
            ...styles,
            backgroundColor: '#F9F9F9',
            borderColor: hasError ? 'red' : (isFocused ? '#339A5B' : '#D9D9D9'),
            boxShadow: hasError ? '1px red' : (isFocused ? '1px #339A5B' : null),
            '&:hover': {
                borderColor: hasError ? 'red' : (isFocused ? '#D9D9D9' : '#339A5B')
            },
        }),

        option: (styles, { data, isDisabled, isFocused, isSelected }) => ({
            ...styles,
            backgroundColor: isDisabled
                ? undefined
                : isSelected
                    ? '#ecf9ec'
                    : isFocused
                        ? '#f2f2f2'
                        : undefined,
            color: isDisabled
                ? '#ccc'
                : isSelected
                    ? 'black'
                    : data.color,
            cursor: isDisabled ? 'not-allowed' : 'default',

            '&:active': {
                backgroundColor: !isDisabled && (isSelected ? 'darkgreen' : '#ecf9ec'),
                color: !isDisabled && (isSelected ? 'white' : 'black')
            }
        }),
        menuPortal: (base) => ({
            ...base,
            zIndex: zIndex
        }),
    });

    

    const cerrarModal = (e) => {
        e.preventDefault();
        onClose();
    }

  return (
    <Modal open={open} onClose={onClose}>
            <div className='w-100'>
                <h3 className='text-center'>Imagen de Añadir</h3>
                <h3 className='text-lg text-center font-black text-gray-800 w-full mb-8'>Añadir Ingreso de Equipo</h3>

                <form>

                    {errors.token_usuario && Array.isArray(errors.token_usuario) && renderErrorMessages(errors.token_usuario)}

                    <div className='flex mb-3 w-full'>
                        <label htmlFor='categoria_id' className='w-56 flex items-center font-normal text-[#454545]'>Equipo: </label>
                        <AsyncSelect
                            cacheOptions
                            defaultOptions
                            loadOptions={loadEquiposOptions}
                            className={`w-full`}
                            styles={getColourStyles(!!errors.equipo_id)}
                            id="equipo_id"
                            value={equipoId}
                            onChange={(option, id) => {
                                setLabelIngreso('');
                                setLabelSalida('');
                                setLabelSaldo('');

                                setEquipoId(option);
                                console.log(option, id);
                                setLabelIngreso(option.ingreso);
                                setLabelSalida(option.salida);
                                setLabelSaldo(option.saldo);
                            }}
                            placeholder="Seleccionar equipo..."
                            noOptionsMessage={() => "No se encontraron resultados"}
                            loadingMessage={() => "Cargando..."}
                            menuPortalTarget={document.querySelector('body')}
                        />
                    </div>

                    {errors.equipo_id && Array.isArray(errors.equipo_id) && renderErrorMessages(errors.equipo_id)}

                    <InputForm
                        id="cantidad_ingreso_equipo"
                        labelName="Cantidad:"
                        inputType="number"
                        value={cantidad}
                        funcion={(e) => setCantidad(e.target.value)}
                        errors={errors.cantidad_ingreso_equipo}
                        labelWidth="w-56"
                        isDisabled={true}
                        bgColor='bg-[#e0e0e0]'
                    />

                    {errors.cantidad_ingreso_equipo && Array.isArray(errors.cantidad_ingreso_equipo) && renderErrorMessages(errors.cantidad_ingreso_equipo)}
                    
                    {/* FECHA DE INGRESO */}

                    <div className='flex mb-3'>

                        <label htmlFor="date_picker" className={`w-56 flex items-center font-normal text-[#454545]`} style={{ width: "158px" }}>Fecha de Ingreso:</label>
                        <input
                            value={fechaIngreso || ""}
                            onChange={(e) => { setFechaIngreso(formatFechaForDB(e.target.value)); console.log(formatFechaForDB(e.target.value)) }}

                            type="datetime-local"
                            max={now_equipo}
                            className={`px-2 py-1 bg-[#F9F9F9] border border-[#D9D9D9] 
                        rounded-md focus:outline-none focus:border-[#339A5B] focus:ring-1 focus:ring-[#339A5B] 
                        ${errors.fecha_ingreso_equipo ? 'border-red-500' : 'border-slate-300'}`}
                        />

                    </div>

                    {errors.fecha_ingreso_equipo && Array.isArray(errors.fecha_ingreso_equipo) && renderErrorMessages(errors.fecha_ingreso_equipo)}

                    <h4 className='text-sm mb-3 text-gray-600'>Datos de herramienta seleccionado: </h4>

                    <div className='flex justify-between flex-wrap'>

                        <InputForm
                            id="ingreso"
                            labelName="Ingreso:"
                            inputType="number"
                            value={labelIngreso}
                            /* funcion={(e) => setCantidad(e.target.value)} */
                            labelWidth="w-16"
                            isDisabled={true}
                            bgColor='bg-[#e0e0e0]'
                            inputWidth='w-32'
                        />

                        <InputForm
                            id="salida"
                            labelName="Salida:"
                            inputType="number"
                            value={labelSalida}
                            /* funcion={(e) => setCantidad(e.target.value)} */
                            labelWidth="w-16"
                            isDisabled={true}
                            bgColor='bg-[#e0e0e0]'
                            inputWidth='w-32'
                        />

                        <InputForm
                            id="saldo"
                            labelName="Saldo:"
                            inputType="number"
                            value={labelSaldo}
                            /* funcion={(e) => setCantidad(e.target.value)} */
                            labelWidth="w-16"
                            isDisabled={true}
                            bgColor='bg-[#e0e0e0]'
                            inputWidth='w-32'
                        />

                    </div>

                    {/* ******************** */}


                    <div className='flex justify-end flex-wrap mb-3 mt-6'>
                        <button
                            disabled={loadingSubmit}
                            className={`flex items-center justify-center py-2 font-semibold 
                            shadow-md rounded-md w-64
                            text-white bg-[#EB8927]
                            me-3
                            max-[439px]:me-0
                            max-[469px]:m-0
                            ${loadingSubmit ? 'cursor-not-allowed opacity-50' : ''}`}

                            onClick={addNewIngresoEquipo}
                            type="submit">

                            {loadingSubmit ?
                                <div className='flex items-center justify-center w-64'>
                                    <RotatingLines
                                        strokeColor="white"
                                        strokeWidth='3'
                                        width='22'
                                        radius="5"
                                        ariaLabel="lines-loading"
                                    />
                                </div>
                                : <>
                                    <FaPlus /> Guardar Ingreso Equipo
                                </>
                            }

                        </button>

                        <button
                            className='py-2 px-4 font-semibold shadow-md rounded-lg w-40
                            max-[426px]:mt-3
                            bg-white text-gray-500'
                            onClick={cerrarModal}
                        >
                            Cerrar
                        </button>
                    </div>

                </form>
            </div>
        </Modal >
  )
}
