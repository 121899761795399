import React, { useState } from 'react';
import { Link } from 'react-router-dom';

export default function SingleCard(props) {
  const [isHovered, setIsHovered] = useState(false);

  // Condicionalmente cambiar el título y el contenido según el tipo (insumos, herramientas o equipos)
  const lista = props.tipo === 'insumos' ? props.listaInsumosBajoStock :
    (props.tipo === "herramientas-salidas-stock" || props.tipo === "herramientas-sin-salida" || props.tipo === "herramientas-fuera-servicio" || props.tipo === "herramientas" ? props.listaHerramientasBajoStock :
      (props.tipo === "equipos-fuera-servicio" || props.tipo === "equipos-salidas-bajo-stock" || props.tipo === "equipos-sin-salida" ? props.listaEquiposBajoStock : []));

  const visibleItems = (lista && lista.length > 0) ? lista.slice(0, 20) : []; // Los primeros 20 registros

  //const remainingItems = (lista.length > 10) ? lista.length - 10 : 0; // Cantidad de registros adicionales
  const remainingItems = props.tipo === 'insumos' ? props.totalCount : 
  props.tipo === 'herramientas-salidas-stock' ? props.totalCount : 
  props.tipo === 'herramientas-sin-salida' ? props.totalCount : 
  props.tipo === 'herramientas-fuera-servicio' ? props.totalCount :
  props.tipo === 'equipos-salidas-bajo-stock' ? props.totalCount :
  props.tipo === 'equipos-fuera-servicio' ? props.totalCount :
  props.tipo === "equipos-sin-salida" ? props.totalCount :
  (lista.length > 20) ? lista.length - 20 : 0; // Cantidad de registros adicionales

  // console.log("CANTIDAD REMANENTE: ", remainingItems);
  // console.log("CANTIDAD REMANENTE DE VISIBLE ITEMS: ", visibleItems.length);

  // Función para guardar los códigos en sessionStorage
  const guardarCodigosEnSessionStorage = () => {
    const codigos = lista.map(item => {
      return props.tipo === "insumos" ? item.codigo :
        props.tipo === "herramientas-salidas-stock" || props.tipo === "herramientas-sin-salida" || props.tipo === "herramientas-fuera-servicio" || props.tipo === "herramientas" ? item.codigo :
          props.tipo === "equipos-fuera-servicio" || props.tipo === "equipos-salidas-bajo-stock" || props.tipo === "equipos-sin-salida" ? item.codigo_equipo : null;
    }).filter(Boolean); // Filtra elementos nulos

    sessionStorage.removeItem('insumosBajoStock');
    sessionStorage.removeItem('herramientas-salidas-stockBajoStock');
    sessionStorage.removeItem('herramientas-sin-salidaBajoStock');
    sessionStorage.removeItem('herramientas-fuera-servicioBajoStock');
    sessionStorage.removeItem('equipos-salidas-bajo-stockBajoStock');
    sessionStorage.removeItem('equipos-fuera-servicioBajoStock');
    sessionStorage.removeItem('equipos-sin-salidaBajoStock');

    sessionStorage.setItem(`${props.tipo}BajoStock`, JSON.stringify(codigos));

  };

  // URL de redirección según el tipo
  const redirectUrl = props.tipo === "insumos" ? "/usuario/lista-insumos-principal" :
    props.tipo === "herramientas-salidas-stock" ? "/usuario/lista-herramientas-salidas" :
    props.tipo === "herramientas-sin-salida" || props.tipo === "herramientas-fuera-servicio" ? "/usuario/lista-herramientas-principal" :
      props.tipo === "equipos-salidas-bajo-stock" ? "/usuario/lista-equipos-salidas" :
      props.tipo === "equipos-fuera-servicio" || props.tipo === "equipos-sin-salida" ? "/usuario/lista-equipos-principal" : "#";

  return (
    <div className={`bg-white w-80 flex items-center justify-between h-36
      rounded-sm shadow-lg 
      px-4 py-6 border-s-4 
      ${props.color} 
      mb-4
      max-[883px]:w-full`}
      title={props.info_bubble}
      onMouseEnter={() => props.showTooltip && setIsHovered(true)} // Activate tooltip if showTooltip is true
      onMouseLeave={() => props.showTooltip && setIsHovered(false)} // Deactivate tooltip if showTooltip is true
    >
      <div>
        <p className={`${props.title_card_style}`}>{props.titulo_tarjeta}</p>
        <p className={`${props.data_card_style}`}>{props.contenido}</p>

        {isHovered && props.showTooltip && ( // Show tooltip if isHovered and showTooltip are true
          <div className="absolute bg-gray-800 text-gray-300 p-2 rounded-md shadow-lg mt-2 text-sm z-50">

            {/* Conditionally render link if there are items in the list */}
            {lista.length > 0 && (
              <Link
                to={redirectUrl} // Redirect to the corresponding URL
                onClick={guardarCodigosEnSessionStorage} // Store codes on click
                className="text-blue-400 my-1 hover:underline"
              >
                {props.tipo === "insumos" ? `Ver insumos` :
                  props.tipo === "herramientas-salidas-stock" ? `Ver salidas de herramientas` :
                  props.tipo === "herramientas-sin-salida" ? `Ver herramientas sin salidas` :
                  props.tipo === "herramientas-fuera-servicio" ? `Ver herramientas fuera de servicio` :
                  props.tipo === "equipos-salidas-bajo-stock" ? `Ver equipos con nulo stock` :
                    props.tipo === "equipos-fuera-servicio" ? `Ver equipos fuera de servicio` : 
                    props.tipo === "equipos-sin-salida" ? `Ver equipos sin salida` : 
                    "Aún no"
                    }
              </Link>
            )}

            <ul className="list-none">
              {visibleItems.map((item, index) => (
                <li key={index}>
                  {props.tipo === 'insumos' ? (
                    `${index + 1}) ${item.codigo} - ${item.nombre} - ${item.color}`
                  ) : props.tipo === "herramientas" ||  props.tipo === "herramientas-sin-salida" || props.tipo === "herramientas-fuera-servicio" || props.tipo === "herramientas-salidas-stock" ? (
                    `${index + 1}) ${item.codigo} - ${item.nombre_herramienta} - ${item.medida} - Saldo: ${item.saldo}`
                  ) : props.tipo === "equipos-fuera-servicio" || props.tipo === "equipos-salidas-bajo-stock" || props.tipo === "equipos-sin-salida" ? (
                    `${index + 1}) ${item.codigo_equipo} - ${item.nombre_equipo} - ${item.nro_serie_equipo} - Saldo: ${item.saldo}`
                  ) : null}
                </li>
              ))}
            </ul>
            {remainingItems > 0 && (
              <p className="text-gray-400 mt-2">
                Hay {remainingItems} registros más
              </p>
            )}
          </div>
        )}
      </div>
      <div>
        {props.tarjeta_logo}
      </div>
    </div>
  );
}