import React, { useState } from 'react';
import * as XLSX from "xlsx";
import * as XLSXStyle from "xlsx-js-style";

import { RotatingLines } from 'react-loader-spinner';
import { FaFileCsv } from 'react-icons/fa';

export default function KardexHerramientasExcel({ kardexHerramientasData }) {

    const [loadingDataExcel, setLoadingDataExcel] = useState(false);

    const getFormattedDate = () => {
        const date = new Date();
        const options = {
            day: '2-digit',
            month: 'long',
            year: 'numeric',
        };
        return date.toLocaleString('es-ES', options);
    };

    const getFormattedHour = () => {
        const date = new Date();
        const options = {
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: true,
        };
        return date.toLocaleString('es-ES', options);
    };

    const titulo = [{ A: "Reporte | Kardex de Herramientas"}, {}];

    const InfoAdicional = {
        A: "Generado por ICB Industrial"
    };

    const FechaActual = {
        A: `Reporte generado el ${getFormattedDate()} a las ${getFormattedHour()}`
    };

    const longitudes = [5, 20, 25, 18, 15, 10, 25, 40, 40, 40, 45, 50, 30, 20, 28, 40, 40, 40, 45, 45, 40, 30, 35, 45, 35, 35, 35, 40];
                    //  A   B   C   D   E   F   G   H   I   J   K   L   M   N   O   P   Q   R   S   T   U   V   W   X   Y   Z   AA AB

    const handleExcelDownload = () => {
        setLoadingDataExcel(true);

        let table = [
            {
                A: "Nro.",
                B: "Movimiento",
                C: "Código",
                D: "Herramienta",
                E: "Medida",
                F: "Can. Ingreso",
                G: "Fecha de Ingreso",
                H: "Nombre Usuario que Registró | Ingreso",
                I: "Email Usuario que Registró | Ingreso",
                J: "Rol Usuario que Registró | Ingreso",

                K: "Doc. de Responsable de Herramienta | Salida",
                L: "Nombre de Responsable de Herramienta | Salida",
                M: "Proyecto | Salida",
                N: "Cantidad Salida",
                O: "Fecha de Salida",
                P: "Nombre Usuario que Registró | Salida",
                Q: "Email Usuario que Registró | Salida",
                R: "Rol Usuario que Registró | Salida",

                S: "¿Devuelto?",
                T: "Cód. de Salida",
                U: "Doc. de Responsable de Herramienta | Devolución",
                V: "Nombre de Responsable de Herramienta | Devolución",
                W: "Proyecto | Devolución",
                X: "Cantidad | Devolución",
                Y: "Fecha de Salida Devolución",
                Z: "Nombre Usuario que Registró | Devolución",
                AA: "Email Usuario que Registró | Devolución",
                AB: "Rol Usuario que Registró | Devolución",
            },
            ...kardexHerramientasData.map((kardexHerramienta, index) => ({
                A: index+1,
                B: kardexHerramienta.es_ingreso_herramienta == 1 ? 'Ingreso' : 
                ( kardexHerramienta.es_ingreso_herramienta == 2 ? 'Devolución' : 
                    kardexHerramienta.es_ingreso_herramienta == 0 && kardexHerramienta.es_devuelto == 0 ? 'Salida sin Devolución' : 
                    (kardexHerramienta.es_ingreso_herramienta == 0 && kardexHerramienta.es_devuelto == 1 ? 'Salida Devuelta' : 'Indefinido')),

                C: kardexHerramienta.codigo,
                D: kardexHerramienta.nombre_herramienta,
                E: kardexHerramienta.medida,
                F: kardexHerramienta.cantidad_ingreso,
                G: kardexHerramienta.fecha_ingreso ? dateFormatter(kardexHerramienta.fecha_ingreso) : null,
                H: kardexHerramienta.nombre_ingreso,
                I: kardexHerramienta.email_ingreso,
                J: kardexHerramienta.rol_ingreso,
                K: kardexHerramienta.responsable_nro_documento_salida,
                L: kardexHerramienta.responsable_nombre_salida,
                M: kardexHerramienta.proyecto_herramienta_salida,
                N: kardexHerramienta.cantidad_salida,
                O: kardexHerramienta.fecha_salida ? dateFormatter(kardexHerramienta.fecha_salida) : null,
                P: kardexHerramienta.nombre_salida,
                Q: kardexHerramienta.email_salida,
                R: kardexHerramienta.rol_salida,

                S: kardexHerramienta.codigo_salida_herramienta,
                T: (kardexHerramienta.es_devuelto == 1 && kardexHerramienta.tiene_merma == 1 ? "Devuelto con Merma" : 
                    (kardexHerramienta.es_devuelto == 1 && kardexHerramienta.tiene_merma == 0 ? "Devuelto" : (
                        kardexHerramienta.es_devuelto == 0 ? "No Devuelto" : ""
                    ))),

                U: kardexHerramienta.nro_documento_responsable_herramienta_devolucion,
                V: kardexHerramienta.nombre_completo_responsable_herramienta_devolucion,
                W: kardexHerramienta.proyecto_herramienta_devolucion,
                X: kardexHerramienta.cantidad_devolucion,
                Y: kardexHerramienta.fecha_devolucion,
                Z: kardexHerramienta.nombre_devolucion,
                AA: kardexHerramienta.email_devolucion,
                AB: kardexHerramienta.rol_devolucion,
            }))
        ];

        const dataFinal = [...titulo, FechaActual, {}, ...table, {}, InfoAdicional];

        setTimeout(() => {
            creandoArchivo(dataFinal);
            setLoadingDataExcel(false);
        }, 1000);
    }

    const creandoArchivo = (dataFinal) => {
        const libro = XLSX.utils.book_new();

        const hoja = XLSX.utils.json_to_sheet(dataFinal, {skipHeader: true});

        //Unir celdas
        hoja["!merges"] = [
            XLSX.utils.decode_range("A1:AB1"),
            XLSX.utils.decode_range("A2:AB2"),
        ];

        // Estilo para A1:G1
        const tituloStyle = {
            font: { bold: true, color: { rgb: "b8671c" }, sz: 18 },
            //fill: { fgColor: { rgb: "C5E1A5" } },
            alignment: { horizontal: "center", vertical: "center" }
        };

        // Aplicar estilo a A1:G1
        const tituloCols = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z', 'AA', 'AB'];
        tituloCols.forEach(col => {
            const cell = `${col}1`;
            if (hoja[cell]) {
                hoja[cell].s = tituloStyle;
            }
        });

        //Aplicar estilos a A3
        const reporteStyle = {
            font: { bold: true, color: { rgb: "b8671c" }, sz: 10 },
        };

        //Aplicar estilos a A3
        const reporteCols = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z', 'AA', 'AB'];
        reporteCols.forEach(col => {
            const cell = `${col}3`;
            if (hoja[cell]) {
                hoja[cell].s = reporteStyle;
            }
        });

        // Agregar estilo a los encabezados A5:S5
        const headerStyle = {
            font: { bold: true, color: { rgb: "FFFFFF" } },
            fill: { fgColor: { rgb: "dd7d23" } },
            alignment: { horizontal: "center", vertical: "center" }
        };

        // Aplicar estilo a los encabezados
        const cols = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z', 'AA', 'AB'];
        cols.forEach(col => {
            const cell = `${col}5`;
            if (hoja[cell]) {
                hoja[cell].s = headerStyle;
            }
        });

        // Aplicar estilos condicionales a las celdas "MOVIMIENTO"
        dataFinal.forEach((row, rowIndex) => {
            if (rowIndex > 4 && row.B) {  // Saltar encabezados y título
                const cell = `B${rowIndex + 1}`;

                const isActive = (row.B === 'Ingreso' ? '1' : 
                    (row.B === "Salida Devuelta" ? '3' : 
                        row.B === "Salida sin Devolución" ? '0' : 
                    row.B === "Devolución" ? "2" : ""));  // Verificar si el estado es 'Activo'

                const style = {
                    fill: { fgColor: { rgb: isActive == '1' ? "218014" :
                         ( isActive == '3' ? "801414" :
                            isActive == '2' ? '2986c4' :
                            isActive == '0' ? 'c26d27' : '') } },

                    font: { color: { rgb: "FFFFFF" } }
                };
                if (hoja[cell]) {
                    hoja[cell].s = style;
                }
            }
        });

        // Aplicar estilos condicionales a las celdas "Devuelto?"
        dataFinal.forEach((row, rowIndex) => {
            if (rowIndex > 4 && row.T) {  // Saltar encabezados y título
                const cell = `T${rowIndex + 1}`;

                const isActive = (row.T === 'Devuelto' ? '1' : 
                    (row.T === "No Devuelto" ? '3' : 
                        row.T === "Devuelto con Merma" ? '0' : 
                     ""));  // Verificar si el estado es 'Activo'

                const style = {
                    fill: { fgColor: { rgb: isActive == '1' ? "218014" :
                         ( isActive == '3' ? "801414" :
                            isActive == '0' ? 'e3ac05' : '') } },

                    font: { color: { rgb: "FFFFFF" } }
                };

                if (hoja[cell]) {
                    hoja[cell].s = style;
                }
            }
        });

        let propiedades = [];

        longitudes.forEach((col) => {
            propiedades.push({
                width: col,
            });
        });

        hoja["!cols"] = propiedades;

        XLSXStyle.utils.book_append_sheet(libro, hoja, "Kardex Herramientas");

        XLSXStyle.writeFile(libro, `excel_kardex_herramientas_${new Date().toLocaleDateString()}.xlsx`);
    };

    const dateFormatter = (dateString) => {
        const parsedDate = new Date(dateString).toLocaleString('es', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
            hour12: true
        });
        return parsedDate;
    };

  return (
    <div>
        <button disabled={loadingDataExcel}
        className={`max-[495px]:ms-1 max-[467px]:ms-0 max-[315px]:mt-3
            ${loadingDataExcel ? 'bg-lime-600 text-white cursor-not-allowed opacity-50 rounded border border-lime-600' :
            `bg-white rounded text-lime-600 
            border border-lime-600
            hover:text-white hover:bg-lime-600 `}
            px-2 py-1 flex items-center 
            `}
               onClick={handleExcelDownload}
        >
            {loadingDataExcel ?
                        <div className='flex items-center justify-center'>
                            <RotatingLines
                                strokeColor="white"
                                strokeWidth='3'
                                width='22'
                                radius="5"
                                ariaLabel="lines-loading"
                            />
                            <p className='ms-3'>Exportando...</p>
                        </div>
                        : <>
                            <FaFileCsv className='me-3' /> Exportar Excel
                        </>
                    }
        </button>
    </div>
  )
}
