import React, { useState, useEffect } from 'react'
import AuthUser from '../../pagesAuth/AuthUser';

import { useMs } from '../../../context/MsContext';

export default function Perfil() {

    const { getUser, getRol } = AuthUser();

    const [name, setName] = useState(getUser().name);
    const [email, setEmail] = useState(getUser().email);

    const { ms } = useMs();

    useEffect(() => {
        document.title = "SDI ICB | Perfil";
    });

    const onNameChange = (e) => {
        setName(e.target.value);
    }

    const onEmailChange = (e) => {
        setEmail(e.target.value);
    }

    return (
        <div className={`${ms ? 'ms-4 transition-all' : 'ms-56 transition-all'} mt-16 pt-2 overflow-y-auto max-[768px]:ms-36`}>
            <h1 className='mb-4 text-2xl text-slate-500 font-semibold'>Datos del usuario</h1>
            <form>
                <div className='mb-4'>
                    <label className='text-slate-500 font-normal text-xl ms-8'>Nombre: </label>
                    <input
                        type="text"
                        className='ps-2 border border-slate-600 rounded'
                        value={name}
                        onChange={onNameChange} />
                </div>

                <div className='mb-4'>
                    <label className='text-slate-500 font-normal text-xl ms-8'>Correo Electrónico: </label>
                    <input
                        type="text"
                        className='ps-2 border border-slate-600 rounded'
                        value={email}
                        onChange={onEmailChange} />
                </div>

                <div className='mb-4'>
                    <label className='text-slate-500 font-normal text-xl ms-8'>Rol: </label>
                    <input
                        type="text"
                        className='ps-2 border border-slate-600 rounded'
                        value={getRol()}
                        disabled />
                </div>
            </form>
        </div>
    )
}
