import React, { useState, useEffect } from 'react';
import { RotatingLines } from 'react-loader-spinner';

import AuthUser from '../../../pages/pagesAuth/AuthUser';
import Modal from '../../modals/Modal';

import { FaPlus } from "react-icons/fa";
import Config from '../../../Config';

import { toast } from 'react-toastify';
import InputForm from '../../inputs/InputForm';

import AsyncSelect from 'react-select/async';


export default function ModalAnadirEquipo({ open, onClose, updateEquipoList }) {

    const { getRol } = AuthUser();

    const [marcaEquipoId, setMarcaEquipoId] = useState('');
    const [proveedorId, setProveedorId] = useState('');
    const [nroSerieEquipo, setNroSerieEquipo] = useState('');
    const [nombreEquipo, setNombreEquipo] = useState('');
    const [tiempoVidaInicial, setTiempoVidaInicial] = useState('');
    const [tiempoVidaFinal, setTiempoVidaFinal] = useState('');

    const [fechaInicialCertificado, setFechaInicialCertificado] = useState('');
    const [fechaFinalCertificado, setFechaFinalCertificado] = useState('');
    
    // Función para formatear la fecha
    const formatFechaForDB = (dateString) => {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    //Variable para restringir fecha
    const now = new Date();
    const now_equipo = formatFechaForDB(now);

    const [errors, setErrors] = useState({});
    const [loadingSubmit, setloadingSubmit] = useState(false);

    const [zIndex, setZIndex] = useState(-1);

    useEffect(() => {
        if (open) {
            //document.addEventListener('mousedown', handleClickOutside);
            setZIndex(9999);
        } else {
            //document.removeEventListener('mousedown', handleClickOutside);
            setZIndex(-1);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
            setErrors({});
        };
    }, [open]);

    const loadMarcasEquiposOptions = async (globalFilter) => {
        try {
            let response;
            if (getRol() === "Administrador") {
                response = await Config.getSelectMarcasEquiposAdmin(globalFilter);
            } else if (getRol() === "Almacenero") {
                response = await Config.getSelectMarcasEquiposAlmacenero(globalFilter);
            } else if (getRol() === "Gerente") {
                response = await Config.getSelectMarcasEquiposGerente(globalFilter);
            }


            const arrayMarcasEquipos = response.data;
            if(arrayMarcasEquipos.exito == 0){
                return [];
            }

            return arrayMarcasEquipos.map(marca_equipo => ({
                value: marca_equipo.id_marcaequipo,
                label: `${marca_equipo.id_marcaequipo} - ${marca_equipo.nombre_marca_equipo}`
            }));
        } catch (error) {
            console.error("Error:", error);
            notify("Ha ocurrido un error al cargar las marcas de equipos. Vuelva a intentarlo más tarde.", "error");
            return [];
        }
    };

    const loadProveedoresOptions = async (inputValue) => {
        try {
            let response;
            if (getRol() === "Administrador") {
                response = await Config.getSelectProveedoresAdmin(inputValue);
            } else if (getRol() === "Almacenero") {
                response = await Config.getSelectProveedoresAlmacenero(inputValue);
            }
            const arrayProveedores = response.data;
            if (arrayProveedores.exito == '0') {
                return [];
            }

            console.log(arrayProveedores);
            return arrayProveedores.map(proveedor => ({
                value: proveedor.id_proveedor,
                label: `${proveedor.nombre_proveedor} - ${proveedor.ruc} - ${proveedor.estado_proveedor === "1" ? 'Activo': 'Inactivo'}`
            }));
        } catch (error) {
            console.error("Error:", error);
            notify("Ha ocurrido un error al cargar los proveedores. Vuelva a intentarlo más tarde.", "error");
            return [];
        }
    };

    const handleClickOutside = (event) => {
        if (event.target.closest(".modal-content") === null) {
            onClose();
        }
    };

    const addNewEquipo = async (e) => {
        e.preventDefault();
        setloadingSubmit(true);
        setErrors({});

        const EquipoData = {
            marca_equipo_id: marcaEquipoId.value,
            proveedor_id: proveedorId.value,
            nro_serie_equipo: nroSerieEquipo,
            nombre_equipo: nombreEquipo,
            tiempo_vida_inicial: tiempoVidaInicial,
            tiempo_vida_final: tiempoVidaFinal,


            fecha_inicial_certificación: null,
            fecha_final_certificación: null,
        };

        try {
            let response;

            if (getRol() === "Administrador") {
                response = await Config.postGuardarEquipoAdmin(EquipoData);
            }else if(getRol() === "Almacenero"){
                response = await Config.postGuardarEquipoAlmacenero(EquipoData);
            }

            if (response.data.errors) {
                setErrors(response.data.errors);
            } else {
                if (response.data.exito == 0) {
                    notify(response.data.message, "error");
                } else if (response.data.exito == 1) {
                    notify("El equipo ha sido añadido correctamente.", "success");
                    setMarcaEquipoId('');
                    setProveedorId('');
                    setNombreEquipo('');
                    setTiempoVidaInicial('');
                    setTiempoVidaFinal('');

                    setFechaInicialCertificado('');
                    setFechaFinalCertificado('');

                    onClose();
                    updateEquipoList();
                } else {
                    notify("Ha ocurrido un error. Vuelva a intentarlo más tarde.", "error");
                }
            }

        } catch (error) {
            console.error("Error: ", error);
            notify("Ha ocurrido un error. Vuelva a intentarlo más tarde.", "error");
            updateEquipoList();
        } finally {
            setloadingSubmit(false);
        };
    }

    const renderErrorMessages = (messages) => {
        return messages.map((msg, index) => (
            <div key={index} style={{ marginLeft: '202px' }}>
                <p className="text-red-500 text-sm -mt-3 mb-3">{msg}</p>
            </div>
        ));
    };

    const notify = (message, type) => {
        toast[type](message, {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
        });
    };

    const getColourStyles = (hasError) => ({
        control: (styles, { isFocused }) => ({
            ...styles,
            backgroundColor: '#F9F9F9',
            borderColor: hasError ? 'red' : (isFocused ? '#339A5B' : '#D9D9D9'),
            boxShadow: hasError ? '1px red' : (isFocused ? '1px #339A5B' : null),
            '&:hover': {
                borderColor: hasError ? 'red' : (isFocused ? '#D9D9D9' : '#339A5B')
            },
        }),

        option: (styles, { data, isDisabled, isFocused, isSelected }) => ({
            ...styles,
            backgroundColor: isDisabled
                ? undefined
                : isSelected
                    ? '#ecf9ec'
                    : isFocused
                        ? '#f2f2f2'
                        : undefined,
            color: isDisabled
                ? '#ccc'
                : isSelected
                    ? 'black'
                    : data.color,
            cursor: isDisabled ? 'not-allowed' : 'default',

            '&:active': {
                backgroundColor: !isDisabled && (isSelected ? 'darkgreen' : '#ecf9ec'),
                color: !isDisabled && (isSelected ? 'white' : 'black')
            }
        }),
        clearIndicator: (styles) => ({
            ...styles,
            zIndex: 99999999,  // Cambia el z-index del botón de "clear"
            cursor: 'pointer',
        }),
        menuPortal: (base) => ({
            ...base,
            zIndex: zIndex
        }),
    });

    const cerrarModal = (e) => {
        e.preventDefault();
        onClose();
    }

  return (
    <Modal open={open} onClose={onClose}>
            <div className='w-100'>
                <h3 className='text-center'>Imagen de Añadir</h3>
                <h3 className='text-lg text-center font-black text-gray-800 w-full mb-8'>Añadir Equipo</h3>

                <form>

                    <div className='flex mb-3 w-full'>
                        <label htmlFor='categoria_id' className='w-80 flex items-center font-normal text-[#454545]'>Marca de Equipo: </label>
                        <AsyncSelect 
                            cacheOptions
                            defaultOptions
                            loadOptions={loadMarcasEquiposOptions}
                            className={`w-full`}
                            styles={getColourStyles(!!errors.marca_equipo_id)}
                            id="categoria_id"
                            value={marcaEquipoId}
                            onChange={(option, id) => { setMarcaEquipoId(option); console.log(option, id); }}
                            placeholder="Seleccionar marca de equipo..."
                            noOptionsMessage={() => "No se encontraron resultados"}
                            loadingMessage={() => "Cargando..."}
                            menuPortalTarget={document.querySelector('body')}
                            isClearable={true}
                        />
                    </div>

                    {errors.marca_equipo_id && Array.isArray(errors.marca_equipo_id) && renderErrorMessages(errors.marca_equipo_id)}

                    <div className='flex mb-3 w-full'>
                        <label htmlFor='proveedor_id' className='w-80 flex items-center font-normal text-[#454545]'>Proveedor: </label>
                        <AsyncSelect 
                            cacheOptions
                            defaultOptions
                            loadOptions={loadProveedoresOptions}
                            className={`w-full`}
                            styles={getColourStyles(!!errors.proveedor_id)}
                            id="proveedor_id"
                            value={proveedorId}
                            onChange={(option, id) => { setProveedorId(option); console.log(option, id);}}
                            isClearable={true}
                            placeholder="Seleccionar proveedor..."
                            noOptionsMessage={() => "No se encontraron resultados"}
                            loadingMessage={() => "Cargando..."}
                            menuPortalTarget={document.querySelector('body')}
                        />
                    </div>

                    {errors.proveedor_id && Array.isArray(errors.proveedor_id) && renderErrorMessages(errors.proveedor_id)}

                    <InputForm
                        id="Equipo"
                        labelName="Nombre de Equipo:"
                        inputType="text"
                        value={nombreEquipo}
                        funcion={(e) => setNombreEquipo(e.target.value)}
                        errors={errors.nombre_equipo}
                        labelWidth="w-80"
                    />

                    {errors.nombre_equipo && Array.isArray(errors.nombre_equipo) && renderErrorMessages(errors.nombre_equipo)}

                    <InputForm
                        id="nro_serie_equipo"
                        labelName="N° Serie:"
                        inputType="text"
                        value={nroSerieEquipo}
                        funcion={(e) => setNroSerieEquipo(e.target.value)}
                        errors={errors.nro_serie_equipo}
                        labelWidth="w-80"
                    />

                    {errors.nro_serie_equipo && Array.isArray(errors.nro_serie_equipo) && renderErrorMessages(errors.nro_serie_equipo)}

                    {/* TIEMPO VIDA INICIAL */}

                    <div className='flex mb-3'>

                        <label htmlFor="date_vida_inicial" className={`w-80 flex items-center font-normal text-[#454545]`} style={{ width: "200px" }}>Tiempo V. Inicial:</label>
                        <input
                            value={tiempoVidaInicial || ""}
                            onChange={(e) => { 
                                const selectedDate = e.target.value;
                                setTiempoVidaInicial(selectedDate);
                                //setTiempoVidaInicial(formatFechaForDB(e.target.value)); console.log(formatFechaForDB(e.target.value)) 
                            }}
                            id = "date_vida_inicial"
                            type="date"
                            max={now_equipo}
                            style={{ width: "150px" }}
                            className={`px-2 py-1 bg-[#F9F9F9] border border-[#D9D9D9] 
                        rounded-md focus:outline-none focus:border-[#339A5B] focus:ring-1 focus:ring-[#339A5B] 
                        ${errors.tiempo_vida_inicial ? 'border-red-500' : 'border-slate-300'}`}
                        />

                    </div>

                    {errors.tiempo_vida_inicial && Array.isArray(errors.tiempo_vida_inicial) && renderErrorMessages(errors.tiempo_vida_inicial)}

                    {/* TIEMPO VIDA FINAL */}

                    <div className='flex mb-3'>

                        <label htmlFor="date_vida_final" className={`w-80 flex items-center font-normal text-[#454545]`} style={{ width: "200px" }}>Tiempo V. Final:</label>
                        <input
                            value={tiempoVidaFinal || ""}
                            onChange={(e) => { 
                                const selectedDate = e.target.value;
                                setTiempoVidaFinal(selectedDate);
                                //setTiempoVidaFinal(formatFechaForDB(e.target.value)); console.log(formatFechaForDB(e.target.value)) 
                            }}
                            id = "date_vida_final"
                            type="date"
                            style={{ width: "150px" }}
                            className={`px-2 py-1 bg-[#F9F9F9] border border-[#D9D9D9] 
                        rounded-md focus:outline-none focus:border-[#339A5B] focus:ring-1 focus:ring-[#339A5B] 
                        ${errors.tiempo_vida_final ? 'border-red-500' : 'border-slate-300'}`}
                        />

                    </div>

                    {errors.tiempo_vida_final && Array.isArray(errors.tiempo_vida_final) && renderErrorMessages(errors.tiempo_vida_final)}

                    <div className='flex justify-end flex-wrap mb-3 mt-6'>
                        <button
                            disabled={loadingSubmit}
                            className={`flex items-center justify-center py-2 font-semibold 
                            shadow-md rounded-md w-44
                            text-white bg-[#EB8927]
                            me-3
                            max-[439px]:me-0
                            max-[469px]:m-0
                            ${loadingSubmit ? 'cursor-not-allowed opacity-50' : ''}`}

                            onClick={addNewEquipo}
                            type="submit">

                            {loadingSubmit ?
                                <div className='flex items-center justify-center w-44'>
                                    <RotatingLines
                                        strokeColor="white"
                                        strokeWidth='3'
                                        width='22'
                                        radius="5"
                                        ariaLabel="lines-loading"
                                    />
                                </div>
                                : <>
                                    <FaPlus /> Guardar Equipo
                                </>
                            }

                        </button>

                        <button
                            className='py-2 px-4 font-semibold shadow-md rounded-lg w-40
                            max-[426px]:mt-3
                            bg-white text-gray-500'
                            onClick={cerrarModal}
                        >
                            Cerrar
                        </button>
                    </div>

                </form>
            </div>
        </Modal >
  )
}
