import React, { useEffect, useState } from 'react';

import Config from '../../../Config';
import AuthUser from '../../../pages/pagesAuth/AuthUser';
import InputForm from '../../inputs/InputForm';

import Modal from '../../modals/Modal';
import { components } from 'react-select';
import { RotatingLines, TailSpin } from 'react-loader-spinner';
import { MdEditDocument } from "react-icons/md";

import { toast } from 'react-toastify';

import AsyncSelect from 'react-select/async';

export default function ModalEditarHerramienta({ open, onClose, updateHerramientaList, editId }) {

    const { getRol } = AuthUser();

    const id = editId;

    const [categoriaHerramientaId, setCategoriaHerramientaId] = useState('');
    const [proveedorId, setProveedorId] = useState('');
    const [nombreHerramienta, setNombreHerramienta] = useState('');
    const [nroSerie, setNroSerie] = useState('');
    const [medida, setMedida] = useState('');
    const [estadoHerramienta, setEstadoHerramienta] = useState('');

    //Para las cantidades por estado
    const [cantIngreso, setCantIngreso] = useState('');
    const [cantSalida, setCantSalida] = useState('');
    const [cantSaldo, setCantSaldo] = useState('');
    const [cantOptimo, setCantOptimo] = useState(0);
    const [cantBueno, setCantBueno] = useState(0);
    const [cantFaltaMante, setCantFaltaMante] = useState(0);
    const [cantFueraS, setCantFueraS] = useState(0);

    // Función para formatear la fecha
    const formatFechaForDB = (dateString) => {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    //Variable para restringir fecha
    const now = new Date();
    const now_herramienta = formatFechaForDB(now);

    const [errors, setErrors] = useState({});

    const [loadingButton, setLoadingButton] = useState(false);
    const [loadingData, setLoadingData] = useState(true);

    const [zIndex, setZIndex] = useState(-1);

    useEffect(() => {

        if (open) {
            /* document.addEventListener('mousedown', handleClickOutside); */
            cargarHerramienta();
            setZIndex(9999);
        } else {
            /* document.removeEventListener('mousedown', handleClickOutside); */
            setZIndex(-1);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [open]);

    const loadCategoriasHerramientasOptions = async (globalFilter) => {
        try {
            let response;
            if (getRol() === "Administrador") {
                response = await Config.getSelectCategoriaHerramientasAdmin(globalFilter);
            } else if (getRol() === "Almacenero") {
                response = await Config.getSelectCategoriaHerramientasAlmacenero(globalFilter);
            }
            const arrayCatHerramientas = response.data;
            if (arrayCatHerramientas.exito == 0) {
                return [];
            }

            return arrayCatHerramientas.map(marca_herramienta => ({
                value: marca_herramienta.id_marcaherramienta,
                label: `${marca_herramienta.id_marcaherramienta} - ${marca_herramienta.nombre_marca_herramienta}`
            }));
        } catch (error) {
            console.error("Error:", error);
            notify("Ha ocurrido un error al cargar las marcas de herramientas. Vuelva a intentarlo más tarde.", "error");
            return [];
        }
    };

    const loadProveedoresOptions = async (inputValue) => {
        try {
            let response;
            if (getRol() === "Administrador") {
                response = await Config.getSelectProveedoresAdmin(inputValue);
            } else if (getRol() === "Almacenero") {
                response = await Config.getSelectProveedoresAlmacenero(inputValue);
            }
            const arrayProveedores = response.data;
            if (arrayProveedores.exito == '0') {
                return [];
            }

            console.log(arrayProveedores);
            return arrayProveedores.map(proveedor => ({
                value: proveedor.id_proveedor,
                label: `${proveedor.nombre_proveedor} - ${proveedor.ruc} - ${proveedor.estado_proveedor === "1" ? 'Activo' : 'Inactivo'}`
            }));
        } catch (error) {
            console.error("Error:", error);
            notify("Ha ocurrido un error al cargar los proveedores. Vuelva a intentarlo más tarde.", "error");
            return [];
        }
    };

    const handleClickOutside = (event) => {
        if (event.target.closest(".modal-content") === null) {
            onClose();
        }
    };

    const cargarHerramienta = async () => {
        setLoadingData(true);
        setErrors({});

        try {

            let response;

            if (getRol() === "Administrador") {
                response = await Config.getOneHerramientaAdmin(id);
            } else if (getRol() === "Almacenero") {
                response = await Config.getOneHerramientaAlmacenero(id);
            }

            console.log(response);

            if (response.data.exito == 0) {
                notify(`Error: ${response.data.message}`, "error");
            } else {

                //console.log("PROVEEDOR: " + response.data.proveedor);

                setCategoriaHerramientaId(response.data.marca_herramienta);
                setProveedorId(response.data.proveedor);
                setNombreHerramienta(response.data.nombre_herramienta);
                setNroSerie(response.data.nro_serie_herramienta);
                setMedida(response.data.medida);
                setEstadoHerramienta(response.data.estado_herramienta);
                //console.log("ESTADO HERRAMIENTA: ", response.data.estado_herramienta);

                setCantIngreso(response.data.ingreso);
                setCantSalida(response.data.salida);
                setCantSaldo(response.data.saldo);
                setCantOptimo(response.data.cantidad_optimo);
                setCantBueno(response.data.cantidad_buen_estado);
                setCantFaltaMante(response.data.cantidad_falta_mantenimiento);
                setCantFueraS(response.data.cantidad_fuera_servicio);

            }

        } catch (error) {
            console.error(error);
            notify(`Ha ocurrido un error. Vuelva a intentarlo más tarde. ${error}`, "error");
        } finally {
            setLoadingData(false);
        };
    }

    const onSubmit = async (e) => {
        e.preventDefault();
        setLoadingButton(true);

        try {

            const HerramientaData = {
                marca_herramienta_id: (categoriaHerramientaId.id_marcaherramienta ? categoriaHerramientaId.id_marcaherramienta : categoriaHerramientaId.value),
                proveedor_id: proveedorId ? (proveedorId.id_proveedor ? proveedorId.id_proveedor : proveedorId.value) : null,
                nombre_herramienta: nombreHerramienta,
                nro_serie_herramienta: nroSerie,
                medida: medida,
                estado_herramienta: (estadoHerramienta.value == 0 || estadoHerramienta.value == 1 || estadoHerramienta.value == 2 || estadoHerramienta.value == 3) ? estadoHerramienta.value : estadoHerramienta,

                cantidad_optimo: cantOptimo,
                cantidad_buen_estado: cantBueno,
                cantidad_fuera_servicio: cantFueraS,
                cantidad_falta_mantenimiento: cantFaltaMante
            };

            console.log("ANTES DE UPDATE: ", HerramientaData);

            let response;

            if (getRol() === "Administrador") {
                response = await Config.putUpdateHerramientaAdmin(id, HerramientaData);
            } else if (getRol() === "Almacenero") {
                response = await Config.putUpdateHerramientaAlmacenero(id, HerramientaData);
            }


            if (response.data.errors) {
                setErrors(response.data.errors);
            } else {
                if (response.data.exito == 0) {
                    notify(`Error: ${response.data.message}`, "error");
                } else if (response.data.exito == 1) {
                    notify("El Herramienta ha sido editado correctamente.", "success");
                    setCategoriaHerramientaId('');
                    setProveedorId('');
                    setNombreHerramienta('');
                    setNroSerie('');
                    setMedida('');
                    setEstadoHerramienta('');
                    onClose();
                    updateHerramientaList();
                } else {
                    notify("Ha ocurrido un error. Vuelva a intentarlo más tarde.", "error");
                }
            }


        } catch (error) {
            console.log(error);
            updateHerramientaList();
            notify("Ha ocurrido un error. Vuelva a intentarlo más tarde.", "error");
        } finally {
            setLoadingButton(false);
        };
    }

    const renderErrorMessages = (messages) => {
        return messages.map((msg, index) => (
            <div style={{ marginLeft: '202px' }}><p key={index} className="text-red-500 text-sm -mt-3 mb-3 ms-1">{msg}</p></div>
        ));
    };

    const notify = (message, type) => {
        toast[type](message, {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
        });
    };

    const getColourStyles = (hasError) => ({
        control: (styles, { isFocused }) => ({
            ...styles,
            backgroundColor: '#F9F9F9',
            borderColor: hasError ? 'red' : (isFocused ? '#339A5B' : '#D9D9D9'),
            boxShadow: hasError ? '1px red' : (isFocused ? '1px #339A5B' : null),
            '&:hover': {
                borderColor: hasError ? 'red' : (isFocused ? '#D9D9D9' : '#339A5B')
            },
        }),

        option: (styles, { data, isDisabled, isFocused, isSelected }) => ({
            ...styles,
            backgroundColor: isDisabled
                ? undefined
                : isSelected
                    ? '#ecf9ec'
                    : isFocused
                        ? '#f2f2f2'
                        : undefined,
            color: isDisabled
                ? '#ccc'
                : isSelected
                    ? 'black'
                    : data.color,
            cursor: isDisabled ? 'not-allowed' : 'default',

            '&:active': {
                backgroundColor: !isDisabled && (isSelected ? 'darkgreen' : '#ecf9ec'),
                color: !isDisabled && (isSelected ? 'white' : 'black')
            }
        }),

        clearIndicator: (styles) => ({
            ...styles,
            zIndex: 99999999,  // Cambia el z-index del botón de "clear"
            cursor: 'pointer',
        }),

        menuPortal: (base) => ({
            ...base,
            zIndex: zIndex
        }),

    });

    const cerrarModal = (e) => {
        e.preventDefault();
        onClose();
    };

    const estadoHerramientaOptions = [
        { value: 0, label: "Óptimo" },
        { value: 1, label: "Buen Estado" },
        { value: 2, label: "Fuera de Servicio" },
        { value: 3, label: "Múltiple" },
        { value: 4, label: "Falta Mantenimiento" }
    ];

    const loadEstadoHerramientaOptions = (inputValue, callback) => {
        const filteredOptions = estadoHerramientaOptions.filter(option =>
            option.label.toLowerCase().includes(inputValue.toLowerCase())
        );
        callback(filteredOptions);
    };

    return (
        <Modal open={open} onClose={onClose}>
            <div className='w-100'>
                <h3 className='text-center'>Imagen de Editar</h3>
                <h3 className='text-lg text-center font-black text-gray-800 w-full mb-8'>Editar Herramienta</h3>

                <form>

                    {!loadingData ?

                        <>

                            <div className='flex mb-3 w-full'>
                                <label htmlFor='categoria_id' className='w-80 flex items-center font-normal text-[#454545]'>Marca de Herramienta: </label>
                                <AsyncSelect
                                    cacheOptions
                                    defaultOptions
                                    loadOptions={loadCategoriasHerramientasOptions}
                                    className={`w-full`}
                                    styles={getColourStyles(!!errors.marca_herramienta_id)}
                                    id="categoria_id"
                                    //value={categoriaHerramientaId}
                                    defaultValue={
                                        categoriaHerramientaId && categoriaHerramientaId.id_marcaherramienta
                                            ? {
                                                id: categoriaHerramientaId.id_marcaherramienta, label: `${categoriaHerramientaId.id_marcaherramienta} - ${categoriaHerramientaId.nombre_marca_herramienta}`
                                            }
                                            : null

                                    }
                                    onChange={(option, id) => { setCategoriaHerramientaId(option); console.log(option, id); }}
                                    placeholder="Seleccionar marca de herramienta..."
                                    noOptionsMessage={() => "No se encontraron resultados"}
                                    loadingMessage={() => "Cargando..."}
                                    menuPortalTarget={document.querySelector('body')}
                                />
                            </div>

                            {errors.marca_herramienta_id && Array.isArray(errors.marca_herramienta_id) && renderErrorMessages(errors.marca_herramienta_id)}

                            <div className='flex mb-3 w-full'>
                                <label htmlFor='proveedor_id' className='w-80 flex items-center font-normal text-[#454545]'>Proveedor: </label>
                                <AsyncSelect
                                    cacheOptions
                                    defaultOptions
                                    loadOptions={loadProveedoresOptions}
                                    className={`w-full`}
                                    styles={getColourStyles(!!errors.proveedor_id)}
                                    id="proveedor_id"
                                    // value={proveedorId}
                                    defaultValue={
                                        proveedorId && proveedorId.id_proveedor
                                            ? {
                                                id: proveedorId.id_proveedor,
                                                label: `${proveedorId.id_proveedor} - ${proveedorId.nombre_proveedor}`
                                            }
                                            : null
                                    }
                                    onChange={(option, id) => { setProveedorId(option); console.log(option, id); }}
                                    isClearable={true}
                                    placeholder="Seleccionar proveedor..."
                                    noOptionsMessage={() => "No se encontraron resultados"}
                                    loadingMessage={() => "Cargando..."}
                                    menuPortalTarget={document.querySelector('body')}

                                />
                            </div>

                            {errors.proveedor_id && Array.isArray(errors.proveedor_id) && renderErrorMessages(errors.proveedor_id)}

                            <InputForm
                                id="Herramienta"
                                labelName="Nombre de Herramienta:"
                                inputType="text"
                                value={nombreHerramienta}
                                funcion={(e) => setNombreHerramienta(e.target.value)}
                                errors={errors.nombre_herramienta}
                                labelWidth="w-80"
                            />

                            {errors.nombre_herramienta && Array.isArray(errors.nombre_herramienta) && renderErrorMessages(errors.nombre_herramienta)}

                            <InputForm
                                id="Herramienta"
                                labelName="N° serie:"
                                inputType="text"
                                value={nroSerie}
                                funcion={(e) => setNroSerie(e.target.value)}
                                errors={errors.nro_serie_herramienta}
                                labelWidth="w-80"
                            />

                            {errors.nro_serie_herramienta && Array.isArray(errors.nro_serie_herramienta) && renderErrorMessages(errors.nro_serie_herramienta)}

                            <InputForm
                                id="medida"
                                labelName="Medida:"
                                inputType="text"
                                value={medida}
                                funcion={(e) => setMedida(e.target.value)}
                                errors={errors.medida}
                                labelWidth="w-80"
                            />

                            {errors.medida && Array.isArray(errors.medida) && renderErrorMessages(errors.medida)}

                            <div className='flex mb-3 w-full'>
                                <label htmlFor='estado_herramienta' className='w-80 flex items-center font-normal text-[#454545]'>Estado de herr.: </label>
                                <AsyncSelect
                                    cacheOptions
                                    defaultOptions={estadoHerramientaOptions}
                                    loadOptions={loadEstadoHerramientaOptions}
                                    className={`w-full`}
                                    styles={getColourStyles(!!errors.estado_herramienta)}
                                    id="estado_herramienta"
                                    // value={estadoHerramienta}
                                    defaultValue={
                                        estadoHerramienta ?
                                            {
                                                id: estadoHerramienta,
                                                label: `${(estadoHerramienta == "0" ? "Óptimo" :
                                                    (estadoHerramienta == "1" ? "Buen Estado" :
                                                        estadoHerramienta == "2" ? "Fuera de Servicio" :
                                                            estadoHerramienta == "3" ? "Múltiple" :
                                                                "Indefinido"))}`
                                            } : null
                                    }
                                    onChange={(option, id) => { setEstadoHerramienta(option); console.log(option, id); }}
                                    isClearable={true}
                                    placeholder="Seleccionar estado..."
                                    noOptionsMessage={() => "No se encontraron resultados"}
                                    loadingMessage={() => "Cargando..."}
                                    menuPortalTarget={document.querySelector('body')}
                                />
                            </div>

                            {errors.estado_herramienta && Array.isArray(errors.estado_herramienta) && renderErrorMessages(errors.estado_herramienta)}

                            <h4 className='text-sm mb-3 text-gray-600'>Cantidades de la herramienta por estado: </h4>

                            <div className='flex justify-between flex-wrap'>

                                <InputForm
                                    id="ingreso"
                                    labelName="Ingreso:"
                                    inputType="number"
                                    value={cantIngreso}
                                    /* funcion={(e) => setCantidad(e.target.value)} */
                                    labelWidth="w-80"
                                    isDisabled={true}
                                    bgColor='bg-[#e0e0e0]'
                                //inputWidth='w-32'
                                />

                                <InputForm
                                    id="cantidad_ingreso"
                                    labelName="Cantidad Óptimo:"
                                    inputType="number"
                                    value={cantOptimo}
                                    funcion={(e) => setCantOptimo(e.target.value)}
                                    labelWidth="w-80"
                                    bgColor='bg-green-100'
                                />

                                {errors.cantidad_optimo && Array.isArray(errors.cantidad_optimo) && renderErrorMessages(errors.cantidad_optimo)}

                                <InputForm
                                    id="cantidad_ingreso"
                                    labelName="Cantidad Buen Estado:"
                                    inputType="number"
                                    value={cantBueno}
                                    funcion={(e) => setCantBueno(e.target.value)}
                                    labelWidth="w-80"
                                    bgColor='bg-orange-100'
                                />

                                {errors.cantidad_buen_estado && Array.isArray(errors.cantidad_buen_estado) && renderErrorMessages(errors.cantidad_buen_estado)}

                                <InputForm
                                    id="cantidad_ingreso"
                                    labelName="Cantidad F. Mantenimiento:"
                                    inputType="number"
                                    value={cantFaltaMante}
                                    funcion={(e) => setCantFaltaMante(e.target.value)}
                                    labelWidth="w-80"
                                    bgColor='bg-yellow-100'
                                />

                                {errors.cantidad_falta_mantenimiento && Array.isArray(errors.cantidad_falta_mantenimiento) && renderErrorMessages(errors.cantidad_falta_mantenimiento)}

                                <InputForm
                                    id="cantidad_ingreso"
                                    labelName="Cantidad Fuera de Servicio:"
                                    inputType="number"
                                    value={cantFueraS}
                                    funcion={(e) => setCantFueraS(e.target.value)}
                                    labelWidth="w-80"
                                    bgColor='bg-red-100'
                                />

                                {errors.cantidad_fuera_servicio && Array.isArray(errors.cantidad_fuera_servicio) && renderErrorMessages(errors.cantidad_fuera_servicio)}

                                <InputForm
                                    id="salida"
                                    labelName="Salida:"
                                    inputType="number"
                                    value={cantSalida}
                                    /* funcion={(e) => setCantidad(e.target.value)} */
                                    labelWidth="w-80"
                                    isDisabled={true}
                                    bgColor='bg-[#e0e0e0]'
                                //inputWidth='w-32'
                                />

                            </div>

                        </>

                        : <div className='flex justify-center items-center' style={{ height: '217px' }}>
                            <TailSpin className='' width={150} height={150}></TailSpin>
                        </div>}

                    <div className='flex justify-end flex-wrap mb-3 mt-6'>
                        <button
                            disabled={loadingButton}
                            className={`flex gap-2 items-center justify-center py-2 font-semibold 
                            shadow-md rounded-md w-52
                            text-white bg-[#EB8927] me-3
                            ${loadingButton ? 'cursor-not-allowed opacity-50' : ''}`}

                            onClick={onSubmit}
                            type="submit">

                            {loadingButton ?
                                <div className='flex items-center justify-center w-52'>
                                    <RotatingLines
                                        strokeColor="white"
                                        strokeWidth='3'
                                        width='22'
                                        radius="5"
                                        ariaLabel="lines-loading"
                                    />
                                </div>
                                : <>
                                    <MdEditDocument /> Editar Herramienta
                                </>
                            }

                        </button>

                        <button
                            className='py-2 px-4 font-semibold shadow-md rounded-lg w-40
                            bg-white text-gray-500'
                            onClick={cerrarModal}
                        >
                            Cerrar
                        </button>
                    </div>

                </form>
            </div>
        </Modal >
    )
}
