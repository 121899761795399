import React, { useMemo, useState, useEffect } from 'react'
import { Link } from 'react-router-dom';

import { MRT_Localization_ES } from 'material-react-table/locales/es/index.js';

import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import { Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { Box, IconButton, Typography } from '@mui/material';


import AuthUser from '../../../pages/pagesAuth/AuthUser';
import Config from '../../../Config';

import Swal from 'sweetalert2';
import { toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import { RotatingLines } from 'react-loader-spinner';

import { FaPlus, FaFileCsv } from 'react-icons/fa';
import CsvDownloader from 'react-csv-downloader';
import UserPdf from '../../createPdf/UserPdf';

import { FaCircle } from "react-icons/fa";
import ModalAnadirEquipo from '../../forms/Equipo/ModalAnadirEquipo';
import ModalEditarEquipo from '../../forms/Equipo/ModalEditarEquipo';

import { IoIosAlert } from "react-icons/io";
import EquiposExcel from '../../createExcel/EquiposExcel/EquiposExcel';
import EquiposPdf from '../../createPdf/Equipos/EquiposPdf';

export default function EquiposTable() {

  const { getRol } = AuthUser();

  const [equipos, setEquipos] = useState([]);
  const [tableLoading, setTableLoading] = useState(true);

  //table state
  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 5,
  });
  const [rowCount, setRowCount] = useState(0);

  //Para las alertas
  const [equipoFueraServicioAlerta, setEquipoFueraServicioAlerta] = useState('0');
  const [equipoSinSalidaAlerta, setEquipoSinSalidaAlerta] = useState('0');

  // Constante para verificar si estamos en la página de equipos
  const [isEquipoFueraServicioPage, setIsEquipoFueraServicioPage] = useState(false);
  const [isEquipoSinSalidaPage, setIsEquipoSinSalidaPage] = useState(false);

  //Modal
  const [openAdd, setOpenAdd] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);

  const [editId, setEditId] = useState('');

  //Para actualizar luego de agregar o editar
  const updateEquipoList = async () => {
    await getEquiposAll();
  };

  // Limpiar sessionStorage en cambios de página
  useEffect(() => {
    if (isEquipoFueraServicioPage) {
      sessionStorage.removeItem("equipos-fuera-servicioBajoStock");
      setEquipoFueraServicioAlerta('0');
    }

    if (isEquipoSinSalidaPage) {
      sessionStorage.removeItem("equipos-sin-salidaBajoStock");
      setEquipoSinSalidaAlerta('0');
    }
  }, [isEquipoFueraServicioPage, isEquipoSinSalidaPage]); // Ejecuta cada vez que cambia la ubicación de la página

  useEffect(() => {
    getEquiposAll();
  }, [
    pagination.pageIndex,
    pagination.pageSize,
    columnFilters,
    globalFilter,
    sorting,
    equipoFueraServicioAlerta,
    equipoSinSalidaAlerta
  ]);

  const getEquiposAll = async () => {
    setTableLoading(true);

    let codigosEquiposFueraServicio = null;
    if (sessionStorage.getItem("equipos-fuera-servicioBajoStock")) {
      // Leer códigos guardados en sessionStorage
      codigosEquiposFueraServicio = JSON.parse(sessionStorage.getItem("equipos-fuera-servicioBajoStock") || "[]");

      // Actualizar insumoAlerta e insumoAlertaArreglo
      setEquipoFueraServicioAlerta(codigosEquiposFueraServicio.length > 0 ? "1" : "0");
    }

    let codigosEquiposSinSalida = null;
    if (sessionStorage.getItem("equipos-sin-salidaBajoStock")) {
      // Leer códigos guardados en sessionStorage
      codigosEquiposSinSalida = JSON.parse(sessionStorage.getItem("equipos-sin-salidaBajoStock") || "[]");

      // Actualizar insumoAlerta e insumoAlertaArreglo
      setEquipoSinSalidaAlerta(codigosEquiposSinSalida.length > 0 ? "1" : "0");
    }

    let getEquiposList;

    if (getRol() === "Administrador") {
      getEquiposList = Config.getEquiposListAdmin(
        pagination.pageIndex,
        pagination.pageSize,
        globalFilter,
        columnFilters,
        sorting,
        equipoFueraServicioAlerta,
        equipoSinSalidaAlerta
      );
    } else if (getRol() === "Almacenero") {
      getEquiposList = Config.getEquiposListAlmacenero(
        pagination.pageIndex,
        pagination.pageSize,
        globalFilter,
        columnFilters,
        sorting,
        equipoFueraServicioAlerta,
        equipoSinSalidaAlerta
      );
    } else if (getRol() === "Gerente") {
      getEquiposList = Config.getEquiposListGerente(
        pagination.pageIndex,
        pagination.pageSize,
        globalFilter,
        columnFilters,
        sorting,
        equipoFueraServicioAlerta,
        equipoSinSalidaAlerta
      );
    } else if (getRol() === "Operario") {
      getEquiposList = Config.getEquiposListOperario(
        pagination.pageIndex,
        pagination.pageSize,
        globalFilter,
        columnFilters,
        sorting
      );
    }

    try {
      const response = await getEquiposList;
      if (response.data.exito == '0') {
        notify(response.data.message, 'error')
      } else {
        console.log(response);
        setEquipos(response.data.data);
        setRowCount(response.data.total);
        //setClientes(response.data);
        /* console.log(response); */
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setTableLoading(false);
    }
  }

  const deleteOneEquipo = async (id) => {
    setTableLoading(true);
    let deleteOneEquipo;

    if (getRol() == "Administrador") {
      deleteOneEquipo = Config.deleteOneEquipoAdmin(id);
    } else if (getRol() === "Almacenero") {
      deleteOneEquipo = Config.deleteOneEquipoAlmacenero(id);
    }

    //EL DELETE NO ES PARA OPERARIO, SÓLO PARA ADMINISTRADOR

    try {
      const response = await deleteOneEquipo;
      console.log(response);
      await getEquiposAll();
      return { status: response.status, message: response.data.message };
    } catch (error) {
      await getEquiposAll();
      console.error("Error:", error);
      if (error.response) {
        return { status: error.response.status, message: error.response.data.message };
      } else {
        return { status: 500, message: `Error desconocido. Vuelva a intentarlo.` };
      }
    } finally {
      setTableLoading(false);
    }
  }

  const notify = (message, type) => {
    toast[type](message, {
      position: "bottom-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  };

  //SweetAlert2
  const showSwal = async (id) => {
    Swal.fire({
      title: "¿Estás seguro de eliminar este equipo?",
      text: "¡No podrás revertir esta acción!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#dc2626",
      cancelButtonColor: "#71717a",
      confirmButtonText: "Sí, eliminar"
    }).then(async (result) => {
      if (result.isConfirmed) {

        try {
          let mensaje = await deleteOneEquipo(id);

          let title = "";
          let icon = "";
          let text = mensaje.message;

          console.log(mensaje);

          if (mensaje.status === 200) {
            title = "¡Eliminado!";
            icon = "success";
          } else {
            title = "Error";
            icon = "error";
          }

          notify(text, icon);
        } catch (error) {
          console.error("Error eliminando el equipo:", error);
          setTableLoading(false);
          notify(`Ha ocurrido un error. Vuelva a intentarlo más tarde. ${error}`, "error");
        }
      }
    });
  }

  // COLUMNAS DE LA TABLA

  const columns = useMemo(
    () => [
      {
        id: "#",
        header: "Nro.",
        size: 1,
        Cell: ({ row }) => {
          return row.index + 1;
        }
      },
      {
        accessorFn: (row) => (row.marca_equipo ? row.marca_equipo.nombre_marca_equipo : '-'),
        id: "nombre_marca_equipo",
        header: "Marca",
        size: 5
      },
      {
        accessorFn: (row) => (row.proveedor ? row.proveedor.nombre_proveedor : '-'),
        id: "nombre_proveedor",
        header: "Proveedor",
        size: 5
      },
      {
        accessorFn: (row) => (row.nombre_equipo ? row.nombre_equipo : '-'),
        id: "nombre_equipo",
        header: "Equipo",
        size: 5
      },
      {
        accessorFn: (row) => (row.codigo_equipo ? row.codigo_equipo : '-'),
        id: "codigo_equipo",
        header: "Código",
        size: 1,
      },
      {
        accessorFn: (row) => (row.nro_serie_equipo ? row.nro_serie_equipo : '-'),
        id: "nro_serie_equipo",
        header: "N° de serie",
        size: 1,
      },
      {
        accessorFn: (row) => (row.tiempo_vida_inicial ? row.tiempo_vida_inicial : '-'),
        id: "tiempo_vida_inicial",
        header: "F. Vida Inicial",
        size: 1,
      },
      {
        accessorFn: (row) => (row.tiempo_vida_final ? row.tiempo_vida_final : '-'),
        id: "tiempo_vida_final",
        header: "F. Vida Final",
        size: 1,
      },
      {
        accessorFn: (row) => (row.estado_equipo ? row.estado_equipo : '-'),
        id: "estado_equipo",
        header: "Estado Equipo",
        size: 1,
        Cell: ({ cell }) => (
          <Box
            component="span"
            sx={(theme) => ({
              backgroundColor:
                cell.getValue() == "Óptimo"
                  ? theme.palette.success.dark
                  : cell.getValue() == "Buen Estado"
                    ? theme.palette.warning.dark
                    : theme.palette.error.dark,
              borderRadius: '0.25rem',
              color: "white",
              p: "0.25rem"
            })}
          >
            {cell.getValue()}
          </Box>
        ),
      },
      {
        accessorFn: (row) => row.ingreso,
        id: "ingreso",
        header: "Ingreso",
        size: 1,
      },
      {
        accessorFn: (row) => row.salida,
        id: "salida",
        header: "Salida",
        size: 2,
      },
      {
        accessorFn: (row) => row.saldo,
        id: "saldo",
        header: "Saldo",
        size: 2,
      },
      {
        accessorFn: (row) => (row.estado ? row.estado : '-'),
        id: "estado",
        header: "Estado",
        size: 10,
        Cell: ({ cell }) => (
          <Box
            component="span"
            sx={(theme) => ({
              fontSize: "10px",
              color: `${cell.getValue() == '1' ? 'green' : '#d63333'}`,
            })}
          >
            <FaCircle />
          </Box>
        ),
      },
      {
        accessorFn: (row) => (row.ruta_archivo_certificacion ? row.ruta_archivo_certificacion : ""),
        id: "ruta_archivo_certificacion",
        header: "¿Certificado?",
        size: 2,
        Cell: ({ cell }) => (
          <Box
            component="span"
            sx={(theme) => ({
              backgroundColor:
                cell.getValue() != "" && cell.getValue() != null
                  ? theme.palette.success.dark
                  : cell.getValue() != null || cell.getValue() != ""
                    ? theme.palette.grey[500]
                    : theme.palette.error.dark,
              borderRadius: '0.25rem',
              color: "white",
              p: "0.15rem 0.5rem"
            })}
          >
            {
            cell.getValue() == "" || cell.getValue() == null ? 'No' 
            : cell.getValue() != "" && cell.getValue() != null ? 'Sí'
            : '-'
            }
          </Box>
        ),
      },
    ],
    []
  );

  const data = (equipos ? equipos : []);

  const mostrarAccionesRol = () => {
    const rol = getRol();
    return rol === "Administrador" || rol === "Almacenero";
  };

  const table = useMaterialReactTable({
    columns,
    data,

    enableExpandAll: false, //disable expand all button

    muiDetailPanelProps: () => ({
      sx: (theme) => ({
        backgroundColor:
          theme.palette.mode === 'dark'
            ? 'rgba(255,210,244,0.1)'
            : 'rgba(0,0,0,0.1)',
      }),
    }),
    //custom expand button rotation
    muiExpandButtonProps: ({ row, table }) => ({
      onClick: () => table.setExpanded({ [row.id]: !row.getIsExpanded() }), //only 1 detail panel open at a time
      sx: {
        transform: row.getIsExpanded() ? 'rotate(180deg)' : 'rotate(-90deg)',
        transition: 'transform 0.2s',
      },
    }),

    renderDetailPanel: ({ row }) =>
      row.original.proveedor ? (
        <Box
          sx={{
            display: '',
            margin: 'auto',
            gridTemplateColumns: '1fr 1fr',
            width: '100%',
          }}
        >
          <Typography><span className='font-bold'>Proveedor:</span></Typography>
          <Typography><span className='font-bold'>- Nombre:</span> {row.original.proveedor.nombre_proveedor}</Typography>
          {/* <Typography><span className='font-bold'>- Correo:</span> {row.original.usuario.email}</Typography>
          <Typography><span className='font-bold'>- Rol:</span> {row.original.usuario.role.rol}</Typography> */}
        </Box>
      ) : null,

    initialState: {
      showColumnFilters: false,
      density: 'compact',
      columnVisibility: {
        nombre_proveedor: false,
      }
    },

    muiPaginationProps: {
      rowsPerPageOptions: [5, 10, 15, 20, 50, 100, 500],
      showFirstButton: true,
      showLastButton: true,
    },

    manualFiltering: true,
    manualPagination: true,
    manualSorting: true,

    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    rowCount,

    state: {
      columnFilters,
      globalFilter,
      isLoading: tableLoading,
      pagination,
      sorting,
    },

    localization: MRT_Localization_ES,
    muiSearchTextFieldProps: {
      placeholder: 'Buscar todos los equipos',
      sx: { minWidth: '300px' },
      variant: 'outlined',
    },

    enableRowActions: mostrarAccionesRol(),
    positionActionsColumn: mostrarAccionesRol() ? 'last' : undefined,

    renderRowActions: ({ row }) => (
      <Box>
        <IconButton title='Editar' onClick={() => {
          setEditId(row.original.id_equipo);
          setOpenEdit(true);
          console.log("fila", row.original.id_equipo);
        }} color="primary">
          <EditIcon />
        </IconButton>

        {getRol() == "Administrador" || getRol() == "Almacenero" ?
          <IconButton title='Eliminar' onClick={() => {
            showSwal(row.original.id_equipo);
            /* console.log("fila id: ", row.original.id); */
          }}
            color="error">
            <DeleteIcon />
          </IconButton>
          : null}

      </Box>
    ),

    renderTopToolbarCustomActions: ({ table }) => (
      <Box
        sx={{
          display: 'flex',
          gap: '16px',
          padding: '8px',
          flexWrap: 'wrap',
        }}
      >
        <Box sx={{ display: 'flex', gap: '16px' }}>

          {getRol() === "Administrador" || getRol() === "Gerente" ?

            <>
              <EquiposExcel equiposData={equipos} />
              <EquiposPdf equiposData={equipos} />
            </>

            : null}

        </Box>
      </Box>
    ),

  });

  return (
    <div>

      {getRol() == "Administrador" || getRol() == "Almacenero" ?

        <div className='flex justify-start mb-5 flex-wrap'>
          <button className='bg-[#D27313] hover:bg-[#cb6f13] rounded shadow-md text-white p-3 flex items-center'
            onClick={() => { setOpenAdd(true); console.log(openAdd); }}
          >
            <FaPlus className='me-3' />
            Añadir Equipo
          </button>
        </div>

        : null}

      {equipoFueraServicioAlerta === "1" ?
        <>
          <div className='flex items-center bg-blue-400 rounded border border-blue-500 p-2 text-lg text-white mb-5'>
            <IoIosAlert className='flex items-center me-2 text-2xl' /> Está visualizando sólo los equipos que están fuera de servicio. Si desea eliminar este filtro de alerta,
            <span class="ms-2 bg-blue-800 text-white underline cursor-pointer py-1 px-2 rounded hover:bg-blue-900"
              onClick={(e) => { setIsEquipoFueraServicioPage(true) }}> haga click aquí </span>
          </div>
        </>
        :
        null}

      {equipoSinSalidaAlerta === "1" ?
        <>
          <div className='flex items-center bg-blue-400 rounded border border-blue-500 p-2 text-lg text-white mb-5'>
            <IoIosAlert className='flex items-center me-2 text-2xl' /> Está visualizando sólo los equipos que no han tenido salida hasta el momento. Si desea eliminar este filtro de alerta,
            <span class="ms-2 bg-blue-800 text-white underline cursor-pointer py-1 px-2 rounded hover:bg-blue-900"
              onClick={(e) => { setIsEquipoSinSalidaPage(true) }}> haga click aquí </span>
          </div>
        </>
        :
        null}

      <div className='mb-20'>

        <MaterialReactTable
          table={table}
        />

      </div>

      {getRol() == "Administrador" || getRol() == "Almacenero" ?

        <>

          <ModalAnadirEquipo
            open={openAdd}
            onClose={() => setOpenAdd(false)}
            updateEquipoList={updateEquipoList}
          />

          <ModalEditarEquipo
            open={openEdit}
            editId={editId}
            onClose={() => setOpenEdit(false)}
            updateEquipoList={updateEquipoList}
          />

        </>

        : null}

    </div>
  )
}
