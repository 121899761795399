import React, { useState, useEffect } from 'react';
import { RotatingLines, TailSpin } from 'react-loader-spinner';

import AuthUser from '../../../../pages/pagesAuth/AuthUser';
import Modal from '../../../modals/Modal';

import { FaPlus } from "react-icons/fa";
import Config from '../../../../Config';

import { toast } from 'react-toastify';
import InputForm from '../../../inputs/InputForm';
import Swal from 'sweetalert2';
import { MdEditDocument } from "react-icons/md";
import AsyncSelect from 'react-select/async';

export default function ModalEditarReintegroInsumo({ open, onClose, updateInsumoList, editId }) {

    const { getRol, getToken } = AuthUser();

    const id = editId;

    const [userToken, setUserToken] = useState('');
    const [insumoId, setInsumoId] = useState('');
    const [codSalidaInsumo, setCodSalidaInsumo] = useState('');
    const [cantidadReintegro, setCantidadReintegro] = useState('');
    const [observacionReintegro, setObservacionReintegro] = useState('');

    const [cantidadSalidaInsumo, setCantidadSalidaInsumo] = useState('');

    const [tipoDocumentoId, setTipoDocumentoId] = useState('1');
    const [nroDocResponsable, setNroDocResponsable] = useState('');
    const [nombreResponsable, setNombreResponsable] = useState('');
    const [correoResponsable, setCorreoResponsable] = useState('');
    const [proyectoInsumo, setProyectoInsumo] = useState('');

    const [cantidad, setCantidad] = useState('');
    const [fechaSalida, setFechaSalida] = useState('');

    // Función para formatear la fecha
    const formatFechaForDB = (dateString) => {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');
        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    };

    //Variable para restringir fecha
    const now = new Date();
    const now_insumo = formatFechaForDB(now);

    //Mostrar datos actuales de ingreso, salida y saldo
    const [labelIngreso, setLabelIngreso] = useState('');
    const [labelSalida, setLabelSalida] = useState('');
    const [labelSaldo, setLabelSaldo] = useState('');

    const [limiteSalida, setLimiteSalida] = useState('');

    const [nroDocWarning, setNroDocWarning] = useState('');

    const [errors, setErrors] = useState({});

    const [loadingButton, setLoadingButton] = useState(false);
    const [loadingData, setLoadingData] = useState(true);

    const [zIndex, setZIndex] = useState(-1);

    useEffect(() => {
        if (open) {
            //document.addEventListener('mousedown', handleClickOutside);
            cargarReintegroInsumo();
            setUserToken(getToken());
            setZIndex(9999);
        } else {
            //document.removeEventListener('mousedown', handleClickOutside);
            setZIndex(-1);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [open]);

    let notificationShown = false;
    const loadTipDocumentosOptions = async () => {
        let getTipoDocumentosList;

        try {
            if (getRol() === "Administrador") {
                getTipoDocumentosList = await Config.getTipoDocumentosRespoAdmin();
            } else if (getRol() === "Almacenero") {
                getTipoDocumentosList = await Config.getTipoDocumentosRespoAlmacenero();
            }

            const arrayTipDocs = getTipoDocumentosList.data;
            if (arrayTipDocs.exito == 0) {
                return [];
            }

            if (getTipoDocumentosList.data.errors && !notificationShown) {
                notify(getTipoDocumentosList.data.errors, "error");
                notificationShown = true;
            }

            return arrayTipDocs.map(tipDoc => ({
                value: tipDoc.id,
                label: `${tipDoc.id} - ${tipDoc.nombre_documento}`,
            }));
        } catch (error) {
            console.error("Error:", error);
            notify("Ha ocurrido un error al cargar los tipos de documentos. Vuelva a intentarlo más tarde.", "error");
        } finally {

        }
    }

    const handleClickOutside = (event) => {
        if (event.target.closest(".modal-content") === null) {
            onClose();
        }
    };

    const cargarReintegroInsumo = async () => {
        setLoadingData(true);
        setErrors({});

        try {

            let response;

            if (getRol() === "Administrador") {
                response = await Config.getOneReintegroInsumoAdmin(id);
            } else if (getRol() === "Almacenero") {
                response = await Config.getOneReintegroInsumoAlmacenero(id);
            }

            console.log("RESPUESTA: ", response);

            if (response.data.exito == 0) {
                notify(`Error: ${response.data.message}`, "error");
            } else {
                setInsumoId(response.data.insumo);
                setCodSalidaInsumo(response.data.codigo_salida_insumo);
                setCantidadReintegro(response.data.cantidad_reintegro_insumo);
                setObservacionReintegro(response.data.observacion_reintegro_insumo);
                setCantidadSalidaInsumo(response.data.salida_insumo.cantidad);

                // setTipoDocumentoId(response.data.tipo_documento);
                // setNroDocResponsable(response.data.nro_documento_responsable_insumo_salida);
                // setNombreResponsable(response.data.nombre_completo_responsable_insumo_salida);
                // setCorreoResponsable(response.data.correo_responsable_insumo_salida);
                // setProyectoInsumo(response.data.proyecto_insumo_salida);

                // setCantidad(response.data.cantidad);
                // setFechaSalida(response.data.fecha_salida);

                setLabelIngreso(response.data.insumo.ingreso);
                setLabelSalida(response.data.insumo.salida);
                setLabelSaldo(response.data.insumo.saldo);

                //setLimiteSalida(response.data.cantidad + response.data.insumo.saldo);
            }

        } catch (error) {
            console.error(error);
            notify(`Ha ocurrido un error. Vuelva a intentarlo más tarde. ${error}`, "error");
        } finally {
            setLoadingData(false);
        };
    }

    const onSubmit = async (e) => {
        e.preventDefault();
        setLoadingButton(true);

        try {

            const reintegroInsumoData = {
                token_usuario: userToken,               

                cantidad_reintegro: cantidadReintegro,
                observacion_reintegro: observacionReintegro
            };

            console.log("Datos de salida de insumos: ", reintegroInsumoData);

            let response;

            if (getRol() === "Administrador") {
                response = await Config.putUpdateReintegroInsumoAdmin(id, reintegroInsumoData);
            } else if (getRol() === "Almacenero") {
                response = await Config.putUpdateReintegroInsumoAlmacenero(id, reintegroInsumoData);
            }

            if (response.data.errors) {
                setErrors(response.data.errors);
            } else {
                if (response.data.exito == 0) {
                    notify(`Error: ${response.data.message}`, "error");
                } else if (response.data.exito == 1) {
                    notify("La reintegración de insumo ha sido editado correctamente.", "success");
                    setUserToken('');
                    setInsumoId('');
                    setCodSalidaInsumo('');
                    setCantidadReintegro('');
                    setObservacionReintegro('');

                    setTipoDocumentoId('');
                    setNroDocResponsable('');
                    setNombreResponsable('');
                    setCorreoResponsable('');
                    setProyectoInsumo('');

                    setCantidad('');
                    setFechaSalida('');
                    onClose();
                    updateInsumoList();
                } else {
                    notify("Ha ocurrido un error. Vuelva a intentarlo más tarde.", "error");
                }
            }


        } catch (error) {
            console.log(error);
            updateInsumoList();
            notify("Ha ocurrido un error. Vuelva a intentarlo más tarde.", "error");
        } finally {
            setLoadingButton(false);
        };
    }

    const renderErrorMessages = (messages) => {
        return messages.map((msg, index) => (
            <div style={{ marginLeft: '136px' }}><p key={index} className="text-red-500 text-sm -mt-3 mb-3 ms-1">{msg}</p></div>
        ));
    };

    const notify = (message, type) => {
        toast[type](message, {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
        });
    };

    const getColourStyles = (hasError) => ({
        control: (styles, { isFocused }) => ({
            ...styles,
            backgroundColor: '#F9F9F9',
            borderColor: hasError ? 'red' : (isFocused ? '#339A5B' : '#D9D9D9'),
            boxShadow: hasError ? '1px red' : (isFocused ? '1px #339A5B' : null),
            '&:hover': {
                borderColor: hasError ? 'red' : (isFocused ? '#D9D9D9' : '#339A5B')
            },
        }),

        option: (styles, { data, isDisabled, isFocused, isSelected }) => ({
            ...styles,
            backgroundColor: isDisabled
                ? undefined
                : isSelected
                    ? '#ecf9ec'
                    : isFocused
                        ? '#f2f2f2'
                        : undefined,
            color: isDisabled
                ? '#ccc'
                : isSelected
                    ? 'black'
                    : data.color,
            cursor: isDisabled ? 'not-allowed' : 'default',

            '&:active': {
                backgroundColor: !isDisabled && (isSelected ? 'darkgreen' : '#ecf9ec'),
                color: !isDisabled && (isSelected ? 'white' : 'black')
            }
        }),
        menuPortal: (base) => ({
            ...base,
            zIndex: zIndex
        }),
    });

    const cerrarModal = (e) => {
        e.preventDefault();
        onClose();
    };

    return (
        <Modal open={open} onClose={onClose}>
            <div className='w-100'>
                <h3 className='text-center'>Imagen de Editar</h3>
                <h3 className='text-lg text-center font-black text-gray-800 w-full mb-8'>Editar Reintegro Insumo</h3>

                <form>

                    {!loadingData ?

                        <>

                            {errors.token_usuario && Array.isArray(errors.token_usuario) && renderErrorMessages(errors.token_usuario)}
                            <InputForm
                                id="codigo_salida_insumo"
                                labelName="Cod. Salida:"
                                inputType="text"
                                value={codSalidaInsumo}
                                labelWidth="w-56"
                                isDisabled={true}
                                bgColor='bg-[#e0e0e0]'
                            />

                            <InputForm
                                id="insumo"
                                labelName="Insumo"
                                inputType="text"
                                value={`${insumoId.codigo} - ${insumoId.nombre}`}
                                labelWidth="w-56"
                                isDisabled={true}
                                bgColor='bg-[#e0e0e0]'
                            />

                            <InputForm
                                id="cantidad_reintegro"
                                labelName="Cant. Reint.:"
                                inputType="number"
                                value={cantidadReintegro}
                                funcion={(e) => {
                                    setCantidadReintegro(e.target.value);
                                }}
                                errors={errors.cantidad_reintegro}
                                labelWidth="w-56"
                            />


                            {errors.cantidad_reintegro && Array.isArray(errors.cantidad_reintegro) && renderErrorMessages(errors.cantidad_reintegro)}
                            {cantidadSalidaInsumo ? <p className='-mt-3 ms-40 mb-1 text-gray-500' style={{ fontSize: '12px' }}>Cantidad máxima de reintegro posible: {cantidadSalidaInsumo} </p> : null}

                            <InputForm
                                id="observacion_reintegro"
                                labelName="Obs. Reint.:"
                                inputType="text"
                                value={observacionReintegro}
                                funcion={(e) => {
                                    setObservacionReintegro(e.target.value);
                                }}
                                labelWidth="w-56"
                                errors={errors.observacion_reintegro}
                            />

                            {errors.observacion_reintegro && Array.isArray(errors.observacion_reintegro) && renderErrorMessages(errors.observacion_reintegro)}


                            {/* FECHA DE SALIDA */}

                            {/* <div className='flex mb-3'>

                                <label htmlFor="date_picker" className={`w-56 flex items-center font-normal text-[#454545]`} style={{ width: "158px" }}>Fecha de Salida:</label>
                                <input
                                    value={fechaSalida || ""}
                                    onChange={(e) => { setFechaSalida(formatFechaForDB(e.target.value)); console.log(formatFechaForDB(e.target.value)) }}

                                    type="datetime-local"
                                    max={now_insumo}
                                    className={`px-2 py-1 bg-[#F9F9F9] border border-[#D9D9D9] 
    rounded-md focus:outline-none focus:border-[#339A5B] focus:ring-1 focus:ring-[#339A5B] 
    ${errors.fecha_salida ? 'border-red-500' : 'border-slate-300'}`}
                                />

                            </div> */}

                            {errors.fecha_salida && Array.isArray(errors.fecha_salida) && renderErrorMessages(errors.fecha_salida)}

                            <h4 className='text-sm mb-3 text-gray-600'>Datos del insumo seleccionado: </h4>

                            <div className='flex justify-between flex-wrap'>

                                <InputForm
                                    id="ingreso"
                                    labelName="Ingreso:"
                                    inputType="number"
                                    value={labelIngreso}
                                    /* funcion={(e) => setCantidad(e.target.value)} */
                                    labelWidth="w-16"
                                    isDisabled={true}
                                    bgColor='bg-[#e0e0e0]'
                                    inputWidth='w-32'
                                />

                                <InputForm
                                    id="salida"
                                    labelName="Salida:"
                                    inputType="number"
                                    value={labelSalida}
                                    /* funcion={(e) => setCantidad(e.target.value)} */
                                    labelWidth="w-16"
                                    isDisabled={true}
                                    bgColor='bg-[#e0e0e0]'
                                    inputWidth='w-32'
                                />

                                <InputForm
                                    id="saldo"
                                    labelName="Saldo:"
                                    inputType="number"
                                    value={labelSaldo}
                                    /* funcion={(e) => setCantidad(e.target.value)} */
                                    labelWidth="w-16"
                                    isDisabled={true}
                                    bgColor='bg-[#e0e0e0]'
                                    inputWidth='w-32'
                                />

                            </div>
                        </>

                        : <div className='flex justify-center items-center' style={{ height: '217px' }}>
                            <TailSpin className='' width={150} height={150}></TailSpin>
                        </div>}

                    <div className='flex justify-end flex-wrap mb-3 mt-6'>
                        <button
                            disabled={loadingButton}
                            className={`flex gap-2 items-center justify-center py-2 font-semibold 
                            shadow-md rounded-md w-52
                            text-white bg-[#EB8927] me-3
                            ${loadingButton ? 'cursor-not-allowed opacity-50' : ''}`}

                            onClick={onSubmit}
                            type="submit">

                            {loadingButton ?
                                <div className='flex items-center justify-center w-52'>
                                    <RotatingLines
                                        strokeColor="white"
                                        strokeWidth='3'
                                        width='22'
                                        radius="5"
                                        ariaLabel="lines-loading"
                                    />
                                </div>
                                : <>
                                    <MdEditDocument /> Editar Reinteg. Insumo
                                </>
                            }

                        </button>

                        <button
                            className='py-2 px-4 font-semibold shadow-md rounded-lg w-40
                            bg-white text-gray-500'
                            onClick={cerrarModal}
                        >
                            Cerrar
                        </button>
                    </div>

                </form>
            </div>
        </Modal >
    )
}
