import React, { useState } from 'react'
import Navbar from '../components/Navbar';
import { Outlet, useNavigate } from 'react-router-dom';
import AuthUser from '../pages/pagesAuth/AuthUser';

import { MsProvider } from '../context/MsContext';

export default function LayoutUsuario() {

    const { getRol, getLogout, getToken } = AuthUser();

    const [ms, setMs] = useState(false);
    const changeMs = (newMs) => {
        setMs(newMs);
    };

    return (
        <div className='bg-slate-100 h-lvh max-[1523px]:h-full'>
            <MsProvider>
                <Navbar changeMs={changeMs} />
                <Outlet />
            </MsProvider>
        </div>
    )
}
