import { useState, useEffect } from "react";

import { GiLaptop } from "react-icons/gi";
import { ImUserTie } from "react-icons/im";

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { toast } from 'react-toastify';
import Config from "../../Config";

import { RotatingLines } from 'react-loader-spinner';
import { FaPlus } from "react-icons/fa";

export default function ContactoEnviar() {

    const [vistaPreguntas, setVistaPreguntas] = useState(true);
    const [animacion, setAnimacion] = useState("");

    const [enviarAContacto, setEnviarAContacto] = useState("");
    const [nombreCompleto, setNombreCompleto] = useState("");
    const [correoUsuario, setCorreoUsuario] = useState("");
    const [nroCelularUsuario, setNroCelularUsuario] = useState("");
    const [mensajeUsuario, setMensajeUsuario] = useState("");

    const [errors, setErrors] = useState({});

    const [loadingSubmit, setloadingSubmit] = useState(false);

    const sendMessageContact = async (e) => {
        e.preventDefault();

        setloadingSubmit(true);
        setErrors({});

        const messageData = {
            correo_a_contacto: enviarAContacto,
            nombre_completo: nombreCompleto,
            email: correoUsuario,
            nro_contacto: nroCelularUsuario,
            mensaje_contacto: mensajeUsuario
        };

        console.log("Data antes de enviar: "+messageData);

        try {
            let response;

            response = await Config.postEnviarMensajeContacto(messageData);
            console.log(response);

            if (response.data.errors) {
                setErrors(response.data.errors);
            } else {
                if (response.data.exito == 0) {
                    notify(response.data.message, "error");
                } else if (response.data.exito == 1) {
                    notify("El mensaje ha sido enviado correctamente.", "success");
                    setEnviarAContacto('');
                    setNombreCompleto('');
                    setCorreoUsuario('');
                    setNroCelularUsuario('');
                    setMensajeUsuario('');
                } else {
                    notify("Ha ocurrido un error. Vuelva a intentarlo más tarde.", "error");
                }
            }

        } catch (error) {
            console.error("Error: ", error);
            notify("Ha ocurrido un error. Vuelva a intentarlo más tarde.", "error");
        } finally {
            setloadingSubmit(false);
        };
    }

    const renderErrorMessages = (messages) => {
        return messages.map((msg, index) => (
            <div style={{ marginLeft: '10px' }}><p key={index} className="text-red-500 text-sm">{msg}</p></div>
        ));
    };

    const notify = (message, type) => {
        toast[type](message, {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
        });
    };

    const mostrarVistaPreguntas = () => {
        if (!vistaPreguntas) {
            setAnimacion("slide-in-from-left");
            setTimeout(() => {
                setVistaPreguntas(true);
            }, 50);
        }
    };

    const mostrarVistaContacto = () => {
        if (vistaPreguntas) {
            setAnimacion("slide-in-from-right");
            setTimeout(() => {
                setVistaPreguntas(false);
            }, 50);
        }
    };

    //Contenido de acordeones


    return (
        <div>
            <div>
                <h1 className="text-center text-[#2c3136]" style={{ fontSize: '36px' }}>Soporte Técnico</h1>
            </div>

            <div className={`flex justify-center mt-6`} style={{ width: '100%' }}>
                <button className={`${vistaPreguntas ? 'bg-[#EB8927] hover:bg-[#D27313] text-white' : 'bg-gray-200 hover:bg-gray-300 text-slate-800'}
                rounded shadow-sm 
                py-2 px-4 cursor-pointer me-2`}
                    style={{ width: '50%' }}
                    onClick={mostrarVistaPreguntas}
                >
                    Preguntas Frecuentes
                </button>

                <button className={`${!vistaPreguntas ? 'bg-[#EB8927] hover:bg-[#D27313] text-white' : 'bg-gray-200 hover:bg-gray-300 text-slate-800'}  
                rounded shadow-sm 
                py-2 px-4 cursor-pointer me-3`}
                    style={{ width: '50%' }}
                    onClick={mostrarVistaContacto}
                >
                    Contacto
                </button>
            </div>

            <div className={`relative overflow-hidden h-screen`}>

                {/* Vista de Preguntas Frecuentes */}
                <div className={`absolute top-0 w-full h-full transition-transform duration-200 ${animacion}
                    ${vistaPreguntas ? 'translate-x-0' : '-translate-x-full'}`}>
                    <div className="mt-3 me-3">
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1-content"
                                id="panel1-header"
                            >
                                <Typography>01. ¿Qué debo hacer?</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                    Cada uno de los menus Habilitados se maneja mediante filtros
                                    en caso se requiera solo habilitar las alertas aparecera un mensaje
                                    para poder volver a la vista normal.
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel2-content"
                                id="panel2-header"
                            >
                                <Typography>02. ¿PDF y Excel?</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                    Cada uno de ellos se encuentra habilitado para cada menu a excepción
                                    del menú Equipos-Kardex. En este menú se debe habilitar un unico check box
                                    para poder habilitar la opcion de exportar en PDF.
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                    </div>
                </div>

                {/* Vista de Contacto */}
                <div className={`absolute top-0 w-full h-full transition-transform duration-200 ${animacion} 
                    ${!vistaPreguntas ? 'translate-x-0' : 'translate-x-full'}`}
                >


                    <div className="flex flex-col flex-wrap mt-10">

                        <div className="flex flex-wrap justify-evenly">

                            

                            <div className="flex justify-center items-center flex-col 
                            rounded py-2 px-2 w-full soporte_card bg-white max-[1063px]:mt-36 mb-8"
                                style={{ maxWidth: '420px', width: '100%', height: '200px' }}
                            >
                                <ImUserTie className="text-[#EB8927]" size={64} />
                                <p className={`text-[#212529]`} style={{ fontSize: '28px' }}>Contacto Product Owner</p>
                                <p className={`text-[#EB8927] font-semibold`} style={{ fontSize: '16px' }}>StefanoJG1997@gmail.com	</p>
                                <p className={`text-[#EB8927] font-semibold`} style={{ fontSize: '16px' }}>WhatsApp: (+51) 900 759 144</p>
                            </div>

                        </div>


                        <div className="flex flex-col flex-wrap justify-evenly items-center max-[1063px]:mt-8 me-3">
                            <form className="" style={{ maxWidth: '1300px' }}>

                                <div className="flex items-center">
                                    <label className="text-gray-500 font-semibold" style={{ width: '160px' }}>Enviar Mensaje a: </label>
                                    <div className="w-full">
                                        <select className={`border-b-2 w-full px-4 py-3 bg-white rounded-md border-b-[#EB8927]
                                            focus:outline-none focus:border-[#EB8927] focus:ring-1 focus:ring-[#EB8927] text-gray-500 font-semibold
                                            ${errors.correo_a_contacto ? 'border border-b-red-500 border-red-500' : ''}
                                            `}
                                            name="correo_a_contacto"
                                            id="correo_a_contacto"
                                            required
                                            onChange={(e) => {setEnviarAContacto(e.target.value); console.log(e.target.value)}}
                                            value={enviarAContacto}
                                        >
                                            <option value={""}>Seleccione...</option>
                                            <option value={"StefanoJG1997@gmail.com"}>Stefano Janampa | Product Owner</option>
                                        </select>
                                        {errors.correo_a_contacto && Array.isArray(errors.correo_a_contacto) && renderErrorMessages(errors.correo_a_contacto)}
                                    </div>
                                </div>

                                <div className="flex flex-wrap justify-evenly max-[1063px]:justify-center mt-4">

                                    <div>
                                        <input type="text"
                                            className={`input_mensaje_soporte mt-2 font-semibold rounded shadow-sm border-b-2 
                                        focus:outline-none border-b-[#EB8927] py-3 px-4 max-[963px]:w-full
                                        focus:border-[#EB8927] focus:ring-1 focus:ring-[#EB8927]
                                        ${errors.nombre_completo ? 'border border-b-red-500 border-red-500' : ''}`
                                        }
                                            placeholder="Nombre Completo"
                                            name="nombre_completo"
                                            id="nombre_completo"
                                            onChange={(e) => setNombreCompleto(e.target.value)}
                                            value={nombreCompleto}
                                        />
                                        {errors.nombre_completo && Array.isArray(errors.nombre_completo) && renderErrorMessages(errors.nombre_completo)}
                                    </div>

                                    <div>
                                        <input type="email"
                                            className={`input_mensaje_soporte ms-4 mt-2 font-semibold rounded shadow-sm border-b-2 
                                        focus:outline-none border-b-[#EB8927] py-3 px-4 max-[979px]:ms-0 max-[963px]:w-full
                                        focus:border-[#EB8927] focus:ring-1 focus:ring-[#EB8927]
                                        ${errors.email ? 'border border-b-red-500 border-red-500' : ''}
                                        `}
                                            placeholder="Correo Electrónico"
                                            name="email"
                                            id="email"
                                            onChange={(e) => setCorreoUsuario(e.target.value)}
                                            value={correoUsuario}
                                        />
                                        {errors.email && Array.isArray(errors.email) && (
                                        <div style={{ marginLeft: '15px' }}>
                                            {renderErrorMessages(errors.email)}
                                        </div>
                                        )}
                                    </div>

                                    <div>

                                        <input type="text"
                                            className={`input_mensaje_soporte ms-4 mt-2 font-semibold rounded shadow-sm border-b-2 
                                        focus:outline-none border-b-[#EB8927] py-3 px-4 max-[979px]:ms-0 max-[963px]:w-full
                                        focus:border-[#EB8927] focus:ring-1 focus:ring-[#EB8927]
                                        ${errors.nro_contacto ? 'border border-b-red-500 border-red-500' : ''}
                                        `}
                                            placeholder="Número de Contacto"
                                            name="nro_contacto"
                                            id="nro_contacto"
                                            onChange={(e) => setNroCelularUsuario(e.target.value)}
                                            minLength={9}
                                            maxLength={9}
                                            value={nroCelularUsuario}
                                        />
                                        {errors.nro_contacto && Array.isArray(errors.nro_contacto) && (
                                        <div style={{ marginLeft: '15px' }}>
                                            {renderErrorMessages(errors.nro_contacto)}
                                        </div>
                                        )}

                                    </div>

                                </div>

                                <div className="flex flex-wrap justify-evenly mt-8 w-full">
                                    <div className="w-full">
                                        <textarea rows={10} cols={30}
                                            className={`w-full focus:outline-none font-semibold rounded shadow-sm border-b-2 border-b-[#EB8927] py-3 px-4
                                        focus:border-[#EB8927] focus:ring-1 focus:ring-[#EB8927]
                                        ${errors.mensaje_contacto ? 'border border-b-red-500 border-red-500' : ''}
                                        `}
                                            placeholder="Mensaje..."
                                            name="mensaje_contacto"
                                            id="mensaje_contacto"
                                            onChange={(e) => setMensajeUsuario(e.target.value)}
                                            value={mensajeUsuario}
                                        >

                                        </textarea>
                                        {errors.mensaje_contacto && Array.isArray(errors.mensaje_contacto) && renderErrorMessages(errors.mensaje_contacto)}
                                    </div>
                                </div>

                                <div className="flex justify-center mt-8">
                                    <button type="submit"
                                        disabled={loadingSubmit}
                                        className={`flex items-center justify-center py-2 font-semibold 
                                    shadow-sm rounded-md w-44
                                    text-white bg-[#EB8927]
                                    ${loadingSubmit ? 'cursor-not-allowed opacity-50' : ''}`}
                                        onClick={sendMessageContact}
                                    >
                                        {loadingSubmit ?
                                            <div className='flex items-center justify-center w-44'>
                                                <RotatingLines
                                                    strokeColor="white"
                                                    strokeWidth='3'
                                                    width='22'
                                                    radius="5"
                                                    ariaLabel="lines-loading"
                                                />
                                            </div>
                                            : <>
                                                <FaPlus /> Enviar Mensaje
                                            </>
                                        }
                                    </button>
                                </div>

                            </form>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}
