import React, { useEffect, useState } from 'react';

import Config from '../../../Config';
import AuthUser from '../../../pages/pagesAuth/AuthUser';
import InputForm from '../../inputs/InputForm';

import Modal from '../../modals/Modal';

import { RotatingLines, TailSpin } from 'react-loader-spinner';
import { MdEditDocument } from "react-icons/md";

import { toast } from 'react-toastify';

import AsyncSelect from 'react-select/async';

export default function ModalEditarUsuario({ open, onClose, updateUserList, editId }) {

    const { getRol } = AuthUser();

    const id = editId;

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [rol, setRol] = useState('');
    const [estado, setEstado] = useState('');

    const [errors, setErrors] = useState({});

    const [loadingButton, setLoadingButton] = useState(false);
    const [loadingData, setLoadingData] = useState(true);

    const [zIndex, setZIndex] = useState(-1);

    useEffect(() => {

        if (open) {
            document.addEventListener('mousedown', handleClickOutside);
            setZIndex(9999);
            cargarUsuario();
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
            setZIndex(-1);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [open]);

    const loadRolesOptions = async (globalFilter) => {
        try {
            let response;
            if (getRol() === "Administrador") {
                response = await Config.getRolesListAdmin(globalFilter);
            } else if (getRol() === "Gerente") {
                response = await Config.getRolesListAdmin(globalFilter);
            }
            const arrayRoles = response.data;
            if (arrayRoles.exito == 0) {
                return [];
            }

            return arrayRoles.map(rol => ({
                value: rol.id,
                label: `${rol.id} - ${rol.rol}`
            }));
        } catch (error) {
            console.error("Error:", error);
            notify("Ha ocurrido un error al cargar la lista de roles. Vuelva a intentarlo más tarde.", "error");
            return [];
        }
    };

    const handleClickOutside = (event) => {
        if (event.target.closest(".modal-content") === null) {
            onClose();
        }
    };

    const cargarUsuario = async () => {
        setLoadingData(true);
        setErrors({});

        try {

            let response;

            if (getRol() === "Administrador") {
                response = await Config.getUserListAdmin(id);
            } else if (getRol() === "Operario") {
                response = await Config.getUserListGerente(id);
            }
            
            console.log(response);

            if (response.data.exito == 0) {
                notify(`Error: ${response.data.message}`, "error");
            } else {
                setName(response.data.name);
                setEmail(response.data.email);
                setRol(response.data.role);
                setEstado(response.data.is_active);

            }

        } catch (error) {
            console.error(error);
            notify(`Ha ocurrido un error. Vuelva a intentarlo más tarde. ${error}`, "error");
        } finally {
            setLoadingData(false);
        };
    }

    const onSubmit = async (e) => {
        e.preventDefault();
        setLoadingButton(true);

        try {

            const usuarioData = {
                name: name,
                email: email,
                rol_id: (rol.id ? rol.id : rol.value),
                is_active: estado,
            };

            let response;

            if (getRol() === "Administrador") {
                response = await Config.putUpdateUserAdmin(id, usuarioData);
            } else if (getRol() === "Operario") {
                response = await Config.putUpdateUserGerente(id, usuarioData);
            }

            console.log(response);

            if (response.data.errors) {
                setErrors(response.data.errors);
            } else {
                if (response.data.exito == 0) {
                    notify(`Error: ${response.data.message}`, "error");
                } else if (response.data.exito == 1) {
                    notify("El usuario ha sido editado correctamente.", "success");
                    setName('');
                    setEmail('');
                    setRol('');
                    setEstado('');
                    onClose();
                    updateUserList();
                } else {
                    notify(`Ha ocurrido un error. Vuelva a intentarlo más tarde.`, "error");
                }
            }


        } catch (error) {
            console.log(error);
            updateUserList();
            notify(`Ha ocurrido un error. Vuelva a intentarlo más tarde.`, "error");
        } finally {
            setLoadingButton(false);
        };
    }

    const renderErrorMessages = (messages) => {
        return messages.map((msg, index) => (
            <div style={{ marginLeft: '151px' }}><p key={index} className="text-red-500 text-sm -mt-3 mb-3 ms-1">{msg}</p></div>
        ));
    };

    const notify = (message, type) => {
        toast[type](message, {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
        });
    };

    const getColourStyles = (hasError) => ({
        control: (styles, { isFocused }) => ({
            ...styles,
            backgroundColor: '#F9F9F9',
            borderColor: hasError ? 'red' : (isFocused ? '#339A5B' : '#D9D9D9'),
            boxShadow: hasError ? '1px red' : (isFocused ? '1px #339A5B' : null),
            '&:hover': {
                borderColor: hasError ? 'red' : (isFocused ? '#D9D9D9' : '#339A5B')
            },
        }),

        option: (styles, { data, isDisabled, isFocused, isSelected }) => ({
            ...styles,
            backgroundColor: isDisabled
                ? undefined
                : isSelected
                    ? '#ecf9ec'
                    : isFocused
                        ? '#f2f2f2'
                        : undefined,
            color: isDisabled
                ? '#ccc'
                : isSelected
                    ? 'black'
                    : data.color,
            cursor: isDisabled ? 'not-allowed' : 'default',

            '&:active': {
                backgroundColor: !isDisabled && (isSelected ? 'darkgreen' : '#ecf9ec'),
                color: !isDisabled && (isSelected ? 'white' : 'black')
            }
        }),
        menuPortal: (base) => ({
            ...base,
            zIndex: zIndex
        }),
    });

    const cerrarModal = (e) => {
        e.preventDefault();
        onClose();
    };

    return (
        <Modal open={open} onClose={onClose}>
            <div className='w-100'>
                <h3 className='text-center'>Imagen de Editar</h3>
                <h3 className='text-lg text-center font-black text-gray-800 w-full mb-8'>Editar Usuario</h3>

                <form>

                    {!loadingData ?

                        <>

                            <InputForm
                                id="name"
                                labelName="Nombre Completo:"
                                inputType="text"
                                value={name}
                                funcion={(e) => setName(e.target.value)}
                                errors={errors.name}
                                labelWidth="w-52"
                            />

                            {errors.name && Array.isArray(errors.name) && renderErrorMessages(errors.name)}

                            <InputForm
                                id="email"
                                labelName="Correo:"
                                inputType="email"
                                value={email}
                                funcion={(e) => setEmail(e.target.value)}
                                errors={errors.email}
                                labelWidth="w-52"
                            />

                            {errors.email && Array.isArray(errors.email) && renderErrorMessages(errors.email)}

                            <div className='flex mb-3 w-full'>
                                <label htmlFor='categoria_id' className='w-52 flex items-center font-normal text-[#454545]'>Rol: </label>
                                <AsyncSelect
                                    cacheOptions
                                    defaultOptions
                                    loadOptions={loadRolesOptions}
                                    className={`w-full`}
                                    styles={getColourStyles(!!errors.rol_id)}
                                    id="rol_id"
                                    //value={proveedorId}
                                    defaultValue={{ id: rol.id, label: `${rol.id} - ${rol.rol}` }}
                                    onChange={(option, id) => { setRol(option); console.log(option, id); }}
                                    placeholder="Seleccionar proveedor..."
                                    noOptionsMessage={() => "No se encontraron resultados"}
                                    loadingMessage={() => "Cargando..."}
                                    menuPortalTarget={document.querySelector('body')}
                                />
                            </div>

                            {errors.rol_id && Array.isArray(errors.rol_id) && renderErrorMessages(errors.rol_id)}

                            <div className='mb-3 flex'>
                                <label htmlFor='user_active' className='w-52 flex items-center font-normal text-[#454545]'>Activo: </label>
                                <select
                                    className='border w-full px-2 py-1 bg-[#F9F9F9] rounded-md
                                        focus:outline-none focus:border-[#339A5B] focus:ring-1 focus:ring-[#339A5B]'
                                    id="user_active"
                                    defaultValue={estado}
                                    onChange={(e) => setEstado(e.target.value)}
                                    required
                                >
                                    <option value="0">Inactivo</option>
                                    <option value="1">Activo</option>
                                </select>
                            </div>

                            {errors.is_active && Array.isArray(errors.is_active) && renderErrorMessages(errors.is_active)}


                        </>

                        : <div className='flex justify-center items-center' style={{ height: '217px' }}>
                            <TailSpin className='' width={150} height={150}></TailSpin>
                        </div>}

                    <div className='flex justify-end flex-wrap mb-3 mt-6'>
                        <button
                            disabled={loadingButton}
                            className={`flex gap-2 items-center justify-center py-2 font-semibold 
                            shadow-md rounded-md w-52
                            text-white bg-[#EB8927] me-3
                            ${loadingButton ? 'cursor-not-allowed opacity-50' : ''}`}

                            onClick={onSubmit}
                            type="submit">

                            {loadingButton ?
                                <div className='flex items-center justify-center w-52'>
                                    <RotatingLines
                                        strokeColor="white"
                                        strokeWidth='3'
                                        width='22'
                                        radius="5"
                                        ariaLabel="lines-loading"
                                    />
                                </div>
                                : <>
                                    <MdEditDocument /> Editar Usuario
                                </>
                            }

                        </button>

                        <button
                            className='py-2 px-4 font-semibold shadow-md rounded-lg w-40
                            bg-white text-gray-500'
                            onClick={cerrarModal}
                        >
                            Cerrar
                        </button>
                    </div>

                </form>
            </div>
        </Modal >
    )
}
