import React, { createContext, useState, useContext } from 'react';

const MsContext = createContext();

export const MsProvider = ({ children }) => {
  const [ms, setMs] = useState(false); // Valor inicial de ms

  const changeMs = (newMs) => {
    setMs(newMs);
  };

  return (
    <MsContext.Provider value={{ ms, changeMs }}>
      {children}
    </MsContext.Provider>
  );
};

export const useMs = () => useContext(MsContext);