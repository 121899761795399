import React, { useEffect } from 'react';
import AuthUser from '../../pagesAuth/AuthUser';
import { useNavigate } from 'react-router-dom';

import { useMs } from '../../../context/MsContext';

import 'react-toastify/dist/ReactToastify.css';

export default function NotFound404Protected() {

    const { getRol } = AuthUser();
    const navigate = useNavigate();

    const { ms } = useMs();

    useEffect(() => {
        document.title = "SDI Jossmary | Servicios";
        if (getRol() != "Administrador" && getRol() != "Operario") {
            navigate('/usuario/dashboard');
        }
    }, []);


  return (
    <div className={`${ms ? 'ms-4 transition-all' : 'ms-56 transition-all'} 
      mt-16 me-4 pt-2 overflow-y-auto h-screen
      max-[768px]:${ms ? 'ms-2 transition-all' : 'ms-36 transition-all'}
      max-[480px]:mt-28`}>
            <h1 className='mb-4 text-2xl text-slate-500 font-semibold'>404 | Página no encontrada</h1>

        </div>
  )
}
