import React, { useState, useEffect } from 'react';
import { RotatingLines, TailSpin } from 'react-loader-spinner';

import AuthUser from '../../../../pages/pagesAuth/AuthUser';
import Modal from '../../../modals/Modal';

import { FaPlus } from "react-icons/fa";
import Config from '../../../../Config';

import { toast } from 'react-toastify';
import InputForm from '../../../inputs/InputForm';

import AsyncSelect from 'react-select/async';

export default function ModalActualizarDevolucionEquipo({ open, onClose, updateKardexEquipoList, editId }) {

    const { getRol, getToken } = AuthUser();

    const id = editId;

    const [userToken, setUserToken] = useState('');
    const [equipoId, setEquipoId] = useState('');
    const [tipoDocumentoId, setTipoDocumentoId] = useState('');
    const [nroDocResponsable, setNroDocResponsable] = useState('');
    const [nombreResponsable, setNombreResponsable] = useState('');
    const [correoResponsable, setCorreoResponsable] = useState('');
    const [proyectoEquipo, setProyectoEquipo] = useState('');
    const [cantidad, setCantidad] = useState('');
    const [fechaSalida, setFechaSalida] = useState('');

    const [tieneMerma, setTieneMerma] = useState('');
    const [cantidadMerma, setCantidadMerma] = useState('');
    const [observacionMerma, setObservacionMerma] = useState('');

    // Función para formatear la fecha
    const formatFechaForDB = (dateString) => {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = "00";
        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    };

    //Variable para restringir fecha
    const now = new Date();
    const now_equipo = formatFechaForDB(now);

    //Mostrar datos actuales de ingreso, salida y saldo
    const [labelIngreso, setLabelIngreso] = useState('');
    const [labelSalida, setLabelSalida] = useState('');
    const [labelSaldo, setLabelSaldo] = useState('');

    const [errors, setErrors] = useState({});
    const [loadingSubmit, setloadingSubmit] = useState(false);

    const [loadingButton, setLoadingButton] = useState(false);
    const [loadingData, setLoadingData] = useState(true);

    const [zIndex, setZIndex] = useState(-1);

    useEffect(() => {
        if (open) {
            document.addEventListener('mousedown', handleClickOutside);
            cargarSalidaDevEquipo();
            setTieneMerma('');
            setCantidadMerma('');
            setObservacionMerma('');
            setUserToken(getToken());
            setZIndex(9999);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
            setTieneMerma('');
            setCantidadMerma('');
            setObservacionMerma('');
            setZIndex(-1);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
            setErrors({});
        };
    }, [open]);

    const cargarSalidaDevEquipo = async () => {
        setLoadingData(true);
        setErrors({});

        try {

            let response;

            if (getRol() === "Administrador") {
                response = await Config.getOneSalidaDevolucionEquipoAdmin(id);
            } else if (getRol() === "Almacenero") {
                response = await Config.getOneSalidaDevolucionEquipoAlmacenero(id);
            } else if (getRol() === "Gerente") {
                response = await Config.getOneSalidaDevolucionEquipoGerente(id);
            }

            console.log(response);

            if (response.data.exito == 0) {
                notify(`Error: ${response.data.message}`, "error");
            } else {

                setEquipoId(response.data.equipo);
                setTipoDocumentoId(response.data.tipo_documento);
                setNroDocResponsable(response.data.nro_documento_responsable_equipo_salida);
                setNombreResponsable(response.data.nombre_completo_responsable_equipo_salida);
                setCorreoResponsable(response.data.correo_responsable_equipo_salida);
                setProyectoEquipo(response.data.proyecto_equipo_salida);

                setCantidad(response.data.cantidad_salida_equipo);
                setFechaSalida(response.data.fecha_salida_equipo);

                setLabelIngreso(response.data.equipo?.ingreso);
                setLabelSalida(response.data.equipo?.salida);
                setLabelSaldo(response.data.equipo?.saldo);
            }

        } catch (error) {
            console.error(error);
            notify(`Ha ocurrido un error. Vuelva a intentarlo más tarde. ${error}`, "error");
        } finally {
            setLoadingData(false);
        };
    }

    const onSubmit = async (e) => {
        e.preventDefault();
        setLoadingButton(true);

        try {

            const devolucionEquipoData = {
                token_usuario: userToken,
                es_merma: tieneMerma,
                cantidad_merma_equipo: cantidadMerma,
                observacion_merma_equipo: observacionMerma
            };

            let response;

            if (getRol() === "Administrador") {
                response = await Config.putUpdateOneSalidaDevolucionEquipoAdmin(id, devolucionEquipoData);
            } else if (getRol() === "Gerente") {
                response = await Config.putUpdateOneSalidaDevolucionEquipoAlmacenero(id, devolucionEquipoData);
            } else if (getRol() === "Almacenero") {
                response = await Config.putUpdateOneSalidaDevolucionEquipoGerente(id, devolucionEquipoData);
            }

            if (response.data.errors) {
                setErrors(response.data.errors);
            } else {
                if (response.data.exito == 0) {
                    notify(`Error: ${response.data.message}`, "error");
                } else if (response.data.exito == 1) {
                    notify("El equipo ha sido devuelta exitosamente.", "success");
                    setUserToken('');
                    setEquipoId('');
                    setTipoDocumentoId('');
                    setNroDocResponsable('');
                    setNombreResponsable('');
                    setCorreoResponsable('');
                    setProyectoEquipo('');
                    setCantidad('');
                    setFechaSalida('');

                    setTieneMerma('');
                    setCantidadMerma('');
                    setObservacionMerma('');

                    onClose();
                    updateKardexEquipoList();

                    window.location.reload();
                } else {
                    notify("Ha ocurrido un error. Vuelva a intentarlo más tarde.", "error");
                }
            }


        } catch (error) {
            console.log(error);
            updateKardexEquipoList();
            notify("Ha ocurrido un error. Vuelva a intentarlo más tarde.", "error");
        } finally {
            setLoadingButton(false);
        };
    }

    const loadEquiposOptions = async (globalFilter) => {
        let notificationShown = false;
        try {
            let response;
            if (getRol() === "Administrador") {
                response = await Config.getSelectEquiposAdmin(globalFilter);
            } else if (getRol() === "Almacenero") {
                response = await Config.getSelectEquiposAlmacenero(globalFilter);
            }

            const arrayEquipos = response.data;
            if (arrayEquipos.exito == 0) {
                return [];
            }

            if (response.data.errors && !notificationShown) {
                notify(response.data.errors, "error");
                notificationShown = true;
            }

            return arrayEquipos.map(equipo => ({
                value: equipo.id_equipo,
                label: `${equipo.id_equipo} - ${equipo.codigo_equipo} - ${equipo.nombre_equipo} - ${equipo.nro_serie_equipo ? equipo.nro_serie_equipo : '-Sin n° serie-'}`,
                ingreso: equipo.ingreso,
                salida: equipo.salida,
                saldo: equipo.saldo,
            }));
        } catch (error) {
            console.error("Error:", error);
            if (!notificationShown) {
                notify("Ha ocurrido un error al cargar la lista de equipos. Vuelva a intentarlo más tarde.", "error");
                notificationShown = true;
            }
            return [];
        }
    };


    const renderErrorMessages = (messages) => {
        return messages.map((msg, index) => (
            <div key={index} style={{ marginLeft: '162px' }}>
                <p className="text-red-500 text-sm -mt-3 mb-3">{msg}</p>
            </div>
        ));
    };

    const handleClickOutside = (event) => {
        if (event.target.closest(".modal-content") === null) {
            onClose();
        }
    };

    const notify = (message, type) => {
        toast[type](message, {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
        });
    };

    const getColourStyles = (hasError) => ({
        control: (styles, { isFocused }) => ({
            ...styles,
            backgroundColor: '#F9F9F9',
            borderColor: hasError ? 'red' : (isFocused ? '#339A5B' : '#D9D9D9'),
            boxShadow: hasError ? '1px red' : (isFocused ? '1px #339A5B' : null),
            '&:hover': {
                borderColor: hasError ? 'red' : (isFocused ? '#D9D9D9' : '#339A5B')
            },
        }),

        option: (styles, { data, isDisabled, isFocused, isSelected }) => ({
            ...styles,
            backgroundColor: isDisabled
                ? undefined
                : isSelected
                    ? '#ecf9ec'
                    : isFocused
                        ? '#f2f2f2'
                        : undefined,
            color: isDisabled
                ? '#ccc'
                : isSelected
                    ? 'black'
                    : data.color,
            cursor: isDisabled ? 'not-allowed' : 'default',

            '&:active': {
                backgroundColor: !isDisabled && (isSelected ? 'darkgreen' : '#ecf9ec'),
                color: !isDisabled && (isSelected ? 'white' : 'black')
            }
        }),
        menuPortal: (base) => ({
            ...base,
            zIndex: zIndex
        }),
    });

    const cerrarModal = (e) => {
        e.preventDefault();
        onClose();
    }

    let notificationShown = false;
    const loadTipDocumentosOptions = async () => {
        let getTipoDocumentosList;

        try {
            if (getRol() === "Administrador") {
                getTipoDocumentosList = await Config.getTipoDocumentosRespoAdmin();
            } else if (getRol() === "Almacenero") {
                getTipoDocumentosList = await Config.getTipoDocumentosRespoAlmacenero();
            }

            console.log("TIPO DOC: " + getTipoDocumentosList);

            const arrayTipDocs = getTipoDocumentosList.data;
            if (arrayTipDocs.exito == 0) {
                return [];
            }

            if (getTipoDocumentosList.data.errors && !notificationShown) {
                notify(getTipoDocumentosList.data.errors, "error");
                notificationShown = true;
            }

            return arrayTipDocs.map(tipDoc => ({
                value: tipDoc.id,
                label: `${tipDoc.id} - ${tipDoc.nombre_documento}`,
            }));
        } catch (error) {
            console.error("Error:", error);
            notify("Ha ocurrido un error al cargar los tipos de documentos. Vuelva a intentarlo más tarde.", "error");
        } finally {

        }
    }

    return (
        <Modal open={open} onClose={onClose}>
            <div className='w-100'>
                <h3 className='text-center'>Imagen de Actualizar</h3>
                <h3 className='text-lg text-center font-black text-gray-800 w-full mb-8'>Devolver Equipo ({id})</h3>

                <form>

                    {!loadingData ?

                        <>

                            {errors.token_usuario && Array.isArray(errors.token_usuario) && renderErrorMessages(errors.token_usuario)}

                            <InputForm
                                id="tipo_documento_responsable_id"
                                labelName="Tip. Documento:"
                                inputType="text"
                                value={tipoDocumentoId.nombre_documento}
                                //funcion={(e) => setNombreResponsable(e.target.value)}
                                errors={errors.tipo_documento_responsable_id}
                                labelWidth="w-56"
                                isDisabled={true}
                                bgColor='bg-[#e0e0e0]'
                            />

                            {errors.tipo_documento_responsable_id && Array.isArray(errors.tipo_documento_responsable_id) && renderErrorMessages(errors.tipo_documento_responsable_id)}

                            <InputForm
                                id="nro_documento"
                                labelName="N° de documento:"
                                inputType="text"
                                value={nroDocResponsable}
                                //funcion={(e) => setNroDocResponsable(e.target.value.replace(/[^0-9]/g, ""))}
                                errors={errors.nro_documento_responsable_equipo_devolucion}
                                labelWidth="w-56"
                                isDisabled={true}
                                bgColor='bg-[#e0e0e0]'
                            />

                            {errors.nro_documento_responsable_equipo_devolucion && Array.isArray(errors.nro_documento_responsable_equipo_devolucion) && renderErrorMessages(errors.nro_documento_responsable_equipo_devolucion)}

                            <InputForm
                                id="nombre_completo_responsable_equipo_devolucion"
                                labelName="Nombre Compl.:"
                                inputType="text"
                                value={nombreResponsable}
                                errors={errors.nombre_completo_responsable_equipo_devolucion}
                                labelWidth="w-56"
                                isDisabled={true}
                                bgColor='bg-[#e0e0e0]'
                            />

                            {errors.nombre_completo_responsable_equipo_devolucion && Array.isArray(errors.nombre_completo_responsable_equipo_devolucion) && renderErrorMessages(errors.nombre_completo_responsable_equipo_devolucion)}

                            <InputForm
                                id="correo_responsable_equipo_devolucion"
                                labelName="Correo Respons.:"
                                inputType="text"
                                value={correoResponsable}
                                errors={errors.correo_responsable_equipo_devolucion}
                                labelWidth="w-56"
                                isDisabled={true}
                                bgColor='bg-[#e0e0e0]'
                            />

                            {errors.correo_responsable_equipo_devolucion && Array.isArray(errors.correo_responsable_equipo_devolucion) && renderErrorMessages(errors.correo_responsable_equipo_devolucion)}

                            <InputForm
                                id="proyecto_equipo_devolucion"
                                labelName="Proyecto:"
                                inputType="text"
                                value={proyectoEquipo}
                                errors={errors.proyecto_equipo_devolucion}
                                labelWidth="w-56"
                                isDisabled={true}
                                bgColor='bg-[#e0e0e0]'
                            />

                            {errors.proyecto_equipo_devolucion && Array.isArray(errors.proyecto_equipo_devolucion) && renderErrorMessages(errors.proyecto_equipo_devolucion)}

                            <div className='flex mb-3 w-full'>
                                <label htmlFor='equipo_id' className='w-56 flex items-center font-normal text-[#454545]'>Equipo: </label>
                                <AsyncSelect
                                    cacheOptions
                                    defaultOptions
                                    loadOptions={loadEquiposOptions}
                                    className={`w-full`}
                                    styles={getColourStyles(!!errors.equipo_id)}
                                    id="equipo_id"
                                    //value={herramientaId}
                                    defaultValue={
                                        equipoId && equipoId.id_equipo ?
                                        { id: equipoId.id_equipo, label: `${equipoId.id_equipo} - ${equipoId.codigo_equipo} - ${equipoId.nombre_equipo} - ${equipoId.nro_serie_equipo ? equipoId.nro_serie_equipo : '-Sin n° serie-'}` }
                                        : null
                                    }
                                    onChange={(option, id) => {
                                        setLabelIngreso('');
                                        setLabelSalida('');
                                        setLabelSaldo('');

                                        setEquipoId(option);
                                        console.log(option, id);
                                        setLabelIngreso(option.ingreso);
                                        setLabelSalida(option.salida);
                                        setLabelSaldo(option.saldo);
                                    }}
                                    placeholder="Seleccionar equipo..."
                                    noOptionsMessage={() => "No se encontraron resultados"}
                                    loadingMessage={() => "Cargando..."}
                                    isDisabled={true}
                                    menuPortalTarget={document.querySelector('body')}
                                />
                            </div>

                            {errors.equipo_id && Array.isArray(errors.equipo_id) && renderErrorMessages(errors.equipo_id)}

                            <InputForm
                                id="cantidad_devolucion_equipo"
                                labelName="Cantidad:"
                                inputType="number"
                                value={cantidad}
                                funcion={(e) => setCantidad(e.target.value)}
                                errors={errors.cantidad_devolucion_equipo}
                                labelWidth="w-56"
                                isDisabled={true}
                                bgColor='bg-[#e0e0e0]'
                            />

                            {errors.cantidad_devolucion_equipo && Array.isArray(errors.cantidad_devolucion_equipo) && renderErrorMessages(errors.cantidad_devolucion_equipo)}

                            {/* FECHA DE SALIDA */}

                            <div className='flex mb-3'>

                                <label htmlFor="date_picker" className={`w-56 flex items-center font-normal text-[#454545]`} style={{ width: "158px" }}>Fecha de Salida:</label>
                                <input
                                    value={fechaSalida || ""}
                                    onChange={(e) => { setFechaSalida(formatFechaForDB(e.target.value)); console.log(formatFechaForDB(e.target.value)) }}

                                    type="datetime-local"
                                    max={now_equipo}
                                    className={`px-2 py-1 bg-[#e0e0e0] border border-[#D9D9D9] 
                                    rounded-md focus:outline-none focus:border-[#339A5B] focus:ring-1 focus:ring-[#339A5B] 
                                    ${errors.fecha_devolucion ? 'border-red-500' : 'border-slate-300'}`}
                                    disabled={true}
                                />

                            </div>

                            {errors.fecha_devolucion && Array.isArray(errors.fecha_devolucion) && renderErrorMessages(errors.fecha_devolucion)}

                            <div className='flex mb-3'>
                                <label className="w-56 flex items-center font-normal text-[#454545]" style={{ width: "158px" }}>¿Merma?</label>
                                <div className="flex space-x-24 border rounded py-1 px-4">
                                    <label>
                                        <input
                                            type="radio"
                                            name="tieneMerma"
                                            value="1"
                                            checked={tieneMerma === "1"}
                                            onChange={(e) => { setTieneMerma(e.target.value); setCantidadMerma(1);}}
                                            disabled={false}
                                            className="bg-[#e0e0e0] me-3"
                                        />
                                        Sí
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            name="tieneMerma"
                                            value="0"
                                            checked={tieneMerma === "0"}
                                            onChange={(e) => { setTieneMerma(e.target.value); setCantidadMerma(''); setObservacionMerma(''); errors.cantidad_merma_herramienta = null; errors.observacion_merma_herramienta = null; }}
                                            disabled={false}
                                            className="bg-[#e0e0e0] me-3"
                                        />
                                        No
                                    </label>
                                </div>
                            </div>
                            {errors.es_merma && Array.isArray(errors.es_merma) && renderErrorMessages(errors.es_merma)}


                            <InputForm
                                id="cantidad_merma_equipo"
                                labelName="Cantidad Merma:"
                                inputType="number"
                                value={cantidadMerma}
                                funcion={(e) => setCantidadMerma(e.target.value)}
                                errors={errors.cantidad_merma_equipo}
                                labelWidth="w-56"
                                isDisabled={true}
                                bgColor={`bg-[#e0e0e0]`}
                            />

                            {errors.cantidad_merma_equipo && Array.isArray(errors.cantidad_merma_equipo) && renderErrorMessages(errors.cantidad_merma_equipo)}

                            <InputForm
                                id="observacion_merma_equipo"
                                labelName="Observación:"
                                inputType="text"
                                value={observacionMerma}
                                funcion={(e) => setObservacionMerma(e.target.value)}
                                errors={errors.observacion_merma_equipo}
                                labelWidth="w-56"
                                isDisabled={(tieneMerma == "" || tieneMerma == null || tieneMerma == "0") ? true : false}
                                bgColor={(tieneMerma == "" || tieneMerma == null || tieneMerma == "0") ? `bg-[#e0e0e0]` : 'bg-[#F9F9F9]'}
                            />

                            {errors.observacion_merma_equipo && Array.isArray(errors.observacion_merma_equipo) && renderErrorMessages(errors.observacion_merma_equipo)}

                            <h4 className='text-sm mb-3 text-gray-600'>Datos de equipo seleccionado: </h4>

                            <div className='flex justify-between flex-wrap'>

                                <InputForm
                                    id="ingreso"
                                    labelName="Ingreso:"
                                    inputType="number"
                                    value={labelIngreso}
                                    /* funcion={(e) => setCantidad(e.target.value)} */
                                    labelWidth="w-16"
                                    isDisabled={true}
                                    bgColor='bg-[#e0e0e0]'
                                    inputWidth='w-32'
                                />

                                <InputForm
                                    id="salida"
                                    labelName="Salida:"
                                    inputType="number"
                                    value={labelSalida}
                                    /* funcion={(e) => setCantidad(e.target.value)} */
                                    labelWidth="w-16"
                                    isDisabled={true}
                                    bgColor='bg-[#e0e0e0]'
                                    inputWidth='w-32'
                                />

                                <InputForm
                                    id="saldo"
                                    labelName="Saldo:"
                                    inputType="number"
                                    value={labelSaldo}
                                    /* funcion={(e) => setCantidad(e.target.value)} */
                                    labelWidth="w-16"
                                    isDisabled={true}
                                    bgColor='bg-[#e0e0e0]'
                                    inputWidth='w-32'
                                />

                            </div>

                        </>

                        : <div className='flex justify-center items-center' style={{ height: '217px' }}>
                            <TailSpin className='' width={150} height={150}></TailSpin>
                        </div>}

                    {/* ******************** */}


                    <div className='flex justify-end flex-wrap mb-3 mt-6'>
                        <button
                            disabled={loadingButton}
                            className={`flex items-center justify-center py-2 font-semibold 
                            shadow-md rounded-md w-64
                            text-white bg-[#EB8927]
                            me-3
                            max-[439px]:me-0
                            max-[469px]:m-0
                            ${loadingButton ? 'cursor-not-allowed opacity-50' : ''}`}

                            onClick={onSubmit}
                            type="submit">

                            {loadingButton ?
                                <div className='flex items-center justify-center w-64'>
                                    <RotatingLines
                                        strokeColor="white"
                                        strokeWidth='3'
                                        width='22'
                                        radius="5"
                                        ariaLabel="lines-loading"
                                    />
                                </div>
                                : <>
                                    <FaPlus /> Devolver Equipo
                                </>
                            }

                        </button>

                        <button
                            className='py-2 px-4 font-semibold shadow-md rounded-lg w-40
                            max-[426px]:mt-3
                            bg-white text-gray-500'
                            onClick={cerrarModal}
                        >
                            Cerrar
                        </button>
                    </div>

                </form>
            </div>
        </Modal >
    )
}
