import React, { useState, useEffect } from 'react';
import { RotatingLines } from 'react-loader-spinner';

import AuthUser from '../../../pages/pagesAuth/AuthUser';
import Modal from '../../modals/Modal';

import { FaPlus } from "react-icons/fa";
import Config from '../../../Config';

import { toast } from 'react-toastify';
import InputForm from '../../inputs/InputForm';

import AsyncSelect from 'react-select/async';

export default function ModalAnadirHerramienta({ open, onClose, updateHerramientaList }) {

    const { getRol } = AuthUser();

    const [categoriaHerramientaId, setCategoriaHerramientaId] = useState('');
    const [proveedorId, setProveedorId] = useState('');
    const [nombreHerramienta, setNombreHerramienta] = useState('');
    const [nroSerie, setNroSerie] = useState('');
    const [medida, setMedida] = useState('');
    const [estadoHerramienta, setEstadoHerramienta] = useState('');

    // Función para formatear la fecha
    const formatFechaForDB = (dateString) => {
        const date = new Date(dateString);
        // Usar el método toLocaleDateString para evitar que el UTC afecte la fecha
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    //Variable para restringir fecha
    const now = new Date();
    const now_herramienta = formatFechaForDB(now);

    const [errors, setErrors] = useState({});
    const [loadingSubmit, setloadingSubmit] = useState(false);

    const [zIndex, setZIndex] = useState(-1);

    useEffect(() => {
        if (open) {
            //document.addEventListener('mousedown', handleClickOutside);
            setZIndex(9999);
        } else {
            //document.removeEventListener('mousedown', handleClickOutside);
            setZIndex(-1);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
            setErrors({});
        };
    }, [open]);

    const loadCategoriasHerramientasOptions = async (globalFilter) => {
        try {
            let response;
            if (getRol() === "Administrador") {
                response = await Config.getSelectCategoriaHerramientasAdmin(globalFilter);
            } else if (getRol() === "Almacenero") {
                response = await Config.getSelectCategoriaHerramientasAlmacenero(globalFilter);
            }
            const arrayCatHerramientas = response.data;
            if (arrayCatHerramientas.exito == 0) {
                return [];
            }

            return arrayCatHerramientas.map(marca_herramienta => ({
                value: marca_herramienta.id_marcaherramienta,
                label: `${marca_herramienta.id_marcaherramienta} - ${marca_herramienta.nombre_marca_herramienta}`
            }));
        } catch (error) {
            console.error("Error:", error);
            notify("Ha ocurrido un error al cargar las categorías de herramientas. Vuelva a intentarlo más tarde.", "error");
            return [];
        }
    };

    const loadProveedoresOptions = async (inputValue) => {
        try {
            let response;
            if (getRol() === "Administrador") {
                response = await Config.getSelectProveedoresAdmin(inputValue);
            } else if (getRol() === "Almacenero") {
                response = await Config.getSelectProveedoresAlmacenero(inputValue);
            }
            const arrayProveedores = response.data;
            if (arrayProveedores.exito == '0') {
                return [];
            }

            console.log(arrayProveedores);
            return arrayProveedores.map(proveedor => ({
                value: proveedor.id_proveedor,
                label: `${proveedor.nombre_proveedor} - ${proveedor.ruc} - ${proveedor.estado_proveedor === "1" ? 'Activo' : 'Inactivo'}`
            }));
        } catch (error) {
            console.error("Error:", error);
            notify("Ha ocurrido un error al cargar los proveedores. Vuelva a intentarlo más tarde.", "error");
            return [];
        }
    };

    const handleClickOutside = (event) => {
        if (event.target.closest(".modal-content") === null) {
            onClose();
        }
    };

    const addNewHerramienta = async (e) => {
        e.preventDefault();
        setloadingSubmit(true);
        setErrors({});

        const HerramientaData = {
            marca_herramienta_id: categoriaHerramientaId.value,
            proveedor_id: (proveedorId ? proveedorId.value : null),
            nombre_herramienta: nombreHerramienta,
            nro_serie_herramienta: nroSerie,
            medida: medida,
            estado_herramienta: estadoHerramienta.value,
        };

        try {
            let response;

            if (getRol() === "Administrador") {
                response = await Config.postGuardarHerramientaAdmin(HerramientaData);
            } else if (getRol() === "Almacenero") {
                response = await Config.postGuardarHerramientaAlmacenero(HerramientaData);
            }

            if (response.data.errors) {
                setErrors(response.data.errors);
            } else {
                if (response.data.exito == 0) {
                    notify(response.data.message, "error");
                } else if (response.data.exito == 1) {
                    notify("La herramienta ha sido añadido correctamente.", "success");
                    setCategoriaHerramientaId('');
                    setProveedorId('');
                    setNombreHerramienta('');
                    setMedida('');
                    setNroSerie('');
                    setEstadoHerramienta('');
                    onClose();
                    updateHerramientaList();
                } else {
                    notify("Ha ocurrido un error. Vuelva a intentarlo más tarde.", "error");
                }
            }

        } catch (error) {
            console.error("Error: ", error);
            notify("Ha ocurrido un error. Vuelva a intentarlo más tarde.", "error");
            updateHerramientaList();
        } finally {
            setloadingSubmit(false);
        };
    }

    const renderErrorMessages = (messages) => {
        return messages.map((msg, index) => (
            <div key={index} style={{ marginLeft: '202px' }}>
                <p className="text-red-500 text-sm -mt-3 mb-3">{msg}</p>
            </div>
        ));
    };

    const notify = (message, type) => {
        toast[type](message, {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
        });
    };

    const getColourStyles = (hasError) => ({
        control: (styles, { isFocused }) => ({
            ...styles,
            backgroundColor: '#F9F9F9',
            borderColor: hasError ? 'red' : (isFocused ? '#339A5B' : '#D9D9D9'),
            boxShadow: hasError ? '1px red' : (isFocused ? '1px #339A5B' : null),
            '&:hover': {
                borderColor: hasError ? 'red' : (isFocused ? '#D9D9D9' : '#339A5B')
            },
        }),

        option: (styles, { data, isDisabled, isFocused, isSelected }) => ({
            ...styles,
            backgroundColor: isDisabled
                ? undefined
                : isSelected
                    ? '#ecf9ec'
                    : isFocused
                        ? '#f2f2f2'
                        : undefined,
            color: isDisabled
                ? '#ccc'
                : isSelected
                    ? 'black'
                    : data.color,
            cursor: isDisabled ? 'not-allowed' : 'default',

            '&:active': {
                backgroundColor: !isDisabled && (isSelected ? 'darkgreen' : '#ecf9ec'),
                color: !isDisabled && (isSelected ? 'white' : 'black')
            }
        }),
        clearIndicator: (styles) => ({
            ...styles,
            zIndex: 99999999,  // Cambia el z-index del botón de "clear"
            cursor: 'pointer',
        }),
        menuPortal: (base) => ({
            ...base,
            zIndex: zIndex
        }),
    });

    const cerrarModal = (e) => {
        e.preventDefault();
        onClose();
    }

    const estadoHerramientaOptions = [
        { value: 0, label: "Óptimo" },
        { value: 1, label: "Buen Estado" },
        { value: 2, label: "Fuera de Servicio" },
        { value: 4, label: "Falta Mantenimiento" }
    ];

    const loadEstadoHerramientaOptions = (inputValue, callback) => {
        const filteredOptions = estadoHerramientaOptions.filter(option =>
            option.label.toLowerCase().includes(inputValue.toLowerCase())
        );
        callback(filteredOptions);
    };

    return (
        <Modal open={open} onClose={onClose}>
            <div className='w-100'>
                <h3 className='text-center'>Imagen de Añadir</h3>
                <h3 className='text-lg text-center font-black text-gray-800 w-full mb-8'>Añadir Herramienta</h3>

                <form>

                    <div className='flex mb-3 w-full'>
                        <label htmlFor='categoria_id' className='w-80 flex items-center font-normal text-[#454545]'>Marca de Herramienta: </label>
                        <AsyncSelect
                            cacheOptions
                            defaultOptions
                            loadOptions={loadCategoriasHerramientasOptions}
                            className={`w-full`}
                            styles={getColourStyles(!!errors.marca_herramienta_id)}
                            id="categoria_id"
                            value={categoriaHerramientaId}
                            onChange={(option, id) => { setCategoriaHerramientaId(option); console.log(option, id); }}
                            placeholder="Seleccionar marca de herramienta..."
                            noOptionsMessage={() => "No se encontraron resultados"}
                            loadingMessage={() => "Cargando..."}
                            menuPortalTarget={document.querySelector('body')}
                        />
                    </div>

                    {errors.marca_herramienta_id && Array.isArray(errors.marca_herramienta_id) && renderErrorMessages(errors.marca_herramienta_id)}

                    <div className='flex mb-3 w-full'>
                        <label htmlFor='proveedor_id' className='w-80 flex items-center font-normal text-[#454545]'>Proveedor: </label>
                        <AsyncSelect
                            cacheOptions
                            defaultOptions
                            loadOptions={loadProveedoresOptions}
                            className={`w-full`}
                            styles={getColourStyles(!!errors.proveedor_id)}
                            id="proveedor_id"
                            value={proveedorId}
                            onChange={(option, id) => { setProveedorId(option); console.log(option, id); }}
                            isClearable={true}
                            placeholder="Seleccionar proveedor..."
                            noOptionsMessage={() => "No se encontraron resultados"}
                            loadingMessage={() => "Cargando..."}
                            menuPortalTarget={document.querySelector('body')}
                        />
                    </div>

                    {errors.proveedor_id && Array.isArray(errors.proveedor_id) && renderErrorMessages(errors.proveedor_id)}

                    <InputForm
                        id="Herramienta"
                        labelName="Nombre de Herramienta:"
                        inputType="text"
                        value={nombreHerramienta}
                        funcion={(e) => setNombreHerramienta(e.target.value)}
                        errors={errors.nombre_herramienta}
                        labelWidth="w-80"
                    />

                    {errors.nombre_herramienta && Array.isArray(errors.nombre_herramienta) && renderErrorMessages(errors.nombre_herramienta)}

                    <InputForm
                        id="Herramienta"
                        labelName="N° serie:"
                        inputType="text"
                        value={nroSerie}
                        funcion={(e) => setNroSerie(e.target.value)}
                        errors={errors.nro_serie_herramienta}
                        labelWidth="w-80"
                    />

                    {errors.nro_serie_herramienta && Array.isArray(errors.nro_serie_herramienta) && renderErrorMessages(errors.nro_serie_herramienta)}

                    <InputForm
                        id="medida"
                        labelName="Medida:"
                        inputType="text"
                        value={medida}
                        funcion={(e) => setMedida(e.target.value)}
                        errors={errors.medida}
                        labelWidth="w-80"
                    />

                    {errors.medida && Array.isArray(errors.medida) && renderErrorMessages(errors.medida)}

                    <div className='flex mb-3 w-full'>
                        <label htmlFor='estado_herramienta' className='w-80 flex items-center font-normal text-[#454545]'>Estado de herr.: </label>
                        <AsyncSelect
                            cacheOptions
                            defaultOptions={estadoHerramientaOptions}
                            loadOptions={loadEstadoHerramientaOptions}
                            className={`w-full`}
                            styles={getColourStyles(!!errors.estado_herramienta)}
                            id="estado_herramienta"
                            value={estadoHerramienta}
                            onChange={(option, id) => { setEstadoHerramienta(option); console.log(option, id); }}
                            isClearable={true}
                            placeholder="Seleccionar estado..."
                            noOptionsMessage={() => "No se encontraron resultados"}
                            loadingMessage={() => "Cargando..."}
                            menuPortalTarget={document.querySelector('body')}
                        />
                    </div>

                    {errors.estado_herramienta && Array.isArray(errors.estado_herramienta) && renderErrorMessages(errors.estado_herramienta)}

                    <div className='flex justify-end flex-wrap mb-3 mt-6'>
                        <button
                            disabled={loadingSubmit}
                            className={`flex items-center justify-center py-2 font-semibold 
                            shadow-md rounded-md w-44
                            text-white bg-[#EB8927]
                            me-3
                            max-[439px]:me-0
                            max-[469px]:m-0
                            ${loadingSubmit ? 'cursor-not-allowed opacity-50' : ''}`}

                            onClick={addNewHerramienta}
                            type="submit">

                            {loadingSubmit ?
                                <div className='flex items-center justify-center w-44'>
                                    <RotatingLines
                                        strokeColor="white"
                                        strokeWidth='3'
                                        width='22'
                                        radius="5"
                                        ariaLabel="lines-loading"
                                    />
                                </div>
                                : <>
                                    <FaPlus /> Guardar Herramienta
                                </>
                            }

                        </button>

                        <button
                            className='py-2 px-4 font-semibold shadow-md rounded-lg w-40
                            max-[426px]:mt-3
                            bg-white text-gray-500'
                            onClick={cerrarModal}
                        >
                            Cerrar
                        </button>
                    </div>

                </form>
            </div>
        </Modal >
    )
}
